import { AxiosResponse } from 'axios';
import { Action, Dispatch } from 'redux';
import { AxiosClientFactory } from '../helpers/AxiosClientFactory';
import { Reminder } from '../models/Reminder';
import { UpdatedReminder } from '../models/UpdatedReminder';
import { registerSignalRActionCreator } from './SignalRActionCreator';

export const HANDLE_RECEIVED_REMINDER_UPDATE = 'HANDLE_RECEIVED_REMINDER_UPDATE';
export const TURN_OFF_REMINDER = 'TURN_OFF_REMINDER';
export const CHANGE_REMINDER_DATE = 'CHANGE_REMINDER_DATE';
export const GET_REMINDERS = 'GET_REMINDERS';

registerSignalRActionCreator('UpdateFollowUpReminders',
    (updatedReminder: UpdatedReminder): HandleReceivedReminderUpdate => ({ type: HANDLE_RECEIVED_REMINDER_UPDATE, updatedReminder }));

interface TurnOffReminder extends Action<typeof TURN_OFF_REMINDER> {
    personId: number;
    applicationId: number;
}

interface ChangeReminderDate extends Action<typeof CHANGE_REMINDER_DATE> {
    personId: number;
    applicationId: number;
    followUpDate: Date;
}

interface HandleReceivedReminderUpdate extends Action<typeof HANDLE_RECEIVED_REMINDER_UPDATE> {
    updatedReminder: UpdatedReminder;
}

interface GetReminders extends Action<typeof GET_REMINDERS> {
    reminders: Reminder[];
}
export type ReminderChange = TurnOffReminder | ChangeReminderDate | HandleReceivedReminderUpdate | GetReminders;

export const RemindersActionCreator = {
    turnOffReminder: (personId: number, applicationId: number) => {
        return (dispatch: Dispatch<TurnOffReminder>): void => {
            dispatch({ type: TURN_OFF_REMINDER, personId, applicationId });
        };
    },
    changeReminderDate: (personId: number, applicationId: number, followUpDate: Date) => {
        return (dispatch: Dispatch<ChangeReminderDate>): void => {
            dispatch({ type: CHANGE_REMINDER_DATE, personId, applicationId, followUpDate });
        };
    },
    getReminders: () => {
        return (dispatch: Dispatch<GetReminders>): void => {
            AxiosClientFactory
                .getInstance()
                .get<Reminder[]>(`/api/me/reminders`)
                .then((response: AxiosResponse<Reminder[]>) => {
                    dispatch({ type: GET_REMINDERS, reminders: response.data });
                });
        };
    }
};
