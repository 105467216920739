import { JobStatus } from '../../applications/applicant/models/JobStatus';

export const getJobStatuses = (countryId: number) => {
    const statuses = [
        JobStatus.Deputy,
        JobStatus.Domestic,
        JobStatus.Incapable,
        JobStatus.PrivateSector,
        JobStatus.PublicSector,
        JobStatus.Rehabilitation,
        JobStatus.Retired,
        JobStatus.SelfEmployed,
        JobStatus.Student,
        JobStatus.Unemployed
    ];

    if (countryId === 3) {
        statuses.push(JobStatus.FixedTime);
    }

    return statuses;
};
