import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';
import { ConsumerLoanDetails } from '../models/ConsumerLoanDetails';
import { ConsumerLoanApplicant } from '../models/ConsumerLoanApplicant';
import { Redemption } from '../models/Redemption';

export interface UpdateConsumerLoanCommand {
    personId: number;
    applicationId: number;
    details: ConsumerLoanDetails;
    applicant: ConsumerLoanApplicant;
    coApplicant?: ConsumerLoanApplicant | null;
    redemptions: Redemption[];
}

export const putConsumerLoan = async (consumerLoan: UpdateConsumerLoanCommand): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .put('api/consumerloans', consumerLoan);

    return new CommandResult(result);
};
