import React, { FunctionComponent } from 'react';
import AdministrationLayout from './AdministrationLayout';
import { SetApplicationTitleActionProps } from '../../common/interfaces/SetApplicationTitleActionProps';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { SetTitleActionCreator } from '../../common/actions/SetTitleActionCreator';

const StatusesView: FunctionComponent<SetApplicationTitleActionProps> = (props) => {
    props.setTitle('Statuses');

    return (
        <AdministrationLayout>
            <div />
        </AdministrationLayout>
    );
};

const mapStateToProps = (state: any) => ({ ...state.settingsActionsReducer });

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({ ...SetTitleActionCreator }, dispatch);

export default connect<{}, SetApplicationTitleActionProps, {}, any>(mapStateToProps, mapActionCreatorsToProps)(StatusesView);
