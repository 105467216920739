import { FunctionComponent, ReactNode } from 'react';
import React from 'react';
import './boolean-toggle-button-input-field.css';
import { Translate } from 'react-localize-redux';

interface BooleanToggleButtonProps {
    description?: string | ReactNode;
    descriptionKey?: string;
    value: boolean;
    onToggle: (value: boolean) => void;
}

export const BooleanToggleButtonInputField: FunctionComponent<BooleanToggleButtonProps> = (props) => {
    const onClick = () => props.onToggle(!props.value);
    return (
        <div className="toggle-button">
            {renderDescription(props)}
            <label className="switch">
                <input type="checkbox" checked={props.value} onChange={onClick} />
                <span className="slider" />
            </label>
        </div>
    );
};

const renderDescription = (props: BooleanToggleButtonProps) => {
    if (props.description !== undefined) {
        return typeof (props.description) === 'string' ? (<span>{props.description}</span>) : props.description;
    }

    if (props.descriptionKey !== undefined) {
        return <Translate id={props.descriptionKey} />;
    }

    return <React.Fragment />;
};
