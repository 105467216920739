import {AxiosClientFactory} from '../../../common/helpers/AxiosClientFactory';
import {CommandResult} from '../../../common/helpers/CommandResult';

export const revokeMarketingConsents = async (email?: string, mobileNumber?: string): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .post(`/api/MarketingConsents/withdraw`, {email, mobileNumber});

    return new CommandResult(result);
};
