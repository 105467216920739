import React, {FunctionComponent, ReactNode} from 'react';
import {Placement} from 'react-bootstrap/esm/types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BootstrapTooltip from 'react-bootstrap/Tooltip';
import PropTypes from 'prop-types';

interface TooltipProps {
    content?: string | ReactNode | ReactNode[] | null;
    id?: string;
    placement?: Placement;
    children: PropTypes.ReactElementLike;
}

const Tooltip: FunctionComponent<TooltipProps> = (props) => {
    if (!props.content) {
        return props.children;
    }
    return (
        <OverlayTrigger
            placement={props.placement || 'top'}
            overlay={<BootstrapTooltip id={props.id || Math.random().toString()}>{props.content}</BootstrapTooltip>}
        >
            {props.children}
        </OverlayTrigger>
    );
};

export default Tooltip;
