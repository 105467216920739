import {
    ToastMessageAction,
    SHOW_TOAST_MESSAGE,
    HIDE_TOAST_MESSAGE
} from '../actions/ToastMessagesActionCreator';
import { ToastMessage, ToastMessageType } from '../models/ToastMessage';

export const toastMessageActionsReducer = (state: any, action: ToastMessageAction): any => {
    state = state || {};
    state.toastMessages = state.toastMessages || [];

    switch (action.type) {
        case SHOW_TOAST_MESSAGE:
            const toast = state.toastMessages.find((t: ToastMessage) => t.id === action.toast.id);

            if (toast) {
                return {
                    ...state,
                    toastMessages: state.toastMessages.map((t: ToastMessageType) => t === toast ? action.toast : t)
                };
            }

            return {
                ...state,
                toastMessages: [...state.toastMessages, action.toast ]
            };
        case HIDE_TOAST_MESSAGE:
            return {
                ...state,
                toastMessages: state.toastMessages.filter((t: ToastMessage) => t.id !== action.id)
            };
        default:
            return state;
    }
};
