import { Role } from './Role';

export enum MenuItemType {
    ExternalLink = 'ExternalLink',
    RealEstateInformationPage = 'RealEstateInformationPage'
}

export interface MenuItem {
    label: string;
    roles: Role[];
    type: MenuItemType;
    url?: string;
}
