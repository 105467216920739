import React, {FunctionComponent, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {LocalizeContextProps} from "react-localize-redux";
import {createRoot} from "react-dom/client";

interface ExternalChangeModalProps extends LocalizeContextProps{
    title?: string; // or translation key
    message?: string; // or translation key
    onRefresh?: () => void;
}
const ExternalChangeModal: FunctionComponent<ExternalChangeModalProps> = (props) => {
    const [show, setShow] = useState<boolean>(true);
    
    const onRefreshClick = () => {
        setShow(false);
        if (props.onRefresh) {
            props.onRefresh();
        } else {
            window.location.reload();
        }
    }
    
    const onCloseClick = () => {
        document.getElementsByClassName('main-content')[0].classList.add('readonly');
        setShow(false);
    }
    
    return (
        <Modal show={show} centered={true} onHide={onCloseClick}>
            <Modal.Header>
                <Modal.Title>
                    {props.translate(props.title || 'EXTERNAL_CHANGE')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.translate(props.message || 'EXTERNAL_CHANGE_DESCRIPTION')}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onRefreshClick}>
                    {props.translate('REFRESH_THE_DATA')}
                </Button>
                <Button variant="secondary" onClick={onCloseClick}>
                    {props.translate('VIEW_IN_READONLY')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
};
export const showExternalChangeModal = (props: ExternalChangeModalProps) => {
    const portalContainer = createRoot(document.createElement('div'));
    portalContainer.render(<ExternalChangeModal {...props}/>);
}