import React, {useState, useRef, useEffect} from 'react';

export interface ValidationProps {
    required?: string;
    errors?: string[];
    errorCodes?: string[];
    value: any;
    allowZero?: boolean;
    overrideInternalErrors?: (keyof Omit<ValidityState, 'valid'>)[];
}

export function useValidation(props: ValidationProps) {
    const [errorCode, setErrorCode] = useState<string | undefined>();
    const [internalError, setInternalError] = useState<string | undefined>();
    const touched = useRef(false);
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (touched.current) {
            validate();
        }
    }, [props.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        validate();
    }, [props.required, props.errors, props.errors?.length]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const validate = () => {
        if (ref.current) {
            const newErrorCode = getErrorCode(props, props.value, touched.current);
            const newInternalError = getInternalError(ref.current);

            if (newErrorCode !== errorCode) {
                setErrorCode(newErrorCode);
                ref.current.setCustomValidity(newErrorCode || '');
            }
            if (!isValid(ref.current) && !isCustomValidation(ref.current, props) && newInternalError !== internalError) {
                setInternalError(newInternalError);
            } else {
                setInternalError(undefined);
            }
        }
    }
    
    const onTouched = () => {
        touched.current = true;
    };

    return [errorCode, internalError, ref, onTouched] as [string | undefined, string | undefined, React.RefObject<HTMLInputElement>, () => void];
}

const isValid = (input: HTMLInputElement) => input.validity.valid;

const isCustomValidation = (input: HTMLInputElement, props: ValidationProps) => {
    if (props.overrideInternalErrors) {
        const internalValidationErrors: string[] = [];
        for(let state in input.validity) {
            if (input.validity[state]) {
                internalValidationErrors.push(state);
            }
        }
        return internalValidationErrors.some(error => props.overrideInternalErrors!.includes(error as keyof Omit<ValidityState, 'valid'>));
    }
    return false;
}

function getErrorCode(props: ValidationProps, value: any, touched: boolean) {
    if (props.required || props.errorCodes) {
        return ((!value && value !== false && (!props.allowZero || value !== 0)) && touched && props.required)
            || (props.errors && props.errors.find((e) => (e === props.required && !value && (!props.allowZero || value !== 0))
                || (props.errorCodes && props.errorCodes.includes(e))));
    }

    return undefined;
}

function getInternalError(input: HTMLInputElement) {
    return input.validationMessage;
}
