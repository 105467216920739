import {
    CHANGE_REMINDER_DATE,
    HANDLE_RECEIVED_REMINDER_UPDATE,
    TURN_OFF_REMINDER,
    ReminderChange,
    GET_REMINDERS
} from '../actions/RemindersActionCreator';
import { putFollowUpDate } from '../../applications/applicant/api/putFollowUpDate';
import { putFollowUpReminder } from '../../applications/applicant/api/putFollowUpReminder';
import { RemindersState } from '../interfaces/RemindersProps';
import { Reminder } from '../models/Reminder';
import { UpdatedReminder } from '../models/UpdatedReminder';

export function reminderChangeActionReducer(state: RemindersState | undefined, action: ReminderChange) {
    state = state || {
        reminders: []
    };

    switch (action.type) {
        case HANDLE_RECEIVED_REMINDER_UPDATE:
            let reminders = state.reminders.filter((r) => (r.applicationId !== action.updatedReminder.applicationId));
            if (action.updatedReminder.showReminder) {
                reminders = [...reminders, createReminder(action.updatedReminder)];
            }

            return {
                ...state,
                reminders
            };
        case CHANGE_REMINDER_DATE:
            putFollowUpDate(action.personId, action.applicationId, action.followUpDate);
            break;
        case TURN_OFF_REMINDER:
            putFollowUpReminder(action.personId, action.applicationId, false);
            break;
        case GET_REMINDERS:
            return {
                ...state,
                reminders: action.reminders
            };
        default:
            break;
    }

    return state;
}

function createReminder(updatedReminder: UpdatedReminder): Reminder {
    return {
        applicationId: updatedReminder.applicationId,
        personId: updatedReminder.personId,
        firstName: updatedReminder.firstName,
        lastName: updatedReminder.lastName,
        reminderDate: updatedReminder.reminderDate
    };
}
