import React, { FunctionComponent, MouseEventHandler } from 'react';
import { Translate } from 'react-localize-redux';
import { getLoanStatusTranslateKey } from '../../../common/helpers/loanStatusFunctions';
import { LoanStatus } from '../../../common/models/LoanStatus';
import { Applicant } from '../models/Applicant';
import DateTimeFormatter from '../../../common/components/DateTimeFormatter';
import { appendCurrencySymbolForCountryId } from '../../../common/helpers/currencySymbolFunctions';
import { loanStatusToColor } from '../../../common/helpers/loanStatusToColor';
import getProgressCompletionStyling from '../../applicant/helpers/getProgressCompletionStyling';
import { ApplicantsTableStateProps } from '../../../common/interfaces/ApplicantsTableStateProps';
import { connect } from 'react-redux';
import { faBell, faHome, faQuestionCircle, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import ApplicantTooltip from './ApplicantTooltip';
import classNames from "classnames";
import {Team} from "../../applicant/models/Team";
import Tooltip from "../../../common/components/Tooltip";
import TableRows from "../../../common/components/table/TableRows";
import {UserProps} from "../../../common/interfaces/UserProps";
import {isLimitedCaseWorker} from "../../../common/helpers/limitedCaseWorkerLogic";

interface ApplicantsTableRowsProps {
    isLoading: boolean;
    applicants: Applicant[];
    teams: Team[];
    onClick(applicantId: number, applicationId: number, newTab: boolean): void;
}

const ApplicantsTableRows: FunctionComponent<ApplicantsTableRowsProps & ApplicantsTableStateProps & UserProps> = (props) => {

    const renderApplicantProducts = (applicant: Applicant) => {
        const getTranslationKeyForProduct = (product: number): string => {
            switch (product) {
                case 0: return 'PRODUCTS.CONSUMER_LOAN';
                case 1: return 'PRODUCTS.CREDIT_CARD';
                case 2: return 'PRODUCTS.MORTGAGE';
                case null: return '';
                default: throw new Error('Error mapping product translation: ' + product);
            }
        };

        if (applicant.product === undefined) {
            return (<span />);
        }

        return (
            <span className="p-0">
                <Translate id={getTranslationKeyForProduct(applicant.product)} />
            </span>
        );
    };

    const renderLoanStatus = (loanStatus: LoanStatus, applicantFormProgress: number | null) => {
        const statusClassName = `status${loanStatusToColor(loanStatus, ' status-', applicantFormProgress)}`;

        return (
            <span className={statusClassName}>
                <Translate id={getLoanStatusTranslateKey(loanStatus)} />
            </span>
        );
    };

    const renderApplicationFormProgress = (value: number | null) => {
        if (value !== null) {
            return (<span style={getProgressCompletionStyling(value)}>{Math.min(value, 100)}%</span>);
        }

        return null;
    };

    const renderApplicationFormProgressColumn = (value: number | null) =>
        (props.tableState.loanStatus === 1 ? (<td>{renderApplicationFormProgress(value)}</td>) : null);

    const renderApplicantRow = (applicant: Applicant, props: ApplicantsTableRowsProps & UserProps) => {
        const clickHandler = () => props.onClick(applicant.id, applicant.applicationId, false);
        const mouseDownHandler: MouseEventHandler<HTMLTableRowElement> = (e) => {
            if (e.button === 1) {
                props.onClick(applicant.id, applicant.applicationId, true);
                e.preventDefault();
            }
        };
        const numericStyle = { textAlign: 'right', paddingRight: '55px', whiteSpace: 'nowrap' } as React.CSSProperties;
        const reminderIcon = <FontAwesomeIcon style={{ marginRight: '5px', color: '#707070', opacity: applicant.followUpReminder ? 1 : 0 }} icon={faBell} />;
        const propertyIcon = <FontAwesomeIcon style={{ marginLeft: '5px', color: '#707070', opacity: applicant.hasProperty ? 1 : 0 }} icon={faHome} />;

        return (
            <tr
                onClick={clickHandler}
                onMouseDown={mouseDownHandler}
                key={`applicant-${applicant.id}/${applicant.applicationId}`}
                className={classNames('cursor-pointer', { 'applicant-has-debt-collections': (applicant.numberOfDebtCollections || 0) > 0})  }
            >
                <td className="uppercase" style={{display: 'flex', alignItems: 'center'}}>
                    {!isLimitedCaseWorker(props) && renderTeamName(applicant, props.teams)}
                    {renderNameWithOverlayTrigger(applicant)}
                </td>
                {renderApplicationFormProgressColumn(applicant.applicationFormProgress)}
                <td><DateTimeFormatter date={applicant.submittedDate} /></td>
                <td>{reminderIcon}<DateTimeFormatter date={applicant.followUpDate} /></td>
                <td><DateTimeFormatter date={applicant.paymentDate} /></td>
                <td style={numericStyle}>{appendCurrencySymbolForCountryId(applicant.loanAmount, applicant.countryId)}{propertyIcon}</td>
                <td style={numericStyle}>{appendCurrencySymbolForCountryId(applicant.grantedAmount, applicant.countryId)}</td>
                <td>{renderApplicantProducts(applicant)}</td>
                <td>{applicant.advertisementSite}</td>
                <td>{renderLoanStatus(applicant.loanStatus, applicant.applicationFormProgress)}</td>
            </tr>
        );
    };

    return (
        <TableRows rows={props.applicants} renderRow={applicant => renderApplicantRow(applicant, props)} isLoading={props.isLoading}/>
    );
};

const renderNameWithOverlayTrigger = (applicant: Applicant) =>
    (
        <OverlayTrigger
            delay={{ show: 500, hide: 0 }}
            placement="auto"
            overlay={
                <Popover id="applicant-tooltip" className="popover applicant-tooltip">
                    <ApplicantTooltip
                        {...applicant}
                        personId={applicant.id}
                    />
                </Popover>
            }
        >
            <span>{applicant.firstName} {applicant.lastName}{renderCoApplicantIcon(applicant)}</span>
        </OverlayTrigger>
    );

const renderCoApplicantIcon = (applicant: Applicant) => applicant.hasCoApplicant ? <FontAwesomeIcon style={{ marginLeft: '5px', color: '#707070' }} icon={faUserFriends} /> : null;

const renderTeamName = (applicant: Applicant, teams: Team[]) => {
    if (!teams.length) {
        return null;
    }
    const team = teams.find(team => team.id === applicant.teamId);
    const teamName = team ? team.name : undefined;
    const teamNameCountry = teamName ? teamName.toLowerCase().includes('polska') ? 'PL' : 'NO' : null;
    const teamNameSrc = teamNameCountry ? `http://purecatamphetamine.github.io/country-flag-icons/3x2/${teamNameCountry}.svg` : null;
    if (!teamNameSrc) {
        const unassignedTeamStyle = {height: '12px', marginRight: '12px', minWidth: '18px', maxWidth: '18px', background: 'linear-gradient(0deg, darkgray, lightgray)'};
        return (
            <Tooltip content={<Translate id={'UNASSIGNED'} />}>
                <div style={unassignedTeamStyle}>
                    <FontAwesomeIcon style={{height: '9px', position: 'relative', top: '-6px', left: '2px'}} icon={faQuestionCircle} />
                </div>
            </Tooltip>
        );
    }
    const teamNameStyle = { display: 'inline-block', marginRight: '12px', height: '12px' } as React.CSSProperties;
    return (
        <Tooltip content={teamName}>
            <img
                style={teamNameStyle}
                alt={teamName}
                src={teamNameSrc}/>
        </Tooltip>
    )
}

const mapStateToProps = (state: any) => ({ 
    ...state.applicantsViewDataReducer,
    ...state.userActionsReducer
});

export default connect<ApplicantsTableStateProps & UserProps, {}, {}, any>(mapStateToProps)(ApplicantsTableRows);
