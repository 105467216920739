export class SortUtilities {
    public static sortByNumberProperty<T = any>(array: T[], propSelectFn: (item: T) => number): T[] {
        const sortFn = (a: T, b: T) => propSelectFn(a) - propSelectFn(b);
        return array.sort(sortFn);
    }

    public static sortDescByNumberProperty<T = any>(array: T[], propSelectFn: (item: T) => number): T[] {
        const sortFn = (a: T, b: T) => propSelectFn(b) - propSelectFn(a);
        return array.sort(sortFn);
    }

    public static sortByDateProperty<T>(array: T[], propSelectFn: (item: T) => Date): T[] {
        const sortFn = (a: T, b: T) => new Date(propSelectFn(a)).valueOf() - (new Date(propSelectFn(b)).valueOf());
        return array.sort(sortFn);
    }

    public static sortDescByDateProperty<T>(array: T[], propSelectFn: (item: T) => Date): T[] {
        const sortFn = (a: T, b: T) => new Date(propSelectFn(b)).valueOf() - (new Date(propSelectFn(a)).valueOf());
        return array.sort(sortFn);
    }

    public static sortByStringProperty<T>(array: T[], propSelectFn: (item: T) => string): T[] {
        const sortFn = (a: T, b: T) => propSelectFn(a).localeCompare(propSelectFn(b));
        return array.sort(sortFn);
    }

    public static sortDescByStringProperty<T>(array: T[], propSelectFn: (item: T) => string): T[] {
        const sortFn = (a: T, b: T) => propSelectFn(b).localeCompare(propSelectFn(a));
        return array.sort(sortFn);
    }
}
