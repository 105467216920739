import React, { ReactNode, FunctionComponent, MouseEventHandler } from 'react';
import { Translate } from 'react-localize-redux';
import { ConsumerLoanApplicant } from '../models/ConsumerLoanApplicant';
import NumberInputField, { NumberInputFieldKind } from '../../../common/components/input-fields/NumberInputField';

interface HouseholdProps {
    applicant: ConsumerLoanApplicant;
    coApplicant?: ConsumerLoanApplicant | null;
    countryId: number;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

const Household: FunctionComponent<HouseholdProps> = (props) => {
    return (
        <div className="left-column column-card" onClick={props.onClick}>
            <div className="card-heading">
                <span className="title">
                    <Translate id="HOUSEHOLD" />
                </span>
            </div>
            <div className="card-content row">
                {renderTotalYearlyIncome(props)}
                {renderTotalMonthlyIncome(props)}
                {renderMonthlyIncome(props)}
                {renderMonthlyRent(props)}
            </div>
        </div>
    );
};

function getValue(getterFn: (applicant: ConsumerLoanApplicant) => number, applicant?: ConsumerLoanApplicant | null): number {
    return (applicant === undefined || applicant === null) ? 0 : getterFn(applicant);
}

function renderTotalYearlyIncome(props: HouseholdProps): ReactNode {
    const grossYearlyIncomeGetter = (applicant: ConsumerLoanApplicant) => (applicant.grossYearlyIncome);
    const value = getValue(grossYearlyIncomeGetter, props.applicant) + getValue(grossYearlyIncomeGetter, props.coApplicant);

    return (
        <div className="card-content-item col-6" key={'card-content-item-1'}>
            <NumberInputField
                name="TOTAL_YEARLY_INCOME"
                value={value}
                descriptionKey="TOTAL_YEARLY_INCOME"
                kind={NumberInputFieldKind.Money}
                countryId={props.countryId}
            />
        </div>
    );
}

function renderTotalMonthlyIncome(props: HouseholdProps) {
    const afterTaxMonthlyIncomeGetter = (applicant: ConsumerLoanApplicant) => (applicant.afterTaxMonthlyIncome);
    const extraMonthlyIncomeGetter = (applicant: ConsumerLoanApplicant) => (applicant.extraMonthlyIncome);
    const value = getValue(afterTaxMonthlyIncomeGetter, props.applicant) + getValue(afterTaxMonthlyIncomeGetter, props.coApplicant)
        + getValue(extraMonthlyIncomeGetter, props.applicant) + getValue(extraMonthlyIncomeGetter, props.coApplicant);

    return (
        <div className="card-content-item col-6" key={'card-content-item-2'}>
            <NumberInputField
                name="TOTAL_MONTHLY_INCOME"
                value={value}
                descriptionKey="TOTAL_MONTHLY_INCOME"
                kind={NumberInputFieldKind.Money}
                countryId={props.countryId}
            />
        </div>
    );
}

function renderMonthlyIncome(props: HouseholdProps): ReactNode {
    const afterTaxMonthlyIncomeGetter = (applicant: ConsumerLoanApplicant) => (applicant.afterTaxMonthlyIncome);
    const value = getValue(afterTaxMonthlyIncomeGetter, props.applicant) + getValue(afterTaxMonthlyIncomeGetter, props.coApplicant);

    return (
        <div className="card-content-item col-6" key={'card-content-item-3'}>
            <NumberInputField
                name="INCOME_PER_MONTH"
                value={value}
                descriptionKey="INCOME_PER_MONTH"
                kind={NumberInputFieldKind.Money}
                countryId={props.countryId}
            />
        </div>
    );
}

function renderMonthlyRent(props: HouseholdProps): ReactNode {
    const dwellingMonthlyRentGetter = (applicant: ConsumerLoanApplicant) => (applicant.dwellingMonthlyRent);
    const value = getValue(dwellingMonthlyRentGetter, props.applicant) + getValue(dwellingMonthlyRentGetter, props.coApplicant);

    return (
        <div className="card-content-item col-6" key={'card-content-item-4'}>
            <NumberInputField
                name="RENT_PER_MONTH"
                value={value}
                descriptionKey="RENT_PER_MONTH"
                kind={NumberInputFieldKind.Money}
                countryId={props.countryId}
            />
        </div>
    );
}

export default Household;
