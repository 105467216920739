import React, {CSSProperties} from 'react';
import GenericDropdown from '../GenericDropdown';
import InputField, {InputFieldProps} from './InputField';
import {useValidation, ValidationProps} from '../../helpers/useValidation';
import classNames from 'classnames';

interface DropdownInputFieldProps<T> extends InputFieldProps, ValidationProps {
    name: string;
    value: string | number | null | undefined;
    keyValue?: (item: T) => string | number | null | undefined;
    displayValue?: (item: T) => string;
    dropdownId?: string;
    style?: CSSProperties;
    sortDisplayValues?: boolean;
    items: T[];
    searchable?: boolean;
    filterPlaceholder?: string;
    renderUndefinedItems?: boolean;
    onValueChanged?: (keyValue: string | number | null | undefined, name: string, item: T) => void;
}

function DropdownInputField<T, WrapperProps = any>(props: DropdownInputFieldProps<T>) {
    const [errorCode, internalError, ref, onTouched] = useValidation({ ...props, allowZero: true });

    return (
        <InputField<WrapperProps>
            className={props.className}
            afterContent={props.afterContent}
            descriptionKey={props.descriptionKey} 
            description={props.description} 
            editMode={props.editMode}
            error={internalError} 
            errorCode={errorCode}
            wrapper={props.wrapper}
            wrapperProps={props.wrapperProps}
        >
            {renderValue(props, errorCode, ref, onTouched)}
        </InputField>
    );
}

function renderValue<T>(props: DropdownInputFieldProps<T>, errorCode: string | undefined, ref: React.RefObject<HTMLInputElement>, onTouched: () => void) {
    const onSelectionChanged = (item: T, key: string | number) => {
        onTouched();
        if (props.onValueChanged) {
            props.onValueChanged(key, props.name, item);
        }
    };

    const dropdownId = props.dropdownId || `dropdown-input-field-${props.name}`;

    return (
        <React.Fragment>
            <GenericDropdown
                style={props.style}
                selectedKey={props.value}
                items={props.items}
                keyValue={props.keyValue}
                displayValue={props.displayValue}
                dropdownId={dropdownId}
                sortDisplayValues={props.sortDisplayValues}
                toggleClassName={classNames('form-control', { 'is-invalid': errorCode })}
                searchable={props.searchable}
                filterPlaceholder={props.filterPlaceholder}
                onSelectionChanged={onSelectionChanged}
                readOnly={!props.editMode || !props.onValueChanged}
                renderUndefinedItems={props.renderUndefinedItems}
            />
            <input type="text" defaultValue={(props.value || '').toString()} ref={ref} style={{ display: 'none' }} />
        </React.Fragment>
    );
}

export default DropdownInputField;
