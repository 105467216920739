import React, { FunctionComponent } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { Bank } from '../../models/Bank';
import { Transaction } from '../../models/Transaction';
import CopyToClipboardButton from '../../../../common/components/CopyToClipboardButton';
import Tooltip from '../../../../common/components/Tooltip';

interface BankRepliesBankTitleProps extends LocalizeContextProps {
    bank: Bank;
    transaction?: Transaction;
}

const BankRepliesBankTitle: FunctionComponent<BankRepliesBankTitleProps> = (props) => {
    if (!props.transaction || !props.transaction.id) {
        return <span>{props.bank.name}</span>;
    }

    const transactionIdContent = props.translate('TRANSACTION_ID_WITH_TRACKING_ID', {transactionId: props.transaction.id}).toString();
    const bankRefIdContent = props.transaction.bankInternalRefId
        ? props.translate('BANK_ID_WITH_BANK_INTERNAL_REF_ID', {bankInternalRefId: props.transaction.bankInternalRefId}).toString()
        : null;

    return (
        <CopyToClipboardButton content={renderClipboardContent(transactionIdContent, bankRefIdContent)} key={`bank-name-${props.bank.id}`}>
            <Tooltip content={renderTooltipContent(transactionIdContent, bankRefIdContent)}>
                <span>{props.bank.name}</span>
            </Tooltip>
        </CopyToClipboardButton>
    );
};

function renderTooltipContent(transactionIdContent: string, bankRefIdContent: string | null) {
    if (bankRefIdContent !== null) {
        return <React.Fragment>{transactionIdContent}<br/>{bankRefIdContent}</React.Fragment>;
    }

    return transactionIdContent;
}

function renderClipboardContent(transactionIdContent: string, bankRefIdContent: string | null) {
    if (bankRefIdContent !== null) {
        return transactionIdContent + '\n' + bankRefIdContent;
    }

    return transactionIdContent;
}

export default withLocalize(BankRepliesBankTitle);
