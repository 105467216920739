import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {FunctionComponent, useMemo, useState} from 'react';
import {ConsumerLoanDetails} from '../models/ConsumerLoanDetails';
import {LocalizeContextProps, Translate, withLocalize} from 'react-localize-redux';
import NumberInputField, {NumberInputFieldKind} from '../../../common/components/input-fields/NumberInputField';
import TextInputField from '../../../common/components/input-fields/TextInputField';
import DropdownInputField from '../../../common/components/input-fields/DropdownInputField';
import {YearTranslator} from '../../../common/components/YearTranslator';
import {MetadataProps} from '../../../common/interfaces/MetadataProps';
import {connect} from 'react-redux';
import {AdvertisementSite} from '../models/AdvertisementSite';
import DateTimeInputField, {DateTimeInputFieldKind} from '../../../common/components/input-fields/DateTimeInputField';
import {SelectableItem} from '../../../common/models/SelectableItem';
import {BooleanInputField} from '../../../common/components/input-fields/BooleanInputField';
import moment from 'moment';
import {ProductType} from '../../../common/models/ProductType';
import {SettingsProps} from '../../../common/interfaces/SettingsProps';
import {getLoanAmountLimits} from '../../../common/helpers/settingsHelpers';
import SubstatusesInputField from '../../../common/components/input-fields/SubstatusesInputField';
import InputField from '../../../common/components/input-fields/InputField';
import {UserProps} from '../../../common/interfaces/UserProps';
import {canEditAdvertisementId, isLimitedCaseWorker} from "../../../common/helpers/limitedCaseWorkerLogic";
import mapEnumToSelectableItems from "../../../common/helpers/mapEnumToSelectableItems";
import {Purpose} from "../../../common/models/Purpose";

interface EditApplicationProps {
    details: ConsumerLoanDetails;
    countryId: number;
    errors: string[];
    isComplete: boolean;
    onDetailsChanged: (details: ConsumerLoanDetails) => void;
    substatusIds: number[];
    onSubstatusesChanged: (substatusIds: number[]) => void;
}

const emptyDropdownItem: SelectableItem<number> = { id: null, name: ' ' };

const EditApplication: FunctionComponent<EditApplicationProps & UserProps & MetadataProps & SettingsProps & LocalizeContextProps> = (props) => {
    const repaymentPeriodItems = props.details.productType === ProductType.Mortgage ?
        Array.apply(null, Array(30)).map((_, i) => ({ id: (i + 1) * 12, name: <YearTranslator year={i + 1} /> })) :
        Array.apply(null, Array(15)).map((_, i) => ({ id: (i + 1) * 12, name: <YearTranslator year={i + 1} /> }));

    const onValueChanged = (value: string | number | boolean | Date | null | undefined, name: string) => props.onDetailsChanged({ ...props.details, [name]: value });
    const [advertisementSite, setAdvertisementSite] = useState(findAdvertisementSite(props.metadata.advertisementSites, props.details.advertisementId));
    const purposes = useMemo(() => mapEnumToSelectableItems(Purpose, key => key ? props.translate(`PURPOSE.${key}`).toString() : '', true, false), []); // eslint-disable-line react-hooks/exhaustive-deps
    const onAdvertisementSiteChanged = (id: string | number | null | undefined) => setAdvertisementSite(props.metadata.advertisementSites.find((as) => as.id === id));
    const filterText = props.translate('FILTER').toString();

    if (!advertisementSite && props.userData.user) {
        const as = findAdvertisementSiteById(props.metadata.advertisementSites, props.userData.user!.advertisementSiteId)
                   || findAdvertisementSite(props.metadata.advertisementSites, props.userData.user.advertisementId);
        if (as) {
            setAdvertisementSite(as);
        }
    }

    const productLimits = getLoanAmountLimits(props, props.details.productType);

    const errorCodesData = {
        productType: props.details.productType !== null ? props.translate(getProductTypeLoanAmountOutOfRangeTranslation(props.details.productType)).toString() : undefined,
        loanAmountMinimum: productLimits.Min,
        loanAmountMaximum: productLimits.Max
    };

    const nonLimitedCaseWorkerFields = () => {
        const advertisementIdInput = <DropdownInputField
            name="advertisementId"
            value={props.details.advertisementId}
            items={getAdvertisements(advertisementSite)}
            editMode={true}
            sortDisplayValues={true}
            descriptionKey="ADVERTISEMENT"
            onValueChanged={onValueChanged}
            searchable={true}
            filterPlaceholder={filterText}
        />;
        if (!isLimitedCaseWorker(props)) {
            return <>
                <DropdownInputField
                    name="sourceId"
                    value={props.details.sourceId}
                    items={props.metadata.applicationSources}
                    editMode={true}
                    sortDisplayValues={true}
                    descriptionKey="SOURCE"
                    onValueChanged={onValueChanged}
                    required="SourceIdIsRequired"
                    errors={props.errors}
                    overrideInternalErrors={['valueMissing']}
                    searchable={true}
                    filterPlaceholder={filterText}
                />
                <DropdownInputField
                    name="advertisementSiteId"
                    value={advertisementSite && advertisementSite.id}
                    items={props.metadata.advertisementSites}
                    editMode={true}
                    sortDisplayValues={true}
                    descriptionKey="ADVERTISEMENT_SITE"
                    onValueChanged={onAdvertisementSiteChanged}
                    searchable={true}
                    filterPlaceholder={filterText}
                />
                {advertisementIdInput}
            </>
        }
        if (canEditAdvertisementId(props)) {
            return advertisementIdInput;
        }
        return null;
    };

    return (
        <div className="column-card">
            <div className="card-heading">
                <span className="title"><Translate id="APPLICATION" /></span>
                <span className="options"><FontAwesomeIcon icon={faEdit} /></span>
            </div>
            <div className="card-content">
                <NumberInputField
                    name="loanAmount"
                    value={props.details.loanAmount}
                    kind={NumberInputFieldKind.Money}
                    countryId={props.countryId}
                    editMode={true}
                    descriptionKey="REQUESTED_LOAN_AMOUNT"
                    onValueChanged={onValueChanged}
                    min={productLimits.Min}
                    max={productLimits.Max}
                    required={props.isComplete ? 'LoanAmountIsRequired' : undefined}
                    errors={props.errors}
                    errorCodes={['LoanAmountTooLow', 'LoanAmountTooHigh', 'LoanAmountLowerThanSumOfRefinanceRedemptionsBalance']}
                    overrideInternalErrors={['valueMissing', 'rangeOverflow', 'rangeUnderflow']}
                    errorCodesData={errorCodesData}
                />
                {renderRepaymentPeriodIfRequired(props, repaymentPeriodItems, onValueChanged)}
                <TextInputField
                    name="bankAccount"
                    value={props.details.bankAccount}
                    editMode={true}
                    descriptionKey="BANK_ACCOUNT"
                    maxLength={30}
                    onValueChanged={onValueChanged}
                    required={props.isComplete && props.details.productType !== ProductType.CreditCard ? 'BankAccountIsRequired' : undefined}
                    errors={props.errors}
                    errorCodes={['InvalidBankAccountFormat']}
                    overrideInternalErrors={['valueMissing']}
                />
                <div className="divider" />
                {nonLimitedCaseWorkerFields()}
                <TextInputField
                    name="trackingId"
                    value={props.details.trackingId}
                    editMode={true}
                    descriptionKey="TRACKING_ID"
                    maxLength={256}
                    onValueChanged={onValueChanged}
                />
                <TextInputField
                    name="publisherId"
                    value={props.details.publisherId}
                    editMode={true}
                    descriptionKey="PUBLISHER_ID"
                    maxLength={256}
                    onValueChanged={onValueChanged}
                />
                <div className="half-sized-inputs-container">
                    <div>
                        <DateTimeInputField
                            name="followUpDate"
                            value={props.details.followUpDate}
                            descriptionKey="FOLLOW_UP_DATE"
                            editMode={true}
                            onValueChanged={onValueChanged}
                            kind={DateTimeInputFieldKind.DateWithTime}
                            timeFrom={8}
                            timeTo={20}
                        />
                    </div>
                    <div>
                        <BooleanInputField
                            name="followUpReminder"
                            value={props.details.followUpReminder}
                            descriptionKey="FOLLOW_UP_REMINDER"
                            editMode={true}
                            onValueChanged={onValueChanged}
                        />
                    </div>
                </div>
                <div>
                    {renderDisableNotification(props.details, onValueChanged)}
                </div>
                <InputField descriptionKey={'SUBSTATUSES'} editMode={true}>
                    <SubstatusesInputField
                        editMode={true}
                        substatusIds={props.substatusIds}
                        onSubstatusesChanged={props.onSubstatusesChanged}
                    />
                </InputField>
                {props.details.productType === ProductType.Mortgage &&
                    <DropdownInputField
                        descriptionKey="PURPOSE.LABEL"
                        editMode={true}
                        name="purpose"
                        onValueChanged={onValueChanged}
                        value={props.details.purpose}
                        items={purposes}
                    />
                }
                <TextInputField
                    name="customerComment"
                    value={props.details.customerComment}
                    editMode={true}
                    descriptionKey="CUSTOMER_COMMENT"
                    rows={5}
                    maxLength={2500}
                    onValueChanged={onValueChanged}
                />                
            </div>
        </div>
    );
};

function renderDisableNotification(details: ConsumerLoanDetails, onValueChanged: (value: boolean, name: string) => void) {
    if (!isApplication30DaysOld(details)) {
        return (
            <BooleanInputField
                name="disableNotifications"
                value={details.disableNotifications}
                descriptionKey="DEACTIVATED_ALERT"
                editMode={true}
                onValueChanged={onValueChanged}
            />
        );
    }

    return null;
}

function renderRepaymentPeriodIfRequired(
    props: EditApplicationProps & MetadataProps & SettingsProps & LocalizeContextProps,
    repaymentPeriodItems: Array<{ id: number, name: JSX.Element }>,
    onValueChanged: (value: string | number | boolean | Date | null | undefined, name: string) => void) {

    return props.details.productType !== ProductType.CreditCard ? (
        <DropdownInputField
            name="repaymentPeriod"
            value={props.details.repaymentPeriod}
            items={repaymentPeriodItems}
            editMode={true}
            descriptionKey="REPAYMENT_PERIOD"
            onValueChanged={onValueChanged}
            required={props.isComplete ? 'RepaymentPeriodIsRequired' : undefined}
            errors={props.errors}
            overrideInternalErrors={['valueMissing']}
        />
    ) : null;
}

function isApplication30DaysOld(details: ConsumerLoanDetails) {
    return moment(details.submittedDate).add(30, 'days').isBefore(moment());
}

function getAdvertisements(advertisementSite: AdvertisementSite | undefined) {
    if (!advertisementSite) {
        return [];
    }

    return [emptyDropdownItem].concat(advertisementSite.advertisements as SelectableItem<number>[]);
}

function findAdvertisementSite(advertisementSites: AdvertisementSite[], advertisementId: number | null | undefined) {
    if (!advertisementId || !advertisementSites) {
        return undefined;
    }

    return advertisementSites.find((as) => as.advertisements.some((a) => a.id === advertisementId));
}

function findAdvertisementSiteById(advertisementSites: AdvertisementSite[], advertisementSiteId: number | null | undefined) {
    if (!advertisementSiteId || !advertisementSites) {
        return undefined;
    }

    return advertisementSites.find((as) => as.id === advertisementSiteId);
}

function getProductTypeLoanAmountOutOfRangeTranslation(productType: ProductType): string {
    switch (productType) {
        case ProductType.ConsumerLoan: return 'ERRORS.LOAN_AMOUNT_OUT_OF_RANGE_CONSUMER_LOAN';
        case ProductType.CreditCard: return 'ERRORS.LOAN_AMOUNT_OUT_OF_RANGE_CREDIT_CARD';
        case ProductType.Mortgage: return 'ERRORS.LOAN_AMOUNT_OUT_OF_RANGE_MORTGAGE';
        default: return '';
    }
}

const mapStateToProps = (state: any) => ({
    ...state.metadataActionsReducer,
    ...state.userActionsReducer,
    ...state.settingsActionsReducer
});

export default connect<MetadataProps & SettingsProps & UserProps & LocalizeContextProps, {}, EditApplicationProps, any>(mapStateToProps)(withLocalize(EditApplication));
