import React, {FunctionComponent} from "react";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {Translate} from "react-localize-redux";

interface MarketingListsProps {
    onCreateClick?: () => void;
}
const MarketingListsMenu: FunctionComponent<MarketingListsProps> = (props) => {
    return (
        <Dropdown className="actions" drop="down" align="end">
            <Dropdown.Toggle id="application-menu" className="btn-white-bg" style={{ padding: '3px 12px' }}>
                <FontAwesomeIcon icon={faBars} />
            </Dropdown.Toggle>
            <div className="dropdown-arrow-down" />
            <Dropdown.Menu>
                <Dropdown.Item key="new-marketing-list" onClick={props.onCreateClick}>
                    <Translate id="MARKETING_LISTS_VIEW.NEW_MARKETING_LIST" />
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}
export default MarketingListsMenu;