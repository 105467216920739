import React, { FunctionComponent } from 'react';

interface CurrentPageViewProps {
    count: number;
    currentPage: number;
    pageSize: number;
    variant?: string;
}

const CurrentPageView: FunctionComponent<CurrentPageViewProps> = (props) => {
    const start = Math.min(props.currentPage * props.pageSize + 1, props.count);
    const end = Math.min((props.currentPage + 1) * props.pageSize, props.count);
    const variant = props.variant || 'dark';

    return (
        <span className={`${variant} showing-page`}>
            Showing {start}-{end} of {props.count}
        </span>
    );
};

export default CurrentPageView;
