export type ApplicationActionsAction =
    | { type: 'SET_BLACKLIST_VISIBLE' }
    | { type: 'SET_BLACKLIST_HIDDEN' }
    | { type: 'SET_GDPR_VISIBLE' }
    | { type: 'SET_GDPR_HIDDEN' }
    | { type: 'SET_REVOKE_MARKETING_CONSENTS_VISIBLE' }
    | { type: 'SET_REVOKE_MARKETING_CONSENTS_HIDDEN' };

export const setBlacklistVisible = () => ({
    type: 'SET_BLACKLIST_VISIBLE'
} as ApplicationActionsAction);

export const setBlacklistHidden = () => ({
    type: 'SET_BLACKLIST_HIDDEN'
} as ApplicationActionsAction);

export const setGdprVisible = () => ({
    type: 'SET_GDPR_VISIBLE'
} as ApplicationActionsAction);

export const setGdprHidden = () => ({
    type: 'SET_GDPR_HIDDEN'
} as ApplicationActionsAction);

export const setRevokeMarketingConsentsVisible = () => ({
    type: 'SET_REVOKE_MARKETING_CONSENTS_VISIBLE'
} as ApplicationActionsAction);

export const setRevokeMarketingConsentsHidden = () => ({
    type: 'SET_REVOKE_MARKETING_CONSENTS_HIDDEN'
} as ApplicationActionsAction);
