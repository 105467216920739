import {CampaignSummary} from "../../common/models/Campaign";
import {CampaignsFilter} from "../models/CampaignsFilter";
import {AxiosClientFactory} from "../../common/helpers/AxiosClientFactory";
import {TableState} from "../../common/models/TableState";

export const getCampaigns = (params: Partial<CampaignsFilter & TableState>): Promise<CampaignSummary[]> => {
    return AxiosClientFactory
            .getInstance()
            .get<CampaignSummary[]>('/api/campaigns', { params })
            .then((result) => result.data);
};