import {
    GET_SEARCH_RESULTS_BEGIN,
    GET_SEARCH_RESULTS_FAILURE,
    GET_SEARCH_RESULTS_SUCCESS,
    GetSearchResultsAction
} from '../actions/GlobalSearchActionCreators';

export const globalSearchActionReducer = (state: any, action: GetSearchResultsAction): any => {
    state = state || {
        searchResultData: {
            applicants: [],
            applications: []
        }
    };
    switch (action.type) {
        case GET_SEARCH_RESULTS_BEGIN:
            return {
                ...state,
                searchResultData: {
                    applicants: [],
                    applications: [],
                    error: undefined
                }
            };
        case GET_SEARCH_RESULTS_SUCCESS:
            return {
                ...state,
                searchResultData: {
                    applicants: action.applicants,
                    applications: action.applications,
                    error: undefined
                }
            };
        case GET_SEARCH_RESULTS_FAILURE:
            return {
                ...state,
                searchResultData: {
                    applicants: [],
                    applications: [],
                    error: action.error
                }
            };
        default:
            return state;
    }
};
