import {AxiosClientFactory} from '../../common/helpers/AxiosClientFactory';
import {CampaignsFilter} from "../models/CampaignsFilter";
import {TableState} from "../../common/models/TableState";

export const countCampaigns = (params: Partial<CampaignsFilter & TableState>): Promise<number> => {
    return AxiosClientFactory
        .getInstance()
        .get<number>('/api/campaigns/count', {params})
        .then((result) => result.data);
};
