import {
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faBullhorn,
    faCheck,
    faCheckCircle,
    faCog,
    faEnvelope,
    faEnvelopeOpen,
    faExclamationCircle,
    faGears,
    faHourglassHalf,
    faMobileAlt,
    faPaperclip,
    faTimes,
    faUpload
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {FunctionComponent, ReactNode, RefObject, useEffect, useRef, useState} from 'react';
import DateTimeFormatter from '../../../../common/components/DateTimeFormatter';
import {UserImage} from '../../../../common/components/UserImage';
import {
    ConsumerLoanCommentLog,
    ConsumerLoanCustomerCommunicationLog,
    ConsumerLoanEventLog,
    ConsumerLoanHistoryLog,
    ConsumerLoanLog,
    ConsumerLoanLogDocument
} from '../../models/ConsumerLoanLog';
import {ConsumerLoanLogEventType} from '../../models/ConsumerLoanLogEventType';
import {ConsumerLoanLogMessageType} from '../../models/ConsumerLoanLogMessageType';
import {ConsumerLoanLogMessagingEventType} from '../../models/ConsumerLoanLogMessagingEventType';
import {ConsumerLoanLogType} from '../../models/ConsumerLoanLogType';
import {Translate} from 'react-localize-redux';
import Tooltip from '../../../../common/components/Tooltip';
import {getEnumTranslationKey, getTranslationKey} from '../../../../common/helpers/getTranslationKey';
import FileLink from '../../../../common/components/FileLink';
import {getDocument} from '../../api/getDocument';
import ChangeItem from '../ChangeItem';
import {ConsumerLoanLogMessageDeliveryStatus} from '../../models/ConsumerLoanLogMessageDeliveryStatus';
import {User} from '../../models/User';
import CopyToClipboardButton from '../../../../common/components/CopyToClipboardButton';
import {ConsumerLoanLogMessageEngagementStatus} from "../../models/ConsumerLoanLogMessageEngagementStatus";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import PreviewEmailPopover from "../../../../common/components/PreviewEmailPopover";
import classNames from "classnames";
import {beautifyLinks} from "../../../../common/helpers/beautifyLinks";

interface LogsAndCommentsRowProps {
    log: ConsumerLoanLog;
    onDoubleClick?: () => void;
}

const LogsAndCommentsRow: FunctionComponent<LogsAndCommentsRowProps> = (props) => {
    const limitedTextRef = useRef<HTMLDivElement>(null);
    const [expandable, setExpandable] = useState<boolean>(false);
    
    useEffect(() => {
        const scrollHeight = (limitedTextRef?.current?.firstChild as HTMLSpanElement)?.scrollHeight || 0;
        const clientHeight = (limitedTextRef?.current?.firstChild as HTMLSpanElement)?.clientHeight || 0;
        if (scrollHeight > clientHeight) {
            setExpandable(true);
        }
    }, [props.log]);
    
    return (
        <tr 
            className={expandable ? 'expandable' : ''}
            onDoubleClick={props.onDoubleClick} 
        >
            {renderLogContent(props.log, limitedTextRef)}
            <td>
                <DateTimeFormatter date={props.log.timestamp} />
            </td>
        </tr>
    );
};

const renderLogContent = (log: ConsumerLoanLog, limitedTextRef?: RefObject<HTMLDivElement>) => {
    switch (log.logType) {
        case ConsumerLoanLogType.Comment:
            return renderCommentLogContent(log as ConsumerLoanCommentLog, limitedTextRef);
        case ConsumerLoanLogType.Event:
            return renderEventLogContent(log as ConsumerLoanEventLog);
        case ConsumerLoanLogType.Change:
            return renderChangeLogContent(log as ConsumerLoanHistoryLog);
        case ConsumerLoanLogType.CustomerCommunication:
            return renderCustomerCommunicationLogContent(log as ConsumerLoanCustomerCommunicationLog, limitedTextRef);
    }
};

const renderCommentLogContent = (log: ConsumerLoanCommentLog, limitedTextRef?: RefObject<HTMLDivElement>) => {
    return log.user !== null ? (
        <>
            <td>
                <Tooltip id={`tooltip-${log.id}`} content={getUsername(log.user)}>
                    <div className="comment-icon">{getUserImage(log.user.username, log.documents)}</div>
                </Tooltip>
            </td>
            <td>
                {renderMessage(log.message, limitedTextRef)}
                {renderDocumentLink(log)}
            </td>
        </>
    ) : null;
};

const getUsername = (user: User) =>
    user.username.toLocaleLowerCase() === 'customer'
        ? <Translate id="CUSTOMER" />
        : user.displayName;

const getUserImage = (username: string, documents: ConsumerLoanLogDocument[]) =>
    documents && documents.length > 0 && username.toLocaleLowerCase() === 'customer'
        ? <FontAwesomeIcon icon={faUpload} />
        : <UserImage email={username} size={35} />;

const renderLimitedText = (text: string | ReactNode, ref?: RefObject<HTMLDivElement>, afterButton?: ReactNode, onShowClick?: () => void) => {
    return (
        <div ref={ref} className="limited-text">
            {typeof text === 'string' ?
                <span>{text}</span> :
                text
            }
            <div className="button-wrapper">
                <Button
                    variant="link"
                    className="show-more"
                    onClick={onShowClick || (() => ref?.current?.classList.toggle('show'))}
                >
                    <Translate id={'SHOW_MORE'}/>
                </Button>
                <Button
                    variant="link"
                    className="show-less"
                    onClick={onShowClick || (() => ref?.current?.classList.toggle('show'))}
                >
                    <Translate id={'SHOW_LESS'}/>
                </Button>
                {afterButton}
            </div>
        </div>
    )
}

const renderMessage = (message: string, limitedTextRef?: RefObject<HTMLDivElement>) => message !== '' ? (
    <div className="comment-box">
        {renderLimitedText(message, limitedTextRef)}
    </div>
) : null;

const renderDocumentLink = (log: ConsumerLoanCommentLog) => {
    const mapDocument = (doc: ConsumerLoanLogDocument) => {
        const getDoc = () => getDocument(doc.id as number);
        return (
            <div key={`logs-and-comments-document-link-${doc.id}`}>
                <FileLink
                    getFile={getDoc}
                    className="logs-and-comments-document-link"
                >
                    <FontAwesomeIcon icon={faPaperclip} /> {doc.fileName}
                </FileLink>
            </div>
        );
    };

    if (log.documents.length > 0) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                {log.documents.map(mapDocument)}
            </div>
        );
    }
};

const renderEventLogContent = (log: ConsumerLoanEventLog) => {
    return (
        <>
            <td>
                <div className="event-log-icon">
                    <Tooltip id={`tooltip-${log.id}`} content={<Translate id="EVENT" />}>
                        <FontAwesomeIcon icon={faGears} />
                    </Tooltip>
                </div>
            </td>
            <td>
                {renderWarningIcon(log.eventType === ConsumerLoanLogEventType.TrackingFail)}
                <CopyToClipboardButton content={log.message}>
                    <Tooltip id={`message-tooltip-${log.id}`} content={getEventLogMessage(log)}>
                        <span><Translate id={getEnumTranslationKey(ConsumerLoanLogEventType, log.eventType, 'EVENT_TYPE')} /></span>
                    </Tooltip>
                </CopyToClipboardButton>
            </td>
        </>
    );
};

const getEventLogMessage = (log: ConsumerLoanEventLog) =>
    log.eventType === ConsumerLoanLogEventType.TrackingBlocked ? <Translate id={getTranslationKey(log.message, 'TRACKING_BLOCKED_MESSAGES')}/> : log.message;

const renderWarningIcon = (show: boolean) => {
    if (show) {
        return <FontAwesomeIcon icon={faExclamationCircle} className="logs-and-comments-warning-icon" />;
    }
};

const renderChangeLogContent = (log: ConsumerLoanHistoryLog) => {
    return log.user !== null ? <ChangeItem id={log.id} propertyName={log.propertyName} oldValue={log.oldValue} newValue={log.newValue} user={log.user} /> : null;
};

const renderCustomerCommunicationLogContent = (log: ConsumerLoanCustomerCommunicationLog, limitedTextRef?: RefObject<HTMLDivElement>) => {
    const unreadMessage = !log.read && log.eventType === ConsumerLoanLogMessagingEventType.CommunicationFromClient;
    const message = (
        <span className={classNames({
            'message': true,
            'unread-message': unreadMessage,
            'email': log.messageType === ConsumerLoanLogMessageType.Email,
            'sms': log.messageType === ConsumerLoanLogMessageType.SMS
        })}>
            {beautifyLinks(log.responseMessage)}
        </span>
    );
    const customerCommunicationIcons = renderCustomerCommunicationIcons(log);
    return (
        <>
            <td>
                {renderCustomerCommunicationTypeIcon(log)}
            </td>
            <td className="messaging-result">
                {log.messageType === ConsumerLoanLogMessageType.Email ?
                    <OverlayTrigger
                        placement="top"
                        trigger="click"
                        rootClose={true}
                        overlay={<Popover style={{maxWidth: 'fit-content', overflow: 'hidden'}} id={log.id.toString()}>
                            <PreviewEmailPopover id={log.id}/>
                        </Popover>}
                    >
                        {renderLimitedText(message, limitedTextRef, customerCommunicationIcons, () => {/*noop*/})}
                    </OverlayTrigger>
                    : renderLimitedText(message, limitedTextRef, customerCommunicationIcons)
                }
            </td>
        </>
    );
}

const renderCustomerCommunicationTypeIcon = (log: ConsumerLoanCustomerCommunicationLog) => {
    let icon;
    let additionalIcon;
    let additionalIconColor;
    if (log.messageType === ConsumerLoanLogMessageType.SMS) {
        icon = faMobileAlt;
        additionalIcon = log.eventType === ConsumerLoanLogMessagingEventType.CommunicationFromClient ? faArrowAltCircleLeft : faArrowAltCircleRight;
        additionalIconColor = log.eventType === ConsumerLoanLogMessagingEventType.CommunicationFromClient ? '#42D253' : '#559AF4';
    } else {
        icon = log.engagementStatus === ConsumerLoanLogMessageEngagementStatus.Open 
                || log.engagementStatus === ConsumerLoanLogMessageEngagementStatus.Click ? faEnvelopeOpen : faEnvelope;
        additionalIcon = log.engagementStatus === ConsumerLoanLogMessageEngagementStatus.Click ? faCheckCircle : null;
        additionalIconColor = additionalIcon ? '#559AF4' : null;
    }
    return  <Tooltip id={`${log.id}-type-icon`} content={renderCustomerCommunicationDetails(log)}>
        <div className="customer-communication-type-icon">
            <div style={{display: 'flex', alignItems: 'end'}}>
                <FontAwesomeIcon icon={icon} />
                {additionalIcon &&
                    <FontAwesomeIcon
                        style={{
                            color: additionalIconColor,
                            fontSize: '12px',
                            marginLeft: '-3px'
                        }}
                        icon={additionalIcon}
                    />
                }
            </div>
        </div>
    </Tooltip>
}

const renderCustomerCommunicationDetails = (log: ConsumerLoanCustomerCommunicationLog) => {
    let details: JSX.Element;
    const messageTypeKey = log.messageType === ConsumerLoanLogMessageType.Email ? 'EMAIL' : 'SMS';
    switch (log.eventType) {
        case ConsumerLoanLogMessagingEventType.CampaignMessage:
            details = <>
                <Translate id={`${messageTypeKey}_DISPATCH_BY_CAMPAIGN`}/>
                <br/>
                {log.campaignName}
            </>;
            break;
        case ConsumerLoanLogMessagingEventType.CommunicationFromClient:
            details = <>
                <Translate id={`MESSAGING_EVENT_TYPE.COMMUNICATION_FROM_CLIENT.${messageTypeKey}`}/>
            </>;
            break;
        case ConsumerLoanLogMessagingEventType.UserMessage:
            details = <>
                <Translate 
                    id={`${messageTypeKey}_DISPATCH_BY_USER`}
                    data={{username: log.user ? log.user.displayName : 'user'}}
                />
            </>;
            break;
        default:
            details = <>
                <Translate id={`${messageTypeKey}_DISPATCH_BY_SYSTEM`}/>
                <br/>
                <Translate id={getEnumTranslationKey(ConsumerLoanLogMessagingEventType, log.eventType, 'MESSAGING_EVENT_TYPE')}/>
            </>;
            break;
    }
    if (log.engagementStatus === ConsumerLoanLogMessageEngagementStatus.Open) {
        return <>
            {details}
            <br/>
            <Translate id="EMAIL_OPENED"/>
        </>
    }
    if (log.engagementStatus === ConsumerLoanLogMessageEngagementStatus.Click) {
        return <>
            {details}
            <br/>
            <Translate id="EMAIL_OPENED"/>
            <br/>
            <Translate id="LINK_CLICKED"/>
        </>
    }
    return details;
}

const renderCustomerCommunicationIcons = (log: ConsumerLoanCustomerCommunicationLog) => {
    return (
        <div className="customer-communication-icons">
            {renderCustomerCommunicationSourceIcon(log)}
            {log.eventType !== ConsumerLoanLogMessagingEventType.CommunicationFromClient ? renderMessageStatusIcon(log) : null}
        </div>
    )
}

const renderCustomerCommunicationSourceIcon = (log: ConsumerLoanCustomerCommunicationLog) => {
    let icon;
    let tooltipContent;
    switch (log.eventType) {
        case ConsumerLoanLogMessagingEventType.CampaignMessage:
            icon = faBullhorn;
            tooltipContent = log.campaignName;
            break;
        case ConsumerLoanLogMessagingEventType.CommunicationFromClient:
            icon = log.campaignName !== null ? faBullhorn : null;
            tooltipContent = log.campaignName;
            break;
        case ConsumerLoanLogMessagingEventType.UserMessage:
            break;
        default:
            icon = faCog;
            tooltipContent = <Translate id={getEnumTranslationKey(ConsumerLoanLogMessagingEventType, log.eventType, 'MESSAGING_EVENT_TYPE')}/>
            break;
    }
    if (!icon) {
        return null;
    }
    return <Tooltip id={`${log.id}-source-icon`} content={tooltipContent}>
        <FontAwesomeIcon style={{color: '#707070', fontSize: '12px', marginRight: '1px'}} icon={icon}/>
    </Tooltip>;
}

const renderMessageStatusIcon = (log: ConsumerLoanCustomerCommunicationLog) => {
    const messageTypeKey = log.messageType === ConsumerLoanLogMessageType.Email ? 'EMAIL' : 'SMS';
    let tooltipContent;
    let icon;
    let iconColor;
    switch (true) {
        case log.responseCode !== 0 || log.deliveryStatus === null || log.deliveryStatus === ConsumerLoanLogMessageDeliveryStatus.Failed:
            tooltipContent = <Translate id={`MESSAGING_EVENT_TYPE.COMMUNICATION_TO_CLIENT.${messageTypeKey}.FAILED`}/>;
            icon = faTimes;
            iconColor = '#F05B59';
            break;
        case log.deliveryStatus === ConsumerLoanLogMessageDeliveryStatus.Queued:
            tooltipContent = <Translate id={`MESSAGING_EVENT_TYPE.COMMUNICATION_TO_CLIENT.${messageTypeKey}.QUEUED`}/>
            icon = faHourglassHalf;
            iconColor = '#9EA8B2';
            break;
        case log.deliveryStatus === ConsumerLoanLogMessageDeliveryStatus.Sent:
            tooltipContent = <Translate id={`MESSAGING_EVENT_TYPE.COMMUNICATION_TO_CLIENT.${messageTypeKey}.SENT`}/>
            icon = faCheck;
            iconColor = '#9EA8B2';
            break;
        case log.deliveryStatus === ConsumerLoanLogMessageDeliveryStatus.Delivered:
            tooltipContent = <Translate id={`MESSAGING_EVENT_TYPE.COMMUNICATION_TO_CLIENT.${messageTypeKey}.DELIVERED`}/>
            icon = faCheck;
            iconColor = '#42D253';
            break;
    }
    return <Tooltip id={`${log.id}-status-icon`} content={tooltipContent}>
        <FontAwesomeIcon style={{color: iconColor, fontSize: '12px', minWidth: '12px'}} icon={icon}/>
    </Tooltip>;
}

export default LogsAndCommentsRow;
