import React, {FunctionComponent, useEffect, useState} from "react";
import "./Incomes.css";
import {ConsumerLoan} from "../../../../models/ConsumerLoan";
import {Translate} from "react-localize-redux";
import NumberInputField, {NumberInputFieldKind} from "../../../../../../common/components/input-fields/NumberInputField";
import {NewLoanData} from "../NewLoan/NewLoan";
import {AdditionalInformation, AdditionalInformationType} from "../../../../../../common/models/AdditionalInformation";


interface IncomesProps {
    application: ConsumerLoan;
    applicantAdditionalInformation: AdditionalInformation[];
    personId?: number;
    newLoanData?: NewLoanData;
    taxEffectPercentage?: number;
    visibleFields?: Omit<AdditionalInformation, 'value' | 'id'>[];
    onChange?: (total: number) => void;
    incomeChange?: (income: AdditionalInformation) => void;
    applicationChange?: (application: ConsumerLoan) => void;
    readonly?: boolean;
}
const Incomes: FunctionComponent<IncomesProps> = (props) => {
    
    const createEmptyIncomeField = (name: string): AdditionalInformation => ({
        type: AdditionalInformationType.Income,
        name,
        value: null
    });
    
    const createAdditionalIncomes = (): AdditionalInformation[] => {
        const visibleIncomes = (props.visibleFields || []).filter(f => f.type === AdditionalInformationType.Income).map(f => f.name);
        const applicantIncomes = props.applicantAdditionalInformation.filter(f => f.type === AdditionalInformationType.Income).map(f => f.name);
        const additionalIncomesNames = Array.from(new Set([...visibleIncomes, ...applicantIncomes]));
        return additionalIncomesNames.map(n => props.applicantAdditionalInformation!.find(ai => ai.name === n && ai.type === AdditionalInformationType.Income) || createEmptyIncomeField(n));
    }
    
    const [additionalIncomes, setAdditionalIncomes] = useState<AdditionalInformation[]>(createAdditionalIncomes());
    const [afterTaxMonthlyIncome, setAfterTaxMonthlyIncome] = useState<number | null | undefined>(props.application.applicant.afterTaxMonthlyIncome);
    const [coApplicantMonthlyIncome, setCoApplicantMonthlyIncome] = useState<number | null | undefined>(props.application.coApplicant ? props.application.coApplicant.afterTaxMonthlyIncome : undefined);
    const [taxEffectOfBorrowing, setTaxEffectOfBorrowing] = useState<number | null | undefined>();
    const [incomes, setIncomes] = useState<number>(0);

    useEffect(() => {
        setIncomes(calculateIncomes());
    }, [afterTaxMonthlyIncome, taxEffectOfBorrowing, additionalIncomes]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.newLoanData) {
            setTaxEffectOfBorrowing(calculateTaxEffectOfBorrowing(props.newLoanData))
        }
    }, [props.newLoanData, props.taxEffectPercentage]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setAdditionalIncomes(createAdditionalIncomes());
    }, [props.applicantAdditionalInformation]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        props.onChange && props.onChange(incomes);
    }, [incomes]); // eslint-disable-line react-hooks/exhaustive-deps

    const calculateTaxEffectOfBorrowing = (params: NewLoanData) => {
        const taxEffectOfBorrowing = (params.loanAmount - params.refinance) * params.interestRate / 100 / 12 * (props.taxEffectPercentage || 0) / 100;
        return parseFloat(taxEffectOfBorrowing.toFixed(2));
    }
    
    const calculateIncomes = () => {
        const totalIncomes = additionalIncomes.reduce((total: number, field) => total + (field.value ? parseFloat(field.value) : 0), 0);
        return totalIncomes + (afterTaxMonthlyIncome || 0) + (taxEffectOfBorrowing || 0);
    }

    const updateAdditionalIncome = (name: string, value: number | null) => {
        if (additionalIncomes.some(income => income.name === name)) {
            additionalIncomes.find(income => income.name === name)!.value = (value || 0).toString();
            setAdditionalIncomes([...additionalIncomes]);
        }
    }
    
    const monthlyIncomeChange = (monthlyIncome: number | null, isCoApplicant: boolean = false) => {
        const newApplication = {
            ...props.application,
            [isCoApplicant ? 'coApplicant' : 'applicant']: {
                ...(isCoApplicant ? props.application.coApplicant : props.application.applicant),
                afterTaxMonthlyIncome: monthlyIncome || 0
            }
        }
        props.applicationChange && props.applicationChange(newApplication);
    }

    return (
        <>
            <span className="title">
                <Translate id="INCOMES" />
            </span>
            <div className="incomes-fields">
                <div>
                    <NumberInputField
                        name="afterTaxMonthlyIncome"
                        editMode={!props.readonly}
                        value={afterTaxMonthlyIncome}
                        descriptionKey="AFTER_TAX_MONTHLY_INCOME"
                        kind={NumberInputFieldKind.Money}
                        countryId={props.application.applicant.countryId}
                        onValueChanged={(monthlyIncome) => setAfterTaxMonthlyIncome(monthlyIncome)}
                        onBlur={(monthlyIncome, dirty) => dirty && monthlyIncomeChange(monthlyIncome)}
                    />
                </div>
                {props.application.coApplicant &&
                    <div>
                        <NumberInputField
                            name="coApplicantMonthlyIncome"
                            editMode={!props.readonly}
                            value={coApplicantMonthlyIncome}
                            descriptionKey="CO_APPLICANT_MONTHLY_INCOME"
                            kind={NumberInputFieldKind.Money}
                            countryId={props.application.applicant.countryId}
                            onValueChanged={(monthlyIncome) => setCoApplicantMonthlyIncome(monthlyIncome)}
                            onBlur={(monthlyIncome, dirty) => dirty && monthlyIncomeChange(monthlyIncome, true)}
                        />
                    </div>
                }
                {additionalIncomes.map(income =>
                    <div key={income.name}>
                        <NumberInputField
                            name={income.name}
                            editMode={!props.readonly}
                            value={income.value ? parseFloat(income.value) : null}
                            description={income.name}
                            kind={NumberInputFieldKind.Money}
                            countryId={props.application.applicant.countryId}
                            onValueChanged={(value) => updateAdditionalIncome(income.name, value)}
                            onBlur={(value, dirty) => dirty && props.incomeChange && props.incomeChange(income)}
                        />
                    </div>
                )}
                {!props.readonly &&
                    <div>
                        <NumberInputField
                            className="inline-with-editable"
                            name="taxEffectOfBorrowing"
                            value={taxEffectOfBorrowing}
                            descriptionKey="TAX_EFFECT_OF_BORROWING"
                            kind={NumberInputFieldKind.Money}
                            readonlyPrecision={0}
                            countryId={props.application.applicant.countryId}
                            onValueChanged={(value) => setTaxEffectOfBorrowing(value)}
                        />
                    </div>
                }
            </div>
            <div className="total">
                <NumberInputField
                    name="totalIncomes"
                    value={incomes}
                    descriptionKey="TOTAL"
                    kind={NumberInputFieldKind.Money}
                    readonlyPrecision={0}
                    countryId={props.application.applicant.countryId}
                />
            </div>
        </>
    );
}
export default Incomes;