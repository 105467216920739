import { AxiosClientFactory } from '../../../../common/helpers/AxiosClientFactory';
import { InsuranceBasicDetails } from '../../models/InsuranceBasicDetails';
import {AxiosResponse} from "axios";
import {InsuranceReadModel} from "../../models/InsuranceReadModel";

export const postInsurance = async (personId: number | string, insurance: InsuranceBasicDetails): Promise<AxiosResponse<InsuranceReadModel>> => {
    return AxiosClientFactory
        .getInstance()
        .post(`/api/applicants/${personId}/insurances`, insurance);
};
