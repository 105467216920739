import React, { FunctionComponent, CSSProperties } from 'react';
import { connect } from 'react-redux';
import { UserImage } from './UserImage';
import TopbarButtons from './TopbarButtons';
import { SettingsProps } from '../interfaces/SettingsProps';
import { getSidebarBackgroundColorStyle, getLogo, getSidebarFontColor } from '../helpers/settingsHelpers';
import { UserProps } from '../interfaces/UserProps';

type TopbarProps = SettingsProps & UserProps;

const Topbar: FunctionComponent<TopbarProps> = (props) => {
    let email = '';
    let name = '';

    if (props.userData.user !== undefined) {
        email = props.userData.user.username;
        name = props.userData.user.displayName;
    }

    const sidebarBackgroundColorStyle = {
        ...getSidebarBackgroundColorStyle(props),
        ...getSidebarFontColor(props)
    };

    const style: CSSProperties = {
        marginRight: '10px',
        textTransform: 'uppercase',
        fontSize: '12px'
    };

    return (
        <div className="topbar" style={sidebarBackgroundColorStyle}>
            <TopbarButtons logoSetting={getLogo(props)} />
            <div>
                <span style={style}>{name}</span>
                <UserImage email={email} size={35} />
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer,
    ...state.settingsActionsReducer
});

export default connect<TopbarProps, {}, {}, any>(mapStateToProps)(Topbar);
