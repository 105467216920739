import axios, { AxiosResponse, Canceler } from 'axios';
import { Action, Dispatch } from 'redux';
import { AxiosClientFactory } from '../helpers/AxiosClientFactory';
import { ApplicantSearchResult } from '../models/ApplicantSearchResult';
import { ApplicationSearchResult } from '../models/ApplicationSearchResult';
import { SearchResultDataModel } from '../models/SearchResultDataModel';
const CancelToken = axios.CancelToken;

export const GET_SEARCH_RESULTS_BEGIN = 'GET_SEARCH_RESULTS_BEGIN';
export const GET_SEARCH_RESULTS_SUCCESS = 'GET_SEARCH_RESULTS_SUCCESS';
export const GET_SEARCH_RESULTS_FAILURE = 'GET_SEARCH_RESULTS_FAILURE';

interface GetSearchResultsBeginAction extends Action<typeof GET_SEARCH_RESULTS_BEGIN> {
}

interface GetSearchResultsSuccessAction extends Action<typeof GET_SEARCH_RESULTS_SUCCESS> {
    applicants: ApplicantSearchResult[];
    applications: ApplicationSearchResult[];
}

interface GetSearchResultsFailureAction extends Action<typeof GET_SEARCH_RESULTS_FAILURE> {
    error: any;
}

export type GetSearchResultsAction = GetSearchResultsBeginAction
    | GetSearchResultsSuccessAction
    | GetSearchResultsFailureAction;

const getSearchResults = (): GetSearchResultsBeginAction => ({
    type: GET_SEARCH_RESULTS_BEGIN
});

const getSearchResultsSuccess = (data: SearchResultDataModel): GetSearchResultsSuccessAction => ({
    applicants: data.applicants,
    applications: data.applications,
    type: GET_SEARCH_RESULTS_SUCCESS
});

const getSearchResultsError = (error: any): GetSearchResultsFailureAction => ({
    error,
    type: GET_SEARCH_RESULTS_FAILURE
});

let cancelPreviousRequest: Canceler | undefined;
export const GlobalSearchActionCreators = {
    searchApplicantsAndApplications: (query: string) => {
        return (dispatch: Dispatch, getState: any) => {
            dispatch(getSearchResults());
            if (cancelPreviousRequest) {
                cancelPreviousRequest();
            }
            return AxiosClientFactory
                .getInstance()
                .get('/api/applicants/search', { 
                    params: { filter: query }, 
                    cancelToken: new CancelToken((canceler: Canceler) => cancelPreviousRequest = canceler) 
                })
                .then((response: AxiosResponse<SearchResultDataModel>) => {
                    dispatch(getSearchResultsSuccess(response.data));
                    cancelPreviousRequest = undefined;
                })
                .catch((error: any) => dispatch(getSearchResultsError(error)));
        };
    }
};
