import { Document } from '../models/Document';

export class File {
    public static fromDocument(document: Document) {
        const byteString = atob(document.binaryData);
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new File(document.fileName, new Blob([ia], {type: document.fileType}));
    }

    public readonly name: string;
    public readonly blob: Blob;

    constructor(name: string, blob: Blob) {
        this.name = name;
        this.blob = blob;
    }

    public download() {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(this.blob);
        a.href = url;
        a.download = this.name;
        a.click();
        window.URL.revokeObjectURL(url);
    }
}
