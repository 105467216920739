import {
    GET_METADATA_BEGIN,
    GET_METADATA_FAILURE,
    GET_METADATA_SUCCESS,
    GetMetadataAction
} from '../actions/MetadataActionsCreator';

export function metadataActionsReducer(state: any, action: GetMetadataAction) {
    state = state || {
        metadata: {
            isLoading: false,
            isLoaded: false,
            error: undefined,
            banks: [],
            advertisementSites: [],
            applicationSources: [],
            teams: []
        }
    };
    switch (action.type) {
        case GET_METADATA_BEGIN:
            return {
                ...state,
                metadata: {
                    isLoading: true,
                    isLoaded: false,
                    error: undefined,
                    banks: [],
                    advertisementSites: [],
                    applicationSources: [],
                    teams: []
                }
            };
        case GET_METADATA_SUCCESS:
            return {
                ...state,
                metadata: {
                    isLoading: false,
                    isLoaded: true,
                    banks: action.banks,
                    advertisementSites: action.advertisementSites,
                    applicationSources: action.applicationSources,
                    teams: action.teams
                }
            };
        case GET_METADATA_FAILURE:
            return {
                ...state,
                metadata: {
                    isLoading: false,
                    isLoaded: false,
                    error: action.error,
                    banks: [],
                    advertisementSites: [],
                    applicationSources: [],
                    teams: []
                }
            };
        default: return state;
    }
}
