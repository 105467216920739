import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { ApplicantsFilter } from '../models/ApplicantsFilter';

const getExportedData = async (path: string, params?: ApplicantsFilter): Promise<Blob> =>
    (await AxiosClientFactory
        .getInstance()
        .get<Blob>(`/api/export/${path}`, { params, responseType: 'blob' }))
    .data;

export const exportCustomers = (params: ApplicantsFilter): Promise<Blob> => getExportedData('customers', params);

export const exportApplications = (params: ApplicantsFilter): Promise<Blob> => getExportedData('applications', params);

export const exportEconomyOverview = (applicationId: number, bankName: string | null, purpose: string | null): Promise<Blob> => getExportedData(`economyOverviews/${applicationId}`, {bankName, purpose} as any);
