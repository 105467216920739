import { PublicClientApplication } from "@azure/msal-browser";

const config = {
    auth: {
      authority: process.env.REACT_APP_AUTHORITY,
      clientId: process.env.REACT_APP_CLIENTID as string,
      redirectUri: window.origin
    }
};

export const publicClientApplication = new PublicClientApplication(config);
export const authRequest = {
  scopes: [process.env.REACT_APP_API_ACCESS_SCOPE as string]
}

export const getToken = () => 
{
  const request = {
    ...authRequest,
    account: getAccount()
  };
  
  return publicClientApplication.acquireTokenSilent(request);
};

export const getAccount = () => publicClientApplication.getActiveAccount() || publicClientApplication.getAllAccounts()[0];