import React, {FunctionComponent, useEffect, useState} from "react";
import "./Loans.css";
import {ConsumerLoan} from "../../../../models/ConsumerLoan";
import {Translate} from "react-localize-redux";
import NumberInputField, {NumberInputFieldKind} from "../../../../../../common/components/input-fields/NumberInputField";
import {getDebtTypeTranslateKey} from "../../../../helpers/getDebtTypeTranslateKey";
import {Redemption} from "../../../../models/Redemption";
import {getTranslationKey} from "../../../../../../common/helpers/getTranslationKey";
import {calculateMonthlyCost} from "../../../../helpers/calculators";

export interface LoansData {
    totalMonthlyCosts: number;
    totalLoansAmount: number;
}

interface LoansProps {
    application: ConsumerLoan;
    personId: number;
    onChange: (data: LoansData) => void;
    redemptionsChange: (redemptions: Redemption[]) => void;
}
const Loans: FunctionComponent<LoansProps> = (props) => {
    const calculateRedemptionsTotalBalance = () => notRefinancedRedemptions.reduce((total: number, redemption) => total + redemption.balance, 0);
    const calculateRedemptionsMonthlyCosts = () => notRefinancedRedemptions.reduce((total: number, redemption) => total + (redemption.repaymentMonthlyCost || calculateMonthlyCost(redemption)), 0);

    const [redemptions, setRedemptions] = useState<Redemption[]>(structuredClone(props.application.redemptions));
    const notRefinancedRedemptions = structuredClone(redemptions).filter(redemption => !redemption.refinance);
    const [redemptionsMonthlyCosts, setRedemptionsMonthlyCosts] = useState<number>(calculateRedemptionsMonthlyCosts());
    const [redemptionsTotalBalance] = useState<number>(calculateRedemptionsTotalBalance());
    
    useEffect(() => {
        props.onChange({
            totalMonthlyCosts: redemptionsMonthlyCosts,
            totalLoansAmount: redemptionsTotalBalance
        });
    }, [redemptionsMonthlyCosts]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (JSON.stringify(redemptions) !== JSON.stringify(props.application.redemptions)) {
            props.redemptionsChange(structuredClone(redemptions))
        }
    }, [redemptions]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const updateRedemptionMonthlyCost = (id: number, monthlyCost: number | null) => {
        if (redemptions.some(r => r.id === id)) {
            redemptions.find(r => r.id === id)!.repaymentMonthlyCost = monthlyCost || undefined;
            setRedemptions([...redemptions]);
        }
    }

    const renderRow = (redemption: Redemption) => (
        <tr key={redemption.id}>
            <td><Translate id={getDebtTypeTranslateKey(redemption.debtType)} /></td>
            <td>{redemption.lender}</td>
            <td><Translate id={getTranslationKey((redemption.debtOwner || 'applicant'), 'DEBT_OWNER')} /></td>
            <td>
                <NumberInputField
                    className="smooth"
                    name="balance"
                    value={redemption.balance}
                    kind={NumberInputFieldKind.Money}
                    countryId={props.application.applicant.countryId}
                />
            </td>
            <td>
                <NumberInputField
                    className="smooth"
                    name="balance"
                    value={redemption.interestRate}
                    kind={NumberInputFieldKind.Percent}
                    step={0.01}
                    countryId={props.application.applicant.countryId}
                />
            </td>
            <td>
                <NumberInputField
                    className="smooth"
                    name="balance"
                    value={redemption.remainingRepaymentPeriod}
                    kind={NumberInputFieldKind.Months}
                    countryId={props.application.applicant.countryId}
                />
            </td>
            <td>
                <NumberInputField
                    name="monthlyCost"
                    editMode={true}
                    value={redemption.repaymentMonthlyCost = redemption.repaymentMonthlyCost || calculateMonthlyCost(redemption)}
                    kind={NumberInputFieldKind.Money}
                    countryId={props.application.applicant.countryId}
                    onValueChanged={(monthlyCost) => {
                        redemption.repaymentMonthlyCost = (monthlyCost || undefined);
                        setRedemptionsMonthlyCosts(calculateRedemptionsMonthlyCosts());
                    }}
                    onBlur={(monthlyCost, dirty) => dirty && updateRedemptionMonthlyCost(redemption.id, monthlyCost)}
                />
            </td>
        </tr>
    );

    return (
        <>
            <span className="title">
                <Translate id="LOANS" />
            </span>
            <table className="loans-table">
                <thead>
                    <tr>
                        <th><Translate id="PRODUCT_TYPE" /></th>
                        <th><Translate id="BANK" /></th>
                        <th><Translate id="DEBT_OWNER.LABEL" /></th>
                        <th><Translate id="LOAN_AMOUNT" /></th>
                        <th><Translate id="INTEREST_RATE" /></th>
                        <th><Translate id="REMAINING_REPAYMENT_PERIOD" /></th>
                        <th><Translate id="BANK_REPORT.MONTHLY_COST" /></th>
                    </tr>
                </thead>
                <tbody>
                    {notRefinancedRedemptions.map((redemption) => renderRow(redemption))}
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={3}><Translate id="TOTAL" /></th>
                        <th>
                            <NumberInputField
                                name="balance"
                                value={redemptionsTotalBalance}
                                kind={NumberInputFieldKind.Money}
                                countryId={props.application.applicant.countryId}
                            />
                        </th>
                        <th colSpan={2}>{/*Empty space to align footer cells properly*/}</th>
                        <th>
                            <NumberInputField
                                name="monthlyCosts"
                                value={redemptionsMonthlyCosts}
                                kind={NumberInputFieldKind.Money}
                                countryId={props.application.applicant.countryId}
                            />
                        </th>
                    </tr>
                </tfoot>
            </table>
        </>
    );
}
export default Loans;