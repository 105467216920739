export const getEducationLevelsTranslateKeys = (educationLevel: string): string => {
    switch (educationLevel) {
        case 'primary': return 'EDUCATION_LEVELS.PRIMARY';
        case 'vocational': return 'EDUCATION_LEVELS.VOCATIONAL';
        case 'high school': return 'EDUCATION_LEVELS.HIGH_SCHOOL';
        case 'bachelors degree': return 'EDUCATION_LEVELS.BACHELORS_DEGREE';
        case 'masters degree': return 'EDUCATION_LEVELS.MASTERS_DEGREE';
        case 'other': return 'EDUCATION_LEVELS.OTHER';
        default: return '';
    }
};
