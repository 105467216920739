import { JobStatus } from '../models/JobStatus';

// to do
export const getJobStatusTranslateKey = (jobStatus: JobStatus): string => {
    switch (jobStatus) {
        case JobStatus.Deputy: return 'JOB_STATUS.DEPUTY';
        case JobStatus.Domestic: return 'JOB_STATUS.DOMESTIC';
        case JobStatus.Incapable: return 'JOB_STATUS.INCAPABLE';
        case JobStatus.PrivateSector: return 'JOB_STATUS.PRIVATE_SECTOR';
        case JobStatus.PublicSector: return 'JOB_STATUS.PUBLIC_SECTOR';
        case JobStatus.Rehabilitation: return 'JOB_STATUS.REHABILITATION';
        case JobStatus.Retired: return 'JOB_STATUS.RETIRED';
        case JobStatus.SelfEmployed: return 'JOB_STATUS.SELF_EMPLOYED';
        case JobStatus.Student: return 'JOB_STATUS.STUDENT';
        case JobStatus.Unemployed: return 'JOB_STATUS.UNEMPLOYED';
        case JobStatus.FixedTime: return 'JOB_STATUS.FIXED_TIME';
    }
};
