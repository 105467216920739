import { localizeReducer } from 'react-localize-redux';
import { AnyAction, combineReducers } from 'redux';
import { metadataActionsReducer } from '../applications/applicant/reducers/metadataActionsReducer';
import { applicantDataReducer } from '../applications/applicant/reducers/applicantDataReducer';
import { applicantsViewDataReducer } from '../applications/applicants/reducers/applicantsViewDataReducer';
import { customerAdvisorsTeamsReducer } from '../applications/applicants/reducers/customerAdvisorsTeamsReducer';
import { globalSearchActionReducer } from '../common/reducers/globalSearchActionReducer';
import { loadingBarWrapperReducer } from '../common/reducers/loadingBarWrapperReducer';
import { settingsActionsReducer } from '../common/reducers/settingsActionsReducer';
import { setTitleActionsReducer } from '../common/reducers/setTitleActionsReducer';
import { substatusesReducer } from '../applications/applicants/reducers/substatusesReducer';
import { publishersReducer } from '../applications/applicant/reducers/publishersReducer';
import { sidebarReducer } from '../common/reducers/sidebarReducer';
import { toastMessageActionsReducer } from '../common/reducers/toastMessageActionsReducer';
import { userActionsReducer } from '../common/reducers/userActionsReducer';
import { receivedMessagesActionsReducer } from '../common/reducers/receivedMessagesActionsReducer';
import { reminderChangeActionReducer } from '../common/reducers/reminderChangeActionReducer';
import { communicationTemplatesReducer } from '../common/reducers/communicationTemplatesReducer';
import { campaignsViewDataReducer } from '../campaigns/reducers/campaignsViewDataReducer';
import { campaignViewDataReducer } from '../campaigns/reducers/campaignViewDataReducer';
import { RouterState } from 'redux-first-history';
import { Reducer } from 'react';

export function createRootReducer(routerReducer: Reducer<RouterState, AnyAction>) {
    return combineReducers({
        applicantDataReducer,
        applicantsViewDataReducer,
        campaignsViewDataReducer,
        campaignViewDataReducer,
        customerAdvisorsTeamsReducer,
        metadataActionsReducer,
        settingsActionsReducer,
        globalSearchActionReducer,
        loadingBarWrapperReducer,
        toastMessageActionsReducer,
        localize: localizeReducer,
        setTitleActionsReducer,
        substatusesReducer,
        publishersReducer,
        sidebarReducer,
        userActionsReducer,
        receivedMessagesActionsReducer,
        communicationTemplates: communicationTemplatesReducer,
        reminderChangeActionReducer,
        router: routerReducer // key MUST BE router
    });
}
