import React, { FunctionComponent } from 'react';
import { ApplicantBoxProps } from './ApplicantBox';
import NumberInputField, { NumberInputFieldKind } from '../../../common/components/input-fields/NumberInputField';
import DateTimeInputField, { DateTimeInputFieldKind } from '../../../common/components/input-fields/DateTimeInputField';
import { withLocalize } from 'react-localize-redux';
import DropdownInputField from '../../../common/components/input-fields/DropdownInputField';
import { getEducationLevels } from '../helpers/getEducationLevels';
import { getMilitaryStatuses } from '../helpers/getMilitaryStatuses';
import { getEducationLevelsTranslateKeys } from '../helpers/getEducationLevelsTranslateKeys';
import { getMilitaryStatusesTranslateKeys } from '../helpers/getMilitaryStatusesTranslateKeys';

const FinnishApplicantFields: FunctionComponent<ApplicantBoxProps> = (props: ApplicantBoxProps) => {
    const onValueChanged = (value: string | number | boolean | Date | null | undefined, name: string): void => props.onApplicantChanged({ ...props.applicant, [name]: value });
    const educationLevels = getEducationLevels().map((x) => ({ id: x, name: props.translate(getEducationLevelsTranslateKeys(x).toString()) }));
    const militaryStatuses = getMilitaryStatuses().map((x) => ({ id: x, name: props.translate(getMilitaryStatusesTranslateKeys(x).toString()) }));
    const genders = [{ id: 'male', name: props.translate('GENDERS.MALE').toString() }, { id: 'female', name: props.translate('GENDERS.FEMALE').toString() }];

    return (
        <React.Fragment>
            <NumberInputField
                descriptionKey="DWELLING_SIZE"
                editMode={true}
                name="dwellingSize"
                required={props.isComplete ? 'DwellingSizeIsRequired' : undefined}
                errors={props.errors}
                overrideInternalErrors={['valueMissing']}
                kind={NumberInputFieldKind.SquareMetres}
                onValueChanged={onValueChanged}
                value={props.applicant.dwellingSize}
            />
            <DropdownInputField
                descriptionKey="EDUCATION_LEVEL"
                editMode={true}
                name="educationLevel"
                required={props.isComplete ? 'EducationLevelIsRequired' : undefined}
                errors={props.errors}
                overrideInternalErrors={['valueMissing']}
                onValueChanged={onValueChanged}
                value={props.applicant.educationLevel === null ? undefined : props.applicant.educationLevel} // hack
                items={educationLevels}
            />
            <DropdownInputField
                descriptionKey="MILITARY_STATUS"
                editMode={true}
                name="militaryStatus"
                required={props.isComplete ? 'MilitaryStatusIsRequired' : undefined}
                errors={props.errors}
                overrideInternalErrors={['valueMissing']}
                onValueChanged={onValueChanged}
                value={props.applicant.militaryStatus === null ? undefined : props.applicant.militaryStatus} // hack
                items={militaryStatuses}
            />
            <DateTimeInputField
                descriptionKey="EMPLOYMENT_END_DATE"
                editMode={true}
                name="employmentEndDate"
                errors={props.errors}
                errorCodes={[]}
                onValueChanged={onValueChanged}
                value={props.applicant.employmentEndDate}
                kind={DateTimeInputFieldKind.YearWithMonth}
            />
            <DropdownInputField
                descriptionKey="GENDER"
                editMode={true}
                name="gender"
                required={props.isComplete ? 'GenderIsRequired' : undefined}
                errors={props.errors}
                overrideInternalErrors={['valueMissing']}
                onValueChanged={onValueChanged}
                value={props.applicant.gender === null ? undefined : props.applicant.gender} // hack
                items={genders}
            />
        </React.Fragment>
    );
};

export default withLocalize(FinnishApplicantFields);
