import React, { FunctionComponent, useState } from 'react';
import ConfirmationModal from './ConfirmationModal';

export interface NavigationPromptProps {
    message: string;
    title: string;
    callback: (prevent: boolean) => void;
}

const NavigationPrompt: FunctionComponent<NavigationPromptProps> = (props) => {
    const [show, setShow] = useState(true);
    const onConfirm = () => { props.callback(true); setShow(false); };
    const onCancel = () => { props.callback(false); setShow(false); };
    return <ConfirmationModal show={show} title={props.title} message={props.message} onConfirm={onConfirm} onCancel={onCancel}/>;
};

export default NavigationPrompt;
