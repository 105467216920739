import React, {ChangeEvent, useRef, FunctionComponent, useEffect, useState, SetStateAction} from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { SettingsActionCreator } from '../../common/actions/SettingsActionCreators';
import { SetTitleActionCreator } from '../../common/actions/SetTitleActionCreator';
import { Setting } from '../../common/models/Setting';
import { SettingsProps } from '../../common/interfaces/SettingsProps';
import AdministrationLayout from './AdministrationLayout';
import { faEdit, faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { putSetting } from '../api/putSetting';
import { postSettingsLogo } from '../api/postSettingsLogo';
import ActionIcon from '../../common/components/ActionIcon';
import { InputGroup, FormControl } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import GenericDropdown from '../../common/components/GenericDropdown';
import { SetApplicationTitleActionProps } from '../../common/interfaces/SetApplicationTitleActionProps';

interface SettingsViewProps {
    loadSettings: () => void;
}

interface SettingsViewState {
    editedSetting: Setting | undefined;
    tempImage: any | undefined;
    tempFile: any | undefined;
}

const settingTypes = [
    { name: 'APP_LOGO', type: 'image' },
    { name: 'APP_TITLE', type: 'string' },
    { name: 'FAVICON', type: 'image' },
    { name: 'MAIN_BG_COLOR', type: 'color' },
    { name: 'MAIN_FONT_COLOR', type: 'color' },
    { name: 'SIDEBAR_BG_COLOR', type: 'color' },
    { name: 'SIDEBAR_FONT_COLOR', type: 'color' },
    { name: 'COUNTRY_ID', type: 'select' },
    { name: 'MY_APPLICATION_URLS', type: 'string'}
];

const countries = [
    { name: 'Norway', id: '1' },
    { name: 'Denmark', id: '2' },
    { name: 'Finland', id: '3' },
    { name: 'Sweden', id: '4' }
];

const SettingsView: FunctionComponent<SettingsViewProps & SettingsProps & SetApplicationTitleActionProps> = (props) =>  {
    const fileInputReference = useRef<HTMLInputElement>(null);
    const [state, setState] = useState<SettingsViewState>({
        editedSetting: undefined,
        tempImage: undefined,
        tempFile: undefined
    });
    
    useEffect(() => {
        props.setTitle('Settings');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    if (props.settingsData && props.settingsData.isLoaded) {
        return (
            <AdministrationLayout>
                <div className="col-12 settings-table">
                    <div className="table-header">
                        <div><Translate id="NAME"/></div>
                        <div><Translate id="VALUE"/></div>
                        <div/>
                    </div>
                    {props.settingsData.settings.map((setting, index) => mapSetting(props, state, setState, fileInputReference, setting, index))}
                </div>
            </AdministrationLayout>
        );
    } else {
        return (<div/>);
    }
}

function save(props: SettingsViewProps, state: SettingsViewState) {
    const setting = state.editedSetting;
    if (setting !== undefined) {
        const type = settingTypes.filter((x) => (x.name === setting.name))[0].type;

        if (type === 'image') {
            postSettingsLogo(state.tempFile).then(
                (result) => putSetting(setting.name, result).then(
                    () => props.loadSettings && props.loadSettings()));
        } else {
            putSetting(setting.name, setting.value).then(
                () => props.loadSettings && props.loadSettings());
        }
    }
}

function cancel(setState: React.Dispatch<SetStateAction<SettingsViewState>>) {
    setState({
        editedSetting: undefined,
        tempImage: undefined,
        tempFile: undefined
    });
}

function renderEditableValue(
    state: SettingsViewState, 
    setState: React.Dispatch<SetStateAction<SettingsViewState>>, 
    setting: Setting, 
    type: string, 
    fileInputReference: React.MutableRefObject<HTMLInputElement | null>
) {
    if (state.editedSetting === undefined) { return; }

    const onFileSelected = (event) => {
        const file = event.target.files[0];
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            if (e.target) {
                const result = e.target.result;
                setState(state => ({...state, tempFile: file, tempImage: result }));
            }
        };
        fileReader.readAsDataURL(file);
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (state.editedSetting) {
            const name = state.editedSetting.name;
            const value = event.target.value;
            setState(state => ({
                ...state,
                editedSetting: {
                    name,
                    value
                }
            }));
        }
    };

    if (type === 'image') {
        const imageSrc = state.tempImage !== undefined
            ? state.tempImage
            : `${process.env.REACT_APP_BASE_API_URL}/api/settings/logos/${setting.value}`;

        const onClick = () => fileInputReference.current && fileInputReference.current.click();

        return (
            <div>
                <input type="file" ref={fileInputReference} onChange={onFileSelected} style={{ display: 'none' }} />
                <img 
                    alt={setting.value}
                    src={imageSrc} onClick={onClick} 
                />
            </div>
        );

    }
    if (type === 'color') {
        return (<div><ColorEditor value={state.editedSetting.value} onChange={onChange} /></div>);
    }
    if (type === 'string') {
        return (<div><input type="text" value={state.editedSetting.value} onChange={onChange} /></div>);
    }
    if (type === 'select') {
        const selectionChanged = (item: { name: string, id: string }) => {
            setState(state => ({
                ...state,
                editedSetting: {
                    name: setting.name,
                    value: item.id
                }
            }));
        };

        return (
            <div>
                <GenericDropdown
                    dropdownId="country"
                    selectedItem={countries.find((i) => state.editedSetting !== undefined && i.id === state.editedSetting.value)}
                    items={countries}
                    style={{ width: '210px' }}
                    onSelectionChanged={selectionChanged}
                />
            </div>
        );
    }
}

function renderReadOnlyValue(setting: Setting, type: string) {
    if (type === 'image') {
        return (<div>
            <img 
                alt={setting.value}
                src={`${process.env.REACT_APP_BASE_API_URL}/api/settings/logos/${setting.value}`} 
            />
        </div>);
    }
    if (type === 'color') {
        return (<div><ColorEditor value={setting.value} disabled={true} /></div>);
    }
    if (type === 'string') {
        return (<div>{setting.value}</div>);
    }
    if (type === 'select') {
        return (<div>{countries.filter((c) => (c.id === setting.value))[0].name}</div>);
    }
}

function mapSetting(
    props: SettingsViewProps, 
    state: SettingsViewState, 
    setState: React.Dispatch<SetStateAction<SettingsViewState>>, 
    fileInputReference: React.MutableRefObject<HTMLInputElement | null>, 
    setting: Setting, 
    index: number
) {
    const type = settingTypes.filter((x) => (x.name === setting.name))[0].type;

    const edit = (): void => setState(state => ({...state, editedSetting: setting }));

    // isEdit
    if (state.editedSetting !== undefined && setting.name === state.editedSetting.name) {
        return (
            <div key={`setting-${index}`}>
                <div>{setting.name}</div>
                <div>{renderEditableValue(state, setState, setting, type, fileInputReference)}</div>
                <div>
                    <ActionIcon action={() => save(props, state)} icon={faSave} tooltipKey="SAVE" />
                    <ActionIcon action={() => cancel(setState)} icon={faTimesCircle} tooltipKey="CANCEL" />
                </div>
            </div>
        );
    }

    return (
        <div key={`setting-${index}`}>
            <div>{setting.name}</div>
            <div>{renderReadOnlyValue(setting, type)}</div>
            <div><ActionIcon action={edit} icon={faEdit} tooltipKey="EDIT" /></div>
        </div>
    );
}

const ColorEditor = (props: { value: string, onChange?: any, disabled?: boolean }) => { // change any to something more appropriate
    const disabled = props.disabled || false;

    const renderInput = () => (props.onChange !== undefined
        ? (<input value={props.value} type="color" onChange={props.onChange} disabled={disabled} />)
        : (<input value={props.value} type="color" disabled={disabled} />)
    );

    const renderFormControl = () => (props.onChange !== undefined
        ? (<FormControl value={props.value} onChange={props.onChange} type="text" disabled={disabled} />)
        : (<FormControl value={props.value} type="text" disabled={disabled} />)
    );

    return (
        <InputGroup className="color-picker">
            <InputGroup>
                <InputGroup.Text>
                    {renderInput()}
                </InputGroup.Text>
            </InputGroup>
            {renderFormControl()}
        </InputGroup>
    );
};

const mapStateToProps = (state: any) => ({ ...state.settingsActionsReducer });

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({ ...SettingsActionCreator, ...SetTitleActionCreator }, dispatch);

export default connect<SettingsProps,SetApplicationTitleActionProps & SettingsViewProps, any, SettingsViewState>(mapStateToProps, mapActionCreatorsToProps)(SettingsView);
