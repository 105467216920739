import {FollowUp} from '../../../common/models/FollowUp';
import {
    RESET_TABLE_STATE,
    SET_ADVERTISEMENTS,
    SET_AMOUNT_FROM,
    SET_AMOUNT_TO,
    SET_APPLICATION_SOURCES,
    SET_BANK,
    SET_CLOSE_DATE_FROM,
    SET_CLOSE_DATE_TO,
    SET_CUSTOMER_ADVISORS_IDS,
    SET_EXCLUDED_TEAM_ID,
    SET_FOLLOW_UP,
    SET_FOLLOW_UP_DATE_FROM,
    SET_FOLLOW_UP_DATE_TO,
    SET_GROUP_BY,
    SET_LOAN_STATUS,
    SET_NOMINAL_INTEREST_FROM,
    SET_ORDER,
    SET_PAGE,
    SET_PAGESIZE,
    SET_PRIMARY_LANGUAGE,
    SET_PRODUCTS,
    SET_PROPERTY_REQUIRED,
    SET_PUBLISHER_ID,
    SET_REFINANCED_AMOUNT_FROM,
    SET_REFINANCED_AMOUNT_TO,
    SET_SUBSTATUSES,
    SET_TEAM_ID,
    SetApplicantsViewTableParamsAction
} from '../actions/ApplicantsViewDataActionCreators';
import {ApplicantsGroupBy} from "../models/ApplicantsGroupBy";
import {ApplicantsViewFilter} from "../components/ApplicantsView";

const defaultTableState: ApplicantsViewFilter = {
    pageNumber: 0,
    pageSize: 20,
    loanStatus: null,
    followUp: null,
    followUpDateFrom: null,
    followUpDateTo: null,
    propertyRequired: false,
    bankId: null,
    products: [],
    advertisementSiteIds: [],
    advertisementIds: [],
    substatusIds: [],
    applicationSourceIds: [],
    orderBy: 1, // Submitted date
    orderDirection: 1, // Descending
    primaryLanguage: null,
    teamId: null,
    excludedTeamId: null,
    closeDateFrom: null,
    closeDateTo: null,
    publisherId: null,
    customerAdvisorsIds: [],
    groupBy: ApplicantsGroupBy.ByApplicantAndProduct
};

export const applicantsViewDataReducer = (state: any, action: SetApplicantsViewTableParamsAction) => {
    state = state || {};

    if (!state.tableState) {
        state.tableState = defaultTableState;
    }

    switch (action.type) {
        case SET_PAGE:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    pageNumber: action.page
                }
            };
        case SET_PAGESIZE:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    pageSize: action.pageSize,
                    pageNumber: 0
                }
            };
        case SET_ORDER:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    orderBy: action.orderBy,
                    orderDirection: action.orderDirection
                }
            };
        case SET_PRODUCTS:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    products: action.products,
                    pageNumber: 0
                }
            };
        case SET_LOAN_STATUS:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    loanStatus: action.loanStatus,
                    // reset table parameters to default
                    pageNumber: 0,
                    orderBy: 1, // Submitted date
                    orderDirection: 1 // Descending
                }
            };
        case SET_FOLLOW_UP:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    followUp: action.followUp,
                    pageNumber: 0
                }
            };
        case SET_BANK:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    bankId: action.bankId,
                    pageNumber: 0
                }
            };
        case SET_ADVERTISEMENTS:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    advertisementSiteIds: action.advertisementSiteIds,
                    advertisementIds: action.advertisementIds,
                    pageNumber: 0
                }
            };
        case SET_APPLICATION_SOURCES:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    applicationSourceIds: action.applicationSourceIds
                }
            };
        case SET_SUBSTATUSES:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    substatusIds: action.substatusIds,
                    pageNumber: 0
                }
            };
        case SET_PRIMARY_LANGUAGE:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    primaryLanguage: action.primaryLanguage,
                    pageNumber: 0
                }
            };
        case SET_TEAM_ID:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    teamId: action.teamId,
                    pageNumber: 0
                }
            };
        case SET_EXCLUDED_TEAM_ID: {
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    excludedTeamId: action.excludedTeamId,
                    pageNumber: 0
                }
            };
        }
        case SET_CLOSE_DATE_FROM:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    closeDateFrom: action.closeDateFrom,
                    pageNumber: 0
                }
            };
        case SET_CLOSE_DATE_TO:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    closeDateTo: action.closeDateTo,
                    pageNumber: 0
                }
            };
        case SET_FOLLOW_UP_DATE_FROM:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    followUp: action.followUpDateFrom ? FollowUp.Custom : null,
                    followUpDateFrom: action.followUpDateFrom,
                    pageNumber: 0
                }
            };
        case SET_FOLLOW_UP_DATE_TO:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    followUp: action.followUpDateTo ? FollowUp.Custom : null,
                    followUpDateTo: action.followUpDateTo,
                    pageNumber: 0
                }
            };
        case SET_PROPERTY_REQUIRED:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    propertyRequired: action.propertyRequired,
                    pageNumber: 0
                }
            };
        case SET_AMOUNT_FROM:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    amountFrom: action.amountFrom,
                    pageNumber: 0
                }
            };
        case SET_AMOUNT_TO:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    amountTo: action.amountTo,
                    pageNumber: 0
                }
            };
        case SET_REFINANCED_AMOUNT_FROM:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    refinancedAmountFrom: action.refinancedAmountFrom,
                    pageNumber: 0
                }
            };
        case SET_REFINANCED_AMOUNT_TO:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    refinancedAmountTo: action.refinancedAmountTo,
                    pageNumber: 0
                }
            };
        case SET_NOMINAL_INTEREST_FROM:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    nominalInterestFrom: action.nominalInterestFrom,
                    pageNumber: 0
                }
            };
        case SET_PUBLISHER_ID:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    publisherId: action.publisherId,
                    pageNumber: 0
                }
            };
        case SET_CUSTOMER_ADVISORS_IDS: {
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    customerAdvisorsIds: action.customerAdvisorsIds,
                    pageNumber: 0
                }
            }
        }
        case SET_GROUP_BY: {
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    groupBy: action.groupBy,
                    pageNumber: 0
                }
            }
        }
        case RESET_TABLE_STATE:
            return {
                ...state,
                tableState: defaultTableState
            };
        default:
            return state;
    }
};
