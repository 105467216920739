import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { AxiosResponse } from 'axios';
import { PropertyInternalDetails } from '../models/PropertyInternalDetails';

export const putPropertyInternalDetails = async (
    id: number,
    details: PropertyInternalDetails): Promise<string[]> => {
    return new Promise<string[]>((resolve) => {
        AxiosClientFactory
            .getInstance()
            .put(`api/PropertyInternalDetails/${id}`, details)
            .then((response: AxiosResponse<string[]>) => resolve(response.data));
    });
};
