import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { Applicant } from '../models/Applicant';
import { TableState } from '../../../common/models/TableState';
import { ApplicantsFilter } from '../models/ApplicantsFilter';

export const getApplicants = (params: ApplicantsFilter & TableState): Promise<Applicant[]> => {
    return new Promise<Applicant[]>((executor) => {
        params = params || { pageSize: 20, pageNumber: 0, orderDirection: 0 };

        AxiosClientFactory
            .getInstance()
            .get<Applicant[]>('/api/applicants', { params })
            .then((result) => executor(result.data));
    });
};
