import React, { FunctionComponent, useEffect, useState } from 'react';
import { faComment, faFilter } from '@fortawesome/free-solid-svg-icons';
import './received-messages.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    ReceivedMessagesState,
    ReceivedMessagesDispatchProps,
    ReceivedMessagesStateFilter
} from '../../interfaces/ReceivedMessagesProps';
import { ReceivedMessagesActionCreator } from '../../actions/ReceivedMessagesActionCreator';
import classNames from 'classnames';
import ReceivedMessagesModal from './ReceivedMessagesModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from "../Tooltip";
import {Translate} from "react-localize-redux";

const ReceivedMessages: FunctionComponent<ReceivedMessagesState & ReceivedMessagesDispatchProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => props.refreshUnreadMessageCount(), [props.filter.substatusIds.length, props.filter.primaryLanguage, props.filter.campaign]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => setIsModalOpen(true);
    const hideModal = () => setIsModalOpen(false);

    return (
        <React.Fragment>
            <Tooltip content={<Translate id={getTooltipKey(props.filter.substatusIds)} />}>
                <div className={classNames('received-messages-icon', 'cursor-pointer', { 'unread-messages': props.unreadCount > 0 })} onClick={showModal}>
                    <FontAwesomeIcon icon={faComment}/>
                    {getFilterIcon(props.filter)}
                    <div className="message-count">{props.unreadCount}</div>
                </div>
            </Tooltip>
            <ReceivedMessagesModal show={isModalOpen} onClose={hideModal} />
        </React.Fragment>
    );
};

const getTooltipKey = (substatusIds: number[]) => substatusIds.length === 0 ? 'RECEIVED_MESSAGES' : 'RECEIVED_MESSAGES_FILTERED';

function getFilterIcon(filter: ReceivedMessagesStateFilter) {
    if (filter.substatusIds.length === 0 && !filter.primaryLanguage && !filter.campaign) {
        return null;
    }

    return <div className="filter-icon"><FontAwesomeIcon icon={faFilter}/></div>;
}

const mapStateToProps = (state: any) => ({
    ...state.receivedMessagesActionsReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators({
    ...ReceivedMessagesActionCreator
} as any, dispatch);

export default connect<ReceivedMessagesState, ReceivedMessagesDispatchProps, {}, any>(mapStateToProps, mapActionCreatorsToProps)(ReceivedMessages);
