import React, {FunctionComponent} from 'react';
import LoadingBarWrapper from './LoadingBarWrapper';
import Sidebar from './Sidebar';
import ToastMessages from './ToastMessages';
import ApplicationTitle from './ApplicationTitle';
import Topbar from './Topbar';
import ReceivedMessages from './received-messages/ReceivedMessages';
import Reminders from './reminders/Reminders';

const Layout: FunctionComponent = (props) => {
    return (
        <React.Fragment> {/* <div id="root"> */}
            <ApplicationTitle />
            <LoadingBarWrapper />
            <Topbar />
            {props.children}
            <Sidebar />
            <ToastMessages />
            <ReceivedMessages />
            <Reminders />
        </React.Fragment>
    );
};

export default Layout;
