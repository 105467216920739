import { useEffect } from 'react';
import { NewApplicationState } from '../models/NewApplicationState';
import { Redemption } from '../models/Redemption';
import { CommandError } from '../../../common/helpers/CommandResult';
import { LoanStatus } from '../../../common/models/LoanStatus';

export function useServerValidation(
        state: NewApplicationState, callServerValidation: (state: NewApplicationState) => Promise<CommandError[]>, handleResult: (errors: CommandError[]) => void) {
    const sumOfRedemptionsWithRefinance = state.redemptions.filter((r) => r.refinance).reduce((sum, r) => sum + (r.balance || 0), 0);
    const serverValidationFields = [
        state.details.productType,
        state.details.loanAmount,
        state.details.bankAccount,
        state.applicant.socialSecurityNumber,
        state.applicant.postalCode,
        state.applicant.email,
        state.applicant.mobileNumber,
        state.coApplicant && state.coApplicant.socialSecurityNumber,
        state.coApplicant && state.coApplicant.postalCode,
        state.coApplicant && state.coApplicant.email,
        state.coApplicant && state.coApplicant.mobileNumber,
        sumOfRedemptionsWithRefinance
    ];

    const handleValidationResult = (errors: CommandError[]) => {
        handleResult(
            (!state.validationLoanStatus || state.validationLoanStatus === LoanStatus.Incomplete)
                ? errors.filter((e) => !(e.message || e.code).endsWith('Required'))
                : errors
        );
    };

    useEffect(() => {
        if (state.modified && serverValidationFields.some((x) => x)) {
            const handler = setTimeout(() => {
                callServerValidation(state).then(handleValidationResult);
            }, 500);

            return () => clearTimeout(handler);
        }
    }, serverValidationFields); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useRedemptionsValidation(redemptions: Redemption[], isComplete: boolean, errors: string[], setExpandedDebtTypes: ((update: (dt: number[]) => number[]) => void)) {
    useEffect(() => {
        if (isComplete && errors && errors.length > 0) {
            const invalidDebtTypes = redemptions
                .filter((r) => !r.balance || !r.lender || !r.debtOwner)
                .map((r) => r.debtType);

            if (invalidDebtTypes.length > 0) {
                setExpandedDebtTypes((dt) => dt.concat(invalidDebtTypes));
            }
        }
    }, [errors.join(',')]); // eslint-disable-line react-hooks/exhaustive-deps
}
