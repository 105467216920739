import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {createRootReducer} from '../_reducers/rootReducerCreator';
import {IHistoryContext} from "redux-first-history/build/es6/create";

const taurusAppStateKey = 'taurus-app-state';
const localStorageProperties = [
    'applicantsViewDataReducer.tableState', 
    'campaignsViewDataReducer', 
    'localize.languages', 
    'receivedMessagesActionsReducer.filter.substatusIds', 
    'receivedMessagesActionsReducer.filter.primaryLanguage', 
    'receivedMessagesActionsReducer.filter.campaign'
];
const localStoreSlicers = [tableStateSlicer];

function configureStore(history: IHistoryContext) {
    const store = createStore(
        createRootReducer(history.routerReducer),
        readFromStorage(),
        compose(applyMiddleware(thunk, history.routerMiddleware))
    );

    store.subscribe(debouncedFunction(() => saveToStorage(store.getState())));
    return store;
}

function readFromStorage() {
    const initialStateString = localStorage.getItem(taurusAppStateKey);
    return initialStateString ? JSON.parse(initialStateString) : {};
}

function debouncedFunction(func: (...args: any[]) => void) {
    let timeout: NodeJS.Timeout;
    return () => {
        clearTimeout(timeout);
        timeout = setTimeout(func, 250);
    };
}

function getNestedObject(nestedObj: object, path: string) {
    return path.split('.').reduce((obj, key) =>
        (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
}

function setNestedObject(object: object, path: string[], value: object) {
    if (path.length === 1) {
        object[path[0]] = value;
    } else {
        const propertyName = path.shift();

        if (propertyName) {
            object[propertyName] = setNestedObject(object[propertyName] || {}, path, value);
        }
    }

    return object;
}

function saveToStorage(state: object) {
    const toStore = localStorageProperties.reduce((obj, key) => setNestedObject(obj, key.split('.'), getNestedObject(state, key)), {});
    const slicedState = localStoreSlicers.reduce((s, slicer) => slicer(s), toStore);
    localStorage.setItem(taurusAppStateKey, JSON.stringify(slicedState));
}

function tableStateSlicer(state: any) {
    if (state.applicantsViewDataReducer.tableState && state.applicantsViewDataReducer.tableState.pageNumber) {
        return { ...state, applicantsViewDataReducer: { ...state.applicantsViewDataReducer, tableState: { ...state.applicantsViewDataReducer.tableState, pageNumber: 0 } } };
    }

    return state;
}

export default configureStore;
