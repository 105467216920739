import { TranslateFunction } from 'react-localize-redux';
import { LoanStatus } from '../models/LoanStatus';
import mapEnumToSelectableItems from './mapEnumToSelectableItems';
import { getEnumTranslationKey } from './getTranslationKey';
import { SelectableItem } from '../models/SelectableItem';

export const getLoanStatusTranslateKey = (loanStatus: string | number | null): string => {
    return getEnumTranslationKey(LoanStatus, loanStatus, 'LOAN_STATUS', 'ALL');
};

const nameMapper = (translate: TranslateFunction) => (loanStatus: string | null) =>
    translate('LOAN_STATUS.PREFIX').toString() + translate(getLoanStatusTranslateKey(loanStatus)).toString();

// custom sort options to make denied last one
const optionSort = (a: SelectableItem<number>, b: SelectableItem<number>): number => {
    if (a.id === null || b.id === 3) {
        return -1;
    }

    if (a.id === 3 || b.id === null) {
        return 1;
    }

    return a.id - b.id;
};

export const mapLoanStatusesToSelectableItems = (translate: TranslateFunction) =>
    mapEnumToSelectableItems<number>(LoanStatus, nameMapper(translate)).sort(optionSort);
