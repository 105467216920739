import { TranslateFunction } from 'react-localize-redux';
import { Team } from "../../applications/applicant/models/Team";

export interface TeamItem {
    id: number | null;
    name: string;
}

export const getTeamItems = (translate: TranslateFunction, teams: Team[]): TeamItem[] =>
    [{ id: null, name: translate('ALL') } as TeamItem].concat(
        teams.map<TeamItem>((team) => ({ id: team.id, name: team.name }))
    );

export const getExcludedTeamItems = (translate: TranslateFunction, teams: Team[]): TeamItem[] =>
    [{ id: null, name: translate('NONE') } as TeamItem].concat(
        teams.map<TeamItem>((team) => ({ id: team.id, name: team.name }))
    );
    