import React, { FunctionComponent, ReactNode } from 'react';
import { Translate } from 'react-localize-redux';
import { getProductTypeTranslateKey } from '../../../common/helpers/productTypeFunctions';
import { ProductType } from '../../../common/models/ProductType';
import { ConsumerLoanBrief } from '../models/ConsumerLoanBrief';
import './generic-tabs.css';

interface ProductTabsProps {
    selectedProduct: ProductType | 'insurance' | undefined | null;
    otherLoans: ConsumerLoanBrief[];
    showInsuranceTab: boolean;
    hasInurances: boolean;
    scopeSourceId?: number | null;
    onSelectionChanged(productType: ProductType | 'insurance'): void;
}

const ProductTabs: FunctionComponent<ProductTabsProps> = (props) => {
    const products = [
        ProductType.ConsumerLoan,
        ProductType.CreditCard,
        ProductType.Mortgage,
        'insurance'
    ] as Array<ProductType & 'insurance'>;

    const mapItems = (item: ProductType | 'insurance', index: number): ReactNode => {

        if (!props.showInsuranceTab && item === 'insurance') {
            return null;
        }

        const onClickHandler = (): void => props.onSelectionChanged(item);
        let classes = 'generic-tab';

        if (item === props.selectedProduct) {
            classes += ' active';
        }

        if (item === 'insurance' && props.hasInurances)
        {
            classes += ' positive';
        }

        if (item !== 'insurance' && props.otherLoans.map((x) => props.scopeSourceId === undefined || props.scopeSourceId === null || props.scopeSourceId === x.sourceId ? x.productType : null).indexOf(item) > -1) {
            classes += ' positive';
        }

        return (
            <div className={classes} key={`generic-tab-${index}`} onClick={onClickHandler}>
                <Translate id={item === 'insurance' ? 'INSURANCE' : getProductTypeTranslateKey(item)} />
                <div className="selection-dot" />
            </div>
        );
    };

    return (
        <div className="generic-tabs">
            {products.map(mapItems)}
        </div>
    );
};

export default ProductTabs;
