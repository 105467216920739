import {
    Campaign,
    CampaignRecipientsFilter,
    CampaignSender,
    CampaignStatus,
    CampaignTestModel,
    CampaignType,
    LaunchCampaignModel
} from "../../../../common/models/Campaign";
import {CustomerCommunicationConfig} from "../../../models/CustomerCommunicationConfig";
import SmsCounter from "sms-counter";
import {Optional} from "utility-types";
import moment from "moment";
import {campaignRecipientsDefaultFilter} from "../../../reducers/campaignViewDataReducer";

export enum CampaignValidationErrors {
    NAME_IS_REQUIRED = 'NAME_IS_REQUIRED',
    RECIPIENTS_ARE_REQUIRED = 'RECIPIENTS_ARE_REQUIRED',
    RECIPIENTS_FILTER_IS_REQUIRED = 'RECIPIENTS_FILTER_IS_REQUIRED',
    TEMPLATE_IS_REQUIRED = 'TEMPLATE_IS_REQUIRED',
    CONTENT_TOO_LONG = 'CONTENT_TOO_LONG',
    SUBJECT_IS_REQUIRED = 'SUBJECT_IS_REQUIRED',
    SENDER_IS_REQUIRED = 'SENDER_IS_REQUIRED',
    RECIPIENT_MOBILE_NUMBER_IS_REQUIRED = 'RECIPIENT_MOBILE_NUMBER_IS_REQUIRED',
    RECIPIENT_NAME_IS_REQUIRED = 'RECIPIENT_NAME_IS_REQUIRED',
    RECIPIENT_EMAIL_IS_REQUIRED = 'RECIPIENT_EMAIL_IS_REQUIRED',
    LAUNCH_DATE_FROM_PAST = 'LAUNCH_DATE_FROM_PAST',
    START_BATCH_SIZE_IS_REQUIRED = 'START_BATCH_SIZE_IS_REQUIRED',
    DAILY_PERCENT_RAMP_UP_IS_REQUIRED = 'DAILY_PERCENT_RAMP_UP_IS_REQUIRED'
}

export const validateCampaign = (campaign: Optional<Campaign, 'name' | 'id'>, config: CustomerCommunicationConfig, recipients?: number) => {
    if (campaign.status === CampaignStatus.SENT) {
        // do not validate already sent campaign
        return [];
    }
    const errors: string[] = [];
    if (checkNameRequired(campaign.name)) {
        errors.push(CampaignValidationErrors.NAME_IS_REQUIRED);
    }
    if (checkRecipientsRequired(recipients)) {
        errors.push(CampaignValidationErrors.RECIPIENTS_ARE_REQUIRED);
    }
    if (checkRecipientsFilterRequired(campaign.campaignRecipientsFilter)) {
        errors.push(CampaignValidationErrors.RECIPIENTS_FILTER_IS_REQUIRED);
    }
    if (checkTemplateRequired(campaign.template)) {
        errors.push(CampaignValidationErrors.TEMPLATE_IS_REQUIRED);
    }
    if (campaign.type === CampaignType.SMS) {
        if (checkContentTooLong(campaign.template || '', config.smsMaxMessages)) {
            errors.push(CampaignValidationErrors.CONTENT_TOO_LONG);
        }
    }
    if (campaign.type === CampaignType.EMAIL) {
        if (checkSubjectRequired(campaign.subject)) {
            errors.push(CampaignValidationErrors.SUBJECT_IS_REQUIRED);
        }
        if (checkSenderRequired(campaign)) {
            errors.push(CampaignValidationErrors.SENDER_IS_REQUIRED);
        }
    }
    return errors;
}

export const validateCampaignTestModel = (testCampaign: CampaignTestModel) => {
    const errors: string[] = [];
    if (testCampaign.type === CampaignType.SMS) {
        if (checkRecipientMobileNumberRequired(testCampaign.recipientMobileNumber)) {
            errors.push(CampaignValidationErrors.RECIPIENT_MOBILE_NUMBER_IS_REQUIRED);
        }
    }
    if (testCampaign.type === CampaignType.EMAIL) {
        if (checkRecipientNameRequired(testCampaign.recipientName)) {
            errors.push(CampaignValidationErrors.RECIPIENT_NAME_IS_REQUIRED);
        }
        if (checkRecipientEmailRequired(testCampaign.recipientEmail)) {
            errors.push(CampaignValidationErrors.RECIPIENT_EMAIL_IS_REQUIRED);
        }
    }
    return errors;
}

export const validateLaunchCampaignModel = (launchCampaign: LaunchCampaignModel) => {
    const errors: string[] = [];
    if (checkLaunchDateFromPast(launchCampaign.launchDate)) {
        errors.push(CampaignValidationErrors.LAUNCH_DATE_FROM_PAST);
    }
    if (checkStartBatchSizeRequired(launchCampaign.startBatchSize)) {
        errors.push(CampaignValidationErrors.START_BATCH_SIZE_IS_REQUIRED);
    }
    if (checkDailyPercentRampUpRequired(launchCampaign.dailyPercentRampUp)) {
        errors.push(CampaignValidationErrors.DAILY_PERCENT_RAMP_UP_IS_REQUIRED);
    }
    return errors;
}

type IsError = boolean;
export const checkContentTooLong = (template: string | null, maxMessages: number): IsError => SmsCounter.count(template).messages > maxMessages;
export const checkNameRequired = (name?: string | null): IsError => !name;
export const checkSenderRequired = (sender: CampaignSender): IsError => !sender.senderAddress || !sender.senderName;
export const checkSubjectRequired = (subject?: string | null): IsError => !subject;
export const checkTemplateRequired = (template?: string | null): IsError => !template;
export const checkRecipientNameRequired = (name?: string | null): IsError => !name;
export const checkRecipientEmailRequired = (email?: string | null): IsError => !email;
export const checkRecipientMobileNumberRequired = (mobileNumber?: string | null): IsError => !mobileNumber;
export const checkStartBatchSizeRequired = (startBatchSize?: number | null): IsError => startBatchSize === null || startBatchSize === undefined;
export const checkLaunchDateFromPast = (date?: string): IsError => moment() > moment(date);
export const checkDailyPercentRampUpRequired = (dailyPercentRampUp?: number | null): IsError => dailyPercentRampUp === null || dailyPercentRampUp === undefined;
export const checkRecipientsRequired = (recipients?: number): IsError => (recipients !== undefined && recipients === 0);
export const checkRecipientsFilterRequired = (filter?: CampaignRecipientsFilter | null): IsError => !filter || JSON.stringify(filter) === JSON.stringify(campaignRecipientsDefaultFilter);