import {AdditionalInformation} from "../../../common/models/AdditionalInformation";
import {AxiosClientFactory} from "../../../common/helpers/AxiosClientFactory";
import {AxiosResponse} from "axios";


export const putAdditionalInformation = async (applicantId: number, additionalInformation: AdditionalInformation): Promise<string> => {
    if (additionalInformation.id) {
        return new Promise<string>((resolve) => {
            AxiosClientFactory
                .getInstance()
                .put(`api/applicants/${applicantId}/additionalInformation/${additionalInformation.id}`, additionalInformation)
                .then((response: AxiosResponse<string>) => resolve(response.data));
        });
    }
    return new Promise<string>((resolve) => {
        AxiosClientFactory
            .getInstance()
            .post(`api/applicants/${applicantId}/additionalInformation`, additionalInformation)
            .then((response: AxiosResponse<string>) => resolve(response.data));
    });
}