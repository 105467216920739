import React, {FunctionComponent, ReactNode} from 'react';
import Tooltip from '../../../../common/components/Tooltip';
import Translation from '../../../../common/components/Translation';
import {appendCurrencySymbolForCountryId} from '../../../../common/helpers/currencySymbolFunctions';
import {BankStatus} from '../../../../common/models/BankStatus';
import {ProductType} from '../../../../common/models/ProductType';
import {ConsumerLoan} from '../../models/ConsumerLoan';
import {DebtType} from '../../models/DebtType';
import {Redemption} from '../../models/Redemption';
import {Transaction} from '../../models/Transaction';
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {MetadataProps} from "../../../../common/interfaces/MetadataProps";
import {connect} from "react-redux";
import {getEnumTranslationKey} from "../../../../common/helpers/getTranslationKey";
import NumberInputField, {NumberInputFieldKind} from "../../../../common/components/input-fields/NumberInputField";

interface ConsumerDebtsProps extends LocalizeContextProps, MetadataProps {
    consumerLoan: ConsumerLoan | undefined;
    countryId: number;
}
const ConsumerDebts: FunctionComponent<ConsumerDebtsProps> = (props) => {
    if (props.consumerLoan === undefined) {
        return null;
    }

    const paidTransaction = props.consumerLoan.transactions.find(t => t.bankStatus === BankStatus.Paid);

    if (!paidTransaction) {
        return null;
    }

    return (<div className="consumer-debts">
        <span className="title">
            <Translate id="LOANS" />
        </span>
        <table className="table table-borderless applicants-table">
            <thead><tr><th>Monthly payment</th><th>Repayment period</th><th>Loan type</th><th>Balance</th><th></th></tr></thead>
            <tbody>
                {renderRow("last-paid", getLoanMonthlyCost(paidTransaction), renderProductType(props.consumerLoan), paidTransaction.repaymentPeriod, paidTransaction.amount, renderStatus(paidTransaction, props), props.countryId)}
                {props.consumerLoan.redemptions.sort(compareRedemptions).map((r) => renderRow(`$r-${r.id}`, r.repaymentMonthlyCost, renderDebtType(r) , r.remainingRepaymentPeriod, r.balance, '', props.countryId))}
            </tbody>
        </table>
        <div className="total">
            <NumberInputField
                name="total"
                value={getTotal(props.consumerLoan, paidTransaction)}
                descriptionKey="TOTAL"
                kind={NumberInputFieldKind.Money}
                readonlyPrecision={0}
                countryId={props.countryId}
            />
        </div>
    </div>);
};

const compareRedemptions = (r1: Redemption, r2: Redemption) => r2.balance - r1.balance;

const getLoanMonthlyCost = (pt: Transaction) => pt.monthlyCost && pt.monthlyCost + (pt.installmentFee || 0);

const renderProductType = (cl: ConsumerLoan) => <Translation id={ProductType[cl.details.productType || ProductType.ConsumerLoan]} prefix="PRODUCTS" />;

const renderStatus = (pt: Transaction, props: ConsumerDebtsProps) => <Tooltip content={getTransactionDescription(pt, props)}><div className="insurance-status status status-green"><Translation id={`${BankStatus[pt.bankStatus || BankStatus.Paid]}`} prefix="BANK_STATUS"/></div></Tooltip>;

const renderDebtType = (r: Redemption) => <Translation id={DebtType[r.debtType]} prefix="DEBT_TYPE" />;

const renderRow = (key: string, monthly: number | undefined, debtType: ReactNode, repaymentPeriod: number | undefined, balance: number | undefined, extra: ReactNode, countryId: number) => 
    <tr key={key}><td>{appendCurrencySymbolForCountryId(monthly, countryId)}</td><td>{repaymentPeriod || '-'}</td><td>{debtType}</td><td>{appendCurrencySymbolForCountryId(balance, countryId)}</td><td>{extra}</td></tr>;

const getTotal = (cl: ConsumerLoan, pt: Transaction) => cl.redemptions.reduce((p, c) => p + (c.repaymentMonthlyCost || 0), getLoanMonthlyCost(pt) || 0);

const getTransactionDescription = (transaction: Transaction, props: ConsumerDebtsProps) => {
    const status = props.translate(getEnumTranslationKey(BankStatus, transaction.bankStatus, 'BANK_STATUS')).toString();
    const bank = props.metadata.banks.find(b => b.id === transaction.bankId)!.name;
    const date = (new Date(props.consumerLoan!.details.paymentDate || transaction.modifiedOn)).toLocaleDateString();
    return <Translate id="TRANSACTION_DESCRIPTION" data={{status, date, bank}}/>
}

const mapStateToProps = (state: any) => ({
    ...state.metadataActionsReducer
});

export default connect<MetadataProps, {}, {}, {}>(mapStateToProps)(withLocalize(ConsumerDebts));