import { faExclamationTriangle, faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { ToastMessage, ToastMessageType } from '../models/ToastMessage';
import { hideToastMessage } from '../actions/ToastMessagesActionCreator';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Toast } from 'react-bootstrap';

interface ToastMessagesStateProps {
    toastMessages: ToastMessage[];
}

interface ToastMessagesDispatchProps {
    hideToastMessage: typeof hideToastMessage;
}

const ToastMessages: FunctionComponent<ToastMessagesStateProps & ToastMessagesDispatchProps> = (props) => {
    if (props.toastMessages === undefined || props.toastMessages.length === 0) {
        return null;
    }

    return (
        <div className="toast-container">
            {props.toastMessages.map((t) => renderToast(t, props.hideToastMessage))}
        </div>
    );
};

function renderToast(toastMessage: ToastMessage, hideMessage: (id: string) => void) {
    const onClose = () => hideMessage(toastMessage.id);
    return (
        <Toast key={toastMessage.id} className={toastMessage.messageType} delay={getDelay(toastMessage)} autohide={true} onClose={onClose}>
            <div className="icon">
                {renderIcon(toastMessage.messageType)}
            </div>
            <div className="centered">
                <strong className="me-auto">{toastMessage.title}</strong>
                {renderBody(toastMessage)}
            </div>
            <div className="centered">
                <span onClick={onClose} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faTimes} />
                </span>
            </div>
        </Toast>
    );
}

function getDelay(toastMessage: ToastMessage) {
    return toastMessage.messageType === 'error' ? 5000 : 3000;
}

function renderIcon(messageType: ToastMessageType) {
    if (messageType === 'error') {
        return <FontAwesomeIcon icon={faExclamationTriangle} />;
    }

    return <FontAwesomeIcon icon={faCheckCircle} />;
}

function renderBody(toastMessage: ToastMessage) {
    const content = Array.isArray(toastMessage.content) ? toastMessage.content : [toastMessage.content];
    return content.map((c, i) => <div key={i}>{c}</div>);
}

const mapStateToProps = (state: any) => ({
    ...state.toastMessageActionsReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    hideToastMessage
}, dispatch);

export default connect<ToastMessagesStateProps, ToastMessagesDispatchProps, {}, any>(mapStateToProps, mapActionCreatorsToProps)(ToastMessages);
