import {
    GetSettingsAction,
    GET_SETTINGS_BEGIN,
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAILURE
} from '../actions/SettingsActionCreators';

export function settingsActionsReducer(state: any, action: GetSettingsAction) {
    state = state || {
        settingsData: {
            settings: [],
            isLoading: false,
            isLoaded: false,
            error: undefined
        }
    };
    switch (action.type) {
        case GET_SETTINGS_BEGIN:
            return {
                ...state,
                settingsData: {
                    settings: [],
                    isLoaded: false,
                    isLoading: true
                }
            };
        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                settingsData: {
                    settings: action.settings,
                    isLoaded: true,
                    isLoading: false
                }
            };
        case GET_SETTINGS_FAILURE:
            return {
                ...state,
                settingsData: {
                    error: action.error,
                    isLoaded: true,
                    isLoading: false
                }
            };
        default:
            return state;
    }
}
