import React, {FunctionComponent, useEffect, useState} from "react";
import "./Summary.css";
import {ConsumerLoan} from "../../../../models/ConsumerLoan";
import {NewLoanData} from "../NewLoan/NewLoan";
import NumberInputField, {NumberInputFieldKind} from "../../../../../../common/components/input-fields/NumberInputField";
import {Translate} from "react-localize-redux";
import {LoansData} from "../Loans/Loans";
import {calculateLTV, calculateMortgageLoan} from "../../../../helpers/calculators";

interface SummaryProps {
    application: ConsumerLoan;
    taxEffectPercentage: number;
    stressTestIncreaseByPercentage: number;
    newLoanData?: NewLoanData;
    totalIncomes: number;
    totalExpenses: number;
    loansData?: LoansData;
}
const Summary: FunctionComponent<SummaryProps> = (props) => {
    
    const calculateMonthlyPayments = () => {
        const loansMonthlyCosts = props.loansData ? props.loansData.totalMonthlyCosts : 0;
        const newLoanMonthlyCost = props.newLoanData ? props.newLoanData.monthlyCost : 0;
        return loansMonthlyCosts + newLoanMonthlyCost;
    }

    const calculateLiquidityBeforeIncrease = () => {
        return props.totalIncomes - props.totalExpenses - calculateMonthlyPayments();
    }
    
    const calculateTotalLoansAmount = () => {
        const totalLoansAmount = props.loansData ? props.loansData.totalLoansAmount : 0;
        const newLoanAmount = props.newLoanData ? props.newLoanData.loanAmount : 0;
        return totalLoansAmount + newLoanAmount;
    }
    
    const calculateIncreasedMonthlyPayments = () => {
        const monthlyPaymentsAfterIncrease = calculateMonthlyPayments() + calculateTotalLoansAmount() * props.stressTestIncreaseByPercentage / 100 / 12 * (100 - props.taxEffectPercentage) / 100;
        return parseFloat(monthlyPaymentsAfterIncrease.toFixed(2));
    }

    const calculateLiquidityAfterIncrease = () => {
        return props.totalIncomes - props.totalExpenses - calculateIncreasedMonthlyPayments();
    }

    const calculateIncomeDebtRatio = () => {
        const grossYearlyIncome = props.application.applicant.grossYearlyIncome + ((props.totalIncomes - props.application.applicant.afterTaxMonthlyIncome) * 12);
        return parseFloat((calculateTotalLoansAmount() / grossYearlyIncome).toFixed(2));
    }

    const calculateMortgageLoans = () => {
        const newLoanAmount = props.newLoanData ? props.newLoanData.loanAmount : 0;
        const notRefinancedMortgageLoansAmount = calculateMortgageLoan(props.application.redemptions, true);
        return newLoanAmount + notRefinancedMortgageLoansAmount;
    }
    
    const calculateLtvPercent = () => {
        const ltv = calculateLTV(
            calculateMortgageLoans(), 
            props.application.property ? props.application.property.totalCommonDebt : undefined, 
            props.application.property ? props.application.property.totalEstimatedValue : undefined
        );
        return parseFloat((ltv * 100).toFixed(1));
    }
    
    const [liquidityBeforeIncrease, setLiquidityBeforeIncrease] = useState<number>(calculateLiquidityBeforeIncrease());
    const [liquidityAfterIncrease, setLiquidityAfterIncrease] = useState<number>(calculateLiquidityAfterIncrease());
    const [incomeDebtRatio, setIncomeDebtRatio] = useState<number>(calculateIncomeDebtRatio());
    const [ltvPercent, setLtvPercent] = useState<number>(calculateLtvPercent());

    useEffect(() => {
        setLiquidityBeforeIncrease(calculateLiquidityBeforeIncrease());
        setLiquidityAfterIncrease(calculateLiquidityAfterIncrease());
        setIncomeDebtRatio(calculateIncomeDebtRatio());
        setLtvPercent(calculateLtvPercent())
    }, [props.newLoanData, props.totalIncomes, props.totalExpenses, props.loansData, props.stressTestIncreaseByPercentage]); // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <>
            <span className="title">
                <Translate id="SUMMARY" />
            </span>
            <div className="row">
                <div className="col-3">
                    <NumberInputField
                        className={liquidityBeforeIncrease < 0 ? 'error' : 'success'}
                        name="liquidityBeforeIncrease"
                        value={liquidityBeforeIncrease}
                        descriptionKey="LIQUIDITY_BEFORE_INCREASE"
                        kind={NumberInputFieldKind.Money}
                        readonlyPrecision={0}
                        countryId={props.application.applicant.countryId}
                    />
                </div>
                <div className="col-3">
                    <NumberInputField
                        className={liquidityAfterIncrease < 0 ? 'error' : 'success'}
                        name="liquidityAfterIncrease"
                        value={liquidityAfterIncrease}
                        description={
                            <Translate
                                id="LIQUIDITY_AFTER_INCREASE_BY_PERCENTAGE"
                                data={{percentage: props.stressTestIncreaseByPercentage}}
                            />
                        }
                        kind={NumberInputFieldKind.Money}
                        readonlyPrecision={0}
                        countryId={props.application.applicant.countryId}
                    />
                </div>
                <div className="col-3">
                    <NumberInputField
                        name="incomeDebtRatio"
                        value={incomeDebtRatio}
                        descriptionKey="DEBT_RATIO"
                        countryId={props.application.applicant.countryId}
                    />
                </div>
                {ltvPercent !== Infinity &&
                    <div className="col-3">
                        <NumberInputField
                            className={ltvPercent > 85 ? 'error' : 'success'}
                            name="ltv"
                            value={ltvPercent}
                            descriptionKey="PROPERTY.LTV"
                            kind={NumberInputFieldKind.Percent}
                            countryId={props.application.applicant.countryId}
                        />
                    </div>
                }
            </div>
        </>
    );
}
export default Summary;