import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {forwardRef} from 'react';
import InputField, {InputFieldProps} from './InputField';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import {formatDate, formatDateWithTime} from '../../helpers/dateFormatter';
import {LocalizeContextProps, withLocalize} from 'react-localize-redux';
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB';
import {FormControl, InputGroup} from 'react-bootstrap';
import {useValidation, ValidationProps} from '../../helpers/useValidation';
import DropdownInputField from './DropdownInputField';
import {SelectableItem} from '../../models/SelectableItem';
import * as Popper from "@popperjs/core";
import moment from "moment";

registerLocale('en-GB', enGB);
setDefaultLocale('en-GB'); // TODO: Replace with language from current culture

export enum DateTimeInputFieldKind {
    Date = 'date',
    LongDate = 'long-date',
    DateWithTime = 'date-time',
    YearWithMonth = 'year-month',
    Year = 'year'
}

interface DateTimeInputFieldProps extends InputFieldProps, LocalizeContextProps, ValidationProps {
    name: string;
    value: Date | string | null | undefined;
    kind?: DateTimeInputFieldKind;
    valuePostfix?: string;
    disabled?: boolean;
    popperPlacement?: Popper.Placement | undefined;
    defaultOpen?: boolean;
    timeFrom?: number;
    timeTo?: number;
    onValueChanged?: (value: Date | null, name: string) => void;
    onCalendarClose?: () => void;
}

interface CustomDateTimeInputProps {
    value?: string;
    disabled?: boolean;
    onClick?: any;
    onChange?: any;
    inputRef: React.RefObject<HTMLInputElement>;
    placeholderText?: string;
}

function DateTimeInputField<WrapperProps>(props: DateTimeInputFieldProps) {
    const [errorCode, internalError, ref, onTouched] = useValidation(props);

    if (props.kind === DateTimeInputFieldKind.Year) {
        return renderYearPicker(props);
    }

    return (
        <InputField<WrapperProps>
            className={props.className}
            afterContent={props.afterContent}
            descriptionKey={props.descriptionKey} 
            description={props.description} 
            editMode={props.editMode}
            error={internalError}
            errorCode={errorCode}
            wrapper={props.wrapper}
            wrapperProps={props.wrapperProps}
        >
            {renderValue(props, ref, onTouched)}
        </InputField>
    );
}

function renderValue(props: DateTimeInputFieldProps, ref: React.RefObject<HTMLInputElement>, onTouched: () => void) {
    if (!props.editMode) {
        if (props.value === undefined || props.value === null) {
            return '-';
        }

        if (props.kind && props.kind !== DateTimeInputFieldKind.DateWithTime) {
            return <span>{formatDate(props.value, props.translate)}</span>;
        }

        return <span>{formatDateWithTime(props.value, props.translate)}</span>;
    }

    const selected = props.value ? new Date(props.value) : null;
    const onValueChanged = (value: any) => {
        onTouched();
        if (props.onValueChanged) {
            props.onValueChanged(value, props.name);
        }
    };

    const placeholderText = props.placeholderKey && props.translate(props.placeholderKey).toString();
    const timeInterval = 5;

    return (
        <DatePicker
            includeTimes={props.kind === DateTimeInputFieldKind.DateWithTime ? getIncludedTimes(props.timeFrom, props.timeTo, timeInterval) : []}
            startOpen={props.defaultOpen}
            selected={selected}
            onChange={onValueChanged}
            dateFormat={getTimeFormat(props.kind)}
            showTimeSelect={props.kind === DateTimeInputFieldKind.DateWithTime}
            timeFormat="HH:mm"
            timeIntervals={timeInterval}
            showMonthYearPicker={props.kind === DateTimeInputFieldKind.YearWithMonth}
            customInput={<CustomDateTimeInputRef inputRef={ref} placeholderText={placeholderText} />}
            disabled={props.disabled}
            popperPlacement={props.popperPlacement}
            onCalendarClose={props.onCalendarClose}
        />
    );
}

const CustomDateTimeInput = (props: CustomDateTimeInputProps, ref: any) => (
    <InputGroup ref={ref}>
        <FormControl
            type="text"
            value={props.value || ''}
            onChange={props.onChange}
            onClick={props.onClick}
            disabled={props.disabled}
            ref={props.inputRef as any}
            placeholder={props.placeholderText}
        />
        <InputGroup.Text onClick={props.disabled ? undefined : props.onClick}>
            <FontAwesomeIcon icon={faCalendarAlt} />
        </InputGroup.Text>
    </InputGroup>
);

const CustomDateTimeInputRef = forwardRef(CustomDateTimeInput);

const currentYear = new Date().getFullYear();
const years = Array.apply(null, Array(currentYear - 1900 + 1)).map((_, i) => ({ id: currentYear - i, name: (currentYear - i).toString() } as SelectableItem<number>));

function renderYearPicker(props: DateTimeInputFieldProps) {
    const value = props.value ? new Date(props.value).getFullYear() : undefined;
    const onValueChanged = (year: string | number | null | undefined) =>
        props.onValueChanged && props.onValueChanged(new Date(year as number, 0, 1, 0, 0, 0, 0), props.name);
    return (
        <DropdownInputField
            {...props}
            value={value}
            onValueChanged={onValueChanged}
            items={years}
        />
    );
}

function getTimeFormat(kind?: DateTimeInputFieldKind) {
    switch (kind) {
        case DateTimeInputFieldKind.YearWithMonth:
            return 'MMMM yyyy';
        case DateTimeInputFieldKind.Date:
            return 'dd/M-yy';
        case DateTimeInputFieldKind.LongDate:
            return 'dd.MM.yyyy';
        default:
            return 'dd/M-yy HH:mm';
    }
}

function getIncludedTimes(from = 0, to = 24, interval = 5) {
    const includedTimes: Date[] = [];
    const m = moment(new Date()).set({hours: from, minutes: 0});
    const today = m.day();
    while (m.hours() < to && m.day() === today) {
        includedTimes.push(m.toDate());
        m.add(interval, 'minutes');
    }
    return includedTimes;
}

export default withLocalize(DateTimeInputField);
