import { faAngleDown, faAngleUp, faCog, faComment, faHourglassHalf, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, ReactNode, MouseEvent } from 'react';
import { Bank } from '../../models/Bank';
import { Transaction } from '../../models/Transaction';
import Tooltip from '../../../../common/components/Tooltip';
import { BankStatus } from '../../../../common/models/BankStatus';
import { Translate } from 'react-localize-redux';
import { isWaitingForBankReply } from '../../helpers/transactionsLogic';

interface BankRepliesRowIconsProps {
    bank: Bank;
    transaction: Transaction | undefined;
    expandedBankIds: number[];
    onToggleExpanded: (bankId: number, event: MouseEvent) => void;
}

function createStatusIcon(key: string, icon: IconDefinition, tooltip: ReactNode | string, spin: boolean) {
    return <span key={key} className="status-icon"><Tooltip id="br-comment-icon" content={tooltip}><FontAwesomeIcon icon={icon} spin={spin}/></Tooltip></span>;
}

function getStatusIcon(transaction: Transaction | undefined): ReactNode[] {
    const result: ReactNode[] = [];

    if (transaction === undefined) {
        return result;
    }

    if (transaction.bankStatus === BankStatus.GrantedAccepted || transaction.bankStatus === BankStatus.GrantedRejected) {
        if (transaction.isBankReportProcessed === true) {
            result.push(createStatusIcon('br-waiting', faHourglassHalf, <Translate id="WAITING_FOR_BANK_STATUS"/>, false));
        }

        result.push(createStatusIcon('br-sending-to-bank', faCog, <Translate id={getSendingToBankTooltipKey(transaction.bankStatus)}/>, true));
    } else {
        if (transaction.comment) {
            result.push(createStatusIcon('br-comment-icon', faComment, transaction.comment, false));
        }

        if (isWaitingForBankReply(transaction)) {
            result.push(createStatusIcon('br-sent-to-bank', faCog, <Translate id="SENT_TO_BANK"/>, true));
        } else if (!transaction.isManually) {
            result.push(createStatusIcon('br-not-manual-icon', faCog, <Translate id="BANK_INTEGRATION"/>, false));
        }
    }

    return result;
}

function getSendingToBankTooltipKey(bankStatus?: BankStatus) {
    if (bankStatus === BankStatus.GrantedAccepted) {
        return 'SENDING_ACCEPT_TO_BANK';
    }

    return 'SENDING_REJECT_TO_BANK';
}

const BankRepliesRowIcons: FunctionComponent<BankRepliesRowIconsProps> = (props) => {
    const expansionIcon = props.expandedBankIds.some(id => id === props.bank.id) ? faAngleUp : faAngleDown;
    const toggleExpanded = (e: MouseEvent) => props.onToggleExpanded(props.bank.id, e);

    return (
        <td>
            {getStatusIcon(props.transaction)}
            <span className="grey-button" onClick={toggleExpanded}><FontAwesomeIcon icon={expansionIcon} /></span>
        </td>
    );
};

export default BankRepliesRowIcons;
