import React, {FunctionComponent, useEffect, useState} from "react";
import "./MortgageCalculatorModal.css";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {ConsumerLoan} from "../../models/ConsumerLoan";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {getMortgageCalculatorOptions} from "../../../../common/helpers/settingsHelpers";
import {SettingsProps} from "../../../../common/interfaces/SettingsProps";
import {connect} from "react-redux";
import NewLoan, {NewLoanData} from "./Sections/NewLoan/NewLoan";
import Incomes from "./Sections/Incomes/Incomes";
import Expenses from "./Sections/Expenses/Expenses";
import Loans, {LoansData} from "./Sections/Loans/Loans";
import Summary from "./Sections/Summary/Summary";
import {getAdditionalInformation} from "../../api/getAdditionalInformation";
import {AdditionalInformation} from "../../../../common/models/AdditionalInformation";
import Footer from "./Sections/Footer/Footer";
import DraggableModalDialog from "../../../../common/components/DraggableModalDialog";
import {putAdditionalInformation} from "../../api/putAdditionalInformation";
import {showToastMessage, ShowToastMessageProps} from "../../../../common/actions/ToastMessagesActionCreator";
import {bindActionCreators} from "redux";
import {putConsumerLoan, UpdateConsumerLoanCommand} from "../../api/putConsumerLoan";
import {ConsumerLoanDetails} from "../../models/ConsumerLoanDetails";
import {putConsumerLoanDetails} from "../../api/putConsumerLoanDetails";
import {File} from "../../../../common/helpers/File";
import {exportEconomyOverview} from "../../../applicants/api/export";
import {getBanks} from "../../api/getBanks";
import {ProductType} from "../../../../common/models/ProductType";
import {Redemption} from "../../models/Redemption";
import {putRedemptions} from "../../api/putRedemptions";
import {MortgageCalculatorOptions} from "./MortgageCalculatorOptions";

interface MortgageCalculatorModalProps extends SettingsProps, ShowToastMessageProps, LocalizeContextProps {
    application: ConsumerLoan;
    updateApplication: (application: ConsumerLoan) => void;
    personId: number;
    show: boolean;
    onClose: () => void;
}
const MortgageCalculatorModal: FunctionComponent<MortgageCalculatorModalProps> = (props) => {
    const [options, setOptions] = useState<MortgageCalculatorOptions>(getMortgageCalculatorOptions(props));
    const [applicantAdditionalInformation, setApplicantAdditionalInformation] = useState<AdditionalInformation[]>([]);
    const [banks, setBanks] = useState<string[]>([]);
    
    useEffect(() => {
        getBanks().then(response => {
            const activeBanksNames = response.data
                .filter(bank => bank.active && bank.bankIntegrations.some(integration => integration.productType === ProductType.Mortgage))
                .map(bank => bank.name);
            setBanks(activeBanksNames);
        });
    }, []);
    
    useEffect(() => {
        if (props.show) {
            getAdditionalInformation(props.application.applicant.id).then(response => setApplicantAdditionalInformation(response.data || []));
        }
    }, [props.application.applicant.id, props.show]);

    const updateAdditionalInformation = (expense: AdditionalInformation) => {
        putAdditionalInformation(props.application.applicant.id, expense).then(
            (success) => {
                props.showToastMessage('success', props.translate('UPDATE_APPLICATION_SUCCESS').toString(), success);
            }
        );
    }
    
    const updateApplication = (application: ConsumerLoan) => {
        const command: UpdateConsumerLoanCommand = {
            personId: props.personId,
            applicationId: props.application.id,
            ...application
        };
        putConsumerLoan(command).then(
            (result) => {
                if (result.success) {
                    props.updateApplication(application)
                    props.showToastMessage('success', props.translate('UPDATE_APPLICATION_SUCCESS').toString(), '');
                }
            }
        );
    }
    
    const updateApplicationDetails = (details: ConsumerLoanDetails) => {
        putConsumerLoanDetails(props.personId, props.application.id, details).then(
            (success) => {
                props.updateApplication({
                    ...props.application,
                    details
                })
                props.showToastMessage('success', props.translate('UPDATE_APPLICATION_SUCCESS').toString(), success);
            }
        );
    }
    
    const updateRedemptions = (redemptions: Redemption[]) => {
        putRedemptions(props.personId, props.application.id, redemptions).then(
            (success) => {
                props.updateApplication({
                    ...props.application,
                    redemptions
                });
                props.showToastMessage('success', props.translate('UPDATE_APPLICATION_SUCCESS').toString(), success);
            }
        );
    }

    const generateEconomyOverview = (bank: string | null, purpose: string | null) => new Promise<File>(resolve => {
        exportEconomyOverview(props.application.id, bank, purpose).then(blob => resolve(new File(`${props.application.id} - economy overview`, blob)))
    });
    
    // New loan
    const [newLoanData, setNewLoanData] = useState<NewLoanData>();

    const renderNewLoanSection = () => (
        <NewLoan
            {...props}
            {...options}
            applicantAdditionalInformation={applicantAdditionalInformation}
            onChange={(data) => setNewLoanData(data)}
            mortgageCalculationChange={updateAdditionalInformation}
            applicationDetailsChange={updateApplicationDetails}
            stressTestChange={value => setOptions({...options, stressTestIncreaseByPercentage: value})}
        />
    );

    // Incomes
    const [totalIncomes, setTotalIncomes] = useState<number>(0);
    const renderIncomesSection = () => (
        <Incomes
            {...props}
            {...options}
            applicantAdditionalInformation={applicantAdditionalInformation}
            newLoanData={newLoanData}
            onChange={(total) => setTotalIncomes(total)}
            incomeChange={updateAdditionalInformation}
            applicationChange={updateApplication}
        />
    );
    
    // Expenses
    const [totalExpenses, setTotalExpenses] = useState<number>(0);
    const renderExpensesSection = () => (
        <Expenses
            {...props}
            {...options}
            applicantAdditionalInformation={applicantAdditionalInformation}
            onChange={(total) => setTotalExpenses(total)}
            expenseChange={updateAdditionalInformation}
        />
    );
    
    // Loans
    const [loansData, setLoansData] = useState<LoansData>();
    const renderLoansSection = () => (
        <Loans
            {...props}
            onChange={(data) => setLoansData(data)}
            redemptionsChange={updateRedemptions}
        />
    )
    
    // Summary
    const renderSummarySection = () => (
        <Summary 
            {...props}
            {...options}
            newLoanData={newLoanData} 
            totalIncomes={totalIncomes} 
            totalExpenses={totalExpenses} 
            loansData={loansData} 
        />
    );

    return (
        <Modal 
            show={props.show} 
            dialogAs={DraggableModalDialog}
            centered={true} 
            size="xl" 
            onHide={props.onClose}
        >
            <Modal.Header>
                <Modal.Title>
                    <Translate id="MORTGAGE_CALCULATOR" />
                </Modal.Title>
                <button 
                    onClick={props.onClose} 
                    style={{border: 'none', background: 'transparent'}}
                >
                    <FontAwesomeIcon icon={faTimes}/>
                </button>
            </Modal.Header>
            <Modal.Body style={{paddingTop: 0}}>
                <div className="mortgage-calculator">
                    {renderNewLoanSection()}
                    <div className="row">
                        <div className="col-6">
                            {renderIncomesSection()}
                        </div>
                        <div className="col-6">
                            {renderExpensesSection()}
                        </div>
                    </div>
                    {renderLoansSection()}
                    {renderSummarySection()}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Footer 
                    applicationId={props.application.id}
                    purpose={props.application.details.purpose}
                    banks={banks}
                    getEconomyOverViewClick={generateEconomyOverview}
                />
            </Modal.Footer>
        </Modal>
    )
}
const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer
});
const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators({
    showToastMessage
} as any, dispatch);
export default connect<SettingsProps, ShowToastMessageProps, {}, any>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(MortgageCalculatorModal));