import React, {FunctionComponent, useEffect, useState} from "react";
import "./CampaignsView.css";
import {SettingsProps} from "../../common/interfaces/SettingsProps";
import {getMainBackgroundColorStyle} from "../../common/helpers/settingsHelpers";
import {connect} from "react-redux";
import RefreshButton from "../../common/components/RefreshButton";
import {Dropdown, Table} from "react-bootstrap";
import {CampaignStatus, CampaignSummary, CampaignType} from "../../common/models/Campaign";
import TableHeader, {TableHeaderCellModel} from "../../common/components/table/TableHeader";
import TableRows from "../../common/components/table/TableRows";
import {Translate} from "react-localize-redux";
import TablePagination from "../../common/components/table/TablePagination";
import {bindActionCreators, Dispatch} from "redux";
import {CampaignsViewDataState} from "../reducers/campaignsViewDataReducer";
import {CampaignsViewDataActionCreators, CampaignsViewDataActions} from "../actions/CampaignsViewDataActionCreators";
import CampaignsViewFilter from "./CampaignsViewFilter";
import {countCampaigns} from "../api/countCampaigns";
import {faBars, faEnvelope, faMobileAlt} from "@fortawesome/free-solid-svg-icons";
import {NavigateFunction, useNavigate} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SetApplicationTitleActionProps} from "../../common/interfaces/SetApplicationTitleActionProps";
import {SetTitleActionCreator} from "../../common/actions/SetTitleActionCreator";
import {CampaignViewDataActionCreators, CampaignViewDataActions} from "../actions/CampaignViewDataActionCreators";

type CampaignsViewStateProps = CampaignsViewDataState & SettingsProps;
type CampaignsViewActionProps = CampaignsViewDataActions & Pick<CampaignViewDataActions, 'editCampaign'> & SetApplicationTitleActionProps;
type CampaignsViewProps =  CampaignsViewStateProps & CampaignsViewActionProps;

const CampaignsView: FunctionComponent<CampaignsViewProps> = (props) => {
    const navigate = useNavigate();
    const [campaignsCount, setCampaignsCount] = useState<number>(props.campaigns.length);

    useEffect(() => {
        props.setTitle('Campaigns');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        refreshCampaigns();
    }, [props.filter, props.tableState]); // eslint-disable-line react-hooks/exhaustive-deps

    const refreshCampaigns = () => {
        props.loadCampaigns({...props.filter, ...props.tableState});
        countCampaigns({...props.filter, ...props.tableState}).then(count => setCampaignsCount(count))
    }
    
    const createCampaign = (type: CampaignType) => {
        props.editCampaign({type, campaignRecipientsFilter: null }, false);
        navigate('/campaign/new');
    }

    return (
        <main className="main-content" style={getMainBackgroundColorStyle(props)}>
            <div
                className="jumbotron text-center bg-white flex-column justify-content-center row pb-4 pt-4 px-0 campaigns-view">
                <div className="col-12 toolbar px-5">
                    <CampaignsViewFilter {...props}/>
                    <Dropdown className="actions" drop="down" align="end">
                        <Dropdown.Toggle id="application-menu" className="btn-white-bg" style={{ padding: '3px 12px' }}>
                            <FontAwesomeIcon icon={faBars} />
                        </Dropdown.Toggle>
                        <div className="dropdown-arrow-down" />
                        <Dropdown.Menu>
                            <Dropdown.Item key="new-campaign-sms" onClick={() => createCampaign(CampaignType.SMS)}>
                                <Translate id="CAMPAIGNS_VIEW.NEW_SMS_CAMPAIGN" />
                            </Dropdown.Item>
                            <Dropdown.Item key="new-campaign-email" onClick={() => createCampaign(CampaignType.EMAIL)}>
                                <Translate id="CAMPAIGNS_VIEW.NEW_EMAIL_CAMPAIGN" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <hr className="table-separator"/>
                <div className="col-12 pt-4 table-container px-5">
                    <RefreshButton
                        style={{position: 'absolute', right: '0'}}
                        isLoading={props.isLoading}
                        onClick={refreshCampaigns}
                    />
                    <Table
                        className="campaigns-table"
                        hover={true}
                        borderless={true}
                    >
                        <TableHeader cells={campaignTableHeaderCells}/> 
                        <TableRows<CampaignSummary>
                            isLoading={props.isLoading}
                            rows={props.campaigns}
                            renderRow={campaign => (
                                <tr 
                                    key={campaign.id}
                                    onAuxClick={() => openCampaign(navigate, true, campaign.id, campaign.status === CampaignStatus.DRAFT)}
                                    onClick={e => openCampaign(navigate, e.ctrlKey, campaign.id, campaign.status === CampaignStatus.DRAFT)}
                                >
                                    <td>{campaign.name}</td>
                                    <td>{renderStatusCell(campaign.status)}</td>
                                    <td>{campaign.recipients}</td>
                                    <td>{campaign.delivered}</td>
                                    <td>{campaign.engagements}</td>
                                    <td>{renderSentAtCell(campaign.sentAt)}</td>
                                    <td>{renderTypeCell(campaign.type)}</td>
                                </tr>
                            )}
                        />
                    </Table>
                </div>
            </div>
            <TablePagination
                isLoading={props.isLoading}
                pageNumber={props.tableState.pageNumber}
                pageSize={props.tableState.pageSize}
                onPagePicked={(pageNumber) => props.setPageNumber(pageNumber)}
                onPageSizePicked={(pageSize) => props.setPageSize(pageSize)}
                count={campaignsCount}
                pickableSizes={[20, 50, 100, 250, 500]}
            />
        </main>
    );
}

const campaignTableHeaderCells: TableHeaderCellModel[] = [
    {index: 0, translateKey: 'CAMPAIGNS_VIEW.TABLE_HEADERS.NAME'},
    {index: 1, translateKey: 'CAMPAIGNS_VIEW.TABLE_HEADERS.STATUS'},
    {index: 2, translateKey: 'CAMPAIGNS_VIEW.TABLE_HEADERS.RECIPIENTS'},
    {index: 3, translateKey: 'CAMPAIGNS_VIEW.TABLE_HEADERS.DELIVERED'},
    {index: 4, translateKey: 'CAMPAIGNS_VIEW.TABLE_HEADERS.ENGAGEMENTS'},
    {index: 5, translateKey: 'CAMPAIGNS_VIEW.TABLE_HEADERS.START_DATE'},
    {index: 6, translateKey: 'CAMPAIGNS_VIEW.TABLE_HEADERS.TYPE'}
]

const renderStatusCell = (status: CampaignStatus) => {
    switch(status) {
        case CampaignStatus.DRAFT:
            return <span className="status status-gray"><Translate id="CAMPAIGN_STATUS.DRAFT"/></span>
        case CampaignStatus.SENT:
            return <span className="status status-blue"><Translate id="CAMPAIGN_STATUS.SENT"/></span>
    }
}

const renderSentAtCell = (sentAt?: string | null) => (
    sentAt
    ? new Date(sentAt).toLocaleDateString()
    : <Translate id={'MESSAGING_EVENT_TYPE.COMMUNICATION_TO_CLIENT.NOT_SENT_YET'}/>
)

const renderTypeCell = (type: CampaignType) => {
    const iconStyle = {fontSize: '17px', color: '#707070', width: '24px'};
    switch(type) {
        case CampaignType.SMS:
            return <FontAwesomeIcon icon={faMobileAlt} style={iconStyle}/>
        case CampaignType.EMAIL:
            return <FontAwesomeIcon icon={faEnvelope} style={iconStyle}/>
    }
}

const openCampaign = (navigate: NavigateFunction, newTab: boolean, campaignId: number, edit: boolean) => {
    const path = `/campaign/${campaignId}${edit ? '/edit' : ''}`;
    if (newTab) {
        window.open(path, '_blank');
    } else {
        navigate(path);
    }
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.campaignsViewDataReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    editCampaign: CampaignViewDataActionCreators.editCampaign,
    ...CampaignsViewDataActionCreators,
    ...SetTitleActionCreator
}, dispatch);

export default connect<CampaignsViewStateProps, CampaignsViewActionProps>(mapStateToProps, mapActionCreatorsToProps)(CampaignsView);