import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

interface InsuranceActionsProps {
    createInsurance: () => void;
}

const InsuranceActions: FunctionComponent<InsuranceActionsProps> = (props) => {
    return (
        <Dropdown drop="down" align="end">
            <Dropdown.Toggle id="application-menu" className="btn-white-bg" style={{ padding: '3px 12px' }}>
                <FontAwesomeIcon icon={faBars} />
            </Dropdown.Toggle>
            <div className="dropdown-arrow-down" />
            <Dropdown.Menu>
                <Dropdown.Item key="applicant-actions-copy-application" onClick={props.createInsurance}>
                    <Translate id="NEW_INSURANCE" />
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default InsuranceActions;
