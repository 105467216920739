import React, { FunctionComponent } from 'react';
import { Image } from 'react-bootstrap';

interface UserImageProps {
	email: string;
	size?: number;
}

export const UserImage: FunctionComponent<UserImageProps> = (props) => {
	if (props.email) {
		return (
			<Image
				height={props.size || 48}
				width={props.size || 48}
				src={`${process.env.REACT_APP_BASE_API_URL}/api/users/${encodeURIComponent(props.email)}/profileimage`}
				roundedCircle={true}
				style={{ objectFit: 'cover' }}
			/>
		);
	} else {
		return <div />;
	}
};
