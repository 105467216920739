import moment from 'moment';
import { TranslateFunction } from 'react-localize-redux';

const dateFormat = 'DD/M-YY';
const nextWeekDateFormat = 'dddd';

export const formatDateWithTime = (date: moment.MomentInput, translate: TranslateFunction): string => {
    if (!date) {
        return '';
    }
    const m = moment(date);
    return formatDate(m, translate) + m.format(' HH:mm');
};

export const formatDate = (date: moment.MomentInput, translate?: TranslateFunction): string => {
    if (!date) {
        return '';
    }

    if (translate === undefined) {
        return moment(date).format(dateFormat);
    }

    return moment(date).calendar(undefined, {
        sameDay: `[${translate('TODAY')}]`,
        nextDay: nextWeekDateFormat,
        nextWeek: nextWeekDateFormat,
        lastDay: `[${translate('YESTERDAY')}]`,
        lastWeek: dateFormat,
        sameElse: dateFormat
    });
};
