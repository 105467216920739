import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { Popover } from 'react-bootstrap';
import { getConsumerLoanLogs } from '../../applicant/api/getConsumerLoanLogs';
import { ConsumerLoanLog } from '../../applicant/models/ConsumerLoanLog';
import LogsAndCommentsTable from '../../applicant/components/logs-and-comments/LogsAndCommentsTable';
import { logsAndCommentsFiltersStorage } from '../../applicant/helpers/logsAndCommentsFiltersStorage';
import { ConsumerLoanLogType } from '../../applicant/models/ConsumerLoanLogType';
import { ConsumerLoanOverview } from '../models/ConsumerLoanOverview';
import { getConsumerLoanOverview } from '../api/getConsumerLoanOverview';
import LoanPicker from '../../applicant/components/LoanPicker';
import { SubstatusesProps } from '../../../common/interfaces/SubstatusesProps';
import SubstatusesActionsCreator from '../actions/SubstatusesActionsCreator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Substatus } from '../models/Substatus';
import { loanStatusToColor } from '../../../common/helpers/loanStatusToColor';
import {Translate} from "react-localize-redux";
import {beautifyName} from "../../../common/helpers/beautifyName";

interface ApplicantTooltipOwnProps {
    personId: number;
    applicationId: number;
    firstName: string | null;
    lastName: string | null;
    hasCoApplicant?: boolean;
}

type ApplicantTooltipProps = ApplicantTooltipOwnProps & SubstatusesProps & typeof SubstatusesActionsCreator;

const logsPageSize = 10;

const ApplicantTooltip: FunctionComponent<ApplicantTooltipProps> = (props: ApplicantTooltipProps) => {
    const [logs, setLogs] = useState<ConsumerLoanLog[]>([]);
    const [logsCount, setLogsCount] = useState(0);
    const [overview, setOverview] = useState<ConsumerLoanOverview|null>(null);

    useEffect(() => {
        if (props.substatuses === undefined && !props.isLoading) {
            props.loadSubstatuses();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadOverview(props.personId, props.applicationId, setOverview);
        loadLogs(props.personId, props.applicationId, !!props.hasCoApplicant, setLogs, setLogsCount);
    }, [props.applicationId]); // eslint-disable-line react-hooks/exhaustive-deps

    let displayName: JSX.Element | string = <Translate id="NO_DATA"/>;
    if (props.firstName || props.lastName) {
        displayName = beautifyName(`${props.firstName || ''} ${props.lastName || ''}`, false)
    }

    return (
        <React.Fragment>
            <Popover.Header as="h3" className="uppercase">{displayName}{renderCoApplicantName(overview)}</Popover.Header>
            <Popover.Body>
            {renderOverview(props.applicationId, overview, props.substatuses)}
            {renderLogs(logs, logsCount)}
            </Popover.Body>
        </React.Fragment>
    );
};

const renderCoApplicantName = (overview: ConsumerLoanOverview | null) =>
    overview && overview.coApplicantFirstName && overview.coApplicantLastName
    ? <span>, {beautifyName(`${overview.coApplicantFirstName} ${overview.coApplicantLastName}`, false)}</span>
    : null;

const renderOverview = (applicationId: number, overview: ConsumerLoanOverview | null, substatuses: Substatus[] | undefined) => {
    if (!overview || !substatuses) {
        return null;
    }

    return (
        <div className="applicant-tooltip-overview">
            <LoanPicker loans={overview.applications} selectedLoanId={applicationId}/>
            <div className="substatuses">{overview.substatusIds.map((id) => renderSubstatus(id, substatuses))}</div>
        </div>
    );
};

const renderSubstatus = (id: number, substatuses: Substatus[]) => {
    const substatus = substatuses.find((s) => s.id === id);
    return substatus
    ? <span key={id} className={`status ${getCssClass(substatus)}`}>{substatus.name}</span>
    : null;
};

const getCssClass = (substatus: Substatus) => {
    if (substatus.loanStatuses.length !== 1) {
        return 'status-gray';
    }

    return `status-${loanStatusToColor(substatus.loanStatuses[0])}`;
};

const renderLogs = (logs: ConsumerLoanLog[], logsCount: number) =>
    logsCount !== 0
    ? <LogsAndCommentsTable logs={logs} logCount={logsCount} pageNumber={0} pageSize={logsPageSize} orderDirection={1} />
    : null;

async function loadOverview(personId: number, applicationId: number, setOverview: Dispatch<SetStateAction<ConsumerLoanOverview|null>>) {
    const result = await getConsumerLoanOverview(personId, applicationId);
    setOverview(result.data);
}

async function loadLogs(personId: number, applicationId: number, hasCoApplicant: boolean, setLogs: Dispatch<SetStateAction<ConsumerLoanLog[]>>, setLogsCount: Dispatch<SetStateAction<number>>) {
    const filters = logsAndCommentsFiltersStorage.get();
    const logTypes: ConsumerLoanLogType[] = [];

    if (filters.filterComments === true) {
        logTypes.push(ConsumerLoanLogType.Comment);
    }

    if (filters.filterEvents === true) {
        logTypes.push(ConsumerLoanLogType.Event);
        logTypes.push(ConsumerLoanLogType.CustomerCommunication);
    }

    const result = await getConsumerLoanLogs(personId, applicationId, hasCoApplicant, 0, logsPageSize, 1, '', logTypes);
    setLogs(result.loanLogs);
    setLogsCount(result.loanLogsCount);
}

const mapStateToProps = (state: any) => ({
    ...state.substatusesReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators(SubstatusesActionsCreator, dispatch);

export default connect<SubstatusesProps, typeof SubstatusesActionsCreator, ApplicantTooltipOwnProps, any>(mapStateToProps, mapActionCreatorsToProps)(ApplicantTooltip);
