export enum AdditionalInformationType {
    Income = 'income',
    Expense = 'expense',
    MortgageCalculation = 'mortgage-calculation'
}
export interface AdditionalInformation {
    type: AdditionalInformationType;
    name: string;
    value: string | null;
    id?: number;
}