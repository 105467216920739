export const getDwellingTranslateKey = (status: string): string => {
    switch (status) {
        case 'condominium':
            return 'DWELLING_TYPE.CONDOMINIUM';
        case 'living with parents':
            return 'DWELLING_TYPE.LIVING_WITH_PARENTS';
        case 'lodger':
            return 'DWELLING_TYPE.LODGER';
        case 'own house':
            return 'DWELLING_TYPE.OWN_HOUSE';
        case 'rental':
            return 'DWELLING_TYPE.RENTAL';
        default:
            return '';
    }
};
