export const getCurrencySymbolForCountryId = (id: number) => {
    switch (id) {
        case 1:
        case 2:
        case 4:
            return 'kr';
        case 3:
            return '€';
        default:
            throw new Error('getCurrencySymbolForCountryId: unknown id ' + id);
    }
};

export const appendCurrencySymbolForCountryId = (value: number | null | undefined, countryId: number) => {
    if (value === null || value === undefined) {
        return '';
    }

    return `${value.toLocaleString()} ${getCurrencySymbolForCountryId(countryId)}`;
};
