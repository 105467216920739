import React, { FunctionComponent, ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { File } from '../helpers/File';

interface FileLinkProps {
    getFile: () => Promise<File>;
    className?: string;
    children: ReactNode;
    variant?: 'primary' | 'link';
}

const FileLink: FunctionComponent<FileLinkProps> = (props) => {
    const download = async () => {
        const file = await props.getFile();
        file.download();
    };

    return <Button variant={props.variant} className={props.className} onClick={download}>{props.children}</Button>;
};

FileLink.defaultProps = {
    variant: 'link'
}

export default FileLink;
