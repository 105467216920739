import React, {PropsWithChildren, ReactNode} from "react";

interface TableRowsProps<T> {
    isLoading?: boolean;
    rows: T[];
    renderRow: (row: T, index: number) => ReactNode;
}

function TableRows<T>(props: PropsWithChildren<TableRowsProps<T>>) {
    if (props.isLoading) {
        return (
            <tbody>
                <tr>
                    <td />
                </tr>
            </tbody>
        );
    }
    return (
        <tbody>
            {props.rows.map((row, i) => props.renderRow(row, i))}
        </tbody>
    )
}
export default TableRows;