import React, {FunctionComponent, useEffect, useState} from "react";
import "./MarketingListsView.css";
import {getMainBackgroundColorStyle} from "../../common/helpers/settingsHelpers";
import {Table} from "react-bootstrap";
import RefreshButton from "../../common/components/RefreshButton";
import TableHeader, {TableHeaderCellModel} from "../../common/components/table/TableHeader";
import TableRows from "../../common/components/table/TableRows";
import TablePagination from "../../common/components/table/TablePagination";
import {SettingsProps} from "../../common/interfaces/SettingsProps";
import {connect} from "react-redux";
import {NavigateFunction, useNavigate} from "react-router";
import {MarketingListSummary} from "../model/MarketingListSummary";
import {bindActionCreators, Dispatch} from "redux";
import {SetTitleActionCreator} from "../../common/actions/SetTitleActionCreator";
import {SetApplicationTitleActionProps} from "../../common/interfaces/SetApplicationTitleActionProps";
import {LocalizeContextProps, withLocalize} from "react-localize-redux";
import {countMarketingLists} from "../api/countMarketingLists";
import {getMarketingLists as getMarketingListsAPI} from "../api/getMarketingLists";
import MarketingListsMenu from "./menus/MarketingListsMenu";
import {UserProps} from "../../common/interfaces/UserProps";

type MarketingListsViewProps = SettingsProps & UserProps & SetApplicationTitleActionProps & LocalizeContextProps;
const MarketingListsView: FunctionComponent<MarketingListsViewProps> = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [marketingLists, setMarketingLists] = useState<MarketingListSummary[]>([]);
    const [marketingListsCount, setMarketingListsCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(20);

    useEffect(() => {
        props.setTitle(props.translate('MARKETING_LISTS').toString());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        getMarketingLists();
    }, [pageSize, pageNumber]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const getMarketingLists = async () => {
        setIsLoading(true);
        const params = {pageNumber, pageSize};
        setMarketingLists(await getMarketingListsAPI(params) || []);
        setMarketingListsCount(await countMarketingLists() || 0);
        setIsLoading(false);
    }
        
    return (
        <main className="main-content" style={getMainBackgroundColorStyle(props)}>
            <div className="jumbotron text-center bg-white flex-column justify-content-center row pb-4 pt-4 px-0 marketing-lists-view">
                <MarketingListsMenu onCreateClick={() => navigate('/marketing-list/new')}/>
                <div className="col-12 pt-4 table-container px-5">
                    <RefreshButton
                        style={{position: 'absolute', right: '0'}}
                        isLoading={isLoading}
                        onClick={getMarketingLists}
                    />
                    <Table
                        className="marketing-lists-table"
                        hover={true}
                        borderless={true}
                    >
                        <TableHeader cells={marketingListsTableHeaderCells}/>
                        <TableRows<MarketingListSummary>
                            isLoading={isLoading}
                            rows={marketingLists}
                            renderRow={marketingList => (
                                <tr
                                    key={marketingList.id}
                                    onAuxClick={() => openMarketingList(navigate, true, marketingList.id)}
                                    onClick={e => openMarketingList(navigate, e.ctrlKey, marketingList.id)}
                                >
                                    <td>{marketingList.name}</td>
                                    <td>{marketingList.creator}</td>
                                    <td>{new Date(marketingList.createdAt).toLocaleDateString()}</td>
                                </tr>
                            )}
                        />
                    </Table>
                </div>
            </div>
            <TablePagination
                isLoading={isLoading}
                pageNumber={pageNumber}
                pageSize={pageSize}
                onPagePicked={(pageNumber) => setPageNumber(pageNumber)}
                onPageSizePicked={(pageSize) => setPageSize(pageSize)}
                count={marketingListsCount}
                pickableSizes={[20, 50, 100, 250, 500]}
            />
        </main>
    );
}

const marketingListsTableHeaderCells: TableHeaderCellModel[] = [
    {index: 0, translateKey: 'MARKETING_LISTS_VIEW.TABLE_HEADERS.NAME'},
    {index: 1, translateKey: 'CREATOR'},
    {index: 2, translateKey: 'CREATED_AT'}
];

const openMarketingList = (navigate: NavigateFunction, newTab: boolean, marketingListId: number) => {
    const path = `/marketing-list/${marketingListId}`;
    if (newTab) {
        window.open(path, '_blank');
    } else {
        navigate(path);
    }
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.userActionsReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...SetTitleActionCreator    
}, dispatch);

export default connect<SettingsProps & UserProps, SetApplicationTitleActionProps>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(MarketingListsView));