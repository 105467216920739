import { CommandResult } from '../../../common/helpers/CommandResult';
import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { ApplicantsFilter } from '../../applicants/models/ApplicantsFilter';

export const postSmsToMany = async (text: string, applicantsFilter: ApplicantsFilter) => {
    const client = AxiosClientFactory.getInstance();

    const sendSmsToManyApplicantsCommand = {
        text,
        applicantsFilter
    };

    const result = await client.post('api/sms/sendToMany', sendSmsToManyApplicantsCommand);

    return new CommandResult(result);
};
