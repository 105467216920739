import React, {FunctionComponent, useEffect} from "react";
import "./CampaignView.css";
import {getMainBackgroundColorStyle} from "../../../common/helpers/settingsHelpers";
import {connect} from "react-redux";
import {SettingsProps} from "../../../common/interfaces/SettingsProps";
import {bindActionCreators, Dispatch} from "redux";
import {CampaignViewDataActionCreators, CampaignViewDataActions} from "../../actions/CampaignViewDataActionCreators";
import {campaignRecipientsDefaultFilter, CampaignViewDataState} from "../../reducers/campaignViewDataReducer";
import CampaignViewTabs from "./CampaignViewTabs/CampaignViewTabs";
import CampaignInfo from "./CampaignInfo/CampaignInfo";
import DesignTabContent from "./CampaignViewTabs/TabsContent/DesignTabContent/DesignTabContent";
import CampaignViewActions from "./CampaignViewActions/CampaignViewActions";
import RecipientsTabContent from "./CampaignViewTabs/TabsContent/RecipientsTabContent/RecipientsTabContent";
import EngagementsTabContent from "./CampaignViewTabs/TabsContent/EngagementsTabContent/EngagementsTabContent";
import {CampaignRecipientsFilter, CampaignStatus} from "../../../common/models/Campaign";
import {useLocation, useParams} from "react-router";
import {SetApplicationTitleActionProps} from "../../../common/interfaces/SetApplicationTitleActionProps";
import {SetTitleActionCreator} from "../../../common/actions/SetTitleActionCreator";

type CampaignViewRouteParams = 'campaignId';

type CampaignViewStateProps = CampaignViewDataState & SettingsProps;

type CampaignViewDispatchProps = CampaignViewDataActions & SetApplicationTitleActionProps;

type CampaignViewProps = CampaignViewStateProps & CampaignViewDispatchProps;

const CampaignView: FunctionComponent<CampaignViewProps> = (props) => {
    const params = useParams<CampaignViewRouteParams>();
    const location = useLocation();
    
    useEffect(() => {
        props.setTitle(getPageTitle(props));
    }, [props.campaign, props.campaignDirty, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (!isCreateMode()) {
            if (params.campaignId === undefined) {
                throw new Error('campaign id cannot be undefined');
            }
            props.loadCampaign(parseFloat(params.campaignId));
        } else if (window.location.pathname.endsWith('/new')) {
            props.editCampaign({
                id: undefined,
                name: undefined,
                status: CampaignStatus.DRAFT,
                subject: null,
                senderAddress: null,
                senderName: null,
                campaignRecipientsFilter: {
                    ...campaignRecipientsDefaultFilter, 
                    mobileNumbers: [], 
                    excludedSubstatuses: [], 
                    includedSubstatuses: [], 
                    productType: [],
                    language: null,
                    lastApplicationStatus: null
                } as CampaignRecipientsFilter,
                template: null
            }, false);
        }
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.campaign && props.campaign.id) {
            props.loadCampaignEngagements(props.campaign.id, props.campaignEngagementsTableState);
        }
    }, [props.campaign && props.campaign.id, props.campaignEngagementsTableState]); // eslint-disable-line react-hooks/exhaustive-deps
    
    if (!props.campaign) {
        return <main className="main-content" style={getMainBackgroundColorStyle(props)} />
    }
    
    return (
        <main className="main-content" style={getMainBackgroundColorStyle(props)}>
            <div className="jumbotron text-center bg-white flex-column justify-content-center row pb-4 pt-4 px-0 campaign-view">
                <div className="top-panel px-5">
                    <CampaignInfo createMode={isCreateMode()}/>
                    <CampaignViewTabs editMode={isEditMode()}/> 
                </div>
                <div className="content-panel px-5">
                    {renderContent(props)}
                    {isEditMode() &&
                        <div className="content-footer">
                            <CampaignViewActions createMode={isCreateMode()}/>
                        </div>
                    }
                </div>
            </div>
        </main>
    );
}

const renderContent = (props: CampaignViewProps) => {
    switch(props.activeTab) {
        case 'design':
            return <DesignTabContent editMode={isEditMode()}/>
        case 'recipients':
            return <RecipientsTabContent editMode={isEditMode()}/>
        case 'engagements':
            return <EngagementsTabContent/> 
    }
}

const isEditMode = () => {
    return isCreateMode() || window.location.pathname.endsWith('/edit');
}

const isCreateMode = () => {
    return window.location.pathname.endsWith('/copy') || window.location.pathname.endsWith('/new');
}

const getPageTitle = (props: CampaignViewProps) => {
    switch (true) {
        case window.location.pathname.endsWith('/new'):
            return `Create ${props.campaign && props.campaign!.name ? props.campaign!.name.toUpperCase() : 'new campaign'}`;
        case window.location.pathname.endsWith('/copy'):
            return `Copy ${props.campaign && props.campaign!.name ? props.campaign!.name.toUpperCase() : 'campaign'}`;
        case window.location.pathname.endsWith('/edit'):
            return `${props.campaignDirty ? '*' : ''}Edit ${props.campaign && props.campaign!.name ? props.campaign!.name.toUpperCase() : 'campaign'}`;
        default:
            return props.campaign && props.campaign!.name ? `${props.campaign!.name.toUpperCase()}` : 'Campaign';
    }
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.campaignViewDataReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...CampaignViewDataActionCreators,
    ...SetTitleActionCreator
}, dispatch);

export default connect<CampaignViewStateProps, CampaignViewDispatchProps>(mapStateToProps, mapActionCreatorsToProps)(CampaignView);