import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, MouseEventHandler, ReactNode } from 'react';
import Tooltip from './Tooltip';
import { Translate } from 'react-localize-redux';

interface ActionIconProps {
    icon: IconDefinition;
    iconSpin?: boolean;
    tooltipKey: string;
    tooltip?: ReactNode;
    disabled?: boolean;
    action?: () => void;
}
const ActionIcon: FunctionComponent<ActionIconProps> = (props) => {
    if (props.disabled) {
        return (
            <Tooltip content={props.tooltip || <Translate id={props.tooltipKey} />}>
                <span className="disabled">
                    <FontAwesomeIcon icon={props.icon} spin={props.iconSpin} />
                </span>
            </Tooltip>
        );
    }

    const action: MouseEventHandler<HTMLSpanElement> = (e) => {
        e.stopPropagation();
        if (props.action) {
            props.action();
        }
    };

    return (
        <Tooltip content={<Translate id={props.tooltipKey} />}>
            <span className="cursor-pointer" onClick={action}>
                <FontAwesomeIcon icon={props.icon} spin={props.iconSpin} />
            </span>
        </Tooltip>
    );
};

export default ActionIcon;
