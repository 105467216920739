import { faAngleLeft, faAngleRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, ReactNode } from 'react';
import { createPaginationItemDescriptors } from '../helpers/createPaginationItemDescriptors';
import { mapPaginationItemDescriptorToReactNode } from '../helpers/mapPaginationItemDescriptorToReactNode';
import { PaginationItemDescriptor } from '../models/PaginationItemDescriptor';
import './pickers.css';

export interface PagePickerProps {
    count: number;
    pageSize: number;
    pageNumber: number;
    variant?: string;
    onPagePicked?: (index: number) => void;
}

const PagePicker: FunctionComponent<PagePickerProps> = (props) => {
    const renderButton = (key: string, icon: IconDefinition, onClick: () => void, active: boolean) => {
        if (active) {
            return (
                <li key={key} className="active" onClick={onClick}>
                    <FontAwesomeIcon icon={icon} size="lg"/>
                </li>
            );
        }

        return (
            <li key={key} className="inactive disabled">
                <FontAwesomeIcon icon={icon} size="lg"/>
            </li>
        );
    };

    const onPrevClick = () => {
        props.onPagePicked && props.onPagePicked(props.pageNumber - 1);
    };

    const onNextClick = () => {
        props.onPagePicked && props.onPagePicked(props.pageNumber + 1);
    };

    // add click and key prefix to mapPaginationItemDescriptorToReactNode
    const addClickableToPaginationItemDescriptorToReactNode = (item: PaginationItemDescriptor): ReactNode => {
        return mapPaginationItemDescriptorToReactNode(item, props.onPagePicked, 'paging-item');
    };

    if (props.count <= props.pageSize) {
        return null;
    }

    const pagingItems = createPaginationItemDescriptors(props.count, props.pageSize, props.pageNumber)
        .map(addClickableToPaginationItemDescriptorToReactNode);

    const variant = props.variant || 'dark';

    return (
        <ul className={`${variant} picker-list`}>
            {renderButton('paging-item-prev', faAngleLeft, onPrevClick, props.pageNumber > 0)}
            {pagingItems}
            {renderButton('paging-item-next', faAngleRight, onNextClick, props.pageNumber < (props.count / props.pageSize) - 1)}
        </ul>
    );
};

export default PagePicker;
