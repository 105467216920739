import React, {FunctionComponent} from "react";
import GenericDropdown from "../../common/components/GenericDropdown";
import {CampaignStatus, CampaignType} from "../../common/models/Campaign";
import {CampaignsViewDataActions} from "../actions/CampaignsViewDataActionCreators";
import {LocalizeContextProps, withLocalize} from "react-localize-redux";
import {MetadataProps} from "../../common/interfaces/MetadataProps";
import {connect} from "react-redux";
import {CampaignsViewDataState} from "../reducers/campaignsViewDataReducer";
import mapEnumToSelectableItems from "../../common/helpers/mapEnumToSelectableItems";
import {getEnumTranslationKey} from "../../common/helpers/getTranslationKey";
import {SelectableItem} from "../../common/models/SelectableItem";

type FilterActions = 'setTeamFilter' | 'setStatusFilter' | 'setTypeFilter';
type CampaignsViewFilterProps = Pick<CampaignsViewDataState, 'filter'>
                                & Pick<CampaignsViewDataActions, FilterActions>
                                & LocalizeContextProps
                                & MetadataProps;

const CampaignsViewFilter: FunctionComponent<CampaignsViewFilterProps> = (props) => {
    const statusItems = mapEnumToSelectableItems<CampaignStatus>(
        CampaignStatus,
        status => `${props.translate('CAMPAIGN_STATUS.PREFIX')}${props.translate(getEnumTranslationKey(CampaignStatus, status, 'CAMPAIGN_STATUS', 'ALL'))}`,
        true
    );
    const typeItems = mapEnumToSelectableItems<CampaignType>(
        CampaignType,
        type => `${props.translate('CAMPAIGN_TYPE.PREFIX')}${props.translate(getEnumTranslationKey(CampaignType, type, 'CAMPAIGN_TYPE', 'ALL'))}`,
        true
    );
    return (
        <>
            <GenericDropdown<SelectableItem<CampaignStatus>>
                dropdownId="status"
                selectedKey={props.filter.status !== undefined ? props.filter.status : null}
                items={statusItems}
                renderUndefinedItems={true}
                displayValue={item => item.name}
                keyValue={item => item.id}
                onSelectionChanged={item => props.setStatusFilter(item.id !== null ? item.id : undefined)}
            />
            <GenericDropdown
                dropdownId="teamId"
                selectedKey={props.filter.teamId}
                items={[...props.metadata.teams, undefined]}
                renderUndefinedItems={true}
                displayValue={team => `${props.translate('TEAM')} - ${team ? team.name : props.translate('SHOW_ALL')}`}
                keyValue={team => team ? team.id : undefined}
                onSelectionChanged={(_, teamId) => props.setTeamFilter(teamId as number | undefined)}
            />
            <GenericDropdown<SelectableItem<CampaignType>>
                dropdownId="type"
                selectedKey={props.filter.type !== undefined ? props.filter.type : null}
                items={typeItems}
                renderUndefinedItems={true}
                displayValue={item => item.name}
                keyValue={item => item.id}
                onSelectionChanged={item => props.setTypeFilter(item.id !== null ? item.id : undefined)}
            />
        </>
    );
}

const mapStateToProps = (state: any) => ({
    ...state.metadataActionsReducer
});

export default connect<MetadataProps>(mapStateToProps)(withLocalize(CampaignsViewFilter));