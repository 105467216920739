import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import { InsuranceReadModel } from '../../models/InsuranceReadModel';
import DateTimeInputField, { DateTimeInputFieldKind } from '../../../../common/components/input-fields/DateTimeInputField';
import NumberInputField, { NumberInputFieldKind } from '../../../../common/components/input-fields/NumberInputField';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { getEnumTranslationKey } from '../../../../common/helpers/getTranslationKey';
import { InsuranceBasicDetails } from '../../models/InsuranceBasicDetails';
import mapEnumToSelectableItems from '../../../../common/helpers/mapEnumToSelectableItems';
import RadioButtonsInputField from '../../../../common/components/input-fields/RadioButtonsInputField';
import { InvoiceDeliveryMethod } from '../../models/InvoiceDeliveryMethod';
import { ConsumerLoan } from '../../models/ConsumerLoan';
import ConsumerDebts from './ConsumerDebts';
import {AdditionalInformation} from "../../../../common/models/AdditionalInformation";
import {getAdditionalInformation} from "../../api/getAdditionalInformation";
import ConsumerLiquidity from "./ConsumerLiquidity";

interface InsuranceDetailsProps extends LocalizeContextProps {
    insurance: InsuranceReadModel | InsuranceBasicDetails | null;
    lastPaid?: ConsumerLoan;
    countryId: number;
    editMode: boolean;
    onChange?: (i: InsuranceBasicDetails) => void;
    errors?: string[];
}

const InsuranceDetails: FunctionComponent<InsuranceDetailsProps> = (props) => {
    const [applicantAdditionalInformation, setApplicantAdditionalInformation] = useState<AdditionalInformation[]>([]);
    const deliveryMethods = useMemo(() => getDeliveryMethods(props), []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.lastPaid) {
            getAdditionalInformation(props.lastPaid.applicant.id).then(response => setApplicantAdditionalInformation(response.data || []));
        }
    }, [props.lastPaid]);

    if (props.insurance === null) {
        return null;
    }
    
    const onValueChanged = (value: string | number | boolean | Date | null | undefined, name: string): void =>
        props.onChange && props.onChange({ ...props.insurance as InsuranceBasicDetails, [name]: value });

    const onInstalmentChange = (value: number | null | undefined, _: string) => {
        props.onChange && props.onChange(updateInstalment(props.insurance as InsuranceBasicDetails, value));
    };

    return (
        <div className="insurance-details">
            <div>
                <NumberInputField
                    name="instalment"
                    value={props.insurance.instalment}
                    descriptionKey="INSTALMENT"
                    kind={NumberInputFieldKind.Money}
                    countryId={props.countryId}
                    editMode={props.editMode}
                    onValueChanged={onInstalmentChange}
                    min={2000}
                    max={10000}
                    step={100}
                    required="InstalmentIsRequired"
                    errors={props.errors}
                    errorCodes={["InstalmentTooLow", "InstalmentTooHigh"]}
                    overrideInternalErrors={['valueMissing', 'rangeOverflow', 'rangeUnderflow']}
                />
            </div>
            <div>
                <NumberInputField
                    name="term"
                    value={props.insurance.term}
                    descriptionKey="TERM"
                    kind={NumberInputFieldKind.Months}
                    editMode={props.editMode}
                    onValueChanged={onValueChanged}
                    min={1}
                    required="TermIsRequired"
                    errors={props.errors}
                    overrideInternalErrors={['valueMissing']}
                />
            </div>
            <div>
                <DateTimeInputField
                    name="startDate"
                    value={props.insurance.startDate}
                    descriptionKey="START_DATE"
                    kind={DateTimeInputFieldKind.Date}
                    editMode={props.editMode}
                    onValueChanged={onValueChanged}
                    required="StartDateIsRequired"
                    errors={props.errors}
                    errorCodes={["OverlappingWithExistingInsurance"]}
                    overrideInternalErrors={['valueMissing']}
                />
            </div>
            <div>
                <RadioButtonsInputField
                    name="invoiceDeliveryMethod"
                    value={props.insurance.invoiceDeliveryMethod}
                    descriptionKey="INVOICE_DELIVERY_METHOD"
                    items={deliveryMethods}
                    editMode={props.editMode}
                    onValueChanged={onValueChanged}
                    required="InvoiceDeliveryMethodIsRequired"
                    errors={props.errors}
                    overrideInternalErrors={['valueMissing']}
                />
            </div>
            <div>
                <NumberInputField
                    name="monthlyGrossPremium"
                    value={props.insurance.monthlyGrossPremium}
                    descriptionKey="MONTHLY_GROSS_PREMIUM"
                    kind={NumberInputFieldKind.Money}
                    countryId={props.countryId}
                    editMode={false}
                />
            </div>
            <div>
                <NumberInputField
                    name="monthlyNetPremium"
                    value={props.insurance.monthlyNetPremium}
                    descriptionKey="MONTHLY_NET_PREMIUM"
                    kind={NumberInputFieldKind.Money}
                    countryId={props.countryId}
                    editMode={false}
                />
            </div>
            {renderCancellationEffectiveDate(props.insurance as InsuranceReadModel)}
            <ConsumerLiquidity 
                consumerLoan={props.lastPaid}
                applicantAdditionalInformation={applicantAdditionalInformation}
            />
            <ConsumerDebts 
                consumerLoan={props.lastPaid}
                countryId={props.countryId}
            />
        </div>
    );
};

function getDeliveryMethods(props: InsuranceDetailsProps) {
    const nameMapper = (name: string | null) => props.translate(getEnumTranslationKey(InvoiceDeliveryMethod, name, 'INVOICE_DELIVERY_METHODS')).toString();
    return mapEnumToSelectableItems(InvoiceDeliveryMethod, nameMapper, false);
}


function renderCancellationEffectiveDate(insurance: InsuranceReadModel) {
    if (!insurance.cancellationEffectiveDate) {
        return null;
    }

    return (
        <div>
            <DateTimeInputField
                name="cancellationEffectiveDate"
                value={insurance.cancellationEffectiveDate}
                descriptionKey="CANCELLATION_EFFECTIVE_DATE"
                kind={DateTimeInputFieldKind.Date}
            />
        </div>
    );
}

function updateInstalment(insurance: InsuranceBasicDetails, instalment: number | null | undefined) {
    if (insurance !== null && instalment !== null && instalment !== undefined && instalment >= 2000 && instalment <= 8000) {
        
        instalment = Math.ceil(instalment / 100.0) * 100;
        const gross = instalment * 0.0495;

        return {
            ...insurance,
            instalment,
            monthlyGrossPremium: gross,
            monthlyNetPremium: gross * 0.55
        };
    }

    return {
        ...insurance,
        instalment: instalment || undefined,
        monthlyGrossPremium: undefined,
        monthlyNetPremium: undefined
    };
}

export default withLocalize(InsuranceDetails);
