import {
    GetUserAction,
    GET_USER_BEGIN,
    GET_USER_SUCCESS,
    GET_USER_FAILURE
} from '../actions/UserActionCreators';

export function userActionsReducer(state: any, action: GetUserAction) {
    state = state || {
        userData: {
            user: undefined,
            isLoading: false,
            error: undefined
        }
    };
    switch (action.type) {
        case GET_USER_BEGIN:
            return {
                ...state,
                userData: {
                    user: undefined,
                    isLoading: true
                }
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                userData: {
                    user: action.user,
                    isLoading: false
                }
            };
        case GET_USER_FAILURE:
            return {
                ...state,
                userData: {
                    error: action.error,
                    isLoading: false
                }
            };
        default:
            return state;
    }
}
