import React, {FunctionComponent, useReducer} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft, faBars} from '@fortawesome/free-solid-svg-icons';
import {Translate} from 'react-localize-redux';
import {applicantActionsReducer} from '../reducers/applicantActionsReducer';
import {
    setBlacklistHidden,
    setBlacklistVisible,
    setGdprHidden,
    setGdprVisible,
    setRevokeMarketingConsentsHidden,
    setRevokeMarketingConsentsVisible
} from '../actions/applicationsActionActionsCreator';
import {MarketingConsent} from "../../applicants/models/MarketingConsent";
import {isLimitedCaseWorker} from "../../../common/helpers/limitedCaseWorkerLogic";
import {UserProps} from "../../../common/interfaces/UserProps";

export interface ApplicationActionsState {
    showGdprOptions: boolean;
    showBlacklistOptions: boolean;
    showRevokeMarketingConsentsOptions: boolean;
}

interface ApplicationActionsProps {
    disabled: boolean;
    canRevokeEmailMarketingConsent: boolean;
    canRevokeSmsMarketingConsent: boolean;
    showDenyOption: boolean;
    showRejectOpenTransactionsOption: boolean;
    showMortgageCalculatorOption: boolean;
    isHidden: boolean;
    isBlackListed: boolean;
    canBeDeleted: boolean;
    hasCoApplicant: boolean;
    hasSocialSecurityNumber: boolean;
    hasProperyInformationSystemIntegration: boolean
    copyApplication(): void;
    sendCreditInformationEnrichmentRequest(): void;
    sendPropertyInformationEnrichmentRequest(): void;
    denyApplication(): void;
    rejectOpenTransactions(): void;
    hideApplication(): void;
    unhideApplication(): void;
    putOnBlacklist(lengthInDays?: number): void;
    putOffBlacklist(): void;
    gdprRemoveApplicant(): void;
    gdprRemoveCoApplicant(): void;
    deleteApplication(): void;
    revokeMarketingConsents(particular?: MarketingConsent): void;
    grantMarketingConsents(particular?: MarketingConsent): void;
    openMortgageCalculator(): void;
}

type Action = () => void;

const ApplicationActions: FunctionComponent<ApplicationActionsProps & UserProps> = (props) => {

    const [state, dispatch] = useReducer(applicantActionsReducer, createEmptyState());

    const onMouseEnterBlacklist = () => dispatch(setBlacklistVisible());
    const onMouseLeaveBlacklist = () => dispatch(setBlacklistHidden());

    const onMouseEnterGdpr = () => dispatch(setGdprVisible());
    const onMouseLeaveGdpr = () => dispatch(setGdprHidden());
    
    const onMouseEnterMarketingConsent = () => dispatch(setRevokeMarketingConsentsVisible());
    const onMouseLeaveMarketingConsent = () => dispatch(setRevokeMarketingConsentsHidden());

    const denyApplicationOption = renderDenyApplicationOption(props.showDenyOption, props.disabled, props.denyApplication);
    const rejectOpenTransactionsOption = renderRejectOpenTransactionsOption(props.showRejectOpenTransactionsOption, props.disabled, props.rejectOpenTransactions)
    const hideUnhideApplicationOption = props.isHidden
        ? renderUnhideApplicationOption(props.disabled, props.unhideApplication)
        : renderHideApplicationOption(props.disabled, props.hideApplication);

    const blacklistingOptions = renderBlacklistingOptions(
        props.isBlackListed,
        state.showBlacklistOptions,
        props.disabled,
        props.putOnBlacklist,
        props.putOffBlacklist,
        onMouseEnterBlacklist,
        onMouseLeaveBlacklist);

    const gdprRemoveOptions = renderGDPRRemoveOptions(
        state.showGdprOptions,
        props.hasCoApplicant,
        props.disabled,
        props.gdprRemoveApplicant,
        props.gdprRemoveCoApplicant,
        onMouseEnterGdpr,
        onMouseLeaveGdpr);

    const revokeMarketingConsentOptions = renderMarketingConsentOptions(
        state.showRevokeMarketingConsentsOptions,
        props.disabled,
        props.canRevokeEmailMarketingConsent,
        props.canRevokeSmsMarketingConsent,
        props.revokeMarketingConsents,
        props.grantMarketingConsents,
        onMouseEnterMarketingConsent,
        onMouseLeaveMarketingConsent);
    
    const deleteApplicationOption = renderDeleteApplicationOption(!props.canBeDeleted, props.deleteApplication);

    const mortgageCalculatorOption = renderMortgageCalculatorOption(props.showMortgageCalculatorOption, props.openMortgageCalculator);

    return (
        <Dropdown drop="down" align="end">
            <Dropdown.Toggle id="application-menu" className="btn-white-bg" style={{ padding: '3px 12px' }}>
                <FontAwesomeIcon icon={faBars} />
            </Dropdown.Toggle>
            <div className="dropdown-arrow-down" />
            <Dropdown.Menu>
                <Dropdown.Item key="applicant-actions-copy-application" onClick={props.copyApplication}>
                    <Translate id="NEW_APPLICATION" />
                </Dropdown.Item>
                {renderEnrichmentOptions(props.hasSocialSecurityNumber, props.hasProperyInformationSystemIntegration, props.disabled, props.sendCreditInformationEnrichmentRequest, props.sendPropertyInformationEnrichmentRequest)}
                {hideUnhideApplicationOption}
                {denyApplicationOption}
                {rejectOpenTransactionsOption}
                <Dropdown.Divider key="applicant-actions-divider-2" />
                {!isLimitedCaseWorker(props) &&
                    <>
                        {blacklistingOptions}
                        <Dropdown.Divider key="applicant-actions-divider-3" />
                        {gdprRemoveOptions}
                    </>
                }                
                {revokeMarketingConsentOptions}
                {mortgageCalculatorOption}
                {deleteApplicationOption}
            </Dropdown.Menu>
        </Dropdown>
    );
};

function renderBlacklistingOptions(
    isBlackListed: boolean,
    show: boolean,
    disabled: boolean,
    putOnBlacklist: (lenghtInDays?: number) => void,
    putOffBlacklist: () => void,
    onMouseEnter: Action,
    onMouseLeave: Action) {

    const blackListOptions = [30, 60, 90, 180, 365, undefined].map((x) => {
        const onClick = () => putOnBlacklist(x);

        return (
            <Dropdown.Item key={`blacklist-${x}-days`} onClick={onClick}>
                {x !== undefined &&
                    <span>{x} <Translate id="DAYS" /></span>
                }
                {x === undefined &&
                    <span><Translate id="PERMANENT" /></span>
                }
            </Dropdown.Item>
        );
    });

    if (isBlackListed) {
        blackListOptions.push(
            <React.Fragment key="clear-blacklist">
                <Dropdown.Divider />
                <Dropdown.Item onClick={putOffBlacklist}>
                    <Translate id="REMOVE_BLACKLISTING" />
                </Dropdown.Item>
            </React.Fragment>
        );
    }

    return (
        <div
            className={classNames('dropdown-no-indicator dropdown-submenu dropleft', { disabled })}
            onMouseEnter={disabled ? undefined : onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className={classNames('dropdown-toggle', { disabled })}>
                <span style={{ marginLeft: '-15px', marginRight: '7px' }}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </span>
                <Translate id="BLACKLIST" />
            </div>
            <div className={classNames('dropdown-menu', { show })}>
                <div className="dropdown-arrow-left" />
                {blackListOptions}
            </div>
        </div>
    );
}

function createEmptyState(): ApplicationActionsState {
    return {
        showGdprOptions: false,
        showBlacklistOptions: false,
        showRevokeMarketingConsentsOptions: false
    };
}

function renderEnrichmentOptions(hasSocialSecurityNumber: boolean, hasProperyInformationSystemIntegration: boolean, disabled: boolean, sendCreditInformationEnrichmentRequest: Action, sendPropertyInformationEnrichmentRequest: Action)
{
    if (!hasSocialSecurityNumber)
    {
        return null;
    }

    const propertyInformationItem =
        hasProperyInformationSystemIntegration
        ? (
                <Dropdown.Item onClick={sendPropertyInformationEnrichmentRequest} disabled={disabled}>
                <Translate id="COLLECT_PROPERTIES_INFORMATION" />
            </Dropdown.Item>
            )
        : <></>;

    return (
        <>
            <Dropdown.Divider key="applicant-actions-divider-0" />
            <Dropdown.Item onClick={sendCreditInformationEnrichmentRequest} disabled={disabled}>
                <Translate id="COLLECT_CREDIT_INFORMATION" />
            </Dropdown.Item>
            {propertyInformationItem}
        </>
    );
}

function renderDenyApplicationOption(showDenyOption: boolean, disabled: boolean, denyApplication: Action) {
    if (showDenyOption === true) {
        return (
            <React.Fragment>
                <Dropdown.Item onClick={denyApplication} disabled={disabled}>
                    <Translate id="DENY_APPLICATION" />
                </Dropdown.Item>
            </React.Fragment>
        );
    }

    return null;
}

function renderRejectOpenTransactionsOption(show: boolean, disabled: boolean, rejectOpenTransactions: Action) {
    if (show === true) {
        return (
            <React.Fragment>
                <Dropdown.Item onClick={rejectOpenTransactions} disabled={disabled}>
                    <Translate id="REJECT_OPEN_TRANSACTIONS" />
                </Dropdown.Item>
            </React.Fragment>
        );
    }

    return null;
}

function renderHideApplicationOption(disabled: boolean, hideApplication: Action) {
    return (
        <React.Fragment>
            <Dropdown.Divider key="applicant-actions-divider-1" />
            <Dropdown.Item onClick={hideApplication} disabled={disabled}>
                <Translate id="HIDE_APPLICATION" />
            </Dropdown.Item>
        </React.Fragment>
    );
}

function renderUnhideApplicationOption(disabled: boolean, unhideApplication: Action) {
    return (
        <React.Fragment>
            <Dropdown.Divider key="applicant-actions-divider-11" />
            <Dropdown.Item onClick={unhideApplication} disabled={disabled}>
                <Translate id="UNHIDE_APPLICATION" />
            </Dropdown.Item>
        </React.Fragment>
    );
}

function renderGDPRRemoveOptions(
    show: boolean,
    hasCoApplicant: boolean,
    disabled: boolean,
    gdprRemoveApplicant: Action,
    gdprRemoveCoApplicant: Action,
    onMouseEnter: Action,
    onMouseLeave: Action) {
    return (
        <div
            className={classNames('dropdown-no-indicator dropdown-submenu dropleft', { disabled })}
            onMouseEnter={disabled ? undefined : onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className={classNames('dropdown-toggle', { disabled })}>
                <span style={{ marginLeft: '-15px', marginRight: '7px' }}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </span>
                <Translate id="GDPR_REMOVAL" />
            </div>
            <div className={classNames('dropdown-menu', { show })}>
                <div className="dropdown-arrow-left" />
                <Dropdown.Item onClick={gdprRemoveApplicant}>
                    <Translate id="APPLICANT" />
                </Dropdown.Item>
                {renderCoApplicantOption(hasCoApplicant, gdprRemoveCoApplicant)}
            </div>
        </div>
    );
}

function renderMarketingConsentOptions(
    show: boolean,
    disabled: boolean,
    canRevokeEmailMarketingConsent: boolean,
    canRevokeSmsMarketingConsent: boolean,
    revokeMarketingConsents: (particular?: MarketingConsent) => void,
    grantMarketingConsents: (particular?: MarketingConsent) => void,
    onMouseEnter: Action,
    onMouseLeave: Action) {
    
    return (
        <div
            className={classNames('dropdown-no-indicator dropdown-submenu dropleft', { 'disabled': disabled })}
            onMouseEnter={disabled ? undefined : onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className={classNames('dropdown-toggle', { 'disabled': disabled })}>
                <span style={{ marginLeft: '-15px', marginRight: '7px' }}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </span>
                <Translate id="MARKETING_CONSENTS" />
            </div>
            <div className={classNames('dropdown-menu', { show })}>
                <div className="dropdown-arrow-left" />
                {renderMarketingConsentItem('EMAIL', canRevokeEmailMarketingConsent, disabled, revokeMarketingConsents, grantMarketingConsents, MarketingConsent.EMAIL)}
                {renderMarketingConsentItem('SMS', canRevokeSmsMarketingConsent, disabled, revokeMarketingConsents, grantMarketingConsents, MarketingConsent.SMS)}
                {renderMarketingConsentItem('ALL_CHANNELS', canRevokeEmailMarketingConsent || canRevokeSmsMarketingConsent, disabled, revokeMarketingConsents, grantMarketingConsents)}
            </div>
        </div>
    );
}

function renderMarketingConsentItem(channelKey: string, canRevoke: boolean, disabled: boolean, revokeMarketingConsents: (particular?: MarketingConsent) => void, grantMarketingConsents: (particular?: MarketingConsent) => void, consent?: MarketingConsent) {
    const onClick = canRevoke ? revokeMarketingConsents : grantMarketingConsents;
    const actionKey = canRevoke ? "REVOKE_FOR" : "GRANT_FOR";

    return (
        <Dropdown.Item className={classNames('dropdown-toggle', { 'disabled': disabled })} onClick={() => onClick(consent)}>
        <Translate id={actionKey} data={{channel: <Translate id={channelKey} /> }} />
    </Dropdown.Item>
    );
}

function renderDeleteApplicationOption(disabled: boolean, deleteApplication: Action) {
    return (
        <React.Fragment>
            <Dropdown.Divider key="applicant-actions-divider-5" />
            <Dropdown.Item onClick={deleteApplication} disabled={disabled} style={{color: '#F05B59'}}>
                <Translate id="DELETE_APPLICATION" />
            </Dropdown.Item>
        </React.Fragment>
    );
}
function renderMortgageCalculatorOption(visible: boolean, openMortgageCalculator: Action) {
    if (!visible) {
        return null;
    }
    return (
        <>
            <Dropdown.Divider key="applicant-actions-divider-4" />
            <Dropdown.Item onClick={openMortgageCalculator}>
                <Translate id="MORTGAGE_CALCULATOR" />
            </Dropdown.Item>
        </>
    );
}

function renderCoApplicantOption(hasCoApplicant: boolean, gdprRemoveCoApplicant: Action) {
    return hasCoApplicant ? (
        <Dropdown.Item onClick={gdprRemoveCoApplicant}>
            <Translate id="COAPPLICANT" />
        </Dropdown.Item>
    ) : null;
}

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer
});

export default connect<UserProps, {}, ApplicationActionsProps, any>(mapStateToProps)(ApplicationActions);
