import React, {FunctionComponent, PropsWithChildren, ReactNode} from 'react';
import {Translate} from 'react-localize-redux';
import {FormControl, FormGroup} from 'react-bootstrap';
import classNames from 'classnames';
import Translation from '../Translation';

export interface InputFieldProps<WrapperProps = any> {
    descriptionKey?: string;
    description?: ReactNode | string;
    editMode?: boolean;
    errorCodesData?: any;
    placeholderKey?: string;
    afterContent?: ReactNode | string;
    className?: string;
    wrapper?: FunctionComponent<WrapperProps>;
    wrapperProps?: WrapperProps;
}

interface InputFieldInternalProps {
    error?: string;
    errorCode?: string;
}

function InputField<WrapperProps = any>(props: PropsWithChildren<InputFieldProps<WrapperProps> & InputFieldInternalProps>) {
    const Wrapper = (props.wrapper || React.Fragment) as FunctionComponent;
    if (props.editMode) {
        return(
            <React.Fragment>
                {renderDescription(props)}
                <FormGroup className={classNames('value', {'was-validated': props.errorCode || props.error}, props.className)}>
                    <Wrapper {...props.wrapperProps}>
                        {props.children}
                    </Wrapper>
                    {props.afterContent}
                    {renderValidationMessage(props.errorCode || props.error, props.errorCodesData, !!props.errorCode)}
                </FormGroup>
            </React.Fragment>
        );
    }

    return(
        <React.Fragment>
            <div className={classNames('value', props.className)}>
                <Wrapper {...props.wrapperProps}>
                    {props.children}
                </Wrapper>
                {props.afterContent}
            </div>
            {renderDescription(props)}
        </React.Fragment>
    );
};

function renderDescription(props: InputFieldProps) {
    if (props.description) {
        return <div className="description">{props.description}</div>;
    }

    if (props.descriptionKey) {
        return <div className="description"><Translate id={props.descriptionKey} /></div>;
    }

    return null;
}

function renderValidationMessage(message: string | null | undefined, data: any | undefined, translate: boolean) {
    if (message) {
        return <FormControl.Feedback type="invalid">
            {translate ? <Translation id={message} prefix="ERRORS" data={data} /> : message}            
        </FormControl.Feedback>;
    }

    return null;
}

export default InputField;
