import {ShowToastMessageProps} from "../actions/ToastMessagesActionCreator";
import {LocalizeContextProps} from "react-localize-redux";

export interface CommandError {
    code: string;
    message?: string;
}

export class CommandResult {
    public readonly errors: CommandError[] = [];
    public readonly success: boolean = true;

    constructor(result: any) {
        if (result.response) {
            result = result.response;
        }

        this.success = result.status < 400;

        if (!this.success && result.data.errors) {
            this.errors = Object.keys(result.data.errors)
                .map((code) => ({ code, message: getMessage(result.data.errors[code]) }));
        }
    }
}

function getMessage(messages: any) {
    return Array.isArray(messages) ? messages[0] : messages;
}

export const handleCommandResult = (result: CommandResult, props: ShowToastMessageProps & LocalizeContextProps, successKey: string, successContent?: string[] | string) => {
    if (result.success) {
        props.showToastMessage('success', props.translate(successKey).toString(), successContent || []);
    } else {
        props.showToastMessage('error', props.translate('ACTION_FAILED').toString(), result.errors.map(e => props.translate(e.code).toString()));
    }
}
