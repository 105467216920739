import { Action, Dispatch } from 'redux';
import { AxiosClientFactory } from '../helpers/AxiosClientFactory';
import { AxiosResponse } from 'axios';
import { User } from '../models/User';
import {CachingConfig, invalidateUserCache} from "../helpers/Caching";

export const GET_USER_BEGIN = 'GET_USER_BEGIN';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

interface GetUserBeginAction extends Action<typeof GET_USER_BEGIN> { }

interface GetUserSuccessAction extends Action<typeof GET_USER_SUCCESS> {
    user: User;
}

interface GetUserFailureAction extends Action<typeof GET_USER_FAILURE> {
    error: any;
}

export type GetUserAction = GetUserBeginAction
    | GetUserSuccessAction
    | GetUserFailureAction;

const getUser = (): GetUserBeginAction => ({
    type: GET_USER_BEGIN
});

const getUserSuccess = (user: User): GetUserSuccessAction => ({
    type: GET_USER_SUCCESS,
    user
});

const getUserFailure = (error: any): GetUserFailureAction => ({
    type: GET_USER_FAILURE,
    error
});

export const UserActionCreator = {
    loadUser: () => {
        return (dispatch: Dispatch) => {
            dispatch(getUser());

            AxiosClientFactory
                .getInstance()
                .get<User>(`/api/me`, {
                    id: '/api/me',
                    cache: {
                        ...CachingConfig.LONG,
                        override: invalidateUserCache()
                    }
                })
                .then((response: AxiosResponse<User>) => dispatch(getUserSuccess(response.data)))
                .catch((error) => getUserFailure(error));
        };
    }
};
