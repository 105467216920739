import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {PropsWithChildren, ReactNode, useEffect, useState} from 'react';

interface LazyScrollContainerProps<T> {
    maxCount: number;
    items: T[];
    renderItem: (item: T, index: number) => ReactNode;
    initialPosition?: number;
}

interface LazyScrollContainerState {
    position: number;
}

function getMaxCount<T>(props: LazyScrollContainerProps<T>) {
    return Math.max(props.maxCount, 1);
}

function getMaximumPosition<T>(props: LazyScrollContainerProps<T>) {
    const position = props.items.length - getMaxCount(props);
    return Math.max(position, 0);
}

export function LazyScrollContainer<T>(props: PropsWithChildren<LazyScrollContainerProps<T>>) {
    const initialPosition = props.initialPosition === undefined || props.initialPosition < 0 ? 0 : Math.min(props.initialPosition, getMaximumPosition(props));
    const [state, setState] = useState<LazyScrollContainerState>({ position: initialPosition });
    
    useEffect(() => {
        if (getMaximumPosition(props) < state.position) {
            setState({position: getMaximumPosition(props)});
        }
    }, [props.initialPosition, props.maxCount, props.items]); // eslint-disable-line react-hooks/exhaustive-deps

    const onPrevClick = () => {
        setState((prevState) => ({ position: prevState.position - 1 }));
    }

    const onNextClick = () => {
        setState((prevState) => ({ position: prevState.position + 1 }));
    }

    const children = renderChildren<T>(props, state);
    children.unshift(renderButton('prev', faChevronLeft, onPrevClick, state.position > 0));
    children.push(renderButton('next', faChevronRight, onNextClick,
        state.position + getMaxCount(props) < props.items.length));

    return <>{children}</>;
}

function renderButton(key: string, icon: IconDefinition, onClick: () => void, visible: boolean): ReactNode {
    return (
        <button key={key} className={'btn scroll-button ' + key} onClick={onClick} style={{visibility: visible ? 'visible' : 'hidden'}}>
            <FontAwesomeIcon icon={icon} />
        </button>
    );
}

function renderChildren<T>(props: LazyScrollContainerProps<T>, state: LazyScrollContainerState) {
    return props.items
        .slice(state.position, state.position + getMaxCount(props))
        .map(props.renderItem);
}
