import {TableState} from "../../common/models/TableState";
import {CampaignInteraction, CampaignEngagement} from "../../common/models/Campaign";
import {AxiosClientFactory} from "../../common/helpers/AxiosClientFactory";

export type GetCampaignEngagementsParams = {interactionFilter?: CampaignInteraction} & TableState;

export const getCampaignEngagements = (campaignId: number, params: GetCampaignEngagementsParams): Promise<CampaignEngagement[]> => {
    return AxiosClientFactory
        .getInstance()
        .get<CampaignEngagement[]>(`/api/campaigns/${campaignId}/engagements`, {params})
        .then((result) => result.data);
};