import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { ApplicantsFilter } from '../models/ApplicantsFilter';

export const getApplicantsCount = (params: ApplicantsFilter): Promise<number> => {
    return new Promise<number>((executor) => {
        params = params || { pageSize: 20, pageNumber: 0, orderDirection: 0 };

        AxiosClientFactory
            .getInstance()
            .get<number>('/api/applicants/count', { params })
            .then((result) => executor(result.data));
    });
};
