import {
    CommunicationTemplatesAction,
    SET_COMMUNICATION_TEMPLATES,
    SET_SIGNATURE_TEMPLATES
} from '../actions/CommunicationTemplatesActionCreator';
import { CommunicationTemplatesState } from '../interfaces/CommunicationTemplatesProps';

export function communicationTemplatesReducer(state: CommunicationTemplatesState | undefined, action: CommunicationTemplatesAction) {
    state = state || {
        communicationTemplates: {},
        signatureTemplates: {}
    };

    switch (action.type) {
        case SET_COMMUNICATION_TEMPLATES:
            return {
                ...state,
                communicationTemplates: { ...state.communicationTemplates, [action.messageType]: action.communicationTemplates }
            };
        case SET_SIGNATURE_TEMPLATES:
            return {
                ...state,
                signatureTemplates: { ...state.signatureTemplates, [action.messageType]: action.signatureTemplates }
            };
        default:
            return state;
    }
}
