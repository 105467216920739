import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Reminder } from '../../models/Reminder';
import { Storage } from '../../helpers/Storage';
import moment from 'moment';
import ReminderItem from './ReminderItem';
import './reminders.css';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-localize-redux';
import Tooltip from '../Tooltip';
import { UpdateReminderDispatchProps, RemindersState } from '../../interfaces/RemindersProps';
import { bindActionCreators } from 'redux';
import { RemindersActionCreator } from '../../actions/RemindersActionCreator';
import useInterval from 'use-interval';

interface ShowRemindersFlag { show: boolean; }

const Reminders: FunctionComponent<UpdateReminderDispatchProps & RemindersState> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => props.getReminders(), []);
    const reminders = props.reminders || [];
    const showRemindersFlag = Storage.getItem<ShowRemindersFlag>('show-reminders');
    const [filteredReminders, setFilteredReminders] = useState(props.reminders || []);
    const [displayReminders, setDisplayReminders] = useState(showRemindersFlag && showRemindersFlag.show !== false);
    useInterval(() => setFilteredReminders(reminders.filter(filterReminder).sort(sortReminder)), 1000, true);

    const hideReminders = () => {
        setDisplayReminders(false);
        Storage.setItem<ShowRemindersFlag>('show-reminders', { show: false });
    };
    const showReminders = () => {
        setDisplayReminders(true);
        Storage.setItem<ShowRemindersFlag>('show-reminders', { show: true });
    };

    const onDateChangeHandler = (personId, applicationId, date) => props.changeReminderDate(personId, applicationId, date);

    const onOpenHandler = (personId, applicationId) => props.turnOffReminder(personId, applicationId);

    const mapReminder = (r: Reminder) => (
        <ReminderItem
            key={`reminder-${r.personId}:${r.applicationId}`}
            onDateChange={onDateChangeHandler}
            onOpen={onOpenHandler}
            onHideItems={hideReminders}
            reminder={r}
        />
    );

    return (
        <div className={classNames('reminders', { minimized: !displayReminders })}>
            {filteredReminders.map(mapReminder)}
            <Tooltip content={<Translate id="SHOW_REMINDERS" />}>
                <div className="unminimize-icon" onClick={showReminders}>
                    <span>
                        <FontAwesomeIcon icon={faBell} />
                    </span>
                    <div>{filteredReminders.length > 0 ? filteredReminders.length : ''}</div>
                </div>
            </Tooltip>
        </div>
    );
};

const filterReminder = (r: Reminder): boolean => (moment().add(5, 'minutes').isAfter(moment(r.reminderDate)));

const sortReminder = (a: Reminder, b: Reminder): number => (new Date(b.reminderDate).getTime() - new Date(a.reminderDate).getTime());

const mapStateToProps = (state: any) => ({
    ...state.reminderChangeActionReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators({
    ...RemindersActionCreator
} as any, dispatch);

export default connect<RemindersState, UpdateReminderDispatchProps, {}, any>(mapStateToProps, mapActionCreatorsToProps)(Reminders);
