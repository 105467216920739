import React, {FunctionComponent, useEffect} from "react";
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {CampaignViewDataState} from "../../../reducers/campaignViewDataReducer";
import {CampaignViewDataActionCreators, CampaignViewDataActions} from "../../../actions/CampaignViewDataActionCreators";
import {bindActionCreators, Dispatch} from "redux";
import {CampaignType} from "../../../../common/models/Campaign";

interface CampaignViewTabsProps extends CampaignViewDataState, CampaignViewDataActions {
    editMode: boolean;
}
const CampaignViewTabs: FunctionComponent<CampaignViewTabsProps> = (props) => {
    useEffect(() => {
        if (props.editMode && props.activeTab === 'engagements') {
            props.setActiveTab('design');
        }
        return () => props.setActiveTab('design');
    }, [props.editMode]); // eslint-disable-line react-hooks/exhaustive-deps
    
    if (!props.campaign) {
        return null;
    }
    
    return (
        <div className="generic-tabs">
            <div className={`generic-tab ${props.activeTab === 'design' ? 'active' : ''}`} onClick={() => props.setActiveTab('design')}>
                <Translate id="DESIGN"/>
            </div>
            <div className={`generic-tab ${props.activeTab === 'recipients' ? 'active' : ''}`} onClick={() => props.setActiveTab('recipients')}>
                <Translate id="CAMPAIGNS_VIEW.TABLE_HEADERS.RECIPIENTS"/>
            </div>
            {props.campaign.sentAt && !props.editMode &&
                <div className={`generic-tab ${props.activeTab === 'engagements' ? 'active' : ''}`} onClick={() => props.setActiveTab('engagements')}>
                    <Translate id={props.campaign.type === CampaignType.SMS ? 'REPLIES' : 'CLICKS'}/>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    ...state.campaignViewDataReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...CampaignViewDataActionCreators
}, dispatch);
export default connect<CampaignViewDataState, CampaignViewDataActions>(mapStateToProps, mapActionCreatorsToProps)(CampaignViewTabs);