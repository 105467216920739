import { ApplicantDataAction, SET_APPLICANT_APPLICATIONS, SET_APPLICANT_CURRENT_APPLICATION, SET_APPLICANT_INSURANCES } from '../actions/applicantDataActionsCreator';

export function applicantDataReducer(state: any, action: ApplicantDataAction) {
    state = state || {
        applications: [],
        currentApplication: undefined,
        insurances: []
    };

    switch (action.type) {
        case SET_APPLICANT_APPLICATIONS:
            return {
                ...state,
                applications: action.payload
            };
        case SET_APPLICANT_CURRENT_APPLICATION:
            return {
                ...state,
                currentApplication: action.payload
            };
        case SET_APPLICANT_INSURANCES:
            return {
                ...state,
                insurances: action.payload
            };
        default: return state;
    }
}
