import { TranslateFunction } from 'react-localize-redux';

const languages = ['no', 'fi', 'pl', 'lt', 'en', 'et', 'ru', 'es'];

export interface LanguageItem {
  id: string | null;
  name: string;
}

export const getLanguageItems = (translate: TranslateFunction, prefix: boolean, nullItemLabeled: boolean = true): LanguageItem[] =>
    [{ id: null, name: nullItemLabeled ? getName('ALL', prefix, translate) : ''} as LanguageItem].concat(
      languages.map<LanguageItem>((l) => ({id: l, name: getName(l, prefix, translate)}))
    );

const getName = (key: string, prefix: boolean, translate: TranslateFunction) =>
    prefix ? `${translate('LANGUAGE.PREFIX')}${translate('LANGUAGE.' + key.toUpperCase())}`.toString() : translate('LANGUAGE.' + key.toUpperCase()).toString();
