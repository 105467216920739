import React, { FunctionComponent } from 'react';
import CurrentPageView from '../../../common/components/CurrentPageView';
import PagePicker, { PagePickerProps } from '../../../common/components/PagePicker';
import PageSizePicker, { PageSizePickerProps } from '../../../common/components/PageSizePicker';
import {connect} from "react-redux";
import {SettingsProps} from "../../interfaces/SettingsProps";
import {Setting} from "../../models/Setting";

interface TablePaginationProps extends PagePickerProps, PageSizePickerProps {
    isLoading?: boolean;
}

const TablePagination: FunctionComponent<TablePaginationProps & SettingsProps> = (props) => {
    let mainFontColor = '#FFFFFF';

    if (props.settingsData && props.settingsData.isLoaded) {
        mainFontColor = props.settingsData.settings.filter((x: Setting) => (x.name === 'MAIN_FONT_COLOR'))[0].value;
    }

    if (props.isLoading) {
        return (<div />);
    } else {
        return (
            <div style={{ color: mainFontColor, display: 'flex', justifyContent: 'space-between', height: '19px', lineHeight: '19px' }}>
                <div>
                    <CurrentPageView
                        count={props.count}
                        currentPage={props.pageNumber}
                        pageSize={props.pageSize}
                    />
                    <PageSizePicker
                        pageSize={props.pageSize}
                        onPageSizePicked={props.onPageSizePicked}
                        pickableSizes={props.pickableSizes}
                    />
                </div>
                <div>
                    <PagePicker
                        onPagePicked={props.onPagePicked}
                        count={props.count}
                        pageSize={props.pageSize}
                        pageNumber={props.pageNumber}
                    />
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer
});

export default connect<SettingsProps, {}, TablePaginationProps, any>(mapStateToProps)(TablePagination);
