import { ToggleSidebarAction, TOGGLE_SIDEBAR } from '../actions/SidebarActionCreator';

export interface SidebarState {
    sidebarExpanded?: boolean;
}

export function sidebarReducer(state: any, action: ToggleSidebarAction) {
    state = state || { sidebarExpanded: false };

    if (action.type === TOGGLE_SIDEBAR) {
        return {
            ...state,
            sidebarExpanded: !state.sidebarExpanded
        };
    }

    return state;
}
