import React, { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { Translate } from "react-localize-redux";
import classNames from "classnames";
import { UserProps } from "../../../common/interfaces/UserProps";
import { UserImage } from "../../../common/components/UserImage";
import { CustomerAdvisorsTeam } from "../models/CustomerAdvisorsTeam";
import './customer-advisor-information.css'
import { putCustomerAdvisor } from "../api/putCustomerAdvisor";
import { CustomerAdvisor } from "../models/CustomerAdvisor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { CustomerAdvisorsTeamsProps } from "../../../common/interfaces/CustomerAdvisorsTeamsProps";
import { bindActionCreators } from "redux";
import CustomerAdvisorsTeamsActionsCreator, { CustomerAdvisorsTeamsActions } from "../../applicants/actions/CustomerAdvisorsTeamsActionsCreator";
import {isAdmin} from "../../../common/helpers/isAdmin";

interface CustomerAdvisorInformationProps {
    customerId: number;
    customerAdvisorId: number | null;
    refresh: () => void;
}

const CustomerAdvisorInformation: FunctionComponent<CustomerAdvisorInformationProps & UserProps & CustomerAdvisorsTeamsProps & CustomerAdvisorsTeamsActions> = (props) => {
    useEffect(() => {
        if (props.customerAdvisorsTeams === undefined) {
            props.loadCustomerAdvisorsTeams();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (props.customerAdvisorsTeams === undefined) {
        return null;
    }

    const updateAdvisor = (advisorId: number | null): void => {
        putCustomerAdvisor(props.customerId, advisorId).then(props.refresh);
    };

    const userTeamId = props.userData.user?.teamId || -1;

    const advisorTeamId = props.customerAdvisorsTeams.find(team => team.customerAdvisors.some(user => user.id === props.customerAdvisorId))?.id;

    const userIsAdmin = isAdmin(props);

    // hotfix to allow caseworkers to assign themselves to customers without advisors
    const isDisabled = !userIsAdmin && userTeamId !== advisorTeamId && props.customerAdvisorId !== null;

    const customerAdvisor = props.customerAdvisorsTeams
        .map(team => team.customerAdvisors)
        .reduce((a, b) => a.concat(b))
        .find(advisor => advisor.id === props.customerAdvisorId);

    const triggerContent = customerAdvisor !== undefined ?
        (
            <div className="customer-advisor">
                <div className="photo">
                    <UserImage email={customerAdvisor.username} size={20} />
                </div>
                {customerAdvisor.displayName}
            </div>
        ) : (
            <div className="customer-advisor">
                <div className="photo">
                    <FontAwesomeIcon icon={faPlusCircle} />
                </div>
                <Translate id="NO_ADVISOR" />
            </div>
        );

    return (
        <Dropdown>
            <Dropdown.Toggle disabled={isDisabled} id="advisor-picker" className="btn-white-bg" style={{ padding: '0px' }}>
                {triggerContent}
            </Dropdown.Toggle>
            <div className="dropdown-arrow-down" />
            <Dropdown.Menu>
                {renderMenuContents(userIsAdmin, props.customerAdvisorsTeams, userTeamId, props.customerAdvisorId, updateAdvisor)}
            </Dropdown.Menu>
        </Dropdown>
    );
}

function renderMenuContents(userIsAdmin: boolean, advisorTeams: CustomerAdvisorsTeam[], userTeamId: number, customerAdvisorId: number | null, updateAdvisor: (advisorId: number | null) => void) {
    const myTeam = advisorTeams.find((team) => team.id === userTeamId);
    const otherTeams = advisorTeams.filter((team) => team.id !== userTeamId);

    return (
        <>
            <Dropdown.Item disabled={true}>
                <b><Translate id="CHANGE_ADVISOR" /></b>
            </Dropdown.Item>
            <Dropdown.Divider />
            {renderMyTeam(myTeam, customerAdvisorId, updateAdvisor)}
            {userIsAdmin ? renderOtherTeams(otherTeams, customerAdvisorId, updateAdvisor) : null}
            {renderRemoveAdvisor(customerAdvisorId !== null, updateAdvisor)}
        </>
    );
}

function renderMyTeam(team: CustomerAdvisorsTeam | undefined, customerAdvisorId: number | null, updateAdvisor: (advisorId: number | null) => void) {
    if (team === undefined) {
        return null;
    }

    return (
        <>
            {team.customerAdvisors.map(advisor => renderAdvisor(advisor, customerAdvisorId, updateAdvisor))}
        </>
    );
}

function renderOtherTeams(otherTeams: CustomerAdvisorsTeam[], customerAdvisorId: number | null, updateAdvisor: (advisorId: number | null) => void) {
    if (otherTeams.length < 1) {
        return null;
    }

    return (
        <>
            <Dropdown.Divider />
            {otherTeams.map((team, index) => (
                <AdvisorTeamMenu
                    team={team}
                    customerAdvisorId={customerAdvisorId}
                    updateAdvisor={updateAdvisor}
                    key={`team-${index}`}
                />))}
        </>
    );
}

function renderRemoveAdvisor(shouldRender: boolean, updateAdvisor: (advisorId: number | null) => void) {
    if (!shouldRender) {
        return null;
    }

    return (
        <>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => updateAdvisor(null)}>
                <Translate id="REMOVE_ADVISOR" />
            </Dropdown.Item>
        </>
    );
}

const AdvisorTeamMenu: FunctionComponent<{ team: CustomerAdvisorsTeam, customerAdvisorId: number | null, updateAdvisor: (advisorId: number | null) => void }> = (props) => {
    const [show, setShow] = useState(false);
    const onMouseEnter: React.MouseEventHandler<HTMLElement> = () => setShow(true);
    const onMouseLeave: React.MouseEventHandler<HTMLElement> = () => setShow(false);
    const onClick: React.MouseEventHandler<HTMLElement> = (event) => event.stopPropagation();

    return (
        <div
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className="dropdown-no-indicator dropdown-submenu dropright">
                <div className="dropdown-toggle btn-white-bg customer-advisor-team">
                    <div>
                        {props.team.name}
                    </div>
                    <div style={{ marginRight: '-15px', marginLeft: '7px' }}>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </div>
                </div>
                <div className={classNames('dropdown-menu', { show })}>
                    <div className="dropdown-arrow-right" />
                    {props.team.customerAdvisors.map((advisor) => renderAdvisor(advisor, props.customerAdvisorId, props.updateAdvisor))}
                </div>
            </div>
        </div>
    );
};

function renderAdvisor(advisor: CustomerAdvisor, customerAdvisorId: number | null, updateAdvisor: (advisorId: number | null) => void) {
    if (advisor.id === customerAdvisorId) {
        return null;
    }

    return (
        <Dropdown.Item key={`customer-advisor-${advisor.id}`} onClick={() => updateAdvisor(advisor.id)}>
            <span style={{ marginRight: '5px' }}>
                <UserImage email={advisor.username} size={20} />
            </span>
            {advisor.displayName}
        </Dropdown.Item>
    );
}

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer,
    ...state.customerAdvisorsTeamsReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators({
    ...CustomerAdvisorsTeamsActionsCreator
} as any, dispatch);

export default connect<UserProps & CustomerAdvisorsTeamsProps, CustomerAdvisorsTeamsActions, CustomerAdvisorInformationProps, any>(mapStateToProps, mapActionCreatorsToProps)(CustomerAdvisorInformation);