import React, {FunctionComponent, useState} from "react";
import "./EngagementsTabContent.css";
import {CampaignViewDataState} from "../../../../../reducers/campaignViewDataReducer";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {
    CampaignViewDataActionCreators,
    CampaignViewDataActions
} from "../../../../../actions/CampaignViewDataActionCreators";
import TableRows from "../../../../../../common/components/table/TableRows";
import {formatDateWithTime} from "../../../../../../common/helpers/dateFormatter";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {NavLink} from "react-router-dom";
import TableHeader, {TableHeaderCellModel} from "../../../../../../common/components/table/TableHeader";
import TablePagination from "../../../../../../common/components/table/TablePagination";
import {Table} from "react-bootstrap";
import RefreshButton from "../../../../../../common/components/RefreshButton";
import {countCampaignEngagements} from "../../../../../api/countCampaignEngagements";
import {CampaignEngagement, CampaignInteraction, CampaignType} from "../../../../../../common/models/Campaign";

type EngagementsTabContentProps = CampaignViewDataState & CampaignViewDataActions & LocalizeContextProps;

const EngagementsTabContent: FunctionComponent<EngagementsTabContentProps> = (props) => {
    const [campaignEngagementsCount, setCampaignEngagementsCount] = useState<number>(props.campaignEngagements ? props.campaignEngagements.length : 0);

    if (!props.campaign || props.campaign.id === undefined) {
        return null;
    }
    
    countCampaignEngagements(props.campaign.id).then(count => setCampaignEngagementsCount(count));

    const refreshCampaignEngagements = () => {
        props.loadCampaignEngagements(props.campaign!.id!, props.campaignEngagementsTableState, props.campaign?.type === CampaignType.EMAIL ? CampaignInteraction.CLICKED : undefined);
    }
    
    return (
        <div className="engagements-tab-content">
            <RefreshButton
                style={{position: 'absolute', right: '-36px'}}
                isLoading={props.isLoading}
                onClick={refreshCampaignEngagements}
            />
            <Table
                className="campaigns-table engagements"
                hover={true}
                borderless={true}
                style={{marginBottom: '24px'}}
            >
                <TableHeader cells={props.campaign.type === CampaignType.SMS ? smsCampaignEngagementsTableHeaderCells : emailCampaignEngagementsTableHeaderCells}/>
                <TableRows<CampaignEngagement>
                    isLoading={props.isLoading}
                    rows={props.campaignEngagements || []}
                    renderRow={campaignEngagement => (
                        <tr key={campaignEngagement.personId} style={{cursor: 'default'}}>
                            <td>{renderApplicantCell(campaignEngagement)}</td>
                            {props.campaign!.type === CampaignType.SMS &&
                                <td style={{whiteSpace: 'break-spaces'}}>{campaignEngagement.content}</td>
                            }
                            <td>{formatDateWithTime(campaignEngagement.date, props.translate)}</td>
                            <td>{campaignEngagement.email}</td>
                            <td>{campaignEngagement.mobileNumber}</td>
                            {props.campaign!.type === CampaignType.SMS &&
                                <td><Translate id={campaignEngagement.read ? 'YES' : 'NO'}/></td>
                            }
                        </tr>
                    )}
                />
            </Table>
            <TablePagination
                isLoading={props.isLoading}
                pageNumber={props.campaignEngagementsTableState.pageNumber}
                pageSize={props.campaignEngagementsTableState.pageSize}
                onPagePicked={(pageNumber) => props.setPageNumber(pageNumber, 'engagements')}
                onPageSizePicked={(pageSize) => props.setPageSize(pageSize, 'engagements')}
                count={campaignEngagementsCount}
                pickableSizes={[20, 50, 100, 250, 500]}
            />
        </div>
    );
}

const emailCampaignEngagementsTableHeaderCells: TableHeaderCellModel[] = [
    {index: 0, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.APPLICANT'},
    {index: 1, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.DATE'},
    {index: 2, translateKey: 'EMAIL'},
    {index: 3, translateKey: 'MOBILE_NUMBER'}
]

const smsCampaignEngagementsTableHeaderCells: TableHeaderCellModel[] = [
    {index: 0, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.APPLICANT'},
    {index: 1, translateKey: 'CONTENT'},
    {index: 2, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.DATE'},
    {index: 3, translateKey: 'EMAIL'},
    {index: 4, translateKey: 'MOBILE_NUMBER'},
    {index: 5, translateKey: 'READ'}
]

const renderApplicantCell = (message: CampaignEngagement) => {
    if (!message.personId) {
        return <span className="unknown-sender"><Translate id="UNKNOWN_SENDER"/></span>;
    }
    const path = message.applicationId
        ? `/applicant/${message.personId}/application/${message.applicationId}`
        : `/applicant/${message.personId}`;

    return (
        <NavLink to={path}>
            {message.applicantName}
        </NavLink>
    );    
}

const mapStateToProps = (state: any) => ({
    ...state.campaignViewDataReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...CampaignViewDataActionCreators
}, dispatch);

export default connect<CampaignViewDataState, CampaignViewDataActions>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(EngagementsTabContent));