import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { ConsumerLoanDetails } from '../models/ConsumerLoanDetails';
import { ConsumerLoanApplicant } from '../models/ConsumerLoanApplicant';
import { Redemption } from '../models/Redemption';
import { AddConsumerLoanCommandResult } from '../models/AddConsumerLoanCommandResult';

interface AddConsumerLoanCommand {
    details: ConsumerLoanDetails;
    applicant: ConsumerLoanApplicant;
    coApplicant?: ConsumerLoanApplicant;
    redemptions: Redemption[];
}
export const postConsumerLoan = async (command: AddConsumerLoanCommand, complete: boolean): Promise<AddConsumerLoanCommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .post(`api/consumerloans${complete ? '/saveAsComplete' : ''}`, command);

    return ((result.data) || (result as any).response.data) as AddConsumerLoanCommandResult;
};
