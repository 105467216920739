import {AxiosPromise} from 'axios';
import {AxiosClientFactory} from '../../../common/helpers/AxiosClientFactory';
import {ApplicationSource} from "../models/ApplicationSource";
import {CachingConfig} from "../../../common/helpers/Caching";

export const getApplicationSources = (): AxiosPromise<ApplicationSource[]> => {
    return AxiosClientFactory
        .getInstance()
        .get<ApplicationSource[]>(`/api/applicationsources`, {
            cache: CachingConfig.LONG
        });
};
