import { faFileInvoice, faPaperPlane, faPen, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { FunctionComponent } from "react";
import { LocalizeContextProps, TranslateFunction, withLocalize } from 'react-localize-redux';
import Tooltip from '../../../../common/components/Tooltip';
import { formatDate, formatDateWithTime } from '../../../../common/helpers/dateFormatter';
import { getEnumTranslationKey } from '../../../../common/helpers/getTranslationKey';
import { ChangeInsuranceHistoryReadModel, InsuranceChangeType, InsuranceHistoryReadModel, InsuranceHistoryType, InsuranceReportItemType, InvoiceInsuranceHistoryReadModel, ReportItemInsuranceHistoryReadModel } from '../../models/InsuranceHistoryReadModel';

interface InsuranceHistoryProps extends LocalizeContextProps {
    history?: InsuranceHistoryReadModel[];
}

const InsuranceHistory: FunctionComponent<any> = (props: InsuranceHistoryProps) => {
    if (props.history === undefined) {
        return null;
    }

    return (<table className="insurance-history">
        <tbody>
            {props.history.map((i) => renderRow(i, props.translate))}
        </tbody>
    </table>);
}

function renderRow(item: InsuranceHistoryReadModel, translate: TranslateFunction) {
    switch(item.historyType) {
        case InsuranceHistoryType.Change:
            return renderChangeRow(item as ChangeInsuranceHistoryReadModel, translate);
        case InsuranceHistoryType.Invoice:
            return renderInvoiceRow(item as InvoiceInsuranceHistoryReadModel, translate);
        case InsuranceHistoryType.Report:
            return renderReportRow(item as ReportItemInsuranceHistoryReadModel, translate);
    }
}

const getKey = (item: InsuranceHistoryReadModel) => `${item.historyType}-${item.timestamp}`;

const renderChangeRow =(item: ChangeInsuranceHistoryReadModel, translate: TranslateFunction) =>
    <tr key={getKey(item)}><td><FontAwesomeIcon icon={getChangeIcon(item)}/></td><td>{getChangeText(item, translate)}</td><td>{formatDateWithTime(item.timestamp, translate)}</td></tr>;

const getChangeIcon = (item: ChangeInsuranceHistoryReadModel) =>
    item.changeType === InsuranceChangeType.Cancelled ? faTimesCircle : faPen;

const getChangeText = (item: ChangeInsuranceHistoryReadModel, translate) =>
    translate(item.changeType === InsuranceChangeType.Cancelled ? 'CANCELLED' : 'CREATED');

const renderInvoiceRow = (item: InvoiceInsuranceHistoryReadModel, translate: TranslateFunction) =>
    <tr key={getKey(item)}><td>{renderInvoiceIcon(item, translate)}</td><td>{getInvoiceNumber(item)} {getInvoiceStatus(item, translate)}</td><td>{formatDateWithTime(item.timestamp, translate)}</td></tr>;

const renderInvoiceIcon = (item: InvoiceInsuranceHistoryReadModel, translate: TranslateFunction) =>
    item.balance > 0
        ? <Tooltip content={<span>{translate('INVOICE_DUE_DATE', {dueDate: formatDate(item.dueDate)})}<br/>{translate('INVOICE_BALANCE', {balance: item.balance})}</span>}><FontAwesomeIcon icon={faFileInvoice}/></Tooltip>
        : <FontAwesomeIcon icon={faFileInvoice}/>;

const getInvoiceNumber = (item: InvoiceInsuranceHistoryReadModel) => `Invoice No. ${item.number}`;

const getInvoiceStatus = (item: InvoiceInsuranceHistoryReadModel, translate: TranslateFunction) => <span className={getInvoiceStatusClass(item)}>{translate(getInvoiceStatusText(item))}</span>;

const getInvoiceStatusClass = (item: InvoiceInsuranceHistoryReadModel) => item.balance <= 0
    ? "paid-text"
    : (moment(item.dueDate) < moment().startOf('day')) ? 'error-text ' : '';

const getInvoiceStatusText = (item: InvoiceInsuranceHistoryReadModel) => item.balance <= 0
    ? "INVOICE_PAID"
    : (moment(item.dueDate) < moment().startOf('day')) ? 'PAYMENT_DATE_EXCEEDED' : 'INVOICE_NOT_PAID';

const renderReportRow = (item: ReportItemInsuranceHistoryReadModel, translate: TranslateFunction) =>
    <tr key={getKey(item)}><td><FontAwesomeIcon icon={faPaperPlane}/></td><td>{getReportText(item, translate)}</td><td>{formatDateWithTime(item.timestamp, translate)}</td></tr>;

const getReportText = (item: ReportItemInsuranceHistoryReadModel, translate: TranslateFunction) =>
    translate(getEnumTranslationKey(InsuranceReportItemType, item.itemType, 'INSURANCE_REPORT_ITEM_TYPE'), {number: item.invoiceNumber});

export default withLocalize(InsuranceHistory);