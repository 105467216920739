import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandError } from '../../../common/helpers/CommandResult';
import { ConsumerLoanDetails } from '../models/ConsumerLoanDetails';
import { ConsumerLoanApplicant } from '../models/ConsumerLoanApplicant';
import { Redemption } from '../models/Redemption';
import { LoanStatus } from '../../../common/models/LoanStatus';

interface ValidateConsumerLoanCommand {
    loanStatus: LoanStatus;
    details: ConsumerLoanDetails;
    applicant: ConsumerLoanApplicant;
    coApplicant?: ConsumerLoanApplicant;
    redemptions: Redemption[];
}

export const validateConsumerLoan = async (data: ValidateConsumerLoanCommand): Promise<CommandError[]> => {
    const result = await AxiosClientFactory
        .getInstance()
        .post(`api/consumerLoans/validate`, data);
    
    if (result.status !== 200) {
        return [];
    }
    return Object.entries(result.data.errors).map((e) => ({ code: e[0], message: e[1] })) as CommandError[];
};
