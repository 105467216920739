import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { Redemption } from '../models/Redemption';
import { AxiosResponse } from 'axios';

export const putRedemptions = async (personId: string | number, loanId: string | number, redemptions: Redemption[]): Promise<string[]> => {
    return new Promise<string[]>((resolve) => {
        AxiosClientFactory
            .getInstance()
            .put(`api/applicants/${personId}/consumerloans/${loanId}/redemptions`, redemptions)
            .then((response: AxiosResponse<string[]>) => resolve(response.data));
    });
};
