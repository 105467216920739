import { CommandResult } from '../../../common/helpers/CommandResult';
import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';

export const postSmsMessage = async (content: string, mobileNumber: string, applicationId: number | string): Promise<CommandResult> => {
    const client = AxiosClientFactory.getInstance();

    if (content === '') {
        throw new Error('Cannot send empty text message');
    }

    if (mobileNumber === '') {
        throw new Error('Cannot send message without mobile number');
    }

    const result = await client.post('api/outboundSmses', { content, mobileNumber, applicationId });

    return new CommandResult(result);
};
