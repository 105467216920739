import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { PropertyInformation } from '../models/PropertyInformation';

export const getRealEstateInformationPageUrl = async (property?: PropertyInformation): Promise<string> => {
    const params =
        property
        ? {
            municipalityNumber: property.municipalityNumber,
            cadastralUnitNumber: property.cadastralUnitNumber,
            propertyUnitNumber: property.propertyUnitNumber,
            leaseholdUnitNumber: property.leaseholdUnitNumber,
            condominiumUnitNumber: property.condominiumUnitNumber,
            cooperationOrganizationNumber: property.cooperationOrganizationNumber,
            shareNumber: property.shareNumber,
            address: `${property.streetName}, ${property.postalCode} ${property.municipality}`
        }
        : {};

    const response = await AxiosClientFactory
        .getInstance()
        .get<string>('/api/propertyInformation/realEstateInfoUrl', { params });

    return response.data;
};
