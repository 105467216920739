import React, { FunctionComponent, useState, useMemo, useEffect } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { withLocalize, LocalizeContextProps, Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';
import { ProductType } from '../models/ProductType';
import { ApplicantCommunicationModel } from '../models/ApplicantCommunicationModel';
import { ApplicantsFilter } from '../../applications/applicants/models/ApplicantsFilter';
import { Transaction } from '../../applications/applicant/models/Transaction';
import { Bank } from '../../applications/applicant/models/Bank';
import { Modal, Button, Form } from 'react-bootstrap';
import { CommunicationTemplate } from '../models/CommunicationTemplate';
import { ShowToastMessageProps, showToastMessage } from '../actions/ToastMessagesActionCreator';
import { CommunicationTemplatesDispatchProps, CommunicationTemplatesState } from '../interfaces/CommunicationTemplatesProps';
import { CommunicationTemplatesActionCreator } from '../actions/CommunicationTemplatesActionCreator';
import { beautifyLastName } from '../helpers/beautifyName';
import { ConsumerLoanApplicant } from '../../applications/applicant/models/ConsumerLoanApplicant';
import { mapProductTypesToSelectableItems } from '../helpers/productTypeFunctions';
import DropdownInputField from './input-fields/DropdownInputField';
import { getLanguageItems } from '../helpers/getLanguageItems';
import GenericDropdown from './GenericDropdown';
import { MessageType } from '../models/MessageType';
import { renderEditor, fillSmsTemplateContentWithData, fillEmailTemplateContentWithData } from '../helpers/SendMailAndSmsUtils';
import { UserProps } from '../interfaces/UserProps';
import TextInputField from './input-fields/TextInputField';
import { MailSender } from '../helpers/useMailSender';
import { SettingsProps } from '../interfaces/SettingsProps';
import { postSmsMessage } from '../../applications/applicant/api/postSmsMessage';
import { CommandResult } from '../helpers/CommandResult';
import { postEmailMessage } from '../../applications/applicant/api/postEmailMessage';
import { postSmsToMany } from '../../applications/applicant/api/postSmsToMany';
import { postEmailToMany } from '../../applications/applicants/api/postEmailToMany';
import {editorConfig} from "./SendEmailModal";
import CommunicationTemplatesSelector from "./CommunicationTemplatesSelector";
import MailSenderSelector from "./MailSenderSelector";
import {isValueSet} from "../helpers/isValueSet";

interface NameValueItem {
    name: string;
    value: string;
}

interface CommonModalProps {
    show: boolean;
    onClose: () => void;
}

interface SendEmailAndSmsModalOwnProps extends CommonModalProps {
    productType?: ProductType;
    applicant?: ConsumerLoanApplicant | null;
    applicationId?: number;
    applicantsFilter?: ApplicantsFilter;
    applicantsCount?: number;
    incompleteFormLink: string;
    myApplicationLink?: string;
    debtInformationUpdateLink?: string;
    transactions?: Transaction[];
    banks?: Bank[];
    publicId?: string;
    onLanguageChanged?: (language: string) => void;
}

type SendEmailAndSmsModalReduxProps = SettingsProps
    & CommunicationTemplatesState
    & UserProps;

type SendEmailAndSmsModalDispatchProps = ShowToastMessageProps
    & CommunicationTemplatesDispatchProps;

type SendEmailAndSmsModalProps = SendEmailAndSmsModalOwnProps
    & SendEmailAndSmsModalReduxProps
    & SendEmailAndSmsModalDispatchProps
    & LocalizeContextProps;

const SendEmailAndSmsModal: FunctionComponent<SendEmailAndSmsModalProps> = (props) => {
    const [productType, setProductType] = useState(props.productType || ProductType.ConsumerLoan);
    const [language, setLanguage] = useState<string | null>(props.applicant ? props.applicant.primaryLanguage : null);
    const languages = useMemo(() => getLanguageItems(props.translate, false, false), []); // eslint-disable-line react-hooks/exhaustive-deps
    const [subject, setSubject] = useState('');
    const [errors, setErrors] = useState<string[]>([]);
    const [mailContent, setMailContent] = useState('');
    const [smsContent, setSmsContent] = useState('');
    const [selectedMailSender, setSelectedMailSender] = useState<MailSender | undefined>(undefined);
    const [emailTemplate, setEmailTemplate] = useState<CommunicationTemplate | undefined>(undefined);
    const [smsTemplate, setSmsTemplate] = useState<CommunicationTemplate | undefined>(undefined);

    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');

    const mobileNumbers = getMobileNumbers(props);
    const emails = getEmails(props);

    const onLanguageChanged = (lang: string | number | null | undefined) => setLanguage(lang === null || lang === undefined ? null : lang.toString());

    useEffect(() => {
        setMobileNumber(props.applicant && props.applicant.mobileNumber ? props.applicant.mobileNumber : '')
        setEmail(props.applicant && props.applicant.email ? props.applicant.email : '');
    }, [props.applicant]); // eslint-disable-line react-hooks/exhaustive-deps

    const selectedNameValueItemDisplay = (x: NameValueItem) => (`${x.name} (${x.value})`);

    const renderMobileSelector = () => {
        const selectedMobileNumber = mobileNumbers.find((x) => x.value === mobileNumber);
        const onSelectedMobileNumberChanged = (x: NameValueItem) => setMobileNumber(x.value);
        return mobileNumbers.length > 1 ? (
            <React.Fragment>
                <div className="description">
                    <Translate id="SELECT_MOBILE_NUMBER" />
                </div>
                <div className="value">
                    <GenericDropdown
                        dropdownId="mobileNumber"
                        selectedItem={selectedMobileNumber}
                        displayValue={selectedNameValueItemDisplay}
                        items={mobileNumbers}
                        onSelectionChanged={onSelectedMobileNumberChanged}
                    />
                </div>
            </React.Fragment>
        ) : null;
    };

    const renderEmailSelector = () => {
        const selectedEmail = emails.find((x) => x.value === email);
        const onSelectedEmailChanged = (x: NameValueItem) => setEmail(x.value);
        return emails.length > 1 ? (
            <React.Fragment>
                <div className="description">
                    <Translate id="SELECT_EMAIL" />
                </div>
                <div className="value">
                    <GenericDropdown
                        dropdownId="email"
                        selectedItem={selectedEmail}
                        displayValue={selectedNameValueItemDisplay}
                        items={emails}
                        onSelectionChanged={onSelectedEmailChanged}
                    />
                </div>
            </React.Fragment>
        ) : null;
    };

    const onSelectedSmsTemplateChanged = (item?: CommunicationTemplate, signature?: string) => {
        setSmsTemplate(item);
        const templateContentWithData = fillSmsTemplateContentWithData(
            (item && item.templateContent) || '',
            signature,
            props.userData.user,
            props.applicant,
            props.incompleteFormLink,
            props.debtInformationUpdateLink,
            props.transactions || [],
            props.banks || [],
            props.publicId
        );
        // for now sms templates don't have signature placeholders
        setSmsContent((templateContentWithData + ' ' + (signature || '')).trimEnd());
    };

    const onSelectedEmailTemplateChanged = (item?: CommunicationTemplate, signature?: string) => {
        setEmailTemplate(item);
        setSubject(item && item.subject !== null ? item.subject : '');
        const templateContentWithData = fillEmailTemplateContentWithData(
            (item && item.templateContent) || '',
            signature,
            props.userData.user,
            props.applicant,
            props.incompleteFormLink,
            props.myApplicationLink || '',
            props.debtInformationUpdateLink,
            props.transactions || [],
            props.banks || [],
            props.publicId
        );
        setMailContent(templateContentWithData);
    };

    const translate = (key: string) => props.translate(key).toString();

    const send = () => {
        setErrors(!subject ? ['SubjectIsRequired'] : []);
        if (errors.length) {
            return;
        }
        if (props.applicant && props.applicationId && mobileNumber && email) {
            const sendSmsSuccessMessage = props.translate('SEND_SMS_SUCCESS', getApplicantPlaceholders(props.applicant, mobileNumber)).toString();
            const onSmsFailure = () => props.showToastMessage('error', translate('SEND_SMS'), translate('SEND_SMS_ERROR'));
            const onSmsSuccess = (successResult: CommandResult): void => {
                if (successResult.success) {
                    props.showToastMessage('success', translate('SEND_SMS'), sendSmsSuccessMessage);
                    props.onClose();
                } else {
                    onSmsFailure();
                }
            };

            postSmsMessage(smsContent, mobileNumber, props.applicationId).then(onSmsSuccess, onSmsFailure);

            const sendEmailSuccessMessage = props.translate('SEND_EMAIL_SUCCESS', { fullname: getFullName(props.applicant), email: props.applicant.email }).toString();
            const onMailFailure = () => props.showToastMessage('error', translate('SEND_EMAIL'), translate('SEND_EMAIL_ERROR'));
            const onEmailSuccess = (successResult: CommandResult): void => {
                if (successResult.success) {
                    props.showToastMessage('success', translate('SEND_EMAIL'), sendEmailSuccessMessage);
                    props.onClose();
                } else {
                    onMailFailure();
                }
            };
            postEmailMessage(subject,
                mailContent,
                selectedMailSender!.mail,
                selectedMailSender!.name,
                email,
                getFullName(props.applicant),
                props.applicationId).then(onEmailSuccess, onMailFailure);

            if (language !== null && props.onLanguageChanged !== undefined && props.applicant.primaryLanguage !== language) {
                props.onLanguageChanged(language);
            }
        }

        if (props.applicantsFilter) {
            const sendSmsSuccessMessage = props.translate('SEND_SMS_TO_MANY_SUCCESS', { count: props.applicantsCount }).toString();
            const onSmsFailure = () => props.showToastMessage('error', translate('SEND_SMS_TO_MANY_TITLE'), translate('SEND_SMS_ERROR'));
            const onSmsSuccess = (successResult: CommandResult): void => {
                if (successResult.success) {
                    props.showToastMessage('success', translate('SEND_SMS_TO_MANY_TITLE'), sendSmsSuccessMessage);
                    props.onClose();
                } else {
                    onFailure();
                }
            };
            postSmsToMany(smsContent, props.applicantsFilter).then(onSmsSuccess, onSmsFailure);

            const sendEmailSuccessMessage = props.translate('SEND_EMAIL_TO_MANY_SUCCESS', { count: props.applicantsCount }).toString();
            const onFailure = () => props.showToastMessage('error', translate('SEND_EMAIL_TO_MANY_TITLE'), translate('SEND_EMAIL_ERROR'));
            const onMailSuccess = (successResult: CommandResult): void => {
                if (successResult.success) {
                    props.showToastMessage('success', translate('SEND_EMAIL_TO_MANY_TITLE'), sendEmailSuccessMessage);
                    props.onClose();
                } else {
                    onFailure();
                }
            };
            postEmailToMany(subject,
                mailContent,
                selectedMailSender!.name,
                selectedMailSender!.mail,
                props.applicantsFilter).then(onMailSuccess, onFailure);
        }
    };

    return (
        <Modal show={props.show} centered={true} className="send-sms-modal" enforceFocus={false}>
            <form>
                <Modal.Header>
                    <Modal.Title>
                        {renderTitle(props, email, mobileNumber)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {renderInformation(props.applicantsCount)}
                        <div>
                            {renderProductType(props.productType === undefined, productType, setProductType, props.translate)}
                            <DropdownInputField
                                descriptionKey="PRIMARY_LANGUAGE"
                                editMode={true}
                                name="primaryLanguage"
                                onValueChanged={onLanguageChanged}
                                value={language || undefined}
                                items={languages}
                            />
                            {renderMobileSelector()}
                            <CommunicationTemplatesSelector
                                type={MessageType.SMS}
                                productType={productType}
                                language={language}
                                descriptionKey="SELECT_SMS_TEMPLATE"
                                selected={smsTemplate}
                                onChange={onSelectedSmsTemplateChanged}
                            />
                            <Form.Group controlId="sms-form">
                                <TextInputField
                                    descriptionKey="SMS_BODY"
                                    editMode={true}
                                    name="content"
                                    onValueChanged={setSmsContent}
                                    required={'ContentIsRequired'}
                                    value={smsContent}
                                    rows={5}
                                />
                            </Form.Group>
                            {renderEmailSelector()}
                            <CommunicationTemplatesSelector
                                type={MessageType.Email}
                                productType={productType}
                                language={language}
                                descriptionKey="SELECT_EMAIL_TEMPLATE"
                                selected={emailTemplate}
                                onChange={onSelectedEmailTemplateChanged}
                            />
                            <MailSenderSelector
                                editMode={true}
                                selected={selectedMailSender}
                                storeSelectedSender={true}
                                onChange={setSelectedMailSender}
                            />
                            <Form.Group controlId="email-form">
                                <TextInputField
                                    descriptionKey="EMAIL_SUBJECT"
                                    editMode={true}
                                    name="subject"
                                    errors={errors}
                                    onValueChanged={setSubject}
                                    required={'SubjectIsRequired'}
                                    value={subject}
                                />
                                <div className="description">{props.translate('EMAIL_CONTENT')}</div>
                                {renderEditor(mailContent, setMailContent, editorConfig)}
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="submit-button" onClick={send}>
                        <Translate id="SEND" />
                    </Button>
                    <Button variant="secondary" onClick={props.onClose}>
                        <Translate id="CANCEL" />
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

const getApplicantPlaceholders = (applicant: ApplicantCommunicationModel, mobileNumber: string | null) =>
    ({ fullName: beautifyLastName(`${applicant.firstName} ${applicant.lastName}`), phoneNumber: mobileNumber });

const getMobileNumbers = (props: SendEmailAndSmsModalOwnProps & LocalizeContextProps): NameValueItem[] => {
    const mainMobileNumber = props.applicant ? props.applicant.mobileNumber : null;
    const secondaryMobileNumber = props.applicant ? props.applicant.additionalMobileNumber : null;
    const mobileNumbers: NameValueItem[] = [];
    if (isValueSet(mainMobileNumber)) { mobileNumbers.push({ name: props.translate('PRIMARY').toString(), value: mainMobileNumber }); }
    if (isValueSet(secondaryMobileNumber)) { mobileNumbers.push({ name: props.translate('SECONDARY').toString(), value: secondaryMobileNumber }); }
    return mobileNumbers;
};

const getEmails = (props: SendEmailAndSmsModalOwnProps & LocalizeContextProps): NameValueItem[] => {
    const mainEmail = props.applicant ? props.applicant.email : null;
    const secondaryEmail = props.applicant ? props.applicant.additionalEmail : null;
    const emails: NameValueItem[] = [];
    if (isValueSet(mainEmail)) { emails.push({ name: props.translate('PRIMARY').toString(), value: mainEmail }); }
    if (isValueSet(secondaryEmail)) { emails.push({ name: props.translate('SECONDARY').toString(), value: secondaryEmail }); }
    return emails;
};

const renderProductType = (
    show: boolean,
    productType: ProductType,
    setProductType: (productType: ProductType) => void,
    translate: TranslateFunction) => {
    if (!show) {
        return null;
    }

    const items = mapProductTypesToSelectableItems(translate, true, false);
    const onChange = (value: string | number | null | undefined) => setProductType(value as ProductType);

    return (
        <DropdownInputField
            style={{ width: '210px' }}
            descriptionKey="PRODUCT_TYPE"
            name="products"
            value={productType}
            items={items}
            editMode={true}
            onValueChanged={onChange}
        />
    );
};

const renderInformation = (count?: number) => count
    ? <div className="info"><Translate id="SEND_EMAIL_AND_SMS_TO_MANY_INFO" data={{ count }} /></div>
    : null;

const getFullName = (applicant: ApplicantCommunicationModel) => beautifyLastName(`${applicant.firstName || ''} ${applicant.lastName || ''}`);

const renderTitle = (
    props: SendEmailAndSmsModalProps & LocalizeContextProps,
    email: string | null,
    phonenumber: string | null) => {
    if (props.applicant && email && phonenumber) {
        return props.translate('SEND_EMAIL_AND_SMS_TITLE', { fullname: getFullName(props.applicant), email, phonenumber }).toString();
    }

    return props.translate('SEND_EMAIL_AND_SMS_TO_MANY_TITLE').toString();
};

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.userActionsReducer,
    ...state.communicationTemplates
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...CommunicationTemplatesActionCreator,
    showToastMessage
} as any, dispatch);

export default connect<SendEmailAndSmsModalReduxProps, SendEmailAndSmsModalDispatchProps, {}, any>(mapStateToProps, mapActionCreatorsToProps)
    (withLocalize(SendEmailAndSmsModal));
