import { AxiosClientFactory } from "../../../common/helpers/AxiosClientFactory";
import { PropertyInformation } from "../models/PropertyInformation";

export const getPropertyInformation = (socialSecurityNumbers: string[]): Promise<PropertyInformation[]> => {
    return new Promise<PropertyInformation[]>((resolve, reject) => {
        AxiosClientFactory
            .getInstance()
            .get<PropertyInformation[]>('/api/propertyinformation', { params: { socialSecurityNumbers } })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    }); 
};
