import React from 'react';
import InputField, {InputFieldProps} from './InputField';
import classNames from 'classnames';
import {Translate} from 'react-localize-redux';
import {useValidation, ValidationProps} from '../../helpers/useValidation';
import {FormControl} from 'react-bootstrap';

export interface BooleanFieldProps extends InputFieldProps, ValidationProps {
    name: string;
    value: boolean | undefined | null;
    disabled?: boolean;
    onValueChanged?: (value: boolean, name: string) => void;
}

export function BooleanInputField<WrapperProps>(props: BooleanFieldProps) {
    const [errorCode, internalError, ref, onTouched] = useValidation(props);

    return (
        <InputField<WrapperProps>
            className={props.className}
            afterContent={props.afterContent}  
            descriptionKey={props.descriptionKey} 
            description={props.description} 
            editMode={props.editMode}
            error={internalError}
            errorCode={errorCode}
            wrapper={props.wrapper}
            wrapperProps={props.wrapperProps}
        >
            {renderValue(props, ref, onTouched)}
        </InputField>
    );
}

function renderValue(props: BooleanFieldProps, ref: React.RefObject<HTMLInputElement>, onTouched: () => void) {
    const onTrueClick = () => {
        if (props.onValueChanged) {
            onTouched();
            props.onValueChanged(true, props.name);
        }
    };

    const onFalseClick = () => {
        if (props.onValueChanged) {
            onTouched();
            props.onValueChanged(false, props.name);
        }
    };

    const disabled = props.disabled !== undefined && props.disabled === true;

    return props.editMode === true ? (
        <React.Fragment>
            <div className="btn-group">
                <button
                    type="button"
                    disabled={disabled}
                    className={classNames('btn btn-boolean-input', { selected: props.value === true })}
                    onClick={onTrueClick}
                >
                    <span style={{ textTransform: 'uppercase' }}>
                        <Translate id="YES" />
                    </span>
                </button>
                <button
                    type="button"
                    disabled={disabled}
                    className={classNames('btn btn-boolean-input', { selected: props.value !== undefined && props.value !== null && props.value !== true })}
                    onClick={onFalseClick}
                >
                    <span style={{ textTransform: 'uppercase' }}>
                        <Translate id="NO" />
                    </span>
                </button>
            </div>
            <FormControl
                style={{ display: 'none' }}
                name={props.name}
                type="boolean"
                value={props.value !== undefined && props.value !== null ? props.value.toString() : ''}
                ref={ref as any}
                readOnly={true}
            />
        </React.Fragment>
    ) : (<span className="value"><Translate id={props.value === true ? 'YES' : 'NO'} /></span>);
}