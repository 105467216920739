import { SelectableItem } from '../models/SelectableItem';

const mapEnumToSelectableItems = <IdType extends unknown>(enumToMap: object, nameMapper: (key: string | null) => string, addNull: boolean = true, keyAsId = true): SelectableItem<IdType>[] => {
    const enumKeys = Object
        .keys(enumToMap)
        .filter((value: string) => (typeof enumToMap[value] === 'string'));

    nameMapper = nameMapper || ((key) => key ? enumToMap[key] : '');

    const result: SelectableItem<IdType>[] =
    [...enumKeys]
        .map((key) => ({
            id: keyAsId ? parseInt(key, 10) : enumToMap[key],
            name: nameMapper(key)
        }));

    if (addNull) {
        result.unshift({ id: null, name: nameMapper(null)});
    }

    return result;
};

export default mapEnumToSelectableItems;
