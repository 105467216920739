import { Action, Dispatch } from 'redux';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export interface ToggleSidebarAction extends Action<typeof TOGGLE_SIDEBAR> {
}

const toggleSidebar = (): ToggleSidebarAction => ({
    type: TOGGLE_SIDEBAR
});

export const ToggleSidebarActionCreator = {
    toggleSidebar: () => {
        return (dispatch: Dispatch<ToggleSidebarAction>, getState: any): void => {
            dispatch(toggleSidebar());
        };
    }
};

export interface ToggleSidebarActionProps {
    toggleSidebar(): void;
}
