import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import "./string-collection-input-field.css";
import {OverlayTrigger, Popover} from "react-bootstrap";
import TextInputField from "./TextInputField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {LocalizeContextProps, withLocalize} from "react-localize-redux";

interface StringCollectionInputFieldProps extends LocalizeContextProps {
    value?: string[];
    name: string;
    description?: string; // or translation key
    edit?: boolean;
    rows?: number;
    className?: string;
    onValueChange?: (value: string[]) => void;
}
const StringCollectionInputField: FunctionComponent<StringCollectionInputFieldProps> = (props) => {
    const [valueStr, setValueStr] = useState<string | undefined>();
    const overlayRef = useRef<any | null>(null);
    
    useEffect(() => {
        setValueStr(props.edit ? props.value?.join('\n') : props.value?.join(', '));
    }, [props.edit, props.value]);
    
    const onValueChanged = (valueStr: string) => {
        let value: string[] = [];
        if (valueStr) {
            value = valueStr.split('\n');
        }
        props.onValueChange && props.onValueChange(value);
    }
    
    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Popover id="string-collection-input-field" style={{maxWidth: '250px', height: 'auto', maxHeight: '140px', overflowY: 'auto'}}>
                    <Popover.Body>
                        {props.edit ?
                            <TextInputField
                                name={props.name}
                                value={valueStr}
                                onValueChanged={onValueChanged}
                                editMode={true}
                                rows={props.rows}
                            /> : valueStr
                        }
                    </Popover.Body>
                </Popover>
            }
            trigger={props.edit ? 'click' : ['hover', 'focus']}
            rootClose={true}
            target={overlayRef.current}
        >
            <div className={`string-collection-input-field ${props.className || ''}`}>
                <TextInputField
                    className="text-field"
                    name={props.name}
                    value={`${props.value?.length} ${props.translate('SELECTED')}`}
                    editMode={props.edit}
                    descriptionKey={props.description}
                    onValueChanged={() => {/* noop */}}
                    afterContent={!props.edit ? <FontAwesomeIcon style={{marginLeft: '4px'}} icon={faQuestionCircle}/> : null}
                />
            </div>
        </OverlayTrigger>
    )
}

StringCollectionInputField.defaultProps = {
    rows: 4
}
export default withLocalize(StringCollectionInputField);