import { RepaymentMethod } from '../models/RepaymentMethod';

// todo - reuse tools we have for enums like this
export const getRepaymentMethodTranslateKey = (x: RepaymentMethod) => {
    switch (x) {
        case RepaymentMethod.DirectDebit:
            return 'REPAYMENT_METHOD_TYPE.DIRECT_DEBIT';
        case RepaymentMethod.Paper:
            return 'REPAYMENT_METHOD_TYPE.PAPER';
        default:
            return '';
    }
};
