import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { Document } from '../../../common/models/Document';
import { File } from '../../../common/helpers/File';

export const getDocument = async (documentId: number): Promise<File> => {
    const response = await AxiosClientFactory
        .getInstance()
        .get<Document>(`/api/documents/${documentId}`);
    return File.fromDocument(response.data);
};

export const getPromissoryNote = async (promissoryNoteId: number): Promise<File> => {
    const response = await AxiosClientFactory
        .getInstance()
        .get<Document>(`/api/promissoryNote/${promissoryNoteId}`);
    return File.fromDocument(response.data);
};
