import {getAccount} from "./AuthProvider";
import {CacheProperties} from "axios-cache-interceptor/src/cache/cache";
import {AXIOS_CACHE_PREFIX} from "./AxiosClientFactory";
import {CachePredicate} from "axios-cache-interceptor/src/util/types";

const METADATA_LONG_PROFILE = 1000 * 60 * 60 * 16; // 16 hours
const METADATA_SHORT_PROFILE = 1000 * 60 * 60 * 16; // 1 hour

interface ICachingConfig<R, D> {
    [key: string]: Partial<CacheProperties<R, D>>;
}

const defaultCachePredicate: CachePredicate = {
    statusCheck: (status) => status >= 200 && status < 400 // copied from axios-cache-interceptor library
}

const noCachePredicate = () => false

export const CachingConfig: ICachingConfig<any, any> = {
    SHORT: {
        ttl: METADATA_SHORT_PROFILE,
        cachePredicate: defaultCachePredicate
    },
    LONG: {
        ttl: METADATA_LONG_PROFILE,
        cachePredicate: defaultCachePredicate
    },
    DEFAULT: {
        cachePredicate: noCachePredicate
    }
}

export function invalidateUserCache(): boolean {
    const cachedUserdata = localStorage[`${AXIOS_CACHE_PREFIX}/api/me`];
    if (!cachedUserdata) {
        return true;
    }
    const cachedUsername = JSON.parse(cachedUserdata)?.data?.data?.username;
    const currentUsername = getAccount().username;
    return cachedUsername !== undefined && currentUsername !== cachedUsername;
}