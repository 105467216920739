export enum InsuranceHistoryType {
    Change = 0,
    Invoice = 1,
    Report = 2
}

export enum InsuranceChangeType {
    Created = 0,
    Cancelled = 1
}

export enum InsuranceReportItemType
{
    NewBusiness = 0,
    PremiumPayment = 1,
    Cancellation = 2
}

export interface InsuranceHistoryReadModel {
    historyType: InsuranceHistoryType;
    timestamp: Date;
}

export interface ChangeInsuranceHistoryReadModel extends InsuranceHistoryReadModel {
    changeType: InsuranceChangeType;
}

export interface InvoiceInsuranceHistoryReadModel extends InsuranceHistoryReadModel {
    number: string;
    dueDate: Date;
    balance: number;
}

export interface ReportItemInsuranceHistoryReadModel extends InsuranceHistoryReadModel {
    itemType: InsuranceReportItemType;
    invoiceNumber: string;
}