import React, {FunctionComponent} from 'react';
import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/ModalDialog';

const DraggableModalDialog: FunctionComponent<any> = (props) => {
    const className = ((props.className || '') + ' draggable-modal').trim();
    return <Draggable handle=".modal-header"><ModalDialog {...props} className={className} /></Draggable>;
};

export default DraggableModalDialog;
