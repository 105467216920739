import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { formatDate, formatDateWithTime } from '../helpers/dateFormatter';

export enum DateTimeFormat {
    DateWithTime,
    Date
}

interface DateTimeFormatterProps extends LocalizeContextProps {
    date: moment.MomentInput;
    format?: DateTimeFormat;
}

const DateTimeFormatter: FunctionComponent<DateTimeFormatterProps> = (props) => {
    const format = props.format || DateTimeFormat.DateWithTime;
    const formattedDate =
        format === DateTimeFormat.DateWithTime ?
            formatDateWithTime(props.date, props.translate) :
            formatDate(props.date, props.translate);

    return (<React.Fragment>{formattedDate}</React.Fragment>);
};

export default withLocalize(DateTimeFormatter);
