import {
    faEraser,
    faFastForward,
    faPaperPlane,
    faRedo,
    faReply,
    IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import React, {FunctionComponent, ReactNode, useState} from 'react';
import {Bank} from '../../models/Bank';
import {Transaction} from '../../models/Transaction';
import {BankStatus} from '../../../../common/models/BankStatus';
import {ManageableComponentProps} from '../../../../common/interfaces/ManageableComponentProps';
import EditIcons from '../EditIcons';
import BankRepliesFormContent from './BankRepliesFormContent';
import {ProductType} from '../../../../common/models/ProductType';
import ActionIcon from '../../../../common/components/ActionIcon';
import {BankRepliesActions} from '../ApplicantView';
import ConfirmationModal from '../../../../common/components/ConfirmationModal';
import {LocalizeContextProps, Translate, TranslateFunction, withLocalize} from 'react-localize-redux';
import {getTranslationKey} from '../../../../common/helpers/getTranslationKey';
import GenericDropdown from '../../../../common/components/GenericDropdown';
import TextClipper from '../../../../common/components/TextClipper';
import getClassNamesByResponseCode from '../../helpers/getClassNamesByResponseCode';
import {getSendUnlockTime, hasBankIntegrtaion, isBankReportReadOnly} from '../../helpers/transactionsLogic';
import DateTimeInputField, {
    DateTimeInputFieldKind
} from '../../../../common/components/input-fields/DateTimeInputField';
import {Col, Row} from 'react-bootstrap';
import {TransactionCommand} from '../../models/TransactionCommand';
import TimeLockedActionIcon from '../../../../common/components/TimeLockedActionIcon';
import {ConsumerLoan} from '../../models/ConsumerLoan';
import {validateApplication} from './SendValidation';

interface BankRepliesFormProps extends ManageableComponentProps<TransactionCommand>, BankRepliesActions, LocalizeContextProps {
    bank: Bank;
    transaction: Transaction;
    productType: ProductType | null;
    application: ConsumerLoan | undefined;
    countryId: number;
    editMode: boolean;
    followUpDate: Date | null;
    onTransactionUpdated: (transaction: Transaction) => void;
    onFormInputChanged: (isEmpty: boolean) => void;
}

type Modal = null | 'SEND' | 'RESEND' | 'SKIP_BANK_RESPONSE' | 'TRY_SENDING_AGAIN' | 'RESET_ERROR';

const allowedStatuses = [BankStatus.Investigation, BankStatus.Granted, BankStatus.GrantedSelected, BankStatus.Sent, BankStatus.Paid, BankStatus.Denied];

const keyValueFunc = (key: BankStatus) => (key);

const displayValueFunc = (key: BankStatus, translate: TranslateFunction) => (translate(`BANK_STATUS.${getTranslationKey(BankStatus[key])}`).toString());

const BankRepliesForm: FunctionComponent<BankRepliesFormProps> = (props) => {
    const [modal, setModal] = useState<Modal>(null);
    const [followUpDate, setFollowUpDate] = useState(props.followUpDate);

    const onStatusChanged = (status: BankStatus) => props.onTransactionUpdated({ ...props.transaction, bankStatus: status });
    const editable = props.canEnterEdit && !isBankReportReadOnly(props.transaction);

    return (
        <div>
            <div className="bank-replies-form-header card-heading">
                {renderMessage(props.transaction)}
                <div className="options">
                    {renderActions(props, props.canEnterEdit, setModal)}
                    <EditIcons key="edit" {...props} data={{ id: props.transaction.id, details: props.transaction, followUpDate }} />
                </div>
            </div>
            <div>
                <Row>
                    <Col />
                    {props.editMode ? renderStatusDropdown(props.translate, props.transaction, onStatusChanged) : null}
                    {props.editMode ? renderFollowUpDate(followUpDate, setFollowUpDate) : null}
                </Row>
            </div>
            <div onClick={editable ? props.enteredEditCallBack : undefined}>
                <BankRepliesFormContent
                    transaction={props.transaction}
                    editMode={props.editMode}
                    onTransactionUpdated={props.onTransactionUpdated}
                    countryId={props.countryId}
                    onFormInputChanged={props.onFormInputChanged}
                />
            </div>
            {renderModal(modal, setModal, mapModalToCallback(modal, props))}
        </div>
    );
};

function renderStatusDropdown(translate: TranslateFunction, transaction: Transaction | undefined, setStatus: (status: BankStatus) => void) {
    const selectedStatus = transaction && transaction.bankStatus !== undefined && allowedStatuses.indexOf(transaction.bankStatus) >= 0
        ? transaction.bankStatus : allowedStatuses[0];

    const displayValue = (key: BankStatus) => displayValueFunc(key, translate);

    return (
        <Col>
            <div className="description"><Translate id="BANK_REPORT.BANK_STATUS" /></div>
            <div className="value">
                <GenericDropdown
                    dropdownId="bank-status"
                    selectedItem={selectedStatus}
                    items={allowedStatuses}
                    style={{ width: '176px' }}
                    onSelectionChanged={setStatus}
                    keyValue={keyValueFunc}
                    displayValue={displayValue}
                />
            </div>
        </Col>
    );
}

function renderFollowUpDate(followUpDate: Date | undefined | null, setFollowUpDate: (followUpDate: Date | null) => void) {
    const onValueChanged = (value: Date | null, name: string) => setFollowUpDate(value);
    return (
        <Col style={{ position: 'static' }}>
            <DateTimeInputField
                name="followUpDate"
                value={followUpDate}
                descriptionKey="FOLLOW_UP_DATE"
                editMode={true}
                onValueChanged={onValueChanged}
                kind={DateTimeInputFieldKind.DateWithTime}
                timeFrom={8}
                timeTo={20}
            />
        </Col>
    );
}

function renderMessage(transaction: Transaction | undefined) {
    if (transaction === undefined || !transaction.responseMessage) {
        return <div className="response-message" />;
    }

    return <TextClipper className={getClassNamesByResponseCode(transaction, 'response-message')} content={transaction.responseMessage as string} />;
}

function renderActions(props: BankRepliesFormProps, canEnterEdit: boolean, setModal: (modal: Modal) => void) {
    const actions: ReactNode[] = [];

    if (props.editMode || !canEnterEdit) {
        return actions;
    }

    if (!isBankReportReadOnly(props.transaction)) {
        if (props.transaction === undefined || props.transaction.id === null) {
            if (hasBankIntegrtaion(props.bank, props.productType)) {
                const error = validateApplication(props.application, props.bank.id);
                actions.push(renderAction('SEND', faPaperPlane, setModal, error));
            }

            return actions;
        }

        if (props.transaction.responseCode !== undefined && props.transaction.responseCode > 0) {
            actions.push(renderAction('RESET_ERROR', faEraser, setModal));
        }

        if (hasBankIntegrtaion(props.bank, props.productType) && props.transaction.bankReportId !== undefined
            && props.transaction.bankStatus !== BankStatus.Paid && props.transaction.bankStatus !== BankStatus.Denied) {
            actions.push(renderTimeLockedActionIcon('RESEND', faRedo, setModal, props.transaction));
        }
    }

    if (props.transaction.responseCode !== undefined && props.transaction.responseCode !== 0
        && (props.transaction.bankStatus === BankStatus.GrantedAccepted || props.transaction.bankStatus === BankStatus.GrantedRejected)) {
        actions.push(renderAction('SKIP_BANK_RESPONSE', faFastForward, setModal));
        actions.push(renderTimeLockedActionIcon('TRY_SENDING_AGAIN', faReply, setModal, props.transaction));
    }

    return actions;
}

function renderAction(key: Modal, icon: IconDefinition, setModal: (modal: Modal) => void, error?: string | null) {
    const callback = () => setModal(key);
    const tooltipKey = error !== undefined && error !== null ? error : (key as string);
    const disabled = error !== undefined && error !== null;
    return <ActionIcon key={`br-action-${key}`} action={callback} icon={icon} tooltipKey={tooltipKey} disabled={disabled} />;
}

function renderTimeLockedActionIcon(key: Modal, icon: IconDefinition, setModal: (modal: Modal) => void, transaction: Transaction) {
    const callback = () => setModal(key);
    return (
        <TimeLockedActionIcon
            key={`br-action-${key}`}
            action={callback}
            icon={icon}
            tooltipKey={key as string}
            unlockTime={getSendUnlockTime(transaction)}
        />
    );
}

function renderModal(key: Modal, setModal: (modal: Modal) => void, callback: () => void) {
    const onConfirmCallback = () => { setModal(null); callback(); };
    const onCancelCallback = () => setModal(null);
    return (
        <ConfirmationModal
            show={key !== null}
            title={key === null ? '' : <Translate id={key} />}
            message={key === null ? '' : <Translate id={`${key}_CONFIRMATION`} />}
            onConfirm={onConfirmCallback}
            onCancel={onCancelCallback}
        />
    );
}

function mapModalToCallback(modal: Modal, props: BankRepliesFormProps): () => void {
    switch (modal) {
        case 'SEND':
        case 'RESEND':
            return () => props.addAutomaticBankReport([props.bank.id], modal);
        case 'SKIP_BANK_RESPONSE':
            return () => props.skipBankFeedback(props.transaction.id as number);
        case 'TRY_SENDING_AGAIN':
            return () => props.retryBankFeedback(props.transaction.id as number);
        case 'RESET_ERROR':
            return () => props.resetResponseCode(props.transaction.id as number);
        default:
            return () => undefined;
    }
}

export default withLocalize(BankRepliesForm);
