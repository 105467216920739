import React, {Dispatch, FunctionComponent, SetStateAction, useEffect, useState} from 'react';
import {InsuranceReadModel} from '../../models/InsuranceReadModel';
import {Translate} from 'react-localize-redux';
import {InsuranceType} from '../../models/InsuranceType';
import {getEnumTranslationKey} from '../../../../common/helpers/getTranslationKey';
import './insurances.css';
import {faAngleDown, faAngleUp, faBan, faCog, faPencil} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import InsuranceDetails from './InsuranceDetails';
import ActionIcon from '../../../../common/components/ActionIcon';
import DateTimeInputField, {DateTimeInputFieldKind} from '../../../../common/components/input-fields/DateTimeInputField';
import moment from 'moment';
import {Button, Modal} from 'react-bootstrap';
import Tooltip from '../../../../common/components/Tooltip';
import InsuranceHistory from './InsuranceHistory';
import {InsuranceHistoryReadModel} from '../../models/InsuranceHistoryReadModel';
import {formatDate} from '../../../../common/helpers/dateFormatter';
import {InsuranceStatus} from "../../models/InsuranceStatus";
import {CancellationCode} from "../../models/CancellationCode";

interface InsurancesProps {
    insurances: InsuranceReadModel[];
    countryId: number;
    history: { [id: number]: InsuranceHistoryReadModel[] };
    onExpanded: (id: number) => void;
    onCancel: (id: number, cancellationDate: Date) => void;
    onEdit: (id: number) => void;
}

interface CancellationModalState {
    show: boolean;
    cancellationDate: Date | null;
}

const Insurances: FunctionComponent<InsurancesProps> = (props: InsurancesProps) => {
    const [expandedId, setExpandedId] = useState<number | null>(null);
    const [cancellationModalState, setCancellationModalState] = useState<CancellationModalState>({show: false, cancellationDate: moment().startOf('day').toDate()});
    
    useEffect(() => {
        if(expandedId !== null) {
            props.onExpanded(expandedId)
        }
    }, [expandedId]); // eslint-disable-line react-hooks/exhaustive-deps

    const showCancellationPopup = () => setCancellationModalState((s) => ({...s, show: true}));

    return (
        <div className="column-card insurances">
            <table>
                <thead><tr><th><Translate id="INSURANCES" /></th></tr></thead>
                <tbody>{props.insurances.map((i) => renderRow(i, props.countryId, expandedId, props.history, setExpandedId, showCancellationPopup, props.onEdit))}</tbody>
            </table>

            {renderCancellationPopup(cancellationModalState, setCancellationModalState, expandedId, props.onCancel)}
        </div>
    );
};

function renderRow(
    insurance: InsuranceReadModel, 
    countryId: number, 
    expandedId: number | null, 
    history: { [id: number]: InsuranceHistoryReadModel[] }, 
    setExpandedId: Dispatch<SetStateAction<number | null>>, 
    onCancelClick: () => void,
    onEditClick: (id: number) => void
) {
    return (
        <React.Fragment key={insurance.id}>
        <tr>
            <td>AXA Partners</td>
            <td>{renderInsuranceType(insurance)}</td>
            <td>{renderStatus(insurance)}</td>
            <td>{renderIcons(insurance, expandedId, setExpandedId)}</td>
        </tr>
        {renderExpanded(insurance, countryId, expandedId, history, onCancelClick, onEditClick)}
        </React.Fragment>
    );
}

function renderInsuranceType(insurance: InsuranceReadModel) {
    return <div className="status status-blue"><Translate id={getEnumTranslationKey(InsuranceType, insurance.insuranceType, 'INSURANCE_TYPES')}/></div>;
}

function renderStatus(insurance: InsuranceReadModel) {
    let expirationDate = getExpirationDate(insurance);
    let expired = insurance.insuranceStatus === InsuranceStatus.Expired || expirationDate < moment();
    
    return (
        <Tooltip content={getStatusTooltipContent(insurance, expired)}>
            <div className={getStateCssClasses(insurance.insuranceStatus, expired)}><Translate id={getStatusKey(insurance.insuranceStatus, expired)}/></div>
        </Tooltip>
    );    
}

function getExpirationDate(insurance: InsuranceReadModel) {
    return moment(insurance.startDate).add(insurance.term, 'M').endOf('M');
}

function getStatusTooltipContent(insurance: InsuranceReadModel, expired: boolean) {
    switch (insurance.insuranceStatus) {
        case InsuranceStatus.Draft:
            return insurance.startDate 
                ? <Translate id="SCHEDULED_ON" data={{date: formatDate(moment(insurance.startDate))}} />
                : <Translate id="UNSCHEDULED" />
        case InsuranceStatus.Active:
            return <Translate id={expired ? 'EXPIRED_ON' : 'VALID_TILL'} data={{date: formatDate(getExpirationDate(insurance))}} />
        case InsuranceStatus.Cancelled:
            return insurance.cancellationCode !== null && insurance.cancellationCode !== undefined
                ? <Translate 
                    id={getEnumTranslationKey(CancellationCode, insurance.cancellationCode, 'CANCELLATION_CODE')}
                    data={{date: formatDate(moment(insurance.cancellationEffectiveDate).format('DD/M-YY'))}}
                />
                : <Translate id="CANCELLED_SINCE" data={{date: formatDate(moment(insurance.cancellationEffectiveDate).format('DD/M-YY'))}} />;
        case InsuranceStatus.Expired:
            return <Translate id="EXPIRED_ON" data={{date: formatDate(getExpirationDate(insurance))}} />;
    }
}

function getStateCssClasses(status: InsuranceStatus, expired: boolean) {
    let statusCssClass;
    switch (status) {
        case InsuranceStatus.Draft:
            statusCssClass = 'status-blue';
            break;
        case InsuranceStatus.Active:
            statusCssClass = expired ? 'status-red' : 'status-green';
            break;
        case InsuranceStatus.Cancelled:
            statusCssClass = 'status-gray';
            break;
        case InsuranceStatus.Expired:
            statusCssClass = 'status-red';
            break;
    }
    return `insurance-status status ${statusCssClass}`;
}

function getStatusKey(status: InsuranceStatus, expired: boolean) {
    switch (status) {
        case InsuranceStatus.Draft:
            return 'DRAFT';
        case InsuranceStatus.Active:
            return expired ? 'EXPIRED' : 'ACTIVE';
        case InsuranceStatus.Cancelled:
            return 'CANCELLED';
        case InsuranceStatus.Expired:
            return 'EXPIRED';
    }
}

function renderIcons(insurance: InsuranceReadModel, expandedId: number | null, setExpandedId: Dispatch<SetStateAction<number | null>>) {
    const toggleExpanded = () => setExpandedId(expandedId !== insurance.id ? insurance.id : null);

    return (
        <>
            {renderInvoiceIndicator(insurance)}
            <span className="status-icon"><FontAwesomeIcon icon={faCog} /></span>
            <span className="grey-button" onClick={toggleExpanded}><FontAwesomeIcon icon={expandedId === insurance.id ? faAngleUp : faAngleDown} /></span>
        </>
    );
}

function renderInvoiceIndicator(insurance: InsuranceReadModel) {
    if (insurance.cancellationEffectiveDate || !insurance.earliestNotPaidInvoiceDueDate) {
        return null;
    }

    return <Tooltip content={<Translate id="NOT_PAID_SINCE" data={{dueDate: formatDate(insurance.earliestNotPaidInvoiceDueDate)}}/>}><div className={`invoice-indicator not-paid`}></div></Tooltip>;
}

function renderExpanded(
    insurance: InsuranceReadModel, 
    countryId: number, 
    expandedId: number | null, 
    history: { [id: number]: InsuranceHistoryReadModel[] }, 
    onCancelClick: () => void,
    onEditClick: (id: number) => void
) {
    if (!expandedId || insurance.id !== expandedId) {
        return null;
    }

    return (
        <tr>
            <td colSpan={5} className="expanded">
                {renderActionButtons(insurance, onCancelClick, onEditClick)}
                <InsuranceDetails insurance={insurance} countryId={countryId} editMode={false}/>
                <InsuranceHistory history={history[insurance.id]}/>
            </td>
        </tr>
    );
}

function renderActionButtons(insurance: InsuranceReadModel, onCancelClick: () => void, onEditClick: (id: number) => void) {
    let actions: JSX.Element[] = [];
    switch (insurance.insuranceStatus) {
        case InsuranceStatus.Draft:
            actions.push(<ActionIcon key="edit-icon" icon={faPencil} tooltipKey="EDIT_INSURANCE" action={() => onEditClick(insurance.id)}/>);
            break;
        case InsuranceStatus.Active:
            if (getExpirationDate(insurance) < moment()) {
                return null;
            }
            actions.push(<ActionIcon key="cancel-icon" icon={faBan} tooltipKey="CANCEL_INSURANCE" action={onCancelClick}/>);
            break;
        case InsuranceStatus.Cancelled:
        case InsuranceStatus.Expired:
            return null;
    }
    return <div className="insurance-buttons">{actions}</div>;
}

function renderCancellationPopup(
    state: CancellationModalState, 
    setState: Dispatch<SetStateAction<CancellationModalState>>,
    expandedId: number | null, 
    onInsuranceCancel: (id: number, cancellationDate: Date) => void
) {
    if (!expandedId) {
        return null;
    }

    const cancel = () => setState((s) => ({...s, show: false}));
    const confirm = () => {
        if (state.cancellationDate) {
            onInsuranceCancel(expandedId, state.cancellationDate);
            cancel();
        }
    };

    const updateDate = (value: Date | null) => setState((s) => ({...s, cancellationDate: value}));

    return (
        <Modal show={state.show} centered={true} className="cancel-insurance-modal" onHide={cancel}>
        <form>
            <Modal.Header>
                <Modal.Title>
                    <Translate id="CANCEL_INSURANCE" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p><Translate id="CANCEL_INSURANCE_CONFIRMATION" /></p>
            <DateTimeInputField
                name="cancellationDate"
                descriptionKey="CANCELLATION_DATE"
                value={state.cancellationDate}
                required="CancellationDateIsRequired"
                overrideInternalErrors={['valueMissing']}
                editMode={true}
                kind={DateTimeInputFieldKind.LongDate}
                onValueChanged={updateDate}
            />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="submit-button" onClick={confirm}><Translate id="CONFIRM"/></Button>
                <Button variant="secondary" onClick={cancel}><Translate id="CANCEL"/></Button>
            </Modal.Footer>
        </form>
    </Modal>
    );
}

export default Insurances;
