import { Action, Dispatch } from 'redux';

export const SET_TITLE = 'SET_TITLE';

export interface SetTitleAction extends Action<typeof SET_TITLE> {
    pageTitle: string;
}

const getSetTitleAction = (extraContent: string): SetTitleAction => ({
    type: SET_TITLE,
    pageTitle: extraContent
});

export const SetTitleActionCreator = {
    setTitle: (extraContent: string) => {
        return (dispatch: Dispatch<SetTitleAction>, getState: any): void => {
            dispatch(getSetTitleAction(extraContent));
        };
    }
};
