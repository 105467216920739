import { AxiosClientFactory } from '../../common/helpers/AxiosClientFactory';

export const putSetting = async (name: string, value: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        AxiosClientFactory
            .getInstance()
            .put(`api/settings/${name}`, { value })
            .then(() => resolve())
            .catch((error) => reject(error));
    });
};
