export enum ConsumerLoanLogMessagingEventType {
    NewAccount = 0,
    PasswordReset,
    LockedAccount,
    NewApplication,
    BankDenied,
    BankGranted,
    BankPaid,
    UserMessage,
    CampaignMessage,
    CommunicationFromClient,
    IncompleteFormReminder
}
