import React, {FunctionComponent} from "react";
import {CampaignRecipientsFilter} from "../../../../../../common/models/Campaign";
import {LocalizeContextProps, withLocalize} from "react-localize-redux";
import {CampaignViewDataState} from "../../../../../reducers/campaignViewDataReducer";
import {
    CampaignViewDataActionCreators,
    CampaignViewDataActions
} from "../../../../../actions/CampaignViewDataActionCreators";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import RecipientsFilterPreview from "./RecipientsFilterPreview";
import RecipientsFilter from "./RecipientsFilter";
import DateTimeInputField, {
    DateTimeInputFieldKind
} from "../../../../../../common/components/input-fields/DateTimeInputField";
import {BooleanInputField} from "../../../../../../common/components/input-fields/BooleanInputField";
import SubstatusesInputField from "../../../../../../common/components/input-fields/SubstatusesInputField";
import InputField from "../../../../../../common/components/input-fields/InputField";
import {isValueSet} from "../../../../../../common/helpers/isValueSet";
import {SelectableItem} from "../../../../../../common/models/SelectableItem";
import DropdownInputField from "../../../../../../common/components/input-fields/DropdownInputField";
import StringCollectionInputField from "../../../../../../common/components/input-fields/StringCollectionInputField";

type RecipientsFilterStateProps = CampaignViewDataState & LocalizeContextProps;

type RecipientsFilterDispatchProps = CampaignViewDataActions;

export interface RecipientsFilterProps extends RecipientsFilterStateProps, RecipientsFilterDispatchProps {
    editMode: boolean;
}

const RecipientsFilterContainer: FunctionComponent<RecipientsFilterProps> = (props) => 
    props.editMode
        ? <RecipientsFilterPreview {...props}/>
        : <RecipientsFilter {...props}/>


export const renderDateTimeInputFieldFilter = (name: keyof CampaignRecipientsFilter, descriptionKey: string, props: RecipientsFilterProps) => {
    const value = getFilterProp<string | null>(name, props);
    if (!props.editMode && !isValueSet(value)) {
        return null;
    }
    const onValueChanged = (value: string | null | undefined) => props.editRecipientsFilter({[name]: value});
    return (
        <div>
            <DateTimeInputField
                name={name}
                editMode={props.editMode}
                onValueChanged={value => onValueChanged(value ? value.toISOString() : undefined)}
                value={value ? new Date(value as string) : undefined}
                descriptionKey={descriptionKey}
                kind={DateTimeInputFieldKind.Date}
            />
        </div>
    )
}

export const renderBooleanInputFieldFilter = (name: keyof CampaignRecipientsFilter, descriptionKey: string, props: RecipientsFilterProps) => {
    const value = getFilterProp<boolean>(name, props);
    if (!props.editMode && !isValueSet(value)) {
        return null;
    }
    const onValueChanged = (value: boolean | undefined) => props.editRecipientsFilter({[name]: value});
    return (
        <div>
            <BooleanInputField
                name={name}
                editMode={props.editMode}
                onValueChanged={onValueChanged}
                value={value}
                descriptionKey={descriptionKey}
            />
        </div>
    )
}

export const renderSubstatusesInputFieldFilter = (name: keyof CampaignRecipientsFilter, labelKey: string, props: RecipientsFilterProps) => {
    const value = getFilterProp<number[]>(name, props) || [];
    if (!props.editMode && (!value || !value.length)) {
        return null;
    }
    const onValueChanged = (value: number[]) => props.editRecipientsFilter({[name]: value});
    if (props.editMode) {
        return (
            <div>
                <SubstatusesInputField
                    descriptionKey={labelKey}
                    editMode={true}
                    className={value.length ? 'filter-active' : ''}
                    substatusIds={value}
                    onSubstatusesChanged={onValueChanged}
                    placeholderKey={labelKey}
                />
            </div>
        )
    } else {
        return (
            <div>
                <InputField descriptionKey={labelKey} className="substatuses-input-field-filter">
                    <SubstatusesInputField substatusIds={value}/>
                </InputField>
            </div>
        )
    }
}

export const renderDropdownInputFieldFilter = <T extends unknown>(name: keyof CampaignRecipientsFilter, descriptionKey: string, items: SelectableItem<number | string>[], props: RecipientsFilterProps) => {
    const value = getFilterProp<T>(name, props);
    if (!props.editMode && !isValueSet(value)) {
        return null;
    }
    const onValueChanged = (value: T | undefined) => props.editRecipientsFilter({[name]: value});
    return (
        <div>
            <DropdownInputField
                className={isValueSet(value as any) ? 'filter-active' : ''}
                descriptionKey={descriptionKey}
                editMode={true}
                name={name}
                value={value as any}
                onValueChanged={value => onValueChanged(value as T | undefined)}
                items={items}
            />
        </div>
    )
}

export const renderStringCollectionInputFilter = (name: keyof CampaignRecipientsFilter, labelKey: string, props: RecipientsFilterProps) => {
    const value = getFilterProp<string[]>(name, props) || [];
    if (!props.editMode && (!value || !value.length)) {
        return null;
    }
    
    return (
        <StringCollectionInputField
            value={value}
            name={name}
            description={labelKey}
            edit={props.editMode}
            className={value.length ? 'filter-active' : ''}
            onValueChange={value => props.editRecipientsFilter({[name]: value})}
        />
    )
}

export const getFilterProp = <T extends unknown>(name: keyof CampaignRecipientsFilter, props: RecipientsFilterProps): T | null => {
    if (props.campaign 
        && props.campaign.campaignRecipientsFilter 
        && name in props.campaign.campaignRecipientsFilter
    ) {
        return props.campaign.campaignRecipientsFilter[name] as T;
    }
    return null;
}

const mapStateToProps = (state: any) => ({
    ...state.campaignViewDataReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...CampaignViewDataActionCreators
}, dispatch);

export default connect<RecipientsFilterStateProps, RecipientsFilterDispatchProps>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(RecipientsFilterContainer));