import {AxiosPromise} from 'axios';
import {AxiosClientFactory} from '../../../common/helpers/AxiosClientFactory';
import {AdvertisementSite} from "../models/AdvertisementSite";
import {CachingConfig} from "../../../common/helpers/Caching";

export const getAdvertisementSites = (): AxiosPromise<AdvertisementSite[]> => {
    return AxiosClientFactory
        .getInstance()
        .get<AdvertisementSite[]>(`/api/advertisementsites`, {
            cache: CachingConfig.SHORT
        });
};
