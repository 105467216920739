import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';
import { ProductType } from '../../../common/models/ProductType';

export const putOnBlacklist = async (socialSecurityNumber: string, productType: ProductType, days?: number): Promise<CommandResult> => {
    const params = {
        socialSecurityNumber,
        productType,
        days
    };
    const result = await AxiosClientFactory
        .getInstance()
        .post(`api/blacklist`, params);

    return new CommandResult(result);
};
