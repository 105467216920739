import { Action, Dispatch } from 'redux';
import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { Substatus } from '../models/Substatus';
import {CachingConfig} from "../../../common/helpers/Caching";

export const GET_SUBSTATUSES_BEGIN = 'GET_SUBSTATUSES_BEGIN';
export const GET_SUBSTATUSES_SUCCESS = 'GET_SUBSTATUSES_SUCCESS';
export const GET_SUBSTATUSES_FAILURE = 'GET_SUBSTATUSES_FAILURE';

interface GetSubstatusesBeginAction extends Action<typeof GET_SUBSTATUSES_BEGIN> { }

interface GetSubstatusesSuccessAction extends Action<typeof GET_SUBSTATUSES_SUCCESS> {
    substatuses: Substatus[];
}

interface GetSubstatusesFailureAction extends Action<typeof GET_SUBSTATUSES_FAILURE> {
    error: any;
}

export type GetSubstatusesAction = GetSubstatusesBeginAction
    | GetSubstatusesSuccessAction
    | GetSubstatusesFailureAction;

const getSubstatuses = (): GetSubstatusesBeginAction => ({
    type: GET_SUBSTATUSES_BEGIN
});

const getSubstatusesSuccess = (substatuses: Substatus[]): GetSubstatusesSuccessAction => ({
    type: GET_SUBSTATUSES_SUCCESS,
    substatuses
});

const getSubstatusesFailure = (error: any): GetSubstatusesFailureAction => ({
    type: GET_SUBSTATUSES_FAILURE,
    error
});

const SubstatusesActionsCreator = {
    loadSubstatuses: () => {
        return (dispatch: Dispatch, getState: any) => {
            dispatch(getSubstatuses());

            const httpClient = AxiosClientFactory.getInstance();

            httpClient
                .get<Substatus[]>('/api/substatuses', {
                    cache: CachingConfig.SHORT
                })
                .then((response) => dispatch(getSubstatusesSuccess(response.data)))
                .catch((error) => dispatch(getSubstatusesFailure(error)));
        };
    }
};

export default SubstatusesActionsCreator;
