import React, { CSSProperties, FunctionComponent, PropsWithChildren, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface ExternalSystemLinkProps {
    getUrl: () => Promise<string>;
    style?: CSSProperties;
    as?: 'a' | 'button';
}

const ExternalSystemLink: FunctionComponent<PropsWithChildren<ExternalSystemLinkProps>> = (props) => {
    const [isLoading, setIsLoading ] = useState(false);
    const onClick = (e: React.MouseEvent<Element>) => {
        setIsLoading(true);
        navigateToExternalPage(props.getUrl, setIsLoading);
        e.preventDefault();
    };

    if (isLoading) {
        return <Spinner animation="border" variant="primary" size="sm" style={props.style}/>;
    }

    if (props.as === 'a') {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return <a onClick={onClick} style={props.style}>{props.children}</a>;
    }

    return <Button variant="link" onClick={onClick} style={props.style}>{props.children}</Button>;
};

async function navigateToExternalPage(getUrl: () => Promise<string>, setIsLoading: (isLoading: boolean) => void) {
    const a = document.createElement('a');
    a.href = await getUrl();
    a.target = '_blank';
    a.click();
    setIsLoading(false);
}

export default ExternalSystemLink;
