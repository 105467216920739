import {CampaignCreateModel} from "../../common/models/Campaign";
import {AxiosClientFactory} from "../../common/helpers/AxiosClientFactory";
import {CommandResult} from "../../common/helpers/CommandResult";

class CreateCampaignCommandResult extends CommandResult {
    public readonly campaignId: number;
    constructor(result: any) {
        super(result);
        this.campaignId = result.data.result.id;
    }
}
export const createCampaign = (campaign: CampaignCreateModel): Promise<CreateCampaignCommandResult> => {
    return AxiosClientFactory
        .getInstance()
        .post(`/api/campaigns`, campaign)
        .then((result) => new CreateCampaignCommandResult(result));
};