import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';
import { CommentType } from '../../../common/models/CommentType';
import { CommentNotification } from '../models/CommentNotification';

export const postComment = async (
    personId: string | number,
    consumerLoanId: string | number | undefined,
    comment: string,
    files: any[] = [],
    notification?: CommentNotification): Promise<CommandResult> => {
    const client = AxiosClientFactory.getInstance();

    notification = notification || CommentNotification.None;

    if (files.length > 0) {
        const formData = new FormData();
        files.forEach((f) => formData.append('files', f, f.name)); // 'files' must be same name as IEnumerable<IFormFile> method parameter name in controller
        const fileResponse = await client.post<number[]>(`/api/applications/${consumerLoanId}/documents`, formData);
        const result = await client.post(getUrl(personId, consumerLoanId), {
            message: comment,
            documentIds: fileResponse.data,
            commentType: getCommentType(consumerLoanId),
            commentNotification: notification
        });
        return new CommandResult(result);
    } else {
        const result = await client.post(getUrl(personId, consumerLoanId), {
            message: comment,
            commentType: getCommentType(consumerLoanId),
            commentNotification: notification
        });
        return new CommandResult(result);
    }
};

function getUrl(personId: string | number, consumerLoanId: string | number | undefined): string {
    if (consumerLoanId !== undefined) {
        return `/api/applicants/${personId}/consumerloans/${consumerLoanId}/comments`;
    }

    return `/api/applicants/${personId}/comments`;
}

function getCommentType(consumerLoanId: string | number | undefined) {
    if (consumerLoanId) {
        return CommentType.Appliaction;
    }

    return CommentType.Insurance;
}
