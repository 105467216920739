import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';
import { TransactionCommand } from '../models/TransactionCommand';

export const storeTransaction = async (personId: number | string, loanId: number | string, transactionCommand: TransactionCommand): Promise<CommandResult> => {
    const instance = AxiosClientFactory.getInstance();
    const result = await (transactionCommand.id
        ? instance.put(`api/applicants/${personId}/consumerLoans/${loanId}/transactions/${transactionCommand.id}`, transactionCommand)
        : instance.post(`api/applicants/${personId}/consumerLoans/${loanId}/transactions`, transactionCommand));
    return new CommandResult(result);
};
