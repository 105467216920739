import { User } from '../models/User';
import { CommunicationTemplate } from '../models/CommunicationTemplate';

export const formatSignature = (signatureTemplates: CommunicationTemplate[] = [], languageCode?: string | null, user?: User | null) => {
    const signature = signatureTemplates.find((x) => x.languageCode === languageCode)
        || signatureTemplates.find((x) => !x.languageCode);

    return signature && user
        ? signature.templateContent.replace('[Name]', user.displayName)
        .replace('[NormalizedName]', user.normalizedDisplayName)
        .replace(/\[Email\]/g, user.email)
        .replace(/\[Position\]/g, user.position || '')
        .replace(/\[PhoneNumber\]/g, user.phoneNumber)
        .replace(/\[FacebookUsername\]/g, user.facebookUsername || '')
        : '';
};
