import {
    ChangePendingRequestsCountAction,
    DECREMENT_PENDING_REQUESTS,
    INCREMENT_PENDING_REQUESTS
} from '../actions/LoadingBarWrapperActionCreators';

export const loadingBarWrapperReducer = (state: any, action: ChangePendingRequestsCountAction): any => {
    state = state || {};
    if (state.pendingRequests === undefined) {
        state = { ...state, pendingRequests: 0 };
    }
    switch (action.type) {
        case INCREMENT_PENDING_REQUESTS:
            return {
                ...state,
                pendingRequests: state.pendingRequests + 1
            };
        case DECREMENT_PENDING_REQUESTS:
            return {
                ...state,
                pendingRequests: state.pendingRequests - 1
            };
        default:
            return state;
    }
};
