import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { Translate } from 'react-localize-redux';
import Checkbox from '../../../../common/components/Checkbox';

interface LogsAndCommentsFilterProps {
	filterComments: boolean;
	filterEvents: boolean;
	filterChanges: boolean;
	filterDocuments: boolean;
	pendingRequest: boolean;
	onFilterCommentsClicked: () => void;
	onFilterEventsClicked: () => void;
	onFilterChangesClicked: () => void;
	onFilterDocumentsClicked: () => void;
	onRefreshClick: () => void;
}

const LogsAndCommentsFilter: FunctionComponent<LogsAndCommentsFilterProps> = (props) => {
	const renderRefreshIcon = () => props.pendingRequest
		? (<FontAwesomeIcon icon={faSyncAlt} style={{ opacity: 0.4 }} spin={true} />)
		: (<FontAwesomeIcon icon={faSyncAlt} />);

	const onClick = () => {
		if (props.pendingRequest === false) {
			props.onRefreshClick();
		}
	};

	return (
		<div className="logs-and-comments-filter">
			<Checkbox
				checked={props.filterComments}
				onClick={props.onFilterCommentsClicked}
			>
				<Translate id="COMMENTS" />
			</Checkbox>
			<Checkbox
				checked={props.filterEvents}
				onClick={props.onFilterEventsClicked}
			>
				<Translate id="EVENTS" />
			</Checkbox>
			<Checkbox
				checked={props.filterChanges}
				onClick={props.onFilterChangesClicked}
			>
				<Translate id="CHANGES" />
			</Checkbox>
			<Checkbox
				checked={props.filterDocuments}
				onClick={props.onFilterDocumentsClicked}
			>
				<Translate id="FILES" />
			</Checkbox>
			<div>
				<span className="cursor-pointer" onClick={onClick}>
					{renderRefreshIcon()}
				</span>
			</div>
		</div>
	);
};

export default LogsAndCommentsFilter;
