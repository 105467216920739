import { ConsumerLoan } from '../models/ConsumerLoan';
import { ConsumerLoanApplicantDetails } from '../models/ConsumerLoanApplicantDetails';
import { ConsumerLoanDetails } from '../models/ConsumerLoanDetails';
import { Redemption } from '../models/Redemption';
import { ProductType } from '../../../common/models/ProductType';
import { LoanStatus } from '../../../common/models/LoanStatus';
import { NewApplicationTemplate } from '../models/NewApplicationTemplate';

export type NewApplicationAction =
    | { type: 'SET_CONSUMER_LOAN', data: ConsumerLoan }
    | { type: 'COPY_CONSUMER_LOAN', data: ConsumerLoan, defaults: NewApplicationTemplate | null }
    | { type: 'SET_APPLICANT_DETAILS', data: ConsumerLoanApplicantDetails }
    | { type: 'COPY_APPLICANT_DETAILS', data: ConsumerLoanApplicantDetails, submittedDate?: Date }
    | { type: 'SET_COAPPLICANT_DETAILS', data: ConsumerLoanApplicantDetails }
    | { type: 'UPDATE_PRODUCT_TYPE', data: ProductType }
    | { type: 'UPDATE_CONSUMER_LOAN_DETAILS', data: ConsumerLoanDetails }
    | { type: 'UPDATE_APPLICANT', data: ConsumerLoanApplicantDetails }
    | { type: 'UPDATE_COAPPLICANT', data: ConsumerLoanApplicantDetails }
    | { type: 'UPDATE_REDEMPTIONS', data: Redemption[] }
    | { type: 'SWITCH_APPLICANTS' }
    | { type: 'DELETE_COAPPLICANT' }
    | { type: 'ADD_COAPPLICANT', data: { countryId: number } }
    | { type: 'ADD_EXISTING_COAPPLICANT', data: ConsumerLoanApplicantDetails }
    | { type: 'SET_ERRORS', data: string[] }
    | { type: 'SET_VALIDATION_STATUS', data?: LoanStatus }
    | { type: 'HIDE_DELETE_COAPPLICANT_CONFIRMATION' }
    | { type: 'SHOW_DELETE_COAPPLICANT_CONFIRMATION' }
    | { type: 'HIDE_ADD_COAPPLICANT_CONFIRMATION' }
    | { type: 'SHOW_ADD_COAPPLICANT_CONFIRMATION' }
    | { type: 'SET_SUBSTATUSES', substatusIds: number[] }
    | { type: 'SET_NEW_APPLICATION_DEFAULTS', defaults: NewApplicationTemplate | null };

export const setConsumerLoan = (consumerLoan: ConsumerLoan) => ({
    type: 'SET_CONSUMER_LOAN',
    data: consumerLoan
} as NewApplicationAction);

export const copyConsumerLoan = (consumerLoan: ConsumerLoan, newApplicationTemplate: NewApplicationTemplate | null) => ({
    type: 'COPY_CONSUMER_LOAN',
    data: {
        ...consumerLoan
    },
    defaults: newApplicationTemplate
} as NewApplicationAction);

export const setApplicantDetails = (applicantDetails: ConsumerLoanApplicantDetails) => ({
    type: 'SET_APPLICANT_DETAILS',
    data: applicantDetails    
} as NewApplicationAction);

export const copyApplicantDetails = (applicantDetails: ConsumerLoanApplicantDetails, submittedDate?: Date) => ({
    type: 'COPY_APPLICANT_DETAILS',
    data: { ...applicantDetails },
    submittedDate
} as NewApplicationAction);

export const setCoApplicantDetails = (applicantDetails: ConsumerLoanApplicantDetails) => ({
    type: 'SET_COAPPLICANT_DETAILS',
    data: applicantDetails
} as NewApplicationAction);

export const updateProductType = (productType: ProductType) => ({
    type: 'UPDATE_PRODUCT_TYPE',
    data: productType
} as NewApplicationAction);

export const updateConsumerLoanDetails = (details: ConsumerLoanDetails) => ({
    type: 'UPDATE_CONSUMER_LOAN_DETAILS',
    data: details
} as NewApplicationAction);

export const updateApplicant = (applicantDetails: ConsumerLoanApplicantDetails) => ({
    type: 'UPDATE_APPLICANT',
    data: applicantDetails
} as NewApplicationAction);

export const updateCoApplicant = (applicantDetails: ConsumerLoanApplicantDetails) => ({
    type: 'UPDATE_COAPPLICANT',
    data: applicantDetails
} as NewApplicationAction);

export const updateRedemptions = (redemptions: Redemption[]) => ({
    type: 'UPDATE_REDEMPTIONS',
    data: redemptions
} as NewApplicationAction);

export const switchApplicants = () => ({
    type: 'SWITCH_APPLICANTS'
} as NewApplicationAction);

export const deleteCoApplicant = () => ({
    type: 'DELETE_COAPPLICANT'
} as NewApplicationAction);

export const addCoApplicant = (countryId: number) => ({
    type: 'ADD_COAPPLICANT',
    data: { countryId }
} as NewApplicationAction);

export const addExistingCoApplicant = (applicantDetails: ConsumerLoanApplicantDetails) => ({
    type: 'ADD_EXISTING_COAPPLICANT',
    data: applicantDetails
} as NewApplicationAction);

export const setErrors = (errors: string[]) => ({
    type: 'SET_ERRORS',
    data: errors
} as NewApplicationAction);

export const setValidationStatus = (status?: LoanStatus) => ({
    type: 'SET_VALIDATION_STATUS',
    data: status
} as NewApplicationAction);

export const hideDeleteCoApplicantConfirmationModal = () => ({
    type: 'HIDE_DELETE_COAPPLICANT_CONFIRMATION'
} as NewApplicationAction);

export const showDeleteCoApplicantConfirmationModal = () => ({
    type: 'SHOW_DELETE_COAPPLICANT_CONFIRMATION'
} as NewApplicationAction);

export const hideAddCoApplicantConfirmationModal = () => ({
    type: 'HIDE_ADD_COAPPLICANT_CONFIRMATION'
} as NewApplicationAction);

export const showAddCoApplicantConfirmationModal = () => ({
    type: 'SHOW_ADD_COAPPLICANT_CONFIRMATION'
} as NewApplicationAction);

export const setSubstatuses = (substatusIds: number[]) => ({
    type: 'SET_SUBSTATUSES',
    substatusIds
} as NewApplicationAction);

export const setNewApplicationDefaults = (newApplicationTemplate: NewApplicationTemplate | null) => ({
    type: 'SET_NEW_APPLICATION_DEFAULTS',
    defaults: newApplicationTemplate
} as NewApplicationAction);
