import { Action, Dispatch } from 'redux';
import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import {CachingConfig} from '../../../common/helpers/Caching';
import { Publisher } from '../../../common/models/Publisher';

export const GET_PUBLISHERS_BEGIN = 'GET_PUBLISHERS_BEGIN';
export const GET_PUBLISHERS_SUCCESS = 'GET_PUBLISHERS_SUCCESS';
export const GET_PUBLISHERS_FAILURE = 'GET_PUBLISHERS_FAILURE';

interface GetPublishersBeginAction extends Action<typeof GET_PUBLISHERS_BEGIN> { }

interface GetPublishersSuccessAction extends Action<typeof GET_PUBLISHERS_SUCCESS> {
    publishers: Publisher[];
}

interface GetPublishersFailureAction extends Action<typeof GET_PUBLISHERS_FAILURE> {
    error: any;
}

export type GetPublishersAction = GetPublishersBeginAction
    | GetPublishersSuccessAction
    | GetPublishersFailureAction;

const getPublishers = (): GetPublishersBeginAction => ({
    type: GET_PUBLISHERS_BEGIN
});

const getPublishersSuccess = (publishers: Publisher[]): GetPublishersSuccessAction => ({
    type: GET_PUBLISHERS_SUCCESS,
    publishers
});

const getPublishersFailure = (error: any): GetPublishersFailureAction => ({
    type: GET_PUBLISHERS_FAILURE,
    error
});

export const PublishersActionsCreator = {
    loadPublishers: () => {
        return (dispatch: Dispatch, getState: any) => {
            if (getState().publishersReducer.publishersData.isLoading) {
                return;
            }

            dispatch(getPublishers());

            const httpClient = AxiosClientFactory.getInstance();

            httpClient
                .get<Publisher[]>('/api/publishers', {
                    cache: CachingConfig.LONG
                })
                .then((response) => dispatch(getPublishersSuccess(response.data)))
                .catch((error) => dispatch(getPublishersFailure(error)));
        };
    }
};

export interface PublishersActionsCreatorProps {
    loadPublishers: typeof PublishersActionsCreator.loadPublishers;
}
