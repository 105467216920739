import { TranslateFunction } from 'react-localize-redux';
import { FollowUp } from '../models/FollowUp';
import mapEnumToSelectableItems from './mapEnumToSelectableItems';
import { getEnumTranslationKey } from './getTranslationKey';

export const getFollowUpTranslateKey = (followUp: string | number | null): string => getEnumTranslationKey(FollowUp, followUp, 'FOLLOW_UP', 'ALL');

const nameMapper = (translate: TranslateFunction) => (followUp: string | null) => translate(getFollowUpTranslateKey(followUp)).toString();

export const mapFollowUpsToSelectableItems = (translate: TranslateFunction) => mapEnumToSelectableItems<number>(FollowUp, nameMapper(translate));
