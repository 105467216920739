import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';

export const addAutomaticBankReport = async (personId: string | number, loanId: string | number, bankIds: number[]): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .post(`/api/applicants/${personId}/consumerLoans/${loanId}/automaticBankReport`, bankIds);

    return new CommandResult(result);
};
