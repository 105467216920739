import {AxiosClientFactory} from "../../common/helpers/AxiosClientFactory";
import {CampaignRecipientStatus} from "../../common/models/Campaign";


export interface CountCampaignRecipientsParams {
    campaignId: number;
    status?: CampaignRecipientStatus | null;
}

export const countCampaignRecipients = (params: CountCampaignRecipientsParams): Promise<number> => {
    return AxiosClientFactory
        .getInstance()
        .get<number>(`/api/campaigns/${params.campaignId}/recipients/count`, {params})
        .then((result) => result.data);
};