import { UserProps } from '../interfaces/UserProps';

export function isLimitedCaseWorker(userProps: UserProps): boolean {
    return userProps.userData.user === undefined
        || userProps.userData.user.advertisementId !== null
        || userProps.userData.user.advertisementSiteId !== null
        || userProps.userData.user.applicationSourceId !== null;
}

export function canEditAdvertisementId(userProps: UserProps): boolean {
    return userProps.userData.user !== undefined
        && userProps.userData.user.advertisementId === null
        && userProps.userData.user.advertisementSiteId !== null
}