import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {CSSProperties, FunctionComponent, PropsWithChildren} from 'react';

interface RefreshButtonProps {
    isLoading: boolean;
    onClick?: () => void;
    style?: CSSProperties;
}

const RefreshButton: FunctionComponent<PropsWithChildren<RefreshButtonProps>> = (props) => {
    const style = {color: '#707070', border: 'none', ...(props.style || {}) };

    if (props.isLoading) {
        return (
            <button className="btn" disabled={true} style={style} >
                <FontAwesomeIcon icon={faSyncAlt} spin={true} />
                {props.children}
            </button>
        );
    } else {
        return (
            <button className="btn" onClick={props.onClick} style={style} >
                <FontAwesomeIcon icon={faSyncAlt} />
                {props.children}
            </button>
        );
    }
};

export default RefreshButton;
