import {
    HANDLE_RECEIVED_MESSAGE_NOTIFICATION,
    MARK_AS_READ,
    MARK_AS_UNREAD,
    ReceivedMessagesAction,
    SET_RECEIVED_MESSAGES_CAMPAIGN_FILTER,
    SET_RECEIVED_MESSAGES_PRIMARY_LANGUAGE_FILTER,
    SET_RECEIVED_MESSAGES_SUBSTATUSES_FILTER,
    SET_UNREAD_MESSAGE_COUNT
} from '../actions/ReceivedMessagesActionCreator';
import {ReceivedMessagesState, ReceivedMessagesStateFilter} from '../interfaces/ReceivedMessagesProps';
import {ReceivedMessageNotification} from "../models/ReceivedMessageNotification";

export function receivedMessagesActionsReducer(state: ReceivedMessagesState | undefined, action: ReceivedMessagesAction) {
    state = state || {
        unreadCount: 0,
        filter: {
            substatusIds: [],
            primaryLanguage: null,
            campaign: null
        }
    };
    
    switch (action.type) {
        case SET_UNREAD_MESSAGE_COUNT:
            return {
                ...state,
                unreadCount: action.count
            };
        case SET_RECEIVED_MESSAGES_CAMPAIGN_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    campaign: action.campaign
                }
            };
        case SET_RECEIVED_MESSAGES_PRIMARY_LANGUAGE_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    primaryLanguage: action.primaryLanguage
                }
            };
        case SET_RECEIVED_MESSAGES_SUBSTATUSES_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    substatusIds: action.substatusIds
                }
            };
        case MARK_AS_READ:
            return {
                ...state,
                unreadCount: state.unreadCount - 1
            };
        case MARK_AS_UNREAD:
            return {
                ...state,
                unreadCount: state.unreadCount + 1
            };
        case HANDLE_RECEIVED_MESSAGE_NOTIFICATION:
            // check user scope
            if (action.user && action.user.applicationSourceId && action.user.applicationSourceId !== action.notification.applicationSourceId) {
                return state;
            }

            if (notificationFitFilters(action.notification, state.filter)) {
                if (action.notification.read) {
                    return {
                        ...state,
                        unreadCount: state.unreadCount - 1
                    };
                } else {
                    return {
                        ...state,
                        unreadCount: state.unreadCount + 1
                    };
                }
            }

            return state;
        default:
            return state;
    }
}

function notificationFitFilters(notification: ReceivedMessageNotification, filter: ReceivedMessagesStateFilter): boolean {
    let fit = true;
    if (filter.substatusIds && filter.substatusIds.length > 0) {
        fit = fit && notification.substatusIds.some((i) => filter.substatusIds.includes(i));
    }
    if (filter.primaryLanguage) {
        fit = fit && notification.primaryLanguage === filter.primaryLanguage;
    }
    if (filter.campaign) {
        fit = fit && notification.campaignId === filter.campaign.id;
    }
    return fit;
}
