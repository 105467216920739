import { AxiosClientFactory } from "../../../common/helpers/AxiosClientFactory";
import { CommandResult } from "../../../common/helpers/CommandResult";

export const putCustomerAdvisor = (customerId: number, advisorId: number | null): Promise<CommandResult> => {
    return new Promise<CommandResult>((resolve, reject) => {
        AxiosClientFactory
            .getInstance()
            .put<CommandResult>(`api/customers/${customerId}/advisor`, { advisorId })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
};