import {AxiosClientFactory} from "../../common/helpers/AxiosClientFactory";
import {TableState} from "../../common/models/TableState";
import {MarketingListCustomer} from "../model/MarketingListCustomer";
import {CountMarketingListCustomersPreviewParams} from "./countMarketingListCustomerPreview";

export type GetMarketingListCustomersParams = CountMarketingListCustomersPreviewParams & Partial<TableState>;

export const getMarketingListCustomersPreview = (params: GetMarketingListCustomersParams): Promise<MarketingListCustomer[]> => {
    return AxiosClientFactory
        .getInstance()
        .post<MarketingListCustomer[]>(`/api/marketingLists/customers`, params)
        .then((result) => result.data);
};