import React, { FunctionComponent, ReactNode } from 'react';
import './pickers.css';

export interface PageSizePickerProps {
    pageSize: number;
    variant?: string;
    pickableSizes: number[];
    onPageSizePicked?: (pageSize: number) => void;
}

const PageSizePicker: FunctionComponent<PageSizePickerProps> = (props) => {
    const pagingItems: ReactNode[] = [];

    for (let i = 0; i < props.pickableSizes.length; i++) {
        const pageSize = props.pickableSizes[i];
        const onClickHandler = () => props.onPageSizePicked && props.onPageSizePicked(pageSize);
        if (pageSize === props.pageSize) {
            pagingItems.push(
                <li key={`sizes-${i}`} className="active">{pageSize}</li>
            );
        } else {
            pagingItems.push(
                <li key={`sizes-${i}`} onClick={onClickHandler}>{pageSize}</li>
            );
        }
    }

    const variant = props.variant || 'dark';

    return (<ul className={`${variant} picker-list`}>{pagingItems}</ul>);
};

export default PageSizePicker;
