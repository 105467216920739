import React, {FunctionComponent, ReactNode} from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-localize-redux';

export interface TableHeaderCellModel {
    index: number;
    translateKey: string;
    width?: number;
    numeric?: boolean;
    orderDirection?: number;
}

interface TableHeaderProps {
    cells: TableHeaderCellModel[];
    onClick?: (index: number) => void;
    sorting?: boolean;
}

const TableHeader: FunctionComponent<TableHeaderProps> = (props) => {
    const mapCells = (cell: TableHeaderCellModel, index: number) => {
        const clickHandler = () => props.onClick && props.onClick(cell.index);
        let sortingIcon: ReactNode = null;
        if (props.sorting) {
            let icon: IconProp = faSort;

            if (cell.orderDirection !== undefined) {
                icon = cell.orderDirection === 1 ? faSortDown : faSortUp;
            }
            sortingIcon = <FontAwesomeIcon icon={icon} />
        }

        const style = {
            textAlign: cell.numeric ? 'right' : 'left',
            width: cell.width === undefined ? 'auto' : cell.width + 'px',
            paddingRight: cell.numeric ? '55px' : undefined
        } as React.CSSProperties;

        return (
            <th key={`table-header-${index}`} className="cursor-pointer" onClick={clickHandler} style={style}>
                <Translate id={cell.translateKey} /><span className="ms-1">{sortingIcon}</span>
            </th>
        );
    };

    return (<thead><tr>{props.cells.map(mapCells)}</tr></thead>);
};

export default TableHeader;
