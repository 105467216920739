import {CommandResult} from "../../common/helpers/CommandResult";
import {AxiosClientFactory} from "../../common/helpers/AxiosClientFactory";
import {MarketingList} from "../model/MarketingList";

class CreateMarketingListCommandResult extends CommandResult {
    public readonly listId?: number;
    constructor(result: any) {
        super(result);
        if (this.success) {
            this.listId = result.data.result.id;
        }
    }
}
export const createMarketingList = (list: MarketingList): Promise<CreateMarketingListCommandResult> => {
    return AxiosClientFactory
        .getInstance()
        .post(`/api/marketingLists`, list)
        .then((result) => new CreateMarketingListCommandResult(result));
};