import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';
import { ConsumerLoanDetails } from '../models/ConsumerLoanDetails';
import { ConsumerLoanApplicant } from '../models/ConsumerLoanApplicant';
import { Redemption } from '../models/Redemption';

interface SaveAsCompleteCommand {
    details: ConsumerLoanDetails;
    applicant: ConsumerLoanApplicant;
    coApplicant?: ConsumerLoanApplicant;
    redemptions: Redemption[];
}

export const saveAsComplete = async (personId: number | string, loanId: number | string, data: SaveAsCompleteCommand): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .post(`api/applicants/${personId}/consumerLoans/${loanId}/saveAsComplete`, data);

    return new CommandResult(result);
};
