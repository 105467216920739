import { PaginationItemDescriptor } from '../models/PaginationItemDescriptor';

export const createPaginationItemDescriptors = (itemCount: number, pageSize: number, currentPage: number): PaginationItemDescriptor[] => {
    pageSize = pageSize || 20;

    if (itemCount > 0) {
        itemCount = itemCount - 1;
    }

    const pageCount = Math.floor(itemCount / pageSize);
    const results: PaginationItemDescriptor[] = [];

    if (pageCount < 8) {
        for (let i = 0; i <= pageCount; i++) {
            results.push(
                i === currentPage
                    ? { number: i, isActive: true, isClickable: false }
                    : { number: i, isActive: false, isClickable: true });
        }
    } else {
        results.push(
            currentPage === 0
                ? { number: 0, isActive: true, isClickable: false }
                : { number: 0, isActive: false, isClickable: true });

        let noFirstEllipsis = true;
        for (let i = 1; i <= pageCount - 1; i++) {
            if (i === currentPage) {
                results.push({ number: i, isActive: true, isClickable: false });
            } else if (Math.abs(i - currentPage) <= 2 || (currentPage < 5 && i < 7) || (currentPage > pageCount - 5 && i > pageCount - 7)) {
                results.push({ number: i, isActive: false, isClickable: true });
            } else if (noFirstEllipsis || (i > currentPage + 1)) {
                results.push({ number: i, isActive: false, isClickable: false });
                noFirstEllipsis = false;
            }

            if (i > currentPage + 2 && !(currentPage < 5 && i < 7)) {
                break;
            }
        }

        results.push(
            currentPage === pageCount
                ? { number: pageCount, isActive: true, isClickable: false }
                : { number: pageCount, isActive: false, isClickable: true });
    }

    return results;
};
