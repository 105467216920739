import React, {FunctionComponent} from 'react';
import {ConsumerLoan} from '../../models/ConsumerLoan';
import {AdditionalInformation} from "../../../../common/models/AdditionalInformation";
import Incomes from "../MortgageCalculatorModal/Sections/Incomes/Incomes";
import Expenses from "../MortgageCalculatorModal/Sections/Expenses/Expenses";

interface ConsumerLiquidityProps {
    consumerLoan: ConsumerLoan | undefined;
    applicantAdditionalInformation: AdditionalInformation[];
}
const ConsumerLiquidity: FunctionComponent<ConsumerLiquidityProps> = (props) => {
    if (props.consumerLoan === undefined) {
        return null;
    }

    return (
        <div className="consumer-liquidity">
            <div><Incomes application={props.consumerLoan} applicantAdditionalInformation={props.applicantAdditionalInformation} readonly={true}/></div>
            <div><Expenses application={props.consumerLoan} applicantAdditionalInformation={props.applicantAdditionalInformation} readonly={true}/></div>
        </div>
    );
};

export default ConsumerLiquidity;