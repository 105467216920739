import React, {FunctionComponent} from 'react';
import {faAddressCard, faHandshake, faPhone} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Translate} from 'react-localize-redux';
import {getTranslationKey} from '../helpers/getTranslationKey';
import Tooltip from './Tooltip';
import {faFacebookSquare} from '@fortawesome/free-brands-svg-icons';
import {ReactComponent as BankIdLogo} from '../../bankid.svg';
import {Setting} from '../models/Setting';
import {SettingsProps} from '../interfaces/SettingsProps';
import {connect} from 'react-redux';

interface OriginIconProps {
    origin: string | null;
}

enum Origin {
    ApplicationForm = 'ApplicationForm',
    ApplicationFormWithBankID = 'ApplicationFormWithBankID',
    Taurus = 'Taurus',
    ApiLead = 'ApiLead',
    FacebookLead = 'FacebookLead',
    PartnerIntegration = 'PartnerIntegration'
}

const OriginIcon: FunctionComponent<OriginIconProps & SettingsProps> = (props) => {
    const translationKey = props.origin ? getTranslationKey(props.origin, 'APPLICATION_ORIGIN') : undefined;
    const tooltipContent = <Translate id={translationKey}/>;
    const customIcon = {
        height: '16px',
        marginTop: '-4px'
    };
    const faviconSetting = props.settingsData.settings.find((s: Setting) => s.name === 'FAVICON');
    if (!faviconSetting || !translationKey) {
        
        return null;
    }
    
    return (
        <Tooltip content={tooltipContent} placement="top">
            {(() => {
                switch (props.origin) {
                    case Origin.ApplicationForm:
                        return <FontAwesomeIcon icon={faAddressCard}/>;
                    case Origin.ApplicationFormWithBankID:
                        return <BankIdLogo style={customIcon}/>;
                    case Origin.Taurus:
                        return <img alt="Taurus icon" style={{...customIcon, filter: 'grayscale(1) opacity(0.65)'}} src={`${process.env.REACT_APP_BASE_API_URL}/api/settings/logos/${faviconSetting.value}`}/>;
                    case Origin.ApiLead:
                        return <FontAwesomeIcon icon={faPhone}/>;
                    case Origin.FacebookLead:
                        return <FontAwesomeIcon icon={faFacebookSquare}/>;
                    case Origin.PartnerIntegration:
                        return <FontAwesomeIcon icon={faHandshake}/>;
                    default:
                        return <></>;
                }
            })()}
        </Tooltip>
    );
};

const mapStateToProps = (state: any) => ({ ...state.settingsActionsReducer });

export default connect<SettingsProps, {}, OriginIconProps, any>(mapStateToProps)(OriginIcon);
