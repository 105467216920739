import React, {ChangeEventHandler} from 'react';
import InputField, {InputFieldProps} from './InputField';
import {useValidation, ValidationProps} from '../../helpers/useValidation';
import {Form} from 'react-bootstrap';
import {defaultDisplayValueGetter, defaultKeyValueGetter} from '../../helpers/defaultItemValueGetters';

interface RadioButtonsInputFieldProps<T> extends InputFieldProps, ValidationProps {
    name: string;
    value: string | number | undefined;
    keyValue?: (item: T) => string | number;
    displayValue?: (item: T) => string;
    items: T[];
    onValueChanged?: (keyValue: string | number, name: string) => void;
}

function RadioButtonsInputField<T, WrapperProps = any>(props: RadioButtonsInputFieldProps<T>) {
    const [errorCode, internalError, ref, onTouched] = useValidation({...props, allowZero: true});

    return (
        <InputField<WrapperProps>
            className={props.className}
            afterContent={props.afterContent}
            descriptionKey={props.descriptionKey} 
            description={props.description} 
            editMode={props.editMode}
            error={internalError}
            errorCode={errorCode}
            wrapper={props.wrapper}
            wrapperProps={props.wrapperProps}
        >
            {renderValue(props, ref, onTouched)}
        </InputField>
    );
}

function renderValue<T>(props: RadioButtonsInputFieldProps<T>, ref: React.RefObject<HTMLInputElement>, onTouched: () => void) {
    const keyValue = props.keyValue || defaultKeyValueGetter;
    const displayValue = props.displayValue || defaultDisplayValueGetter;

    if (!props.editMode || !props.onValueChanged) {
        const item = props.items.find((i) => keyValue(i) === props.value);
        return <span>{item ? displayValue(item) : '-'}</span>;
    }

    const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const item = props.items.find((i) => keyValue(i).toString() === e.target.value) || props.items[0];
        const value = keyValue(item);
        onTouched();

        if (props.onValueChanged) {
            props.onValueChanged(value, props.name);
        }
    };

    return (
        <React.Fragment>
            {props.items.map((i) => renderRadio(i, props.name, props.value, onChange, keyValue, displayValue))}
            <input type="text" defaultValue={(props.value || '').toString()} ref={ref} style={{ display: 'none' }} />
        </React.Fragment>
    );
}

function renderRadio<T>(item: T, name: string, selected: string | number | undefined, onChange: ChangeEventHandler<HTMLInputElement>,
                        keyValue: (item: T) => string | number, displayValue: (item: T) => string) {
    const key = keyValue(item);
    return <Form.Check type="radio" key={key} id={`${name}-${(key).toString()}`} value={key} label={displayValue(item)} onChange={onChange} checked={key === selected}/>;
}

export default RadioButtonsInputField;
