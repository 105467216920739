import {
    faAddressBook,
    faAngleDown,
    faAngleUp,
    faCreditCard,
    faPlusSquare,
    faTrashAlt,
    faUserTie
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Dispatch, FunctionComponent, SetStateAction, useState} from 'react';
import {Translate} from 'react-localize-redux';
import {Redemption} from '../models/Redemption';
import {getCurrencySymbolForCountryId} from '../../../common/helpers/currencySymbolFunctions';
import NumberInputField, {NumberInputFieldKind} from '../../../common/components/input-fields/NumberInputField';
import EditLoanForm from './EditLoanForm';
import Translation from '../../../common/components/Translation';
import {DebtType} from '../models/DebtType';
import {useRedemptionsValidation} from '../helpers/newApplicationValidationLogic';
import {connect, shallowEqual} from 'react-redux';
import {RedemptionSource} from '../models/RedemptionSource';
import Tooltip from '../../../common/components/Tooltip';
import {calculateMonthlyCost} from "../helpers/calculators";
import {Setting} from "../../../common/models/Setting";
import {SettingsProps} from "../../../common/interfaces/SettingsProps";

interface EditLoansProps extends SettingsProps {
    redemptions: Redemption[];
    countryId: number;
    errors: string[];
    isComplete: boolean;
    onRedemptionsChanged: (redemptions: Redemption[]) => void;
}

const debtTypes = Object
    .keys(DebtType)
    .filter((value: string) => (typeof DebtType[value] === 'string'))
    .map((value: string) => Number(value));

const EditLoans: FunctionComponent<EditLoansProps> = (props) => {
    const [expandedDebtTypes, setExpandedDebtTypes] = useState<number[]>([]);
    useRedemptionsValidation(props.redemptions, props.isComplete, props.errors, setExpandedDebtTypes);

    return (
        <div className="column-card">
            <div className="card-heading">
                <span className="title"><Translate id="LOANS" /></span>
                <span className="options"><FontAwesomeIcon icon={faCreditCard} /></span>
            </div>
            <div className="card-content">
                {debtTypes.map((dt) => renderLoanGroup(dt, props, expandedDebtTypes, setExpandedDebtTypes))}
                <div className="divider" />
                {renderTotals(props.redemptions, props.countryId)}
            </div>
        </div>
    );
};

function renderLoanGroup(debtType: DebtType, props: EditLoansProps, expandedDebtTypes: number[], setExpandedDebtTypes: Dispatch<SetStateAction<number[]>>) {
    const isExpanded = expandedDebtTypes.includes(debtType);
    const expansionIcon = isExpanded ? faAngleUp : faAngleDown;
    const redemptions = props.redemptions.filter((r) => r.debtType === debtType);
    const toggleExpanded = () => isExpanded
        ? collapse(debtType, props.redemptions, setExpandedDebtTypes, props.onRedemptionsChanged)
        : expand(debtType, setExpandedDebtTypes);
    const onRedemptionChanged = (oldRedemption: Redemption, newRedemption: Redemption) =>
        props.onRedemptionsChanged(props.redemptions.map((r) => r === oldRedemption ? newRedemption : r));
    const onRedemptionRemoved = (redemption: Redemption) => props.onRedemptionsChanged(props.redemptions.filter((r) => r !== redemption));
    const onRedemptionAdded = () => props.onRedemptionsChanged([...props.redemptions, createEmptyRedemption(debtType)]);

    if (isExpanded && redemptions.length === 0) {
        onRedemptionAdded();
    }

    return (
        <React.Fragment key={`loan-group-${debtType}`}>
            <div className="divider" />
            <div className="loans-row">
                <div><Translation id={DebtType[debtType]} prefix="DEBT_TYPE" /></div>
                <div>
                    {sumRedemptions(redemptions).toLocaleString()} {getCurrencySymbolForCountryId(props.countryId)}
                    <span className="grey-button" onClick={toggleExpanded}><FontAwesomeIcon icon={expansionIcon} /></span>
                </div>
            </div>
            <div style={{ display: isExpanded ? 'block' : 'none' }}>
                {renderLoans(props, redemptions, onRedemptionChanged, onRedemptionRemoved, onRedemptionAdded)}
            </div>
        </React.Fragment>
    );
}

function renderLoans(
    props: EditLoansProps,
    redemptions: Redemption[],
    onRedemptionChanged: (oldRedemption: Redemption, newRedemption: Redemption) => void,
    onRedemptionRemoved: (redemption: Redemption) => void,
    onRedemptionAdded: () => void) {

    const renderLoan = (redemption: Redemption, index: number) => {
        const onRemoved = () => onRedemptionRemoved(redemption);

        return (
            <React.Fragment key={`loan-form-${index}`} >
                {index !== 0 ? <div className="short-divider" /> : null}
                <div className="loan-form">
                    <EditLoanForm 
                        redemption={redemption} 
                        countryId={props.countryId} 
                        onRedemptionChanged={onRedemptionChanged} 
                        errors={props.errors} 
                        isComplete={props.isComplete} 
                    />
                    <div className="side-stripe">
                        <div className="source-icon">
                            {renderRedemptionSource(props, redemption.source)}
                        </div>
                        <div className="loan-buttons">
                            <div key="remove" className="grey-button" onClick={onRemoved}><FontAwesomeIcon icon={faTrashAlt} /></div>
                            {index < redemptions.length - 1 ? null : renderAddButton(onRedemptionAdded)}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return redemptions.map(renderLoan);
}

function renderRedemptionSource(props: EditLoansProps, source: RedemptionSource | null) {
    switch (source) {
        case RedemptionSource.Customer:
            return (
                <Tooltip content={<Translate id="REDEMPTION_SOURCE.CUSTOMER" />}>
                    <FontAwesomeIcon icon={faAddressBook} style={{ color: '#707070' }} />
                </Tooltip>
            );
        case RedemptionSource.Taurus:
            const faviconSetting = props.settingsData.settings.find((s: Setting) => s.name === 'FAVICON');
            return (
                <Tooltip content={<Translate id="REDEMPTION_SOURCE.TAURUS" />}>
                    {faviconSetting ?
                        <img
                            alt="Taurus"
                            height="16"
                            width="16"
                            src={`${process.env.REACT_APP_BASE_API_URL}/api/settings/logos/${faviconSetting.value}`} 
                        />
                        :
                        <FontAwesomeIcon icon={faUserTie}/>
                    }
                </Tooltip>
            );
        case RedemptionSource.Gjeldsregisteret:
            return (
                <Tooltip content={<Translate id="REDEMPTION_SOURCE.GJELDSREGISTERET" />}>
                    <img
                        alt="Gjeldsregisteret"
                        height="16"
                        width="16"
                        src="https://www.gjeldsregisteret.com/favicon.png" />
                </Tooltip>
            );
        case RedemptionSource.NorskGjeldsinformasjon:
            return (
                <Tooltip content={<Translate id="REDEMPTION_SOURCE.NORSK_GJELDSINFORMASJON" />}>
                    <img
                        alt="Norsk Gjeldsinformasjon"
                        height="16"
                        width="16"
                        src="https://cdn.motty.no/icons/norsk_gjeldsinformasjon.png" />
                </Tooltip>
            );
        default:
            return null;
    }
}

function renderAddButton(onAdded: () => void) {
    return <div key="add" className="grey-button" onClick={onAdded}><FontAwesomeIcon icon={faPlusSquare} /></div>;
}

function renderTotals(redemptions: Redemption[], countryId: number) {
    const monthlyPayments = redemptions.reduce((sum, r) => sum + (r.repaymentMonthlyCost || calculateMonthlyCost(r)), 0);
    const totalRefinance = sumRedemptions(redemptions.filter((r) => r.refinance));
    return (
        <React.Fragment>
            <div className="loans-total">
                <div>{renderTotalField('LOANS_IN_TOTAL', countryId, sumRedemptions(redemptions))}</div>
                {totalRefinance > 0 &&
                    <div>{renderTotalField('TO_BE_REFINANCED', countryId, totalRefinance)}</div>
                }
            </div>
            {monthlyPayments > 0 &&
                <div className="loans-total">
                    <div>{renderTotalField('MONTHLY_PAYMENTS', countryId, monthlyPayments)}</div>
                </div>
            }
        </React.Fragment>
    );
}

function renderTotalField(name: string, countryId: number, value: number) {
    return <NumberInputField name={name} kind={NumberInputFieldKind.Money} countryId={countryId} value={value} descriptionKey={name} />;
}

function sumRedemptions(redempions: Redemption[]) {
    return redempions.reduce((sum, r) => sum + (r.balance || 0), 0);
}

function createEmptyRedemption(debtType: DebtType) {
    return { debtType, lender: '', source: RedemptionSource.Taurus } as Redemption;
}

function expand(debtType: DebtType, setExpandedDebtTypes: Dispatch<SetStateAction<number[]>>) {
    setExpandedDebtTypes((state) => state.concat([debtType]));
}

function collapse(
    debtType: DebtType,
    redemptions: Redemption[],
    setExpandedDebtTypes: Dispatch<SetStateAction<number[]>>,
    onRedemptionChanged: (redemptions: Redemption[]) => void) {

    setExpandedDebtTypes((state) => state.filter((dt) => dt !== debtType));
    const empty = createEmptyRedemption(debtType);
    onRedemptionChanged(redemptions.filter((r) => r.debtType !== debtType || !shallowEqual(r, empty)));
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer
});

export default connect<SettingsProps, {}, {}, any>(mapStateToProps)(EditLoans);
