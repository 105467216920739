import React, {FunctionComponent, useEffect} from "react";
import "./RecipientsTabContent.css";
import {CampaignViewDataState} from "../../../../../reducers/campaignViewDataReducer";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {
    CampaignViewDataActionCreators,
    CampaignViewDataActions
} from "../../../../../actions/CampaignViewDataActionCreators";
import RecipientsFilterContainer from "./RecipientsFilterContainer";
import RecipientsTable from "./RecipientsTable";
import {Translate} from "react-localize-redux";
import {CampaignValidationErrors, checkRecipientsRequired} from "../../../helpers/validators";
import {CampaignStatus} from "../../../../../../common/models/Campaign";

interface RecipientsTabContentProps extends CampaignViewDataState, CampaignViewDataActions {
    editMode: boolean;
}

const RecipientsTabContent: FunctionComponent<RecipientsTabContentProps> = (props) => {
    
    useEffect(() => {
        if (props.lastCampaignRecipientsFilter !== null && props.campaign?.status === CampaignStatus.DRAFT) {
            // user tried to edit recipient filter
            props.setError(CampaignValidationErrors.RECIPIENTS_ARE_REQUIRED, checkRecipientsRequired(props.campaignRecipientsCount));
        }
    }, [props.campaignRecipientsCount]); // eslint-disable-line react-hooks/exhaustive-deps
    
    if (!props.campaign) {
        return null;
    }
    return (
        <div className="recipients-tab-content">
            {props.errors[CampaignValidationErrors.RECIPIENTS_ARE_REQUIRED] && 
                <p className="error-text"><Translate id={`ERRORS.${CampaignValidationErrors.RECIPIENTS_ARE_REQUIRED}`}/></p>
            }
            {props.errors[CampaignValidationErrors.RECIPIENTS_FILTER_IS_REQUIRED] &&
                <p className="error-text"><Translate id={`ERRORS.${CampaignValidationErrors.RECIPIENTS_FILTER_IS_REQUIRED}`}/></p>
            }
            <div className="recipients-filter">
                <RecipientsFilterContainer editMode={props.editMode}/>
            </div>
            <div className="recipients-table">
                <RecipientsTable editMode={props.editMode}/>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    ...state.campaignViewDataReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...CampaignViewDataActionCreators
}, dispatch);

export default connect<CampaignViewDataState, CampaignViewDataActions>(mapStateToProps, mapActionCreatorsToProps)(RecipientsTabContent);