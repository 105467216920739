import React, {
    ReactNode,
    CSSProperties,
    MouseEvent,
    FunctionComponent,
    useState,
    useEffect, SetStateAction
} from 'react';
import {Translate} from 'react-localize-redux';
import { getCurrencySymbolForCountryId } from '../../../../common/helpers/currencySymbolFunctions';
import { BankStatus } from '../../../../common/models/BankStatus';
import { getBankReportHistory } from '../../api/getBankReportHistory';
import { Bank } from '../../models/Bank';
import { Transaction } from '../../models/Transaction';
import './bank-replies.css';
import { getEnumTranslationKey } from '../../../../common/helpers/getTranslationKey';
import BankRepliesRowIcons from './BankRepliesRowIcons';
import { BankReportHistory } from '../../models/BankReportHistory';
import BankRepliesHistory from './BankRepliesHistory';
import BankRepliesForm from './BankRepliesForm';
import { ManageableComponentProps } from '../../../../common/interfaces/ManageableComponentProps';
import { ProductType } from '../../../../common/models/ProductType';
import { BankRepliesActions } from '../ApplicantView';
import getClassNamesByResponseCode from '../../helpers/getClassNamesByResponseCode';
import BankRepliesBankTitle from './BankRepliesBankTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt, faReply, faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import { TransactionCommand } from '../../models/TransactionCommand';
import BankRepliesButtons from './BankRepliesButtons';
import Checkbox from '../../../../common/components/Checkbox';
import classNames from 'classnames';
import { ConsumerLoan } from '../../models/ConsumerLoan';
import ConfirmationModal from '../../../../common/components/ConfirmationModal';
import BankRepliesRefinances from "./BankRepliesRefinances";
import {ForceUpdateFunction, useForceUpdate} from '../../../../common/helpers/useForceUpdate';
import CopyToClipboardButton from "../../../../common/components/CopyToClipboardButton";
import Tooltip from "../../../../common/components/Tooltip";
import {isAdmin} from "../../../../common/helpers/isAdmin";
import {UserProps} from "../../../../common/interfaces/UserProps";
import {connect} from "react-redux";

// list of product types which allows to show banks without any integraton
const productTypesWithLackOfIntegrationAllowed = [ProductType.ConsumerLoan, ProductType.CreditCard];

interface BankRepliesProps extends ManageableComponentProps<TransactionCommand>, BankRepliesActions, UserProps {
    bankReplies: Transaction[];
    banks: Bank[];
    productType: ProductType | null;
    application: ConsumerLoan | undefined;
    personId: number;
    loanId: number;
    countryId: number;
    followUpDate: Date | null;
    isRefreshing: boolean;
    onRefresh: () => void;
    onFormInputChanged: (isEmpty: boolean) => void;
}

interface BankRepliesState {
    bankReplies: BankReply[];
    confirmUnsavedChanges: boolean;
    editingBankId: number | undefined;
    editingTransaction: Transaction | undefined;
    expandedBankIds: number[];
    isLoading: boolean;
    selectedBankIds: number[];
    selectionMode: boolean;
}

interface BankReply {
    bank: Bank;
    transaction: Transaction | undefined;
    reportHistories: BankReportHistory[] | undefined;
}

const BankReplies: FunctionComponent<BankRepliesProps> = (props) => {
    const forceUpdate = useForceUpdate();
    const [state, setState] = useState<BankRepliesState>({
            bankReplies: createBankReplies(props),
            confirmUnsavedChanges: false,
            editingBankId: undefined,
            editingTransaction: undefined,
            expandedBankIds: [],
            isLoading: false,
            selectedBankIds: [],
            selectionMode: false
    });
    
    useEffect(() => {
        setState(state => ({...state, expandedBankIds: [] }));
    }, [props.loanId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setState(state => ({...state, bankReplies: createBankReplies(props) }));
    }, [props.bankReplies]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.expandedBankIds && state.expandedBankIds.length) {
            state.expandedBankIds.forEach(id => {
                const bankReply = state.bankReplies.find(reply => reply.bank.id === id);
                if (bankReply && !bankReply.reportHistories) {
                    loadHistory(props, setState, forceUpdate, id);
                }
            });
        }
    }, [state.expandedBankIds && state.expandedBankIds.length]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const setSelectionMode = (selectionMode: boolean) => setState(state => ({...state, selectionMode }));
    const setSelectedBankIds = (selectedBankIds: number[]) => setState(state => ({...state, selectedBankIds }));

    let revertChanges = (props: BankRepliesProps) => {
        props.exitedEditCallBack(true);
    }

    let continueEditing = () => {

    }

    const onToggleExpanded = (bankId: number, event: MouseEvent) => {
        event.stopPropagation();
        if (state.editingBankId === bankId) {
            revertChanges = () => {
                setState(() => ({
                    ...state,
                    confirmUnsavedChanges: false,
                    editingBankId: undefined
                }));
                props.exitedEditCallBack(true);
                toggleBankExpand(props, setState, forceUpdate, bankId);
            }
            continueEditing = () => {
                setState({
                    ...state,
                    confirmUnsavedChanges: false
                });
            }
            setState({
                ...state,
                confirmUnsavedChanges: true
            });
        } else {
            toggleBankExpand(props, setState, forceUpdate, bankId);
        }
    }
    
    return (
        <div className="middle-column column-card">
            <table className="bank-replies-container">
                <thead>
                    <tr>
                        <th className={`bank-replies-table-head ${state.selectionMode ? 'selectable' : ''}`} colSpan={6}>
                            {renderSelectAllBanksCheckbox(state, setState)}
                            <Translate id="BANK_REPLIES" />
                            <BankRepliesButtons
                                selectionMode={state.selectionMode}
                                selectedBankIds={state.selectedBankIds}
                                editMode={state.editingBankId !== undefined}
                                canEnterEdit={props.canEnterEdit}
                                banks={props.banks}
                                transactions={props.bankReplies}
                                productType={props.productType}
                                setSelectionMode={setSelectionMode}
                                setSelectedBankIds={setSelectedBankIds}
                                addAutomaticBankReport={props.addAutomaticBankReport}
                                createEmptyTransaction={createEmptyTransaction}
                                onRefresh={props.onRefresh}
                                isRefreshing={props.isRefreshing}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {renderBankRows(
                        props,
                        state,
                        setState,
                        forceUpdate,
                        onToggleExpanded
                    )}
                </tbody>
            </table>
            <ConfirmationModal
                show={state.confirmUnsavedChanges}
                title={<Translate id="UNSAVED_INFORMATION" />}
                message={<Translate id="UNSAVED_INFORMATION_CONFIRMATION" />}
                onConfirm={() => revertChanges(props)}
                onCancel={continueEditing}
            />
        </div>
    );
}

function createBankReplies(props: BankRepliesProps): BankReply[] {
    return visibleBanks(props).map(bank => ({
        bank,
        reportHistories: undefined,
        transaction: getBankTransaction(props, bank)
    }))
}

function visibleBanks(props: BankRepliesProps): Bank[] {
    const banksWithTransaction = props.bankReplies
        .map((transaction: Transaction) => props.banks.filter(b => b.id === transaction.bankId)[0])
        .sort((b1, b2) => compareBanks(b1, b2));
    const activeBanks = props.banks
        .filter((b) =>
            b.active && b.countryId === props.countryId && !banksWithTransaction.some(bwt => bwt.id === b.id) &&
            ((b.bankIntegrations.length === 0 && props.productType !== null && productTypesWithLackOfIntegrationAllowed.includes(props.productType))
                || b.bankIntegrations.some((bi) => bi.productType === props.productType))
        )
        .sort(compareBanks);
    return [...banksWithTransaction, ...activeBanks];
}

function getBankTransaction(props: BankRepliesProps, bank: Bank): Transaction | undefined {
    return props.bankReplies.find(reply => reply.bankId === bank.id);
}

function renderSelectAllBanksCheckbox(state: BankRepliesState, setState: React.Dispatch<SetStateAction<BankRepliesState>>) {
    if (!state.selectionMode) {
        return null;
    }

    const customStyling: CSSProperties = { display: 'inline', paddingLeft: '4px', marginRight: '-5px' };

    const onClick = () => setState((state) => ({
        ...state,
        selectedBankIds: checkbox.props.checked
            ? []
            : state.bankReplies.map(br => br.bank.id)
    }));
    
    const checkbox = (<Checkbox style={customStyling} checked={state.selectedBankIds.length === state.bankReplies.length} onClick={onClick} />);
    return checkbox;
}

function renderExpansionRow(props: BankRepliesProps, state: BankRepliesState, setState: React.Dispatch<SetStateAction<BankRepliesState>>, forceUpdate: ForceUpdateFunction, bankReply: BankReply) {
    const transactionUpdatedCallback = (newTransaction: Transaction) => {
        setState(() => ({
            ...state,
            editingTransaction: newTransaction
        }));
    }
    const enteredEditCallBack = () => {
        setState(() => ({ 
            ...state,
            editingBankId: bankReply.bank.id
        }));
        props.enteredEditCallBack();
    };
    const exitedEditCallBack = (cancelled: boolean) => {
        if (!cancelled && state.editingTransaction) {
            bankReply.transaction = state.editingTransaction;
        }
        setState(() => ({
            ...state,
            isLoading: true,
            editingBankId: undefined,
            editingTransaction: undefined
        }));
        loadHistory(props, setState, forceUpdate, bankReply.bank.id);
        props.exitedEditCallBack(cancelled);
    };

    return (
        <tr className="bank-row-expansion" key={`bank-row-${bankReply.bank.id}-expansion`}>
            <td colSpan={6}>
                <BankRepliesForm
                    {...props}
                    bank={bankReply.bank}
                    transaction={bankReply.bank.id === state.editingBankId 
                        ? state.editingTransaction || bankReply.transaction || createEmptyTransaction(bankReply.bank.id) 
                        : bankReply.transaction || createEmptyTransaction(bankReply.bank.id)
                    }
                    productType={props.productType}
                    application={props.application}
                    onTransactionUpdated={transactionUpdatedCallback}
                    exitedEditCallBack={exitedEditCallBack}
                    enteredEditCallBack={enteredEditCallBack}
                    editMode={state.editingBankId === bankReply.bank.id}
                    followUpDate={props.followUpDate}
                    onFormInputChanged={props.onFormInputChanged}
                />
                {bankReply.transaction ? <BankRepliesRefinances {...props} refinances={bankReply.transaction.refinances} /> : null}
                {state.isLoading && bankReply.reportHistories === undefined ? <FontAwesomeIcon icon={faSyncAlt} spin={true} /> : <BankRepliesHistory bankHistories={bankReply.reportHistories} />}
            </td>
        </tr>
    );
}

function renderBankRow(
    props: BankRepliesProps, 
    state: BankRepliesState, 
    setState: React.Dispatch<SetStateAction<BankRepliesState>>, 
    bankReply: BankReply, 
    onToggleExpanded: (bankId: number, event: MouseEvent) => void
) {
    return (
        <tr 
            className={getClassNamesByResponseCode(bankReply.transaction, 'bank-row')} 
            key={`bank-row-${bankReply.bank.id}`} 
            onClick={(e) => onRowClick(state, setState, onToggleExpanded, e, bankReply.bank.id)}
        >
            <td className={classNames({ selectable: state.selectionMode })} >
                {renderCheckbox(state, setState, bankReply.bank)}
                <BankRepliesBankTitle bank={bankReply.bank} transaction={bankReply.transaction} />
            </td>
            {renderTransactionAmount(props, bankReply.transaction, props.countryId)}
            {renderTransactionInterest(bankReply.transaction)}
            {renderStatus(bankReply.transaction)}
            {renderContinuationUrl(bankReply.transaction)}
            <BankRepliesRowIcons 
                bank={bankReply.bank} 
                transaction={bankReply.transaction} 
                expandedBankIds={state.expandedBankIds} 
                onToggleExpanded={onToggleExpanded} 
            />
        </tr>
    );
}

function renderCheckbox(state: BankRepliesState, setState: React.Dispatch<SetStateAction<BankRepliesState>>, bank: Bank) {
    if (!state.selectionMode) {
        return null;
    }

    const customStyling: CSSProperties = { display: 'inline', paddingLeft: '4px', marginRight: '-4px' };

    const onClick = (e) => toggleBankSelection(state, setState, bank.id, e);

    return (<Checkbox style={customStyling} checked={state.selectedBankIds.includes(bank.id)} onClick={onClick} />);
}

function onRowClick(state: BankRepliesState, setState: React.Dispatch<SetStateAction<BankRepliesState>>, onToggleExpanded: (bankId: number, event: MouseEvent) => void, event: MouseEvent<any>, bankId: number) {
    if (state.selectionMode) {
        toggleBankSelection(state, setState, bankId, event);
    } else {
        onToggleExpanded(bankId, event);
    }
}

function toggleBankSelection(state: BankRepliesState, setState: React.Dispatch<SetStateAction<BankRepliesState>>, bankId: number, event: MouseEvent<any>) {
    if (state.selectionMode) {
        event.stopPropagation();
        setState((state) => ({
            ...state,
            selectedBankIds: state.selectedBankIds.some(id => id === bankId)
                ? state.selectedBankIds.filter(id => id !== bankId)
                : state.selectedBankIds.concat(bankId)
        }));
    }
}

function toggleBankExpand(props: BankRepliesProps, setState: React.Dispatch<SetStateAction<BankRepliesState>>, forceUpdate: ForceUpdateFunction, bankId: number) {
    setState((state: BankRepliesState) => {
        const shouldBeExpanded = !state.expandedBankIds.some(id => id === bankId);
        const expandedBankIds = shouldBeExpanded
            ? state.expandedBankIds.concat(bankId)
            : state.expandedBankIds.filter(id => id !== bankId);
        const bankReply = state.bankReplies.find(br => br.bank.id === bankId);
        if (shouldBeExpanded && bankReply && !bankReply.reportHistories) {
            loadHistory(props, setState, forceUpdate, bankId);

            return {
                ...state,
                expandedBankIds,
                isLoading: true,
            };
        }

        return {
            ...state,
            expandedBankIds,
            isLoading: false
        };
    });
}

function loadHistory(props: BankRepliesProps, setState: React.Dispatch<SetStateAction<BankRepliesState>>, forceUpdate: ForceUpdateFunction, bankId: number) {
    getBankReportHistory(props.personId, props.loanId, bankId).then((results: any) => {
        setState((state) => {
            const bankReply = state.bankReplies.find(br => br.bank.id === bankId);
            if (bankReply) {
                bankReply.reportHistories = results.data;
            }
            return {
                ...state,
                isLoading: false
            }
        });
        forceUpdate();
    });
}

function compareBanks(bank1: Bank, bank2: Bank): number {
    return bank1.name.localeCompare(bank2.name);
}

function renderBankRows(props: BankRepliesProps, state: BankRepliesState, setState: React.Dispatch<SetStateAction<BankRepliesState>>, forceUpdate: ForceUpdateFunction, onToggleExpanded: (bankId: number, event: MouseEvent) => void): ReactNode[] {
    const rows: ReactNode[] = [];
    state.bankReplies.forEach(bankReply => {
        rows.push(renderBankRow(props, state, setState, bankReply, onToggleExpanded));
        if (state.expandedBankIds.some(id => bankReply.bank.id === id)) {
            rows.push(renderExpansionRow(props, state, setState, forceUpdate, bankReply));
        }
    })
    return (rows);
}

function renderTransactionAmount(props: BankRepliesProps, transaction: Transaction | undefined, countryId: number) {
    return transaction === undefined || transaction.amount === undefined || transaction.amount === null ?
        <td /> :
        <td>
            {isAdmin(props) && transaction.bankStatus === BankStatus.Paid && !!transaction.clawback && <>
                <FontAwesomeIcon style={{color: '#FF9900', marginRight: '8px'}} icon={faReply}/>
            </>}
            <span>{transaction.amount.toLocaleString()} {getCurrencySymbolForCountryId(countryId)}</span>
        </td>;
}

function renderTransactionInterest(transaction: Transaction | undefined) {
    return transaction === undefined || transaction.nominalInterest === undefined || transaction.nominalInterest === null
        ? <td />
        : <td><span>{transaction.nominalInterest} %</span></td>;
}

function renderStatus(transaction?: Transaction): ReactNode {
    if (transaction === undefined) {
        return (<td />);
    }

    let statusClassName = 'reply-status ';
    switch (transaction.bankStatus) {
        case BankStatus.Investigation:
            statusClassName += 'status-gray';
            break;
        case BankStatus.Granted:
            statusClassName += 'status-blue';
            break;
        case BankStatus.GrantedSelected:
            statusClassName += 'status-orange';
            break;
        case BankStatus.Sent:
            statusClassName += 'status-turquoise';
            break;
        case BankStatus.Paid:
            statusClassName += 'status-green';
            break;
        case BankStatus.Denied:
            statusClassName += 'status-red';
            break;
        default:
            return (<td />);
    }

    return (
        <td className={statusClassName}>
            <span>
                <Translate id={getEnumTranslationKey(BankStatus, transaction.bankStatus, 'BANK_STATUS', 'ALL')} />
            </span>
        </td>
    );
}

function renderContinuationUrl(transaction: Transaction | undefined) {
    if (!transaction || !transaction.continuationUrl) {
        return (<td />);
    }

    return (
        <td>
            <CopyToClipboardButton content={transaction.continuationUrl!} className="continuation-url" key="continuation-url">
                <Tooltip content={<Translate id="CONTINUATION_URL" data={{url: transaction.continuationUrl}}/>}>
                    <a href={transaction.continuationUrl!} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faExternalLinkAlt} style={{marginRight: '4px'}}/>
                    </a>
                </Tooltip>
            </CopyToClipboardButton>
        </td>
    );
}

function createEmptyTransaction(bankId: number): Transaction {
    return {
        id: null,
        bankId,
        bankStatus: BankStatus.Investigation,
        isManually: true,
        isProcessed: true,
        isBankReportProcessed: true,
        modifiedOn: new Date(),
        refinances: []
    };
}

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer
});

export default connect<UserProps>(mapStateToProps)(BankReplies);
