import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {FunctionComponent, ReactNode, SetStateAction, useState} from 'react';
import Autosuggest, {
    ChangeEvent,
    RenderSuggestionParams,
    RenderSuggestionsContainerParams,
    SuggestionSelectedEventData,
    SuggestionsFetchRequestedParams
} from 'react-autosuggest';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { GlobalSearchActionCreators } from '../actions/GlobalSearchActionCreators';
import { ApplicantSearchResult } from '../models/ApplicantSearchResult';
import { ApplicationSearchResult } from '../models/ApplicationSearchResult';
import './autosuggest-theme.css';
import {NavigateFunction, useNavigate} from "react-router";

interface GlobalSearchReduxProps {
    searchResultData: {
        applicants: ApplicantSearchResult[];
        applications: ApplicationSearchResult[];
    };
}

interface GlobalSearchDispatchProps {
    searchApplicantsAndApplications: (query: string) => void;
}

type GlobalSearchProps = GlobalSearchReduxProps & GlobalSearchDispatchProps & LocalizeContextProps;

interface GlobalSearchState {
    suggestions: any[];
    value: string;
}

const GlobalSearch: FunctionComponent<GlobalSearchProps> = (props) => {
    const [state, setState] = useState<GlobalSearchState>({suggestions: [], value: ''});
    const [timeoutFn, setTimeoutFn] = useState<NodeJS.Timeout>();
    const navigate = useNavigate();

    const searchPlaceHolder = props.translate('HEADER.SEARCH_PLACEHOLDER').toString();

    const inputProps = {
        onChange: (_, params: ChangeEvent) => setState({...state, value: params.newValue}),
        placeholder: searchPlaceHolder,
        value: state.value
    };

    const suggestions: any = [
        {
            title: 'HEADER.CUSTOMERS',
            suggestions: (props.searchResultData && props.searchResultData.applicants) || []
        },
        {
            title: 'HEADER.APPLICATIONS',
            suggestions: (props.searchResultData && props.searchResultData.applications) || []
        }
    ];

    return (
        <Autosuggest
            multiSection={true}
            renderSectionTitle={renderSectionTitle}
            getSectionSuggestions={getSectionSuggestions}
            suggestions={suggestions}
            shouldRenderSuggestions={shouldRenderSuggestions}
            onSuggestionSelected={(_, data) => onSuggestionSelected(data, setState, navigate)}
            onSuggestionsFetchRequested={req => onSuggestionsFetchRequested(props, req, timeoutFn, setTimeoutFn)}
            onSuggestionsClearRequested={() => setState((state) => ({ ...state, suggestions: [] }))}
            getSuggestionValue={() => state.value}
            renderSuggestionsContainer={renderSuggestionsContainer}
            renderSuggestion={renderSuggestion}
            renderInputComponent={renderInputComponent}
            inputProps={inputProps}
        />
    );
}

function renderSectionTitle(section: any) {
    return (<strong><Translate id={section.title} /></strong>);
}

function getSectionSuggestions(section: any) {
    return section.suggestions;
}

function onSuggestionSelected(
    data: SuggestionSelectedEventData<ApplicantSearchResult & ApplicationSearchResult>,
    setState: React.Dispatch<SetStateAction<GlobalSearchState>>,
    navigate: NavigateFunction
): void {
    const currentSuggestion = data.suggestion;
    setState((state) => ({ ...state, value: '' }));
    if (currentSuggestion.applicationId) {
        navigate(`/applicant/${currentSuggestion.personId}/application/${currentSuggestion.applicationId}`);
    } else {
        navigate(`/applicant/${currentSuggestion.personId}`);
    }
}

function onSuggestionsFetchRequested(
    props: GlobalSearchProps, 
    request: SuggestionsFetchRequestedParams, 
    timeoutFn: NodeJS.Timeout | undefined,
    setTimeoutFn: React.Dispatch<SetStateAction<NodeJS.Timeout | undefined>>
): void {
    const searchApplicantsAndApplications = props.searchApplicantsAndApplications;
    if (request.reason === 'input-changed' && searchApplicantsAndApplications) {

        if (timeoutFn) {
            clearTimeout(timeoutFn);
        }

        setTimeoutFn(setTimeout(() => searchApplicantsAndApplications(request.value), 300));
    }
}

function shouldRenderSuggestions(value: string): boolean {
    return value.trim().length > 2;
}

function renderSuggestionsContainer(params: RenderSuggestionsContainerParams): ReactNode {
    return (
        <div {...params.containerProps}>
            <div className="suggestion-wrapper global-search-wrapper">{params.children}</div>
        </div>
    );
}

function renderSuggestion(suggestion: ApplicantSearchResult & ApplicationSearchResult, params: RenderSuggestionParams): ReactNode {
    if (suggestion.applicationId) {
        return (
            <div>
                <div>{suggestion.applicationId}</div>
                <div><small>{suggestion.firstName} {suggestion.lastName}</small></div>
            </div>
        );
    } else {
        return (
            <div>
                <div>{suggestion.firstName} {suggestion.lastName}</div>
                <div><small>{suggestion.socialSecurityNumber}</small></div>
            </div>
        );
    }
}

function renderInputComponent(inputProps: any): ReactNode {
    inputProps.className = inputProps.className + ' form-control customized';

    return (
        <div className="input-group global-search">
            <div className="input-group-prepend">
                <div className="input-group-text customized">
                    <FontAwesomeIcon icon={faSearch} />
                </div>
            </div>
            <input {...inputProps} />
        </div>
    );
}

export default connect<GlobalSearchReduxProps, GlobalSearchDispatchProps, {}, any>(
    (state: any) => (state.globalSearchActionReducer),
    (dispatch: Dispatch) => bindActionCreators(GlobalSearchActionCreators, dispatch)
)(withLocalize(GlobalSearch));
