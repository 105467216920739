import React, {FunctionComponent, PropsWithChildren, ReactNode} from "react";
import {Translate} from "react-localize-redux";

export interface ReadonlyCustomInputFieldProps {
    descriptionKey?: string;
    description?: ReactNode | string;
}

const ReadonlyCustomInputField: FunctionComponent<PropsWithChildren<ReadonlyCustomInputFieldProps>> = (props) => {

    return(
        <React.Fragment>
            <div className="value">{props.children}</div>
            {renderDescription(props)}
        </React.Fragment>
    );
};

function renderDescription(props: ReadonlyCustomInputFieldProps) {
    if (props.description) {
        return <div className="description">{props.description}</div>;
    }

    if (props.descriptionKey) {
        return <div className="description"><Translate id={props.descriptionKey} /></div>;
    }

    return null;
}
export default ReadonlyCustomInputField;