import React, { ReactNode } from 'react';
import { PaginationItemDescriptor } from '../models/PaginationItemDescriptor';

export const mapPaginationItemDescriptorToReactNode = (
    item: PaginationItemDescriptor,
    clickAction: ((index: number) => void) | undefined,
    keyPrefix: string): ReactNode => {
    const clickHandler = item.isClickable ? () => clickAction && clickAction(item.number) : undefined;

    if (item.isClickable === false && item.isActive === false) {
        return (
            <li key={`${keyPrefix}-${item.number}`} className="inactive">&hellip;</li>
        );
    }

    const classNameActive = item.isActive ? 'active' : '';

    return (
        <li key={`${keyPrefix}-${item.number}`} className={classNameActive} onClick={clickHandler}>
            {item.number + 1}
        </li>
    );
};
