import React, {FunctionComponent, ReactNode, SetStateAction, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {LocalizeContextProps, Translate, withLocalize} from 'react-localize-redux';
import {SettingsProps} from '../../../common/interfaces/SettingsProps';
import {SubstatusesProps} from '../../../common/interfaces/SubstatusesProps';
import {MetadataProps} from '../../../common/interfaces/MetadataProps';
import {SetApplicationTitleActionProps} from '../../../common/interfaces/SetApplicationTitleActionProps';
import {ApplicantDataDispatchProps, ApplicantDataStateProps} from '../models/ApplicantDataProps';
import {bindActionCreators, Dispatch} from 'redux';
import GlobalSearch from '../../../common/components/GlobalSearch';
import {ProductType} from '../../../common/models/ProductType';
import MetadataActionsCreator from '../actions/MetadataActionsCreator';
import './applicant-view.css';
import ApplicantInformation from './ApplicantInformation';
import PropertyInformation from './PropertyInformation';
import PropertyInformationModal from './PropertiesInfomationModal/PropertiesInfomationModal'
import Application, {ApplicationManageableData} from './Application';
import BankReplies from './bank-replies/BankReplies';
import Household from './Household';
import LoanPicker from './LoanPicker';
import Loans from './Loans';
import LogsAndComments from './logs-and-comments/LogsAndComments';
import ProductTabs from './ProductTabs';
import ApplicationActions from './ApplicationActions';
import {LoanStatus} from '../../../common/models/LoanStatus';
import {denyApplication as denyApplicationAPI} from '../api/denyApplication';
import {rejectOpenTransactions as rejectOpenTransactionsAPI} from '../api/rejectOpenTransactions';
import {Button, Modal} from 'react-bootstrap';
import {putOnBlacklist} from '../api/putOnBlacklist';
import {removeFromBlacklist} from '../api/removeFromBlacklist';
import {storeTransaction} from '../api/storeTransaction';
import {addAutomaticBankReport as addAutomaticBankReportAPI} from '../api/addAutomaticBankReport';
import {retryBankFeedback} from '../api/retryBankFeedback';
import {skipBankFeedback} from '../api/skipBankFeedback';
import DateTimeFormatter, {DateTimeFormat} from '../../../common/components/DateTimeFormatter';
import {gdprRemovePersonalInformation} from '../api/gdprRemovePersonalInformation';
import {resetResponseCode} from '../api/resetResponseCode';
import {showToastMessage} from '../../../common/actions/ToastMessagesActionCreator';
import {CommandResult} from '../../../common/helpers/CommandResult';
import {getTranslationKey} from '../../../common/helpers/getTranslationKey';
import {SetTitleActionCreator} from '../../../common/actions/SetTitleActionCreator';
import {
    getCreditScoreRules,
    getDebtInformationUpdateLink,
    getIncompleteFormLink,
    getMainBackgroundColorStyle,
    getMortgageCalculatorOptions,
    getMyApplicationUrl
} from '../../../common/helpers/settingsHelpers';
import ConfirmationModal from '../../../common/components/ConfirmationModal';
import {ConsumerLoanApplicant} from '../models/ConsumerLoanApplicant';
import SubstatusesActionsCreator from '../../applicants/actions/SubstatusesActionsCreator';
import {isApplicationReadOnly} from '../helpers/transactionsLogic';
import {TransactionCommand} from '../models/TransactionCommand';
import ApplicationDataActionsCreator from '../actions/applicantDataActionsCreator';
import InformationModal from '../../../common/components/InformationModal';
import SendSmsModal from '../../../common/components/SendSmsModal';
import SendEmailModal from '../../../common/components/SendEmailModal';
import {putApplicantPrimaryLanguge} from '../api/putApplicantPrimaryLanguge';
import {putFollowUpDate} from '../api/putFollowUpDate';
import SendEmailAndSmsModal from '../../../common/components/SendEmailAndSmsModal';
import {getCoApplicantApplications} from '../api/getCoApplicantApplications';
import {UserProps} from '../../../common/interfaces/UserProps';
import {Role} from '../../../common/models/Role';
import {hideApplication as hideApplicationAPI} from '../api/hideApplication';
import {unhideApplication as unhideApplicationAPI} from '../api/unhideApplication';
import {deleteApplication as deleteApplicationAPI} from '../api/deleteApplication';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {revokeMarketingConsents as revokeMarketingConsentsAPI} from '../api/revokeMarketingConsents';
import {grantMarketingConsents as grantMarketingConsentsAPI} from '../api/grantMarketingConsents';
import {MarketingConsent} from '../../applicants/models/MarketingConsent';
import MortgageCalculatorModal from './MortgageCalculatorModal/MortgageCalculatorModal';
import {updatePaymentDate} from "../api/updatePaymentDate";
import {isLimitedCaseWorker} from "../../../common/helpers/limitedCaseWorkerLogic";
import {NavigateFunction, Params, useNavigate, useParams} from "react-router";
import {sendCreditInformationEnrichmentRequest as sendCreditInformationEnrichmentRequestAPI} from '../api/sendCreditInformationEnrichmentRequest';
import {sendPropertyInformationEnrichmentRequest as sendPropertyInformationEnrichmentRequestAPI} from '../api/sendPropertyInformationEnrichmentRequest'
import CustomerAdvisorInformation from './CustomerAdvisorInformation';
import SubstatusesInputField from "../../../common/components/input-fields/SubstatusesInputField";
import {putSubstatuses} from "../api/putSubstatuses";
import { useUpdatedApplicationNotification } from '../helpers/useUpdatedApplicationNotification';
import { useUpdatedTransactionNotification } from '../helpers/useUpdatedTransactionNotification';
import { useUpdatedPropertiesNotification } from '../helpers/useUpdatedPropertiesNotification';
import { BankStatus } from '../../../common/models/BankStatus';

export interface BankRepliesActions {
    addAutomaticBankReport: (bankIds: number[], actionKey: string) => void;
    skipBankFeedback: (transactionId: number) => void;
    retryBankFeedback: (transactionId: number) => void;
    resetResponseCode: (transactionId: number) => void;
}

type ApplicantViewRouteParams = Record<'applicantId' | 'applicationId', string>

// improve on those interfaces
interface ApplicantViewProps extends LocalizeContextProps, SettingsProps, MetadataProps, ApplicantDataStateProps,
    ApplicantDataDispatchProps, SubstatusesProps, UserProps {
    selectedLoanId?: string;
    loadSubstatuses: () => void;
    showToastMessage: typeof showToastMessage;
}

interface ApplicantViewState {
    componentsCanEnterEdit: boolean;
    showDenyApplicationModel: boolean;
    showRejectOpenTransactionsModal: boolean;
    showConfirmBlacklistModal: boolean;
    showConfirmClearBlacklistModal: boolean;
    showGDPRRemoveApplicantModal: boolean;
    showGDPRRemoveCoApplicantModal: boolean;
    showDeleteApplicationModal: boolean;
    gdprSkipCheckConfirmationModalSsn: string | null;
    showApplicationIsReadOnly: boolean;
    showSendSmsToApplicantModal: boolean;
    showSendSmsToCoApplicantModal: boolean;
    selectedApplicantMobileNumber: string;
    selectedCoApplicantMobileNumber: string;
    showSendEmailToApplicantModal: boolean;
    showSendEmailToCoApplicantModal: boolean;
    showSendEmailAndSmsToApplicantModal: boolean;
    showSendEmailAndSmsToCoApplicantModal: boolean;
    showCollectCreditInformationModal: boolean;
    showCollectPropertyInformationModal: boolean;
    showPropertiesInformationModal: boolean;
    showMortgageCalculator: boolean;
    selectedApplicantEmail: string;
    selectedCoApplicantEmail: string;
    blacklistLength?: number;
    canNavigateWithoutConfirmationComments: boolean;
    canNavigateWithoutConfirmationBankReplies: boolean;
    confirmNavigationModal: boolean;
    consumerLoanRefresh: boolean;
}

const ApplicantView: FunctionComponent<ApplicantViewProps & SetApplicationTitleActionProps> = (props) => {
    const params = useParams<ApplicantViewRouteParams>();
    const navigate = useNavigate();
    let previousPersonId = useRef<number | undefined>();
    const [state, setState] = useState<ApplicantViewState>({
        componentsCanEnterEdit: true,
        showDenyApplicationModel: false,
        showRejectOpenTransactionsModal: false,
        showConfirmBlacklistModal: false,
        showConfirmClearBlacklistModal: false,
        showGDPRRemoveApplicantModal: false,
        showGDPRRemoveCoApplicantModal: false,
        showDeleteApplicationModal: false,
        gdprSkipCheckConfirmationModalSsn: null,
        showApplicationIsReadOnly: false,
        confirmNavigationModal: false,
        showSendSmsToApplicantModal: false,
        showSendSmsToCoApplicantModal: false,
        selectedApplicantMobileNumber: '',
        selectedCoApplicantMobileNumber: '',
        selectedApplicantEmail: '',
        selectedCoApplicantEmail: '',
        showSendEmailToApplicantModal: false,
        showSendEmailToCoApplicantModal: false,
        showSendEmailAndSmsToApplicantModal: false,
        showSendEmailAndSmsToCoApplicantModal: false,
        showCollectCreditInformationModal: false,
        showCollectPropertyInformationModal: false,
        showPropertiesInformationModal: false,
        showMortgageCalculator: false,
        canNavigateWithoutConfirmationComments: true,
        canNavigateWithoutConfirmationBankReplies: true,
        consumerLoanRefresh: false
    });

    useEffect(() => {
        if (props.substatuses === undefined) {
            props.loadSubstatuses();
        }
        const currentPersonId = getPersonId(params);
        const currentLoanId = params.applicationId ? getApplicationId(params) : undefined;

        props.loadApplications(currentPersonId).then((apps) => {
            if (apps.length > 0) {
                // if first enter ever or coming back from edit (otherwise DidUpdate will load)
                if (!props.currentApplication || props.currentApplication.id === currentLoanId) {
                    props.loadCurrentApplication(currentPersonId, currentLoanId || apps[0].id);
                }

                // navigated from other view by personId only
                if (currentLoanId === undefined) {
                    navigate(`/applicant/${currentPersonId}/application/${apps[0].id}`);
                }
            } else {
                switchToMainApplicant(props, navigate, currentPersonId);
            }
        });

        if (props.userData.user && props.userData.user.insurances) {
            props.loanInsurances(currentPersonId);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.currentApplication !== undefined) {
            if (!props.currentApplication.applicant.lastName && !props.currentApplication.applicant.firstName) {
                props.setTitle(`Applicant ${props.currentApplication.applicant.socialSecurityNumber}`);
            } else {
                props.setTitle(`Applicant ${props.currentApplication.applicant.lastName || ''},  ${props.currentApplication.applicant.firstName || ''} `);
            }
        }

        const currentPersonId = getPersonId(params);
        const currentLoanId = params.applicationId ? getApplicationId(params) : undefined;

        if (currentPersonId !== undefined) {
            // navigation inside applicant view to different person
            if (previousPersonId.current !== undefined && previousPersonId.current !== currentPersonId) {
                props.loadApplications(currentPersonId).then((apps) => {
                    if (apps.length > 0) {
                        navigate(`/applicant/${currentPersonId}/application/${apps[0].id}`, { replace: true });
                    } else {
                        switchToMainApplicant(props, navigate, currentPersonId);
                    }
                });
                // navigation inside applicant view to different application (or person switched in previous "if" and DidUpdate called again)
            } else if (currentLoanId !== undefined && (props.currentApplication && props.currentApplication.id !== currentLoanId)) {
                props.loadCurrentApplication(currentPersonId, currentLoanId);
            }
        }
        previousPersonId.current = params.applicantId ? parseFloat(params.applicantId) : undefined;
    }, [params.applicationId, params.applicantId, props.currentApplication]);  // eslint-disable-line react-hooks/exhaustive-deps

    useUpdatedApplicationNotification((notification) => {
        if(currentLoanId && currentLoanId === notification.applicationId
            && props.userData.user && (notification.username === null || props.userData.user.username === notification.username)) {
            props.loadCurrentApplication(getPersonId(params), currentLoanId);
        }
    });

    const loadScheduled = useRef<boolean>(false);
    useUpdatedTransactionNotification((notification) => {
        if(!loadScheduled.current && currentLoanId && currentLoanId === notification.applicationId
            && props.userData.user && (notification.username === null || props.userData.user.username === notification.username)) {

            setTimeout(() => {
                props.loadCurrentApplication(getPersonId(params), currentLoanId);
                loadScheduled.current = false; // not in promise by purpose - if new notification comes in middle of loading it should case new data refresh
            }, 800);
            loadScheduled.current = true;
        }
    });

    useUpdatedPropertiesNotification((notification) => {
        if(currentLoanId && props.currentApplication && (notification.socialSecurityNumbers.includes(props.currentApplication.applicant.socialSecurityNumber)
            || (props.currentApplication.coApplicant && notification.socialSecurityNumbers.includes(props.currentApplication.coApplicant.socialSecurityNumber))))
        {
            props.loadCurrentApplication(getPersonId(params), currentLoanId);
        }
    }, [props.currentApplication?.applicant?.socialSecurityNumber, props.currentApplication?.coApplicant?.socialSecurityNumber]);

    let disableEditing = false;
    const currentLoanId = params.applicationId ? getApplicationId(params) : undefined;

    if (props.metadata.isLoaded === false || props.applications.length === 0
        || props.currentApplication === undefined || currentLoanId === undefined
        || (props.currentApplication.id !== currentLoanId)) {
        return <main className="main-content" style={getMainBackgroundColorStyle(props)} />;
    } else if (props.currentApplication.id !== currentLoanId) {
        props.loadCurrentApplication(getPersonId(params), currentLoanId);
    }

    if (isWaitingForCreditScore(props)) {
        disableEditing = true;
    }

    const renderApplicantInformation = (numberOfLoans: number): ReactNode => {
        const applicantInfos: ReactNode[] = [];

        if (props.currentApplication) {
            if (props.currentApplication.applicant) {
                applicantInfos.push(
                    <ApplicantInformation
                        {...props}
                        applicant={props.currentApplication.applicant}
                        key="applicant-1"
                        countryId={props.currentApplication.applicant.countryId}
                        onClick={() => goToEditMode(props, state, setState, navigate, params)}
                        onPhoneNumberClick={phoneNumber => showSendSmsToApplicantModal(setState, phoneNumber)}
                        onEmailClick={email => showSendEmailToApplicantModal(setState, email)}
                        onMessageClick={() => showSendEmailAndSmsToApplicantModal(setState)}
                        loanStatus={props.currentApplication.loanStatus}
                        useCreditScoreSettings={getCreditScoreRules(props) !== undefined}
                        applicationPublicId={props.currentApplication.details.publicId}
                        productType={props.currentApplication.details.productType}
                        sourceId={props.currentApplication.details.sourceId}
                    />
                );
            }

            if (props.currentApplication.coApplicant) {
                applicantInfos.push(
                    <ApplicantInformation
                        {...props}
                        applicant={props.currentApplication.coApplicant}
                        key="applicant-2"
                        countryId={props.currentApplication.applicant.countryId}
                        isCoApplicant={true}
                        onClick={() => goToEditMode(props, state, setState, navigate, params)}
                        onPhoneNumberClick={phoneNumber => showSendSmsToCoApplicantModal(setState, phoneNumber)}
                        onEmailClick={email => showSendEmailToCoApplicantModal(setState, email)}
                        onMessageClick={() => showSendEmailAndSmsToCoApplicantModal(setState)}
                    />
                );
            }

            applicantInfos.push(
                <PropertyInformation
                    property={props.currentApplication.property}
                    applicant={props.currentApplication.applicant}
                    coApplicant={props.currentApplication.coApplicant}
                    loanDetails={props.currentApplication.details}
                    key="property-estimatation"
                    onClick={() => showPropertiesInformationModal(setState)}
                    redemptions={props.currentApplication.redemptions}
                    numberOfLoans={numberOfLoans}
                />
            );
        }

        return (<div style={{ display: 'flex', width: '100%' }}>{applicantInfos}</div>);
    };

    const renderBlacklistInfo = () => {
        if (props.currentApplication && props.currentApplication.isBlacklisted) {
            return (
                <span className="blacklist-info">
                    {props.currentApplication.blacklistExpireDate &&
                        (<>
                            <span style={{ marginRight: '3px' }}><Translate id="BLACKLISTED_UNTIL" /></span>
                            <DateTimeFormatter date={props.currentApplication.blacklistExpireDate} format={DateTimeFormat.Date} />
                        </>)
                    }
                    {!props.currentApplication.blacklistExpireDate &&
                        <Translate id="BLACKLISTED_PERMANENTLY" />
                    }
                </span>
            );
        }

        return (<span />);
    };

    if (props.currentApplication !== undefined && props.currentApplication.loanStatus === LoanStatus.Investigation) {
        const creditScoreRules = getCreditScoreRules(props);
        if (creditScoreRules !== undefined && props.currentApplication.applicant.creditScore === undefined) {
            return null;
        }
    }

    const hasInsuranceAccess = props.userData.user ? props.userData.user.insurances : false;

    const filteredApplications = props.applications.filter((x) => x.productType === getSelectedProductType(props));

    return (
        <main className="main-content" style={getMainBackgroundColorStyle(props)}>
            <div className="jumbotron text-center bg-white flex-column applicant-view">
                <div className="top-panel">
                    <div>
                        {renderApplicantInformation(filteredApplications.length)}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <GlobalSearch />
                                {renderApplicationActionsMenu(props, state, setState, navigate, params)}
                            </div>
                            {!isLimitedCaseWorker(props) &&
                                <div style={{ textAlign: 'right', marginTop: '20px' }}>{renderBlacklistInfo()}</div>
                            }
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                        <ProductTabs
                            selectedProduct={getSelectedProduct(props)}
                            otherLoans={props.applications}
                            onSelectionChanged={productType => onProductTypePicked(props, params, navigate, productType)}
                            showInsuranceTab={hasInsuranceAccess}
                            hasInurances={props.insurances.length > 0}
                            scopeSourceId={props.userData.user && props.userData.user.applicationSourceId}
                        />
                        <CustomerAdvisorInformation
                            customerId={getPersonId(params)}
                            customerAdvisorId={props.currentApplication.applicant.customerAdvisorId}
                            refresh={() => refreshData(props, params)}
                        />
                        <div className="substatuses">{renderSubstatuses(props, params)}</div>
                        <LoanPicker
                            loans={filteredApplications}
                            onLoanPicked={loanId => onLoanPicked(params, navigate, loanId)}
                            selectedLoanId={currentLoanId}
                            countLimit={props.currentApplication.coApplicant && props.currentApplication.property ? 3 : undefined}
                            scopeSourceId={props.userData.user && props.userData.user.applicationSourceId}
                        />
                    </div>
                </div>
                <div className="content-panel">{renderApplicationContent(props, state, setState, navigate, params, isReadonly(props) || disableEditing)}</div>
            </div>
        </main>
    );
}

function allowedToEdit(props: ApplicantViewProps): boolean {
    if (!props.userData || props.userData.user === undefined || props.userData.user.role === Role.Unknown) {
        return false;
    }

    if (props.userData.user.role === Role.CaseWorker) {
        if (!isLimitedCaseWorker(props)) {
            return true;
        }

        return props.currentApplication !== undefined
            && isLimitedCaseWorker(props)
            && (props.userData.user.advertisementId === null || props.currentApplication.details.advertisementId === props.userData.user.advertisementId)
            && props.currentApplication.details.sourceId === props.userData.user.applicationSourceId;
    }

    return true;
}

function renderApplicationActionsMenu(props: ApplicantViewProps, state: ApplicantViewState, setState: React.Dispatch<SetStateAction<ApplicantViewState>>, navigate: NavigateFunction, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    if (!props.currentApplication) {
        return null;
    }

    const allowDeny = props.currentApplication.loanStatus !== LoanStatus.Denied && props.currentApplication.loanStatus !== LoanStatus.Paid;
    const allowRejectOpenTransactions = props.currentApplication.loanStatus === LoanStatus.Paid
        && props.currentApplication.transactions.some(t => t.bankStatus === BankStatus.Granted || t.bankStatus === BankStatus.GrantedSelected || t.bankStatus === BankStatus.Sent || t.bankStatus === BankStatus.Investigation)

    return (
        <ApplicationActions
            disabled={!state.componentsCanEnterEdit || isReadonly(props)}
            canRevokeEmailMarketingConsent={props.currentApplication.applicant.emailMarketingConsent}
            canRevokeSmsMarketingConsent={props.currentApplication.applicant.mobileMarketingConsent}
            canBeDeleted={props.currentApplication.transactions.length === 0}
            showDenyOption={allowDeny}
            showRejectOpenTransactionsOption={allowRejectOpenTransactions}
            showMortgageCalculatorOption={props.currentApplication.details.productType === ProductType.Mortgage && !!getMortgageCalculatorOptions(props)}
            hasSocialSecurityNumber={!!props.currentApplication.applicant.socialSecurityNumber}
            hasProperyInformationSystemIntegration={props.currentApplication.applicant.countryId === 1}
            sendCreditInformationEnrichmentRequest={() => showCollectCreditInformationModal(setState)}
            sendPropertyInformationEnrichmentRequest={() => showCollectPropertyInformationModal(setState)}
            putOnBlacklist={days => showPutOnBlackListModal(setState, days)}
            putOffBlacklist={() => showPutOffBlackListModal(setState)}
            copyApplication={() => copyApplication(navigate, params)}
            denyApplication={() => showDenyApplicationModal(setState)}
            rejectOpenTransactions={() => showRejectOpenTransactionsModal(setState)}
            hideApplication={() => hideApplication(props, params)}
            unhideApplication={() => unhideApplication(props, params)}
            gdprRemoveApplicant={() => showGDPRRemoveApplicantModal(setState)}
            gdprRemoveCoApplicant={() => showGDPRRemoveCoApplicantModal(setState)}
            deleteApplication={() => showDeleteApplicationModal(setState)}
            isBlackListed={props.currentApplication && props.currentApplication.isBlacklisted}
            isHidden={props.currentApplication.details.hidden}
            hasCoApplicant={!!props.currentApplication.coApplicant}
            revokeMarketingConsents={particular => revokeMarketingConsents(props, params, particular)}
            grantMarketingConsents={particular => grantMarketingConsents(props, params, particular)}
            openMortgageCalculator={() => showMortgageCalculator(setState)}
        />
    );
}

function renderApplicationContent(props: ApplicantViewProps, state: ApplicantViewState, setState: React.Dispatch<SetStateAction<ApplicantViewState>>, navigate: NavigateFunction, params: Partial<Params<keyof ApplicantViewRouteParams>>, disableEditing: boolean) {
    const currentLoanId = getApplicationId(params);
    const currentPersonId = getPersonId(params);
    const blackListMessage = state.blacklistLength
        ? props.translate('BLACKLIST_APPLICANT_CONFIRMATION', { blacklistLengthPlaceHolder: state.blacklistLength }).toString()
        : props.translate('PERMANENT_BLACKLIST_APPLICANT_CONFIRMATION').toString();
    const incompleteFormLink = props.currentApplication !== undefined && props.currentApplication.details.productType !== null
        ? getIncompleteFormLink(props,
            props.currentApplication.applicant.primaryLanguage,
            props.currentApplication.details.publicId,
            props.currentApplication.details.productType,
            props.currentApplication.details.sourceId) || ''
        : '';

    const myApplicationLink = props.currentApplication !== undefined
        ? getMyApplicationUrl(props,
            props.currentApplication.details.sourceId,
            props.currentApplication.details.publicId)
        : '';

    const debtInformationUpdateLink = props.currentApplication !== undefined
            ? getDebtInformationUpdateLink(props, props.currentApplication.details.publicId)
            : '';

    if (!props.currentApplication) {
        return null;
    }

    return (
        <React.Fragment>
            <div>
                <Application
                    application={props.currentApplication}
                    advertisementSites={props.metadata.advertisementSites}
                    applicationSources={props.metadata.applicationSources}
                    countryId={props.currentApplication.applicant.countryId}
                    onClick={() => goToEditMode(props, state, setState, navigate, params)}
                    canEnterEdit={state.componentsCanEnterEdit && !disableEditing}
                    enteredEditCallBack={() => disableEditingForComponents(setState)}
                    exitedEditCallBack={() => enableEditingForComponents(props, setState, params)}
                    saveCallBack={data => updateApplicantManageableData(props, params, data)}
                    onRefresh={() => refreshConsumerLoanData(props, setState, params)}
                />
                <Household
                    applicant={props.currentApplication.applicant}
                    coApplicant={props.currentApplication.coApplicant}
                    countryId={props.currentApplication.applicant.countryId}
                    onClick={() => goToEditMode(props, state, setState, navigate, params)}
                />
                <Loans
                    redemptions={props.currentApplication.redemptions}
                    countryId={props.currentApplication.applicant.countryId}
                    onClick={() => goToEditMode(props, state, setState, navigate, params)}
                />
            </div>
            <div>
                <BankReplies
                    personId={currentPersonId}
                    loanId={currentLoanId}
                    countryId={props.currentApplication.applicant.countryId}
                    banks={props.metadata.banks}
                    bankReplies={props.currentApplication.transactions}
                    productType={props.currentApplication.details.productType}
                    application={props.currentApplication}
                    canEnterEdit={state.componentsCanEnterEdit && !disableEditing}
                    enteredEditCallBack={() => disableEditingForComponents(setState)}
                    exitedEditCallBack={() => enableEditingForComponents(props, setState, params)}
                    saveCallBack={transactionCommand => saveTransaction(props, params, transactionCommand)}
                    addAutomaticBankReport={(bankIds, transactionKey) => addAutomaticBankReport(props, params, bankIds, transactionKey)}
                    skipBankFeedback={transactionId => skipBankFeedbackCallback(props, params, transactionId)}
                    retryBankFeedback={transactionId => retryBankFeedbackCallback(props, params, transactionId)}
                    resetResponseCode={transactionId => resetResponseCodeCallback(props, params, transactionId)}
                    followUpDate={props.currentApplication.details.followUpDate || null}
                    onFormInputChanged={isEmpty => onBankRepliesNotesChange(setState, isEmpty)}
                    onRefresh={() => refreshConsumerLoanData(props, setState, params)}
                    isRefreshing={state.consumerLoanRefresh}
                />
            </div>
            <div>
                <LogsAndComments
                    personId={currentPersonId}
                    loanId={currentLoanId}
                    includeCoApplicant={props.currentApplication.coApplicant !== null}
                    translate={key => translate(props, key)}
                    showToastMessage={props.showToastMessage}
                    onFormInputChanged={isEmpty => onCommentInputChange(setState, isEmpty)}
                />
            </div>

            <Modal show={state.showConfirmBlacklistModal} centered={true}>
                <Modal.Header>
                    <Modal.Title>
                        <Translate id="BLACKLIST_APPLICANT" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {blackListMessage}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => blacklistApplicant(props, state, setState, params)}>Confirm</Button>
                    <Button variant="secondary" onClick={() => hidePutOnBlackListModal(setState)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={state.showConfirmClearBlacklistModal} centered={true}>
                <Modal.Header>
                    <Modal.Title>
                        <Translate id="REMOVE_BLACKLISTING" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Translate id="REMOVE_BLACKLISTING_CONFIRMATION" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => clearBlacklistApplicant(props, setState, params)}>Confirm</Button>
                    <Button variant="secondary" onClick={() => hidePutOffBlackListModal(setState)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={state.showDenyApplicationModel} centered={true}>
                <Modal.Header>
                    <Modal.Title>
                        <Translate id="DENY_APPLICATION" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Translate id="DENY_APPLICATION_CONFIRMATION" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => denyApplication(props, setState, params)}>Confirm</Button>
                    <Button variant="secondary" onClick={() => hideDenyApplicationModal(setState)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={state.showRejectOpenTransactionsModal} centered={true}>
                <Modal.Header>
                    <Modal.Title>
                        <Translate id="REJECT_OPEN_TRANSACTIONS" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Translate id="REJECT_OPEN_TRANSACTIONS_CONFIRMATION" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => rejectOpenTransactions(props, setState, params)}>Confirm</Button>
                    <Button variant="secondary" onClick={() => hideRejectOpenTransactionsModal(setState)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={state.showDeleteApplicationModal} centered={true}>
                <Modal.Header>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'red', marginRight: '5px' }} />
                        <Translate id="DELETE_APPLICATION" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Translate id="DELETE_APPLICATION_CONFIRMATION" />
                    <br />
                    <Translate id="DELETE_APPLICATION_CONFIRMATION_MORE" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => deleteApplication(props, setState, navigate, params)}>Confirm</Button>
                    <Button variant="secondary" onClick={() => hideDeleteApplicationModal(setState)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <ConfirmationModal
                show={state.confirmNavigationModal}
                title={translate(props, 'UNSAVED_INFORMATION')}
                message={translate(props, 'UNSAVED_INFORMATION_CONFIRMATION')}
                onConfirm={() => goToEditModeConfirmed(setState, navigate, params)}
                onCancel={() => goToEditModeCanceled(setState)}
            />
            <InformationModal
                show={state.showApplicationIsReadOnly}
                title={<Translate id="CANNOT_EDIT_APPLICATION_TITLE" />}
                message={<Translate id="CANNOT_EDIT_APPLICATION_MESSAGE" />}
                onClose={() => hideApplicationIsReadOnlyModal(setState)}
            />
            <SendEmailAndSmsModal
                show={state.showSendEmailAndSmsToApplicantModal}
                productType={getSelectedProductType(props)}
                applicant={props.currentApplication.applicant}
                applicationId={props.currentApplication.id}
                onClose={() => hideSendEmailAndSmsToApplicantModal(setState)}
                incompleteFormLink={incompleteFormLink}
                myApplicationLink={myApplicationLink}
                debtInformationUpdateLink={debtInformationUpdateLink}
                transactions={props.currentApplication.transactions}
                banks={props.metadata.banks}
                publicId={props.currentApplication.details.publicId}
            />
            <SendEmailAndSmsModal
                show={state.showSendEmailAndSmsToCoApplicantModal}
                productType={getSelectedProductType(props)}
                applicant={props.currentApplication.coApplicant}
                applicationId={props.currentApplication.id}
                onClose={() => hideSendEmailAndSmsToCoApplicantModal(setState)}
                incompleteFormLink={incompleteFormLink}
                myApplicationLink={myApplicationLink}
                transactions={props.currentApplication.transactions}
                banks={props.metadata.banks}
                publicId={props.currentApplication.details.publicId}
            />
            <SendSmsModal
                show={state.showSendSmsToApplicantModal}
                productType={getSelectedProductType(props)}
                applicant={props.currentApplication.applicant}
                applicationId={props.currentApplication.id}
                selectedMobileNumber={state.selectedApplicantMobileNumber}
                onClose={() => hideSendSmsToApplicantModal(setState)}
                incompleteFormLink={incompleteFormLink}
                debtInformationUpdateLink={debtInformationUpdateLink}
                onLanguageChanged={language => updateApplicantLanguage(props, params, language)}
                transactions={props.currentApplication.transactions}
                banks={props.metadata.banks}
                publicId={props.currentApplication.details.publicId}
            />
            <SendSmsModal
                show={state.showSendSmsToCoApplicantModal}
                productType={getSelectedProductType(props)}
                applicant={props.currentApplication.coApplicant}
                applicationId={props.currentApplication.id}
                selectedMobileNumber={state.selectedCoApplicantMobileNumber}
                onClose={() => hideSendSmsToCoApplicantModal(setState)}
                incompleteFormLink={incompleteFormLink}
                transactions={props.currentApplication.transactions}
                banks={props.metadata.banks}
                publicId={props.currentApplication.details.publicId}
            />
            <SendEmailModal
                show={state.showSendEmailToApplicantModal}
                productType={getSelectedProductType(props)}
                applicant={props.currentApplication.applicant}
                applicationId={props.currentApplication.id}
                selectedEmail={state.selectedApplicantEmail}
                onClose={() => hideSendEmailToApplicantModal(setState)}
                incompleteFormLink={incompleteFormLink}
                myApplicationLink={myApplicationLink}
                debtInformationUpdateLink={debtInformationUpdateLink}
                transactions={props.currentApplication.transactions}
                banks={props.metadata.banks}
                publicId={props.currentApplication.details.publicId}
                onLanguageChanged={language => updateApplicantLanguage(props, params, language)}
            />
            <SendEmailModal
                show={state.showSendEmailToCoApplicantModal}
                productType={getSelectedProductType(props)}
                applicant={props.currentApplication.coApplicant}
                applicationId={props.currentApplication.id}
                selectedEmail={state.selectedCoApplicantEmail}
                onClose={() => hideSendEmailToCoApplicantModal(setState)}
                incompleteFormLink={incompleteFormLink}
                myApplicationLink={myApplicationLink}
                transactions={props.currentApplication.transactions}
                banks={props.metadata.banks}
                publicId={props.currentApplication.details.publicId}
            />
            {renderGDPRRemoveConfirmationModal(props, params, setState, state.showGDPRRemoveApplicantModal, props.currentApplication.applicant)}
            {renderGDPRRemoveConfirmationModal(props, params, setState, state.showGDPRRemoveCoApplicantModal, props.currentApplication.coApplicant)}
            {renderGDPRSkipCheckConfirmationModal(props, params, state, setState)}
            <MortgageCalculatorModal
                application={props.currentApplication}
                updateApplication={props.setCurrentApplication}
                personId={getPersonId(params)}
                show={state.showMortgageCalculator}
                onClose={() => setState(state => ({ ...state, showMortgageCalculator: false }))}
            />
            <ConfirmationModal
                show={state.showCollectCreditInformationModal}
                title={translate(props, 'COLLECT_CREDIT_INFORMATION')}
                message={translate(props, 'COLLECT_CREDIT_INFORMATION_CONFIRMATION')}
                onConfirm={() => sendCreditInformationEnrichmentRequest(props, params, setState)}
                onCancel={() => hideCollectCreditInformationModal(setState)}
            />
            <ConfirmationModal
                show={state.showCollectPropertyInformationModal}
                title={translate(props, 'COLLECT_PROPERTIES_INFORMATION')}
                message={translate(props, 'COLLECT_PROPERTIES_INFORMATION_CONFIRMATION')}
                onConfirm={() => sendPropertyInformationEnrichmentRequest(props, setState)}
                onCancel={() => hideCollectPropertyInformationModal(setState)}
            />
            <PropertyInformationModal
                show={state.showPropertiesInformationModal}
                application={props.currentApplication}
                onHide={() => hidePropertiesInformationModal(setState)}
            />
        </React.Fragment>
    );
}

function renderSubstatuses(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    if (props.substatuses !== undefined && props.currentApplication !== undefined) {
        return (
            <SubstatusesInputField
                substatusIds={props.currentApplication.substatusIds}
                editMode={true}
                inlineEditing={true}
                style={{maxWidth: '400px'}}
                onSubstatusesChanged={ids => handleCommandResult(props, params, 'UPDATE_SUBSTATUSES',
                    putSubstatuses(getPersonId(params), getApplicationId(params), ids))}
            />                
        );
    }

    return null;
}

function renderGDPRRemoveConfirmationModal(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>, setState: React.Dispatch<SetStateAction<ApplicantViewState>>, show: boolean, applicant?: ConsumerLoanApplicant | null) {
    if (!applicant) {
        return null;
    }

    const onConfirm = () => {
        hideGDPRRemoveModals(setState);
        gdprRemove(props, setState, params, applicant.socialSecurityNumber, false);
    };

    const modalData = { name: <strong>{applicant.firstName} {applicant.lastName}</strong> };

    return (
        <ConfirmationModal
            show={show}
            title={<Translate id="GDPR_REMOVAL" />}
            message={<Translate id="GDPR_REMOVAL_CONFIRMATION" data={modalData} />}
            onConfirm={onConfirm}
            onCancel={() => hideGDPRRemoveModals(setState)}
        />
    );
}

function renderGDPRSkipCheckConfirmationModal(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>, state: ApplicantViewState, setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    if (state.gdprSkipCheckConfirmationModalSsn === null) {
        return null;
    }

    const onConfirm = () => {
        hideGDPRRemoveModals(setState);
        if (state.gdprSkipCheckConfirmationModalSsn !== null) {
            gdprRemove(props, setState, params, state.gdprSkipCheckConfirmationModalSsn, true);
        }
    };

    return (
        <ConfirmationModal
            show={true}
            title={<Translate id="GDPR_REMOVAL" />}
            message={<Translate id="GDPR_REMOVAL_SKIP_CHECK_CONFIRMATION" />}
            onConfirm={onConfirm}
            onCancel={() => hideGDPRRemoveModals(setState)}
        />
    );
}

function onLoanPicked(params: Partial<Params<keyof ApplicantViewRouteParams>>, navigate: NavigateFunction, loanId: number) {
    navigate(`/applicant/${getPersonId(params)}/application/${loanId}`);
}

function onProductTypePicked(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>, navigate: NavigateFunction, productType: ProductType | 'insurance') {
    if (productType === 'insurance') {
        navigate(`/applicant/${params.applicantId}/insurances`);
        return;
    }

    const scopeSourceId = props.userData.user && props.userData.user.applicationSourceId;
    const latestApplicationByOfType = props.applications.filter((x) => x.productType === productType && (scopeSourceId === undefined || scopeSourceId === null || x.sourceId === scopeSourceId))[0];
    if (latestApplicationByOfType !== undefined) {
        navigate(`/applicant/${params.applicantId}/application/${latestApplicationByOfType.id}`);
    }
}

function saveTransaction(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>, transactionCommand: TransactionCommand) {
    const actionKey = transactionCommand.id ? 'UPDATE_TRANSACTION' : 'ADD_TRANSACTION';
    return handleCommandResult(props, params, actionKey, storeTransaction(getPersonId(params), getApplicationId(params), transactionCommand));
}

function addAutomaticBankReport(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>, bankIds: number[], actionKey: string) {
    handleCommandResult(props, params, actionKey, addAutomaticBankReportAPI(getPersonId(params), getApplicationId(params), bankIds));
}

function skipBankFeedbackCallback(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>, transactionId: number) {
    handleCommandResult(props, params, 'SKIP_BANK_RESPONSE', skipBankFeedback(getPersonId(params), getApplicationId(params), transactionId));
}

function retryBankFeedbackCallback(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>, transactionId: number) {
    handleCommandResult(props, params, 'TRY_SENDING_AGAIN', retryBankFeedback(getPersonId(params), getApplicationId(params), transactionId));
}

function resetResponseCodeCallback(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>, transactionId: number) {
    handleCommandResult(props, params, 'RESET_ERROR', resetResponseCode(getPersonId(params), getApplicationId(params), transactionId));
}

function revokeMarketingConsents(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>, particular?: MarketingConsent) {
    if (props.currentApplication) {
        let revokeEmail = !particular || particular === MarketingConsent.EMAIL ? props.currentApplication.applicant.email : undefined;
        let revokeMobileNumber = !particular || particular === MarketingConsent.SMS ? props.currentApplication.applicant.mobileNumber : undefined;
        handleCommandResult(props, params, 'REVOKE_MARKETING_CONSENTS', revokeMarketingConsentsAPI(revokeEmail, revokeMobileNumber));
    }
}

function grantMarketingConsents(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>, particular?: MarketingConsent) {
    if (props.currentApplication) {
        let grantEmail = !particular || particular === MarketingConsent.EMAIL ? props.currentApplication.applicant.email : undefined;
        let grantMobileNumber = !particular || particular === MarketingConsent.SMS ? props.currentApplication.applicant.mobileNumber : undefined;
        handleCommandResult(props, params, 'GRANT_MARKETING_CONSENTS', grantMarketingConsentsAPI(grantEmail, grantMobileNumber));
    }
}

async function gdprRemove(props: ApplicantViewProps, setState: React.Dispatch<SetStateAction<ApplicantViewState>>, params: Partial<Params<keyof ApplicantViewRouteParams>>, socialSecurityNumber: string, forced: boolean) {
    const commandResult = await gdprRemovePersonalInformation(socialSecurityNumber, forced);
    if (commandResult.success) {
        refreshData(props, params);
        props.showToastMessage('success', translate(props, 'GDPR_REMOVAL'), translate(props, 'GDPR_REMOVAL_SUCCESS'), 'GDPR_REMOVAL');
    } else {
        setState(state => ({ ...state, gdprSkipCheckConfirmationModalSsn: socialSecurityNumber }));
    }
}

function updateApplicantManageableData(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>, { followUpDate, paymentDate }: ApplicationManageableData): Promise<string[] | undefined> {
    if (followUpDate !== undefined) {
        return handleCommandResult(props, params, 'UPDATE_FOLLOW_UP_DATE',
            putFollowUpDate(getPersonId(params), getApplicationId(params), followUpDate));
    }
    if (paymentDate !== undefined) {
        return handleCommandResult(props, params, 'UPDATE_PAYMENT_DATE',
            updatePaymentDate(getPersonId(params), getApplicationId(params), paymentDate));
    }
    return Promise.resolve(undefined);
}

function translate(props: ApplicantViewProps, key: string) {
    return props.translate(key).toString();
}

async function handleCommandResult(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>> | null, action: string, promise: Promise<CommandResult>) {
    const commandResult = await promise;
    if (commandResult.success) {
        if (params) {
            refreshData(props, params);
        }
        props.showToastMessage('success', translate(props, action), translate(props, `${action}_SUCCESS`), action);
    } else {
        props.showToastMessage('error', translate(props, action), commandResult.errors.map((e) => translate(props, `ERRORS.${getTranslationKey(e.code)}`)), action);
    }

    return commandResult.errors.map((e) => e.code);
}

function refreshData(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    const applicantId = getPersonId(params);
    props.loadApplications(applicantId);
    props.loadCurrentApplication(applicantId, getApplicationId(params));
}

function refreshConsumerLoanData(props: ApplicantViewProps, setState: React.Dispatch<SetStateAction<ApplicantViewState>>, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    const personId = getPersonId(params);
    setState(state => ({ ...state, consumerLoanRefresh: true }));
    const oldStatus = props.currentApplication && props.currentApplication.loanStatus;
    props.loadCurrentApplication(personId, getApplicationId(params))
        .then((app) => {
            setState(state => ({ ...state, consumerLoanRefresh: false }));
            if (app.loanStatus !== oldStatus) {
                props.loadApplications(personId);
            }
        });
}

function switchToMainApplicant(props: ApplicantViewProps, navigate: NavigateFunction, personId: number) {
    getCoApplicantApplications(personId).then((coApps) => {
        if (coApps.data.length > 0) {
            navigate(`/applicant/${coApps.data[0].personId}/application/${coApps.data[0].id}`);

            if (!props.currentApplication) {
                props.loadCurrentApplication(coApps.data[0].personId, coApps.data[0].id);
            }
        }
    });
}

function isReadonly(props: ApplicantViewProps) {
    return !props.currentApplication
        || !allowedToEdit(props)
        || isApplicationReadOnly(props.currentApplication.transactions);
}

function goToEditMode(props: ApplicantViewProps, state: ApplicantViewState, setState: React.Dispatch<SetStateAction<ApplicantViewState>>, navigate: NavigateFunction, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    if (isWaitingForCreditScore(props) || isReadonly(props)) {
        showApplicationIsReadOnlyModal(setState);
        return; // don't do navigation if there are creditScoreRules and applicant creditScore is undefined
    }

    if (state.canNavigateWithoutConfirmationComments && state.canNavigateWithoutConfirmationBankReplies) {
        navigate(`/applicant/${params.applicantId}/application/${params.applicationId}/edit`);
    } else {
        setState(state => ({ ...state, confirmNavigationModal: true }));
    }
}

function goToEditModeConfirmed(setState: React.Dispatch<SetStateAction<ApplicantViewState>>, navigate: NavigateFunction, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    navigate(`/applicant/${params.applicantId}/application/${params.applicationId}/edit`);
    setState(state => ({ ...state, confirmNavigationModal: false }));
}

function goToEditModeCanceled(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, confirmNavigationModal: false }));
}

function blacklistApplicant(props: ApplicantViewProps, state: ApplicantViewState, setState: React.Dispatch<SetStateAction<ApplicantViewState>>, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    if (props.currentApplication !== undefined) {
        handleCommandResult(props, params, 'BLACKLIST_APPLICANT',
            putOnBlacklist(props.currentApplication.applicant.socialSecurityNumber, getSelectedProductType(props), state.blacklistLength));
        setState(state => ({
            ...state,
            showConfirmBlacklistModal: false
        }));
    }
}

function clearBlacklistApplicant(props: ApplicantViewProps, setState: React.Dispatch<SetStateAction<ApplicantViewState>>, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    if (props.currentApplication !== undefined) {
        handleCommandResult(props, params, 'REMOVE_BLACKLISTING',
            removeFromBlacklist(props.currentApplication.applicant.socialSecurityNumber, getSelectedProductType(props)));
        setState(state => ({
            ...state,
            showConfirmClearBlacklistModal: false
        }));
    }
}

function showPutOnBlackListModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>, days: number | undefined) {
    setState(state => ({
        ...state,
        showConfirmBlacklistModal: true,
        blacklistLength: days
    }));
}

function hidePutOnBlackListModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showConfirmBlacklistModal: false }));
}

function showPutOffBlackListModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showConfirmClearBlacklistModal: true }));
}

function hidePutOffBlackListModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showConfirmClearBlacklistModal: false }));
}

function showDenyApplicationModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showDenyApplicationModel: true }));
}

function hideDenyApplicationModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showDenyApplicationModel: false }));
}

function showRejectOpenTransactionsModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showRejectOpenTransactionsModal: true }));
}

function hideRejectOpenTransactionsModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showRejectOpenTransactionsModal: false }));
}

function showGDPRRemoveApplicantModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showGDPRRemoveApplicantModal: true }));
}

function showGDPRRemoveCoApplicantModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showGDPRRemoveCoApplicantModal: true }));
}

function showDeleteApplicationModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showDeleteApplicationModal: true }));
}

function hideDeleteApplicationModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showDeleteApplicationModal: false }));
}

function hideGDPRRemoveModals(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({
        ...state,
        showGDPRRemoveApplicantModal: false,
        showGDPRRemoveCoApplicantModal: false,
        gdprSkipCheckConfirmationModalSsn: null
    }));
}

function showApplicationIsReadOnlyModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showApplicationIsReadOnly: true }));
}

function hideApplicationIsReadOnlyModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showApplicationIsReadOnly: false }));
}

function showSendSmsToApplicantModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>, mobileNumber: string) {
    setState(state => ({
        ...state,
        showSendSmsToApplicantModal: true,
        selectedApplicantMobileNumber: mobileNumber
    }));
}

function hideSendSmsToApplicantModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showSendSmsToApplicantModal: false }));
}

function showSendSmsToCoApplicantModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>, mobileNumber: string) {
    setState(state => ({
        ...state,
        showSendSmsToCoApplicantModal: true,
        selectedCoApplicantMobileNumber: mobileNumber
    }));
}

function hideSendSmsToCoApplicantModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showSendSmsToCoApplicantModal: false }));
}

function showSendEmailToApplicantModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>, email: string) {
    setState(state => ({
        ...state,
        showSendEmailToApplicantModal: true,
        selectedApplicantEmail: email
    }));
}

function hideSendEmailToApplicantModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showSendEmailToApplicantModal: false }));
}

function showSendEmailToCoApplicantModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>, email: string) {
    setState(state => ({
        ...state,
        showSendEmailToCoApplicantModal: true,
        selectedCoApplicantEmail: email
    }));
}

function hideSendEmailToCoApplicantModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showSendEmailToCoApplicantModal: false }));
}

function showSendEmailAndSmsToApplicantModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showSendEmailAndSmsToApplicantModal: true }));
}

function hideSendEmailAndSmsToApplicantModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showSendEmailAndSmsToApplicantModal: false }));
}

function showSendEmailAndSmsToCoApplicantModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showSendEmailAndSmsToCoApplicantModal: true }));
}

function hideSendEmailAndSmsToCoApplicantModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showSendEmailAndSmsToCoApplicantModal: false }));
}

function showCollectPropertyInformationModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showCollectPropertyInformationModal: true }));
}

function hideCollectPropertyInformationModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showCollectPropertyInformationModal: false }));
}

function showCollectCreditInformationModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showCollectCreditInformationModal: true }));
}

function hideCollectCreditInformationModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showCollectCreditInformationModal: false }));
}

function showPropertiesInformationModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showPropertiesInformationModal: true }))
}

function hidePropertiesInformationModal(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showPropertiesInformationModal: false }))
}

function updateApplicantLanguage(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>, primaryLanguage: string) {
    const personId = getPersonId(params);
    const applicationId = getApplicationId(params);
    if (props.currentApplication && props.currentApplication.applicant) {
        putApplicantPrimaryLanguge(personId, applicationId, props.currentApplication.applicant.id, primaryLanguage)
            .then(() => props.loadCurrentApplication(personId, applicationId));
    }
}

function copyApplication(navigate: NavigateFunction, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    navigate(`/applicant/${params.applicantId}/application/${params.applicationId}/copy`);
}

function denyApplication(props: ApplicantViewProps, setState: React.Dispatch<SetStateAction<ApplicantViewState>>, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    handleCommandResult(props, params, 'DENY_APPLICATION', denyApplicationAPI(getPersonId(params), getApplicationId(params)));
    setState(state => ({ ...state, showDenyApplicationModel: false }));
}

function rejectOpenTransactions(props: ApplicantViewProps, setState: React.Dispatch<SetStateAction<ApplicantViewState>>, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    handleCommandResult(props, params, 'REJECT_OPEN_TRANSACTIONS', rejectOpenTransactionsAPI(getPersonId(params), getApplicationId(params)));
    setState(state => ({ ...state, showRejectOpenTransactionsModal: false }));
}

function sendCreditInformationEnrichmentRequest(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>, setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    handleCommandResult(props, null, 'COLLECT_CREDIT_INFORMATION', sendCreditInformationEnrichmentRequestAPI(getPersonId(params), getApplicationId(params)));
    hideCollectCreditInformationModal(setState);
}

function sendPropertyInformationEnrichmentRequest(props: ApplicantViewProps, setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    const socialSecurityNumbers: string[] = [];
    if (props.currentApplication?.applicant.socialSecurityNumber !== undefined) {
        socialSecurityNumbers.push(props.currentApplication?.applicant.socialSecurityNumber);
    }

    if (props.currentApplication?.coApplicant?.socialSecurityNumber !== undefined) {
        socialSecurityNumbers.push(props.currentApplication?.coApplicant?.socialSecurityNumber);
    }

    handleCommandResult(props, null, 'COLLECT_PROPERTIES_INFORMATION', sendPropertyInformationEnrichmentRequestAPI(socialSecurityNumbers))
    hideCollectPropertyInformationModal(setState);
}

function hideApplication(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    handleCommandResult(props, params, 'HIDE_APPLICATION', hideApplicationAPI(getPersonId(params), getApplicationId(params)));
}

function unhideApplication(props: ApplicantViewProps, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    handleCommandResult(props, params, 'UNHIDE_APPLICATION', unhideApplicationAPI(getPersonId(params), getApplicationId(params)));
}

function deleteApplication(props: ApplicantViewProps, setState: React.Dispatch<SetStateAction<ApplicantViewState>>, navigate: NavigateFunction, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    deleteApplicationAPI(getPersonId(params), getApplicationId(params)).then((commandResult) => {
        hideDeleteApplicationModal(setState);
        if (commandResult.success) {
            props.showToastMessage('success', translate(props, 'DELETE_APPLICATION'), translate(props, 'DELETE_APPLICATION_SUCCESS'), 'DELETE_APPLICATION');
            navigate(`/applicants`);
        } else {
            props.showToastMessage('error', translate(props, 'DELETE_APPLICATION'), commandResult.errors.map((e) => translate(props, `ERRORS.${getTranslationKey(e.code)}`)), 'DELETE_APPLICATION');
        }
    });
}

function disableEditingForComponents(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, componentsCanEnterEdit: false }));
}

function enableEditingForComponents(props: ApplicantViewProps, setState: React.Dispatch<SetStateAction<ApplicantViewState>>, params: Partial<Params<keyof ApplicantViewRouteParams>>) {
    setState(state => ({ ...state, componentsCanEnterEdit: true }));
    refreshData(props, params);
}

function onCommentInputChange(setState: React.Dispatch<SetStateAction<ApplicantViewState>>, inputIsEmpty: boolean): void {
    setState(state => ({ ...state, canNavigateWithoutConfirmationComments: inputIsEmpty }));
}

function onBankRepliesNotesChange(setState: React.Dispatch<SetStateAction<ApplicantViewState>>, inputIsEmpty: boolean): void {
    setState(state => ({ ...state, canNavigateWithoutConfirmationBankReplies: inputIsEmpty }));
}

function showMortgageCalculator(setState: React.Dispatch<SetStateAction<ApplicantViewState>>) {
    setState(state => ({ ...state, showMortgageCalculator: true }));
}

function getSelectedProductType(props: ApplicantViewProps): ProductType {
    return (props.currentApplication && props.currentApplication.details.productType) || ProductType.ConsumerLoan;
}

function getPersonId(params: Partial<Params<keyof ApplicantViewRouteParams>>): number {
    if (params.applicantId === undefined) {
        throw new Error('applicant Id cannot be undefined');
    }
    return parseInt(params.applicantId, 10);
}

function getApplicationId(params: Partial<Params<keyof ApplicantViewRouteParams>>): number {
    if (params.applicationId === undefined) {
        throw new Error('application Id cannot be undefined');
    }
    return parseInt(params.applicationId, 10);
}

function getSelectedProduct(props: ApplicantViewProps): ProductType | 'insurance' | null {
    if (props.currentApplication) {
        return props.currentApplication.details.productType;
    }

    return null;
}

function isWaitingForCreditScore(props: ApplicantViewProps) {
    if (props.currentApplication !== undefined
        && props.currentApplication.loanStatus === LoanStatus.Investigation) {
        const creditScoreRules = getCreditScoreRules(props);
        return creditScoreRules !== undefined && props.currentApplication.applicant.creditScore === null;
    }

    return false;
}

const mapStateToProps = (state: any) => ({
    ...state.metadataActionsReducer,
    ...state.settingsActionsReducer,
    ...state.substatusesReducer,
    ...state.userActionsReducer,
    ...state.applicantDataReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...MetadataActionsCreator,
    ...SetTitleActionCreator,
    ...SubstatusesActionsCreator,
    ...ApplicationDataActionsCreator,
    showToastMessage
}, dispatch);

export default connect<ApplicantViewProps, SetApplicationTitleActionProps, {}, ApplicantViewState>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(ApplicantView));
