import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';

export const gdprRemovePersonalInformation = async (socialSecurityNumber: string, forced: boolean): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .post(`/api/gdprDataSubject/${socialSecurityNumber}/removePersonalInformation?forced=${forced}`) as any;

    return new CommandResult(result);
};
