import React, {FunctionComponent, ReactNode, useState} from "react";
import "./campaign-input-field.css";
import Autosuggest, {
    RenderSuggestionsContainerParams, ShouldRenderReasons,
    SuggestionsFetchRequestedParams
} from "react-autosuggest";
import {CampaignSearchModel} from "../../models/Campaign";
import {LocalizeContextProps, withLocalize} from "react-localize-redux";
import {searchCampaigns} from "../../../applications/applicant/api/searchCampaigns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

interface CampaignInputFieldProps extends LocalizeContextProps {
    value: CampaignSearchModel | null;
    onCampaignSelected: (campaign: CampaignSearchModel | null) => void;
}
const CampaignInputField: FunctionComponent<CampaignInputFieldProps> = (props) => {
    const [campaignSuggestions, setCampaignSuggestions] = useState<CampaignSearchModel[]>([]);
    const [inputValue, setInputValue] = useState<string>(props.value ? props.value.name : '');

    const inputProps = {
        onChange: (e, {newValue}) => {
            setInputValue(newValue)
        },
        placeholder: props.translate('FILTER_CAMPAIGNS').toString(),
        value: inputValue
    };
    
    const fetchCampaignSuggestions = (request: SuggestionsFetchRequestedParams) => {
        if (request.reason === 'input-changed') {
            searchCampaigns(request.value).then(response => setCampaignSuggestions(response.data));
        }
    };
    
    const clearCampaignInput = () => {
        setInputValue('');
        setCampaignSuggestions([]);
        if (props.value !== null) {
            props.onCampaignSelected(null);
        }
    };

    const renderInputComponent = (inputProps: any) => {
        inputProps.className = inputProps.className + ' form-control customized';
        const clearIconProps = {
            style: {alignItems: 'center', cursor: 'pointer', display: (inputProps.value ? 'flex' : 'none')},
            onClick: () => {
                clearCampaignInput();
            }
        }
        return (
            // data-value is used to calculate width of parent component to fit long input value
            // we adds ' X ' to provide additional space for clear icon
            <div className="multiselect campaign-input-wrapper" data-value={inputValue + ' X '}>
                <div className="campaign-input-container">
                    <input {...inputProps} />
                    <div {...clearIconProps}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>
                </div>
            </div>
        );
    };


    const getCampaignValue = (campaign: CampaignSearchModel) => campaign.name;

    const renderCampaign = (campaign: CampaignSearchModel) => {
        const content: ReactNode[] = [];
        if (!inputValue) {
            content.push(campaign.name);
        } else {
            const displayValue = campaign.name;
            const lowerCase = displayValue.toLocaleLowerCase();
            let index = lowerCase.indexOf(inputValue, 0);
            let startIndex = 0;

            while (index >= 0) {
                // text before filter match
                if (startIndex < index) {
                    content.push(displayValue.substring(startIndex, index));
                }

                // filter match inside <strong>
                content.push(<strong key={campaign.id}>{displayValue.substring(index, index + inputValue.length)}</strong>);

                startIndex = index + inputValue.length;
                index = lowerCase.indexOf(inputValue, startIndex);
            }

            // add text after last match
            if (startIndex < displayValue.length) {
                content.push(displayValue.substring(startIndex));
            }
        }
        if (campaign.sentAt) {
            content.push(` - ${props.translate('CAMPAIGN_STATUS.SENT')} ${new Date(campaign.sentAt).toLocaleDateString()}`);
        }
        return (
            <div key={campaign.id}>{content}</div>
        );
    }

    const renderCampaignSuggestionsContainer = (params: RenderSuggestionsContainerParams) => (
        <div {...params.containerProps}>
            <div className="suggestion-wrapper">{params.children}</div>
        </div>
    );
    
    const shouldRenderCampaignSuggestions = (value: string, reason: ShouldRenderReasons) => {
        if (reason === 'input-blurred') {
            if (props.value && value) {
                setInputValue(props.value.name)
            } else {
                clearCampaignInput();
            }
            return false;
        }
        return reason === 'render' ||
            reason === 'suggestions-updated' ||
            (reason === 'input-changed' && value.trim().length > 2) ||
            (reason === 'input-focused' && campaignSuggestions.length > 0)
    };
    
    return (
        <Autosuggest<CampaignSearchModel>
            suggestions={campaignSuggestions}
            shouldRenderSuggestions={shouldRenderCampaignSuggestions}
            onSuggestionsFetchRequested={fetchCampaignSuggestions}
            onSuggestionsClearRequested={() => {/*handled manually by clearCampaignInput method*/}}
            onSuggestionSelected={(_, {suggestion}) => props.onCampaignSelected(suggestion)}
            getSuggestionValue={getCampaignValue}
            renderSuggestion={renderCampaign}
            renderSuggestionsContainer={renderCampaignSuggestionsContainer}
            renderInputComponent={renderInputComponent}
            inputProps={inputProps}
        />
    );
}
export default withLocalize(CampaignInputField);