import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { AxiosResponse } from 'axios';
import { ConsumerLoanDetails } from '../models/ConsumerLoanDetails';

export const putConsumerLoanDetails = async (
    personId: string | number,
    loanId: string | number,
    data: ConsumerLoanDetails): Promise<string[]> => {
    return new Promise<string[]>((resolve) => {
        AxiosClientFactory
            .getInstance()
            .put(`api/applicants/${personId}/consumerloans/${loanId}`, data)
            .then((response: AxiosResponse<string[]>) => resolve(response.data));
    });
};
