import React, { FunctionComponent } from 'react';
import { Translate } from 'react-localize-redux';

interface YearTranslatorProps {
    year: number;
}

export const YearTranslator: FunctionComponent<YearTranslatorProps> = (props) => {
    return <React.Fragment>{props.year} <Translate id={props.year === 1 ? 'YEAR' : 'YEARS'}/></React.Fragment>;
};
