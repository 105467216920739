import React, { FunctionComponent, ReactNode } from 'react';
import { Translate } from 'react-localize-redux';
import { PropertyOwnership } from '../../models/PropertyOwnership';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBuilding } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../../common/components/Tooltip';

interface PropertiesInfomationModalOwnershipsTableProps {
    propertyOwnerships: PropertyOwnership[];
}

const PropertiesInfomationModalOwnershipsTable: FunctionComponent<PropertiesInfomationModalOwnershipsTableProps> = (props) =>
    (
        <table className="property-owners">
            <thead>
                <tr>
                    <th><Translate id="NAME" /></th>
                    <th><Translate id="PROPERTIES_MODAL.OWNERSHIP_SHARE" /></th>
                    <th><Translate id="PROPERTIES_MODAL.OWNERSHIP_SHARE" /> %</th>
                </tr>
            </thead>
            <tbody>
                {renderOnwershipRows(props.propertyOwnerships)}
            </tbody>
        </table>
    );

const renderOnwershipRows = (ownerships: PropertyOwnership[]) =>
    ownerships.map(ownership => 
        (
            <tr className="ownership-row" key={ownership.identity}>
                <td>{renderidentityType(ownership.identityType)} <b>{ownership.name || ''}</b> ({ownership.identity || ''})</td>
                {rednerOwnershipShares(ownership.shareNumerator, ownership.shareDenominator)}
            </tr>
        ));

const rednerOwnershipShares = (shareNumerator: number | null, shareDenominator: number | null): ReactNode => {
    if (shareNumerator === null || shareDenominator === null) {
        return <td colSpan={2}><Translate id="PROPERTIES_MODAL.NO_SHARE_INFO" /></td>;
    }

    return (
        <>
            <td><b>{shareNumerator}</b> / <b>{shareDenominator}</b></td>
            <td>{Math.round(100 * shareNumerator / shareDenominator)}%</td>
        </>
    );
};

const renderidentityType = (identityType: string | null): ReactNode => {
    switch (identityType) {
        case 'Person':
            return <Tooltip content={<Translate id="PROPERTIES_MODAL.IDENTITY_TYPE.PERSON" />}><FontAwesomeIcon icon={faUser} /></Tooltip>
        case 'Organisasjon':
            return <Tooltip content={<Translate id="PROPERTIES_MODAL.IDENTITY_TYPE.COMPANY" />}><FontAwesomeIcon icon={faBuilding} /></Tooltip>
        default:
            return identityType;
    }
};


export default PropertiesInfomationModalOwnershipsTable;
