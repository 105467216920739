import { Action, Dispatch } from 'redux';
import { AxiosClientFactory } from '../helpers/AxiosClientFactory';
import { AxiosResponse } from 'axios';
import { MessageType } from '../models/MessageType';
import { CommunicationTemplate } from '../models/CommunicationTemplate';

export const SET_COMMUNICATION_TEMPLATES = 'SET_COMMUNICATION_TEMPLATES';
export const SET_SIGNATURE_TEMPLATES = 'SET_SIGNATURE_TEMPLATES';

interface SetCommunicationTemplates extends Action<typeof SET_COMMUNICATION_TEMPLATES> {
    messageType: MessageType;
    communicationTemplates: CommunicationTemplate[];
}

interface SetSignatureTemplates extends Action<typeof SET_SIGNATURE_TEMPLATES> {
    messageType: MessageType;
    signatureTemplates: CommunicationTemplate[];
}

export type CommunicationTemplatesAction = SetCommunicationTemplates | SetSignatureTemplates;

export const CommunicationTemplatesActionCreator = {
    loadCommunicationTemplates: (messageType: MessageType) => {
        return (dispatch: Dispatch<SetCommunicationTemplates>, getState: any): void => {
            AxiosClientFactory
                .getInstance()
                .get<CommunicationTemplate[]>(`api/communicationtemplates/${messageType}`)
                .then((response: AxiosResponse<CommunicationTemplate[]>) => dispatch({
                    type: SET_COMMUNICATION_TEMPLATES,
                    messageType,
                    communicationTemplates: response.data
                }));
        };
    },
    loadSignatures: (messageType: MessageType) => {
        return (dispatch: Dispatch<SetSignatureTemplates>, getState: any): void => {
            AxiosClientFactory
                .getInstance()
                .get<CommunicationTemplate[]>(`api/communicationtemplates/${messageType}/signatures`)
                .then((response: AxiosResponse<CommunicationTemplate[]>) => dispatch({
                    type: SET_SIGNATURE_TEMPLATES,
                    messageType,
                    signatureTemplates: response.data
                }));
        };
    }
};
