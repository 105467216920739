import React, {ReactNode, SetStateAction, useEffect, useState} from 'react';
import InputField, {InputFieldProps} from './InputField';
import {FormControl, InputGroup} from 'react-bootstrap';
import {getCurrencySymbolForCountryId} from '../../helpers/currencySymbolFunctions';
import {LocalizeContextProps, withLocalize} from 'react-localize-redux';
import {useValidation, ValidationProps} from '../../helpers/useValidation';

const MIN_INT = -2147483648;
const MAX_INT = 2147483647;

export enum NumberInputFieldKind {
    Money,
    Percent,
    Years,
    Months,
    Days,
    MoneyPerMonth,
    MoneyPerYear,
    NumberOf,
    SquareMetres
}

interface NumberInputFieldProps extends InputFieldProps, ValidationProps, LocalizeContextProps {
    name: string;
    value: number | null | undefined;
    readonlyValuePostFix?: string | ReactNode;
    kind?: NumberInputFieldKind;
    min?: number;
    max?: number;
    step?: number;
    countryId?: number;
    disabled?: boolean;
    nullable?: boolean;
    readonlyPrecision?: number;
    onValueChanged?: (value: number | null, name: string) => void;
    onBlur?: (value: number | null, dirty: boolean) => void;
}

function NumberInputField<WrapperProps>(props: NumberInputFieldProps) {
    const [errorCode, internalError, ref, onTouched] = useValidation(props);
    const [tempValue, setTempValue] = useState(getTempValue(props));
    const [dirty, setDirty] = useState(false);
    useEffect(() => setTempValue(getTempValue(props)), [props.value, props.editMode]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        // react changes input value after scroll inside input
        // this is a code to disable this
        // https://github.com/facebook/react/issues/24986
        ref.current?.addEventListener("wheel", e => {
            e.preventDefault();
            e.stopImmediatePropagation();
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <InputField<WrapperProps>
            className={props.className}
            afterContent={props.afterContent}
            descriptionKey={props.descriptionKey}
            description={props.description}
            editMode={props.editMode}
            error={internalError}
            errorCode={errorCode}
            errorCodesData={props.errorCodesData}
            wrapper={props.wrapper}
            wrapperProps={props.wrapperProps}
        >
            {renderValue(props, ref, onTouched, tempValue, setTempValue, dirty, setDirty)}
        </InputField>
    );
};

function renderValue(
    props: NumberInputFieldProps,
    ref: React.RefObject<HTMLInputElement>,
    onTouched: () => void,
    tempValue: string,
    setTempValue: React.Dispatch<SetStateAction<string>>,
    dirty: boolean,
    setDirty: React.Dispatch<SetStateAction<boolean>>) {

    if (!props.editMode) {
        if (hasEmptyValue(props)) {
            return <span>-</span>;
        }
        let formattedValue = props.value!;
        if (props.readonlyPrecision !== undefined) {
            formattedValue = parseFloat(props.value!.toFixed(props.readonlyPrecision));
        }

        return <span>{formattedValue.toLocaleString()} {renderUnitOfMeasure(props)} {renderReadonlyValuePostfix(props)}</span>;
    }

    const onValueChanged = (e: any) => {
        setDirty(true);
        const value = getValue(e.target.value, props.nullable);

        if (value && (value > MAX_INT || value < MIN_INT)) {
            return;
        }

        let parsable;
        try {
            Number.parseFloat(value);
            parsable = true;
        } catch (error) {
            parsable = false;
        }

        setTempValue(value !== null ? value.toString() : '');
        const newNumber = value !== null ? Number(value) : null;
        onTouched();
        if (props.onValueChanged && parsable) {
            props.onValueChanged(newNumber, props.name);
        }
    };

    const onBlur = props.onBlur !== undefined ? (e: any) => props.onBlur!(Number(getValue(e.target.value, props.nullable)), dirty) : undefined;

    return (
        <InputGroup>
            <FormControl
                name={props.name}
                type="number"
                value={getDisplayValue(tempValue, props.nullable)}
                onChange={onValueChanged}
                min={props.min || 0}
                max={props.max}
                step={props.step}
                disabled={props.disabled}
                ref={ref as any}
                placeholder={props.placeholderKey && props.translate(props.placeholderKey).toString()}
                onBlur={onBlur}
            />
            {props.kind !== undefined &&
                <InputGroup.Text>{renderUnitOfMeasure(props)}</InputGroup.Text>
            }
        </InputGroup>
    );
}

function getValue(inputValue: any, nullable?: boolean) {
    if (inputValue === undefined || inputValue === null || inputValue === '') {
        if (nullable) {
            return null;
        }

        return '0';
    }

    return inputValue;
}

function getDisplayValue(tempValue: string, nullable?: boolean) {
    if (tempValue === '' && nullable) {
        return '';
    }

    let displayValue: string;
    try {
        if (tempValue.indexOf('.') > -1) {
            displayValue = tempValue;
        } else {
            displayValue = Number(tempValue).toString();
        }
    } catch (error) {
        displayValue = tempValue;
    }
    return displayValue;
}

function renderUnitOfMeasure(props: NumberInputFieldProps) {
    switch (props.kind) {
        case NumberInputFieldKind.Money:
            return props.countryId && getCurrencySymbolForCountryId(props.countryId);
        case NumberInputFieldKind.Percent:
            return '%';
        case NumberInputFieldKind.Years:
            return props.translate('YEARS');
        case NumberInputFieldKind.Months:
            return props.translate('MONTHS');
        case NumberInputFieldKind.Days:
            return props.translate('DAYS');
        case NumberInputFieldKind.MoneyPerMonth:
            return props.countryId && `${getCurrencySymbolForCountryId(props.countryId)}/${props.translate('MONTH_ABBREVIATION')}`;
        case NumberInputFieldKind.MoneyPerYear:
            return props.countryId && `${getCurrencySymbolForCountryId(props.countryId)}/${props.translate('YEAR_ABBREVIATION')}`;
        case NumberInputFieldKind.NumberOf:
            return props.translate('NUMBER_ABBREVIATION');
        case NumberInputFieldKind.SquareMetres:
            return <React.Fragment>m<sup>2</sup></React.Fragment>;
        default:
            return null;
    }
}

function renderReadonlyValuePostfix(props: NumberInputFieldProps) {
    if (props.readonlyValuePostFix === undefined) {
        return null;
    }

    return (props.readonlyValuePostFix);
}

function hasEmptyValue(props: NumberInputFieldProps) {
    return (props.value === null || props.value === undefined);
}

function getTempValue(props: NumberInputFieldProps) {
    if (props.value === undefined || props.value === null) {
        if (props.nullable) {
            return '';
        }

        return '0';
    }
    return props.value.toString();
}

export default withLocalize(NumberInputField);
