import { Action, Dispatch } from "redux";
import { CustomerAdvisorsTeam } from "../../applicant/models/CustomerAdvisorsTeam";
import { AxiosClientFactory } from "../../../common/helpers/AxiosClientFactory";
import { CachingConfig } from "../../../common/helpers/Caching";

export const GET_CUSTOMER_ADVISORS_TEAMS_BEGIN = 'GET_CUSTOMER_ADVISORS_TEAMS_BEGIN';
export const GET_CUSTOMER_ADVISORS_TEAMS_SUCCESS = 'GET_CUSTOMER_ADVISORS_TEAMS_SUCCESS ';
export const GET_CUSTOMER_ADVISORS_TEAMS_FAILURE = 'GET_CUSTOMER_ADVISORS_TEAMS_FAILURE';

interface GetCustomerAdvisorsTeamsBeginAction extends Action<typeof GET_CUSTOMER_ADVISORS_TEAMS_BEGIN> { }

interface GetCustomerAdvisorsTeamsSuccessAction extends Action<typeof GET_CUSTOMER_ADVISORS_TEAMS_SUCCESS> {
    customerAdvisorsTeams: CustomerAdvisorsTeam[];
}

interface GetCustomerAdvisorsTeamsFailureAction extends Action<typeof GET_CUSTOMER_ADVISORS_TEAMS_FAILURE> {
    error: any;
}

export type GetCustomerAdvisorsTeamsAction = GetCustomerAdvisorsTeamsBeginAction
    | GetCustomerAdvisorsTeamsSuccessAction
    | GetCustomerAdvisorsTeamsFailureAction;

const getCustomerAdvisorsTeams = (): GetCustomerAdvisorsTeamsBeginAction => ({
    type: GET_CUSTOMER_ADVISORS_TEAMS_BEGIN
})

const getCustomerAdvisorsTeamsSuccess = (customerAdvisorsTeams: CustomerAdvisorsTeam[]): GetCustomerAdvisorsTeamsSuccessAction => ({
    type: GET_CUSTOMER_ADVISORS_TEAMS_SUCCESS,
    customerAdvisorsTeams
})

const getCustomerAdvisorsTeamsFailure = (error: any): GetCustomerAdvisorsTeamsFailureAction => ({
    type: GET_CUSTOMER_ADVISORS_TEAMS_FAILURE,
    error
})

const CustomerAdvisorsTeamsActionsCreator = {
    loadCustomerAdvisorsTeams: () => {
        return (dispatch: Dispatch, getState: any) => {
            dispatch(getCustomerAdvisorsTeams());

            AxiosClientFactory
                .getInstance()
                .get<CustomerAdvisorsTeam[]>('api/advisors', { cache: CachingConfig.LONG })
                .then((response) => dispatch(getCustomerAdvisorsTeamsSuccess(response.data)))
                .catch((error) => dispatch(getCustomerAdvisorsTeamsFailure(error)));
        }
    }
}

export interface CustomerAdvisorsTeamsActions {
    loadCustomerAdvisorsTeams: () => (dispatch: Dispatch, getState: any) => void;
}

export default CustomerAdvisorsTeamsActionsCreator;