import { ProductType } from "./ProductType";

export enum CampaignStatus {
    DRAFT,
    SENT
}

export enum CampaignType {
    SMS,
    EMAIL
}

export enum CampaignRecipientsFilterApplicationStatus {
    COMPLETE,
    PAID,
    DENIED,
    DENIED_WITH_OFFER,
    COMPLETE_WITH_OFFER,
    INCOMPLETE
}

export enum CampaignRecipientStatus {
    QUEUED,
    SENT,
    DELIVERED,
    FAILED
}

export enum CampaignInteraction {
    REPLIED,
    OPENED,
    CLICKED
}

export interface CampaignRecipientsFilter {
    lastApplicationStatus?: CampaignRecipientsFilterApplicationStatus | null;
    submittedFrom?: string | null;
    submittedTo?: string | null;
    lastStatusUpdateFrom?: string | null;
    lastStatusUpdateTo?: string | null;
    lastCampaign?: string | null;
    includedSubstatuses: number[];
    excludedSubstatuses: number[];
    customerAdvisors: number[];
    language?: string | null;
    excludeInkasso: boolean;
    skipActiveFollowUps?: string | null;
    hasProperty: boolean;
    mobileNumbers: string[];
    emails: string[];
    productType: ProductType[];
    recipientStatus?: CampaignRecipientStatus | null;
}

export interface CampaignSearchModel {
    id: number;
    name: string;
    sentAt?: string | null;
}

export interface CampaignSummary extends CampaignSearchModel {
    status: CampaignStatus;
    type: CampaignType;
    createdAt: string;
    owner: string;
    recipients: number | null;
    delivered: number | null;
    engagements: number | null;
}

export interface Campaign extends CampaignSummary, CampaignSender {
    userId: number;
    template: string | null;
    subject: string | null;
    campaignRecipientsFilter: CampaignRecipientsFilter | null;
}

export interface CampaignRecipient {
    personId: number;
    applicationId: number;
    loanStatus: string;
    submittedDate: string;
    firstName: string;
    lastName: string;
    socialSecurityNumber: string;
    mobileNumber: string;
    email: string;
    productType: string;
    status: CampaignRecipientStatus | null;
}

export interface CampaignEngagement {
    personId: number | null;
    applicantName: string | null;
    applicationId: number | null;
    date: string | null;
    email: string | null;
    mobileNumber: string | null;
    interaction: CampaignInteraction;
    read: boolean;
    content: string | null;
}

export interface CampaignSender {
    senderAddress: string | null;
    senderName: string | null;
}

export interface CampaignCreateModel extends Partial<CampaignSender> {
    name: string;
    template: string | null;
    subject?: string | null;
    type: CampaignType;
    filter: CampaignRecipientsFilter | null;
}

export interface CampaignTestModel extends Omit<CampaignCreateModel, 'filter' | 'name'> {
    recipientName?: string | null;
    recipientEmail?: string | null;
    recipientMobileNumber?: string | null;
}

export interface LaunchCampaignModel {
    id: number;
    launchDate?: string;
    startBatchSize?: number | null;
    dailyPercentRampUp?: number | null;
}