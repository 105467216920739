import React, {FunctionComponent, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Translate} from "react-localize-redux";
import {Campaign, CampaignTestModel, CampaignType} from "../../../../common/models/Campaign";
import {Optional} from "utility-types";
import {CampaignValidationErrors, validateCampaignTestModel} from "../helpers/validators";
import {User} from "../../../../common/models/User";
import TextInputField from "../../../../common/components/input-fields/TextInputField";

interface TestCampaignModalProps {
    campaign: Optional<Campaign, 'id' | 'name'>;
    user?: User;
    show: boolean;
    onTest: (campaign: CampaignTestModel) => void;
    onHide: () => void;
}
const TestCampaignModal: FunctionComponent<TestCampaignModalProps> = (props) => {
    const [errors, setErrors] = useState<string[]>([]);
    const [recipientEmail, setRecipientEmail] = useState<string | null>(props.user ? props.user.email : null);
    const [recipientName, setRecipientName] = useState<string | null>(props.user ? props.user.displayName : null);
    const [recipientMobileNumber, setRecipientMobileNumber] = useState<string | null>(props.user ? props.user.phoneNumber : null);

    const testCampaign = () => {
        const campaignTestModel = {
            ...props.campaign,
            recipientEmail,
            recipientName,
            recipientMobileNumber
        };
        const validationErrors = validateCampaignTestModel(campaignTestModel);
        setErrors(validationErrors);
        if (validationErrors.length > 0) {
            return;
        }
        props.onTest(campaignTestModel);
    };
    
    return (
        <Modal 
            show={props.show} 
            centered={true} 
            className="test-campaign-modal"
            onHide={props.onHide}
        >
            <Modal.Header>
                <Modal.Title>
                    <Translate id="TEST_CAMPAIGN" data={{campaignName: props.campaign.name}}/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.campaign.type === CampaignType.SMS &&
                    <TextInputField
                        editMode={true}
                        name="recipientMobileNumber"
                        errors={errors}
                        errorCodes={[CampaignValidationErrors.RECIPIENT_MOBILE_NUMBER_IS_REQUIRED]}
                        overrideInternalErrors={['valueMissing']}
                        descriptionKey="RECIPIENT_MOBILE_NUMBER"
                        value={recipientMobileNumber}
                        onValueChanged={setRecipientMobileNumber}
                    />
                }
                {props.campaign.type === CampaignType.EMAIL &&
                    <>
                        <TextInputField
                            editMode={true}
                            name="recipientName"
                            errors={errors}
                            errorCodes={[CampaignValidationErrors.RECIPIENT_NAME_IS_REQUIRED]}
                            overrideInternalErrors={['valueMissing']}
                            descriptionKey="RECIPIENT_NAME"
                            value={recipientName}
                            onValueChanged={setRecipientName}
                        />
                        <TextInputField
                            editMode={true}
                            name="recipientEmail"
                            errors={errors}
                            errorCodes={[CampaignValidationErrors.RECIPIENT_EMAIL_IS_REQUIRED]}
                            overrideInternalErrors={['valueMissing']}
                            descriptionKey="RECIPIENT_EMAIL"
                            value={recipientEmail}
                            onValueChanged={setRecipientEmail}
                        />
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={testCampaign}><Translate id="TEST"/></Button>
                <Button variant="secondary" onClick={() => props.onHide()}><Translate id="CANCEL"/></Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TestCampaignModal;