import { faCheck, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {FunctionComponent, CSSProperties, MouseEvent, PropsWithChildren} from 'react';
import './Checkbox.css';

interface CheckboxProps {
    style?: CSSProperties;
    checked: boolean;
    onClick: (event: MouseEvent) => void;
}

const Checkbox: FunctionComponent<PropsWithChildren<CheckboxProps>> = (props) => {
    const cssClasses = 'span-with-checkbox cursor-pointer' + (props.checked ? ' checked' : '');
    const filterCommentsIcon = props.checked ? faCheck : faSquare;

    return (
        <div style={props.style}>
            <span className={cssClasses} onClick={props.onClick}>
                <FontAwesomeIcon icon={filterCommentsIcon} />
                <span>{props.children}</span>
            </span>
        </div>
    );
};

export default Checkbox;
