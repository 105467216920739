import React, { CSSProperties, PropsWithChildren, useEffect } from 'react';
import { SettingsProps } from '../interfaces/SettingsProps';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { SettingsActionCreator } from '../actions/SettingsActionCreators';
import MetadataActionsCreator from '../../applications/applicant/actions/MetadataActionsCreator';
import { Setting } from '../models/Setting';
import { Translate } from 'react-localize-redux';
import { UserProps } from '../interfaces/UserProps';
import { UserActionCreator } from '../actions/UserActionCreators';
import connectToSignalR from '../actions/SignalRActionCreator';
import { MetadataProps } from '../interfaces/MetadataProps';
import { useIsAuthenticated } from '@azure/msal-react';

interface LoadingScreenDispatchProps {
    loadSettings: () => void;
    loadMetadata: () => void;
    loadUser: () => void;
    connectToSignalR: () => void;
}

type LoadingScreenReduxProps = MetadataProps
    & SettingsProps
    & UserProps;

type LoadingScreenProps = LoadingScreenDispatchProps
    & LoadingScreenReduxProps

const LoadingScreen = (props: PropsWithChildren<LoadingScreenProps>) => {
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (props.settingsData === undefined || !props.settingsData.isLoaded || !props.settingsData.isLoading) {
            props.loadSettings();
        }

        if (props.metadata === undefined || !props.metadata.isLoaded || !props.metadata.isLoading) {
            props.loadMetadata();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.settingsData !== undefined && props.settingsData.isLoaded && props.settingsData.settings) {
            const titleSetting = props.settingsData.settings.find((s: Setting) => s.name === 'APP_TITLE');
            const faviconSetting = props.settingsData.settings.find((s: Setting) => s.name === 'FAVICON');
            document.title = titleSetting ? titleSetting.value : '';
            setFavicon(faviconSetting);
        }
    }, [props.settingsData !== undefined && props.settingsData.isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isAuthenticated
            && (props.userData === undefined || (props.userData.user === undefined && !props.userData.isLoading))) {
            props.loadUser();
            setTimeout(() => props.connectToSignalR(), 2500);
        }
    }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

    if (props.settingsData !== undefined && props.settingsData.isLoaded && props.userData.user !== undefined) {
        return props.children as any;
    }

    const divStyle: CSSProperties = {
        margin: '0px',
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)'
    };

    return (
        <div style={divStyle}>
            <Translate id="LOADING" />
        </div>
    );
}

function setFavicon(faviconSetting: Setting | undefined) {
    if (faviconSetting) {
        const link = (document.querySelector('link[rel*="icon"]') || document.createElement('link')) as HTMLLinkElement;
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = `${process.env.REACT_APP_BASE_API_URL}/api/settings/logos/${faviconSetting.value}`;
        document.getElementsByTagName('head')[0].appendChild(link);
    }
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.metadataActionsReducer,
    ...state.userActionsReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...SettingsActionCreator,
    ...MetadataActionsCreator,
    ...UserActionCreator,
    connectToSignalR
}, dispatch);

export default connect<LoadingScreenReduxProps, LoadingScreenDispatchProps, PropsWithChildren<{}>, any>(mapStateToProps, mapActionCreatorsToProps)(LoadingScreen);
