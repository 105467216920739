import {AxiosPromise} from 'axios';
import {AxiosClientFactory} from '../../../common/helpers/AxiosClientFactory';
import {CampaignSearchModel} from "../../../common/models/Campaign";

export const searchCampaigns = (filter: string): AxiosPromise<CampaignSearchModel[]> => {
    const config = {
        params: {
            filter
        }
    };
    return AxiosClientFactory
        .getInstance()
        .get<CampaignSearchModel[]>(`/api/campaigns/search`, config);
};
