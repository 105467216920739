import React, { FunctionComponent, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import TextInputField from './input-fields/TextInputField';
import { AxiosClientFactory } from '../helpers/AxiosClientFactory';
import { AxiosResponse } from 'axios';
import { ApplicantBriefReadModel } from '../models/ApplicantBriefReadModel';
import { getConsumerLoanCoApplicantDetails, getConsumerLoanApplicantDetails } from '../../applications/applicant/api/getConsumerLoanApplicantDetails';
import { ConsumerLoanApplicantDetails } from '../../applications/applicant/models/ConsumerLoanApplicantDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { beautifyLastName } from '../helpers/beautifyName';
import './add-co-applicant-modal.css';

interface AddCoApplicantModalProps {
    show: boolean;
    onSelectExistingApplicant: (applicantDetails: ConsumerLoanApplicantDetails) => void;
    onNewApplicant: () => void;
    onCancel: () => void;
}

const AddCoApplicantModal: FunctionComponent<AddCoApplicantModalProps> = (props) => {
    const [search, setSearch] = useState('');
    const [bufferedSearch, setBuffredSearch] = useState('');
    const [blockSelection, setBlockSelection] = useState(true);
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
    const [searchResults, setSearchResults] = useState<ApplicantBriefReadModel[]>([]);

    useEffect(() => {
        if (bufferedSearch.length < 3) {
            setSearchResults([]);
            setBlockSelection(true);
        } else {
            AxiosClientFactory
                .getInstance()
                .get(`/api/applicantdetails/search/${bufferedSearch}`)
                .then((response: AxiosResponse<ApplicantBriefReadModel[]>) => {
                    setSearchResults(response.data);
                    setBlockSelection(false);
                });
        }
    }, [bufferedSearch]);

    const onSearchValueChanged = (value: string) => {
        if (searchTimeout !== null) {
            clearTimeout(searchTimeout);
        }

        setSearch(value);
        setSearchTimeout(setTimeout(() => setBuffredSearch(value), 300));
    };

    const renderValues = (primary: string | null, secondary: string | null, icon: IconProp, flipIcon: boolean) => {
        if (primary === null && secondary === null) {
            return null;
        }

        let formattedValues: string;
        if (primary !== null && secondary === null) {
            formattedValues = `${primary}`;
        } else if (primary === null && secondary !== null) {
            formattedValues = `${secondary}`;
        } else {
            formattedValues = `${primary} | ${secondary}`;
        }

        return (
            <div>
                <FontAwesomeIcon
                    icon={icon}
                    flip={flipIcon ? 'horizontal' : undefined}
                />
                <span className="applicant-data">
                    {formattedValues}
                </span>
            </div>
        );
    };

    const renderSearchResult = (r: ApplicantBriefReadModel) => {
        const onClick = () => {
            if (blockSelection) {
                return;
            }
            setBlockSelection(true);
            (r.isCoApplicant ? getConsumerLoanCoApplicantDetails : getConsumerLoanApplicantDetails)(r.personId, r.applicationId, r.id)
                .then((applicantDetails: ConsumerLoanApplicantDetails) => {
                    props.onSelectExistingApplicant(applicantDetails);
                    setBlockSelection(false);
                });
        };
        return (
            <div className="applicant-search-result" onClick={onClick}>
                <div>
                    <FontAwesomeIcon icon={faUser} />
                    <span className="applicant-data">{beautifyLastName(`${r.firstName} ${r.lastName}`)} ({r.socialSecurityNumber})</span>
                </div>
                <div>{renderValues(r.mobileNumber, r.additionalMobileNumber, faPhone, true)}</div>
                <div>{renderValues(r.email, r.additionalEmail, faEnvelope, false)}</div>
            </div>
        );
    };

    return (
        <Modal show={props.show} centered={true}>
            <Modal.Header>
                <Modal.Title>
                    <Translate id="ADD_CO_APPLICANT_MODAL_TITLE" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button style={{ width: '100%', marginBottom: '15px' }} variant="light" onClick={props.onNewApplicant}>
                    <FontAwesomeIcon icon={faUser} />
                    <span style={{ marginLeft: '5px' }}>
                        <Translate id="NEW_CO_APPLICANT" />
                    </span>
                </Button>
                <TextInputField
                    value={search}
                    editMode={true}
                    name="search"
                    onValueChanged={onSearchValueChanged}
                    descriptionKey="SEARCH_APPLICANTS"
                />
                <div className="applicant-search-result-list">{searchResults.map(renderSearchResult)}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onCancel} variant="secondary">
                    <Translate id="CANCEL" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddCoApplicantModal;
