import {CampaignRecipient} from "../../common/models/Campaign";
import {AxiosClientFactory} from "../../common/helpers/AxiosClientFactory";
import {TableState} from "../../common/models/TableState";
import {CountCampaignRecipientsParams} from "./countCampaignRecipients";

export type GetCampaignRecipientsParams = CountCampaignRecipientsParams & TableState;

export const getCampaignRecipients = (params: GetCampaignRecipientsParams): Promise<CampaignRecipient[]> => {
    return AxiosClientFactory
        .getInstance()
        .get<CampaignRecipient[]>(`/api/campaigns/${params.campaignId}/recipients`, {params})
        .then((result) => result.data);
};