import React, { FunctionComponent } from 'react';
import { getTranslationKey } from '../helpers/getTranslationKey';
import { renderToStaticMarkup } from 'react-dom/server';
import { Translate } from 'react-localize-redux';

interface TranslationProps {
    id: string;
    prefix?: string;
    default?: string;
    data?: any;
}

const Translation: FunctionComponent<TranslationProps> = (props) => {
    const onMissingTranslation = () => props.default || props.id;
    return <Translate id={getTranslationKey(props.id, props.prefix)} options={{ renderToStaticMarkup, onMissingTranslation }} data={props.data} />;
};

export default Translation;
