export enum JobStatus {
    Deputy = 0,
    Domestic = 1,
    Incapable = 2,
    PrivateSector = 3,
    PublicSector = 4,
    Rehabilitation = 5,
    Retired = 6,
    SelfEmployed = 7,
    Student = 8,
    Unemployed = 9,
    FixedTime = 10
}
