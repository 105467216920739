import { CommandResult } from '../../../common/helpers/CommandResult';
import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { ApplicantsFilter } from '../models/ApplicantsFilter';

export const postEmailToMany = async (subject: string, body: string, senderName: string, senderAddress: string, applicantsFilter: ApplicantsFilter) => {
    const client = AxiosClientFactory.getInstance();

    const sendEmailToManyApplicantsCommand = {
        subject,
        body,
        senderName,
        senderAddress,
        applicantsFilter
    };

    const result = await client.post('api/emails/sendToMany', sendEmailToManyApplicantsCommand);

    return new CommandResult(result);
};
