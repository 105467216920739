import { ApplicationActionsAction } from '../actions/applicationsActionActionsCreator';
import { ApplicationActionsState } from '../components/ApplicationActions';

export function applicantActionsReducer(state: ApplicationActionsState, action: ApplicationActionsAction): ApplicationActionsState {
    switch (action.type) {
        case 'SET_BLACKLIST_VISIBLE': return {
            ...state,
            showBlacklistOptions: true
        };
        case 'SET_BLACKLIST_HIDDEN': return {
            ...state,
            showBlacklistOptions: false
        };
        case 'SET_GDPR_VISIBLE': return {
            ...state,
            showGdprOptions: true
        };
        case 'SET_GDPR_HIDDEN': return {
            ...state,
            showGdprOptions: false
        };
        case 'SET_REVOKE_MARKETING_CONSENTS_VISIBLE': return {
            ...state,
            showRevokeMarketingConsentsOptions: true
        };
        case 'SET_REVOKE_MARKETING_CONSENTS_HIDDEN': return {
            ...state,
            showRevokeMarketingConsentsOptions: false
        };
    }
}
