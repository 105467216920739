import React, {FunctionComponent, MouseEventHandler, ReactNode} from 'react';
import {Translate} from 'react-localize-redux';
import {getDebtTypeTranslateKey} from '../helpers/getDebtTypeTranslateKey';
import {Redemption} from '../models/Redemption';
import NumberInputField, {NumberInputFieldKind} from '../../../common/components/input-fields/NumberInputField';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCreditCard} from '@fortawesome/free-solid-svg-icons';
import {calculateMonthlyCost} from "../helpers/calculators";

interface LoansProps {
    redemptions: Redemption[];
    countryId: number;
    onClick?: MouseEventHandler<HTMLDivElement>;
}

const Loans: FunctionComponent<LoansProps> = (props: LoansProps) => {
    return (
        <div className="left-column column-card" onClick={props.onClick}>
            <div className="card-heading">
                <span className="title">
                    <Translate id="LOANS" />
                </span>
                <span className="options">
                    <FontAwesomeIcon icon={faCreditCard} />
                </span>
            </div>
            <div className="card-content row">
                {props.redemptions.map((r, i) => mapRedeption(r, i, props.countryId))}
                {renderTotal(props.redemptions, props.countryId)}
            </div>
        </div>
    );
};

function mapRedeption(redemption: Redemption, index: number, countryId: number): ReactNode {
    const valuePostFix = redemption.refinance ? '*' : '';

    return (
        <div className="card-content-item col-6" key={`card-content-item-loans-${index}`}>
            <NumberInputField
                name="balance"
                value={redemption.balance}
                description={<Translate id={getDebtTypeTranslateKey(redemption.debtType)} />}
                kind={NumberInputFieldKind.Money}
                countryId={countryId}
                readonlyValuePostFix={valuePostFix}
            />
        </div>
    );
}

function renderTotal(redemptions: Redemption[], countryId: number): ReactNode {
    const redemptionsToRefinance = redemptions.filter((x) => x.refinance);
    const redemptionsNotToRefinance = redemptions.filter((x) => !x.refinance);
    const renderLoansInTotal = (value: number) => (
        <NumberInputField 
            name="loansInTotal"
            countryId={countryId}
            descriptionKey="LOANS_IN_TOTAL" 
            kind={NumberInputFieldKind.Money} 
            value={value}
        />
    );
    const monthlyCostValue = redemptions.reduce((total, r) => total + (r.repaymentMonthlyCost || calculateMonthlyCost(r)), 0);
    const monthlyCost = (monthlyCostValue ? 
        <NumberInputField
            name="monthlyCostsTotal"
            countryId={countryId}
            descriptionKey="BANK_REPORT.MONTHLY_COST"
            kind={NumberInputFieldKind.Money}
            value={monthlyCostValue}
        /> : null
    )

    if (redemptionsToRefinance.length > 0 && redemptionsNotToRefinance.length > 0) {
        return (
            <>
                <div className="card-content-item col-6" key={`card-content-item-loans-${redemptions.length + 1}`}>
                    <NumberInputField
                        name="toBeRefinanced"
                        countryId={countryId}
                        descriptionKey="TO_BE_REFINANCED"
                        kind={NumberInputFieldKind.Money}
                        value={redemptionsToRefinance.reduce((total, r) => total + r.balance, 0)}
                    />
                </div>
                <div className="card-content-item col-6" key={`card-content-item-loans-${redemptions.length + 2}`}>
                    {renderLoansInTotal(redemptionsNotToRefinance.reduce((total, r) => total + r.balance, 0))}
                </div>
                <div className="card-content-item col-6" key={`card-content-item-loans-${redemptions.length + 3}`}>
                    {monthlyCost}
                </div>
            </>
        );
    }

    return (
        <>
            <div className="card-content-item col-6" key={`card-content-item-loans-${redemptions.length + 1}`}>
                {renderLoansInTotal(redemptions.reduce((total, r) => total + r.balance, 0))}
            </div>
            <div className="card-content-item col-6" key={`card-content-item-loans-${redemptions.length + 2}`}>
                {monthlyCost}
            </div>
        </>
    );
}

export default Loans;
