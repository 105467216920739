import { CommandResult } from '../../../common/helpers/CommandResult';
import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';

export const postEmailMessage = async (subject: string, body: string, senderAddress: string, senderName: string, address: string, fullName: string, applicationId: number) => {
    const client = AxiosClientFactory.getInstance();

    const sendEmailCommand = {
        subject,
        body,
        address,
        senderName,
        senderAddress,
        applicationId,
        ApplicantFullName: fullName
    };

    const result = await client.post('api/outboundemails', sendEmailCommand);

    return new CommandResult(result);
};
