import { DebtType } from '../models/DebtType';

export const getDebtTypeTranslateKey = (debtType: DebtType): string => {
    switch (debtType) {
        case DebtType.CarLoan: return 'DEBT_TYPE.CAR_LOAN';
        case DebtType.ConsumerLoan: return 'DEBT_TYPE.CONSUMER_LOAN';
        case DebtType.CreditCard: return 'DEBT_TYPE.CREDIT_CARD';
        case DebtType.MortgageLoan: return 'DEBT_TYPE.MORTGAGE_LOAN';
        case DebtType.StudentLoan: return 'DEBT_TYPE.STUDENT_LOAN';
        default:
            throw new Error('Unknown debt type - cannot translate');
    }
};
