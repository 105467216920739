import { AxiosRequestConfig } from 'axios';
import React, { PropsWithChildren } from 'react';
import { AxiosClientFactory } from '../helpers/AxiosClientFactory';
import { authRequest, getToken, publicClientApplication } from '../helpers/AuthProvider';
import {  MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

const requestInterceptor = async (requestConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const token = await getToken();
    if (!requestConfig.headers) {
        requestConfig.headers = {};
    }
    requestConfig.headers['Authorization'] = `Bearer ${token.accessToken}`;
    return requestConfig;
};

AxiosClientFactory.addRequestInterceptor(requestInterceptor);

const AzureRedirect = (props: PropsWithChildren<{}>) =>
(
    <MsalProvider instance={publicClientApplication}>
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
        >
            {props.children}
        </MsalAuthenticationTemplate>
    </MsalProvider>
);

export default AzureRedirect;
