import {ConsumerLoan} from "../models/ConsumerLoan";
import {ConsumerLoanApplicant} from "../models/ConsumerLoanApplicant";
import {DebtType} from "../models/DebtType";
import {ProductType} from "../../../common/models/ProductType";
import {calculateLTV} from "../helpers/calculators";
import { PropertiesSummary } from "../models/PropertiesSummary";

export const validateApplication = (application: ConsumerLoan) => {
    const results: string[] = [];
    if (loanAmountExceedsIncome(application)) {
        results.push('LOAN_AMOUNT_EXCEEDS_INCOME');
    }
    if (application.details.productType === ProductType.Mortgage && loanAmountExceedsMortgageLTV(application)) {
        results.push('LOAN_AMOUNT_EXCEEDS_MORTGAGE_LTV');
    }
    return results;
}

export function loanAmountExceedsIncome(amount: number, applicant: ConsumerLoanApplicant, coApplicant?: ConsumerLoanApplicant): boolean;
export function loanAmountExceedsIncome(application: ConsumerLoan): boolean;
export function loanAmountExceedsIncome(applicationOrAmount: ConsumerLoan | number, applicant?: ConsumerLoanApplicant, coApplicant?: ConsumerLoanApplicant | null): boolean {
    applicant = typeof applicationOrAmount !== 'number' ? applicationOrAmount.applicant : applicant;
    coApplicant = typeof applicationOrAmount !== 'number' ? applicationOrAmount.coApplicant : coApplicant;
    const amount = typeof applicationOrAmount !== 'number' ? applicationOrAmount.details.loanAmount : applicationOrAmount;
    const applicantYearlyIncome = applicant ? applicant.grossYearlyIncome + 12 * applicant.extraMonthlyIncome : 0;
    const coApplicantYearlyIncome = coApplicant ? coApplicant.grossYearlyIncome + 12 * coApplicant.extraMonthlyIncome : 0;
    const fiveTimesYearlyIncome = (applicantYearlyIncome + coApplicantYearlyIncome) * 5;
    return fiveTimesYearlyIncome > 0 && fiveTimesYearlyIncome < amount;
}

export function loanAmountExceedsMortgageLTV(loansTotal: number, property: PropertiesSummary): boolean;
export function loanAmountExceedsMortgageLTV(application: ConsumerLoan): boolean;
export function loanAmountExceedsMortgageLTV(applicationOrLoansTotal: ConsumerLoan | number, property?: PropertiesSummary): boolean {
    let totalLoanAmount = typeof applicationOrLoansTotal !== 'number' ? applicationOrLoansTotal.details.loanAmount : applicationOrLoansTotal;
    let prop = typeof applicationOrLoansTotal !== 'number' ? applicationOrLoansTotal.property : property;
    if (typeof applicationOrLoansTotal !== 'number') {
        applicationOrLoansTotal.redemptions.forEach(redemption => {
            if (!redemption.refinance && redemption.debtType === DebtType.MortgageLoan) {
                totalLoanAmount += redemption.balance;
            }
        });
    }
    const ltv = calculateLTV(totalLoanAmount, prop ? prop.totalCommonDebt : 0, prop ? prop.totalEstimatedValue : 0);
    return ltv !== Infinity && ltv > 0.85;
}
