import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';

export const putSubstatuses = async (personId: string | number, loanId: string | number, substatusIds: number[]): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .put(`api/applicants/${personId}/consumerloans/${loanId}/substatuses`, { substatusIds });

    return new CommandResult(result);
};
