import { SetTitleAction } from '../actions/SetTitleActionCreator';

export function setTitleActionsReducer(state: any, action: SetTitleAction) {
    state = state || {};

    if (action.type === 'SET_TITLE') {
        return {
            ...state,
            pageTitle: action.pageTitle
        };
    }

    return state;
}
