import React, {FunctionComponent, useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {Campaign, CampaignType, LaunchCampaignModel} from "../../../../common/models/Campaign";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import {Button, Modal, Table} from "react-bootstrap";
import {CampaignValidationErrors, validateLaunchCampaignModel} from "../helpers/validators";
import NumberInputField, {NumberInputFieldKind} from "../../../../common/components/input-fields/NumberInputField";
import DateTimeInputField, {DateTimeInputFieldKind} from "../../../../common/components/input-fields/DateTimeInputField";
import moment from "moment";
import TableHeader from "../../../../common/components/table/TableHeader";
import TableRows from "../../../../common/components/table/TableRows";
import TablePagination from "../../../../common/components/table/TablePagination";

interface LaunchPlanPart {
    date: Date;
    amount: number;
}
interface LaunchCampaignModalProps {
    campaign: Campaign;
    recipients: number;
    show: boolean;
    onLaunched: (campaign: LaunchCampaignModel) => void;
    onHide: () => void;
}
const LaunchCampaignModal: FunctionComponent<LaunchCampaignModalProps> = (props) => {
    const [errors, setErrors] = useState<string[]>([]);
    const [launchDate, setLaunchDate] = useState<Date>(getDefaultLaunchDate());
    const [startBatchSize, setStartBatchSize] = useState<number | null>(parseFloat(localStorage['startBatchSize'] || null));
    const [dailyPercentRampUp, setDailyPercentRampUp] = useState<number | null>(parseFloat(localStorage['dailyPercentRampUp'] || null));
    const [launchPlan, setLaunchPlan] = useState<LaunchPlanPart[]>([]);
    const [launchPlanPageNumber, setLaunchPlanPageNumber] = useState<number>(0);
    const launchPlanPageSize = 7;

    useEffect(() => {
        if (startBatchSize !== null) {
            localStorage['startBatchSize'] = startBatchSize;
        }
    }, [startBatchSize]);

    useEffect(() => {
        if (dailyPercentRampUp !== null) {
            localStorage['dailyPercentRampUp'] = dailyPercentRampUp;
        }
    }, [dailyPercentRampUp]);

    useEffect(() => {
        if (startBatchSize && dailyPercentRampUp && launchDate && props.recipients) {
            setLaunchPlan(getLaunchPlan(startBatchSize, dailyPercentRampUp, launchDate, props.recipients));
            setLaunchPlanPageNumber(0);
        }
    }, [startBatchSize, dailyPercentRampUp, launchDate, props.recipients]);
    
    if (props.campaign.type === CampaignType.SMS) {
        return (
            <ConfirmationModal
                show={props.show}
                title={<Translate id="WARNINGS.ARE_YOU_SURE" />}
                message={<Translate id="WARNINGS.DO_YOU_WANT_TO_SEND_CAMPAIGN" data={{name: props.campaign.name, recipients: props.recipients}} />}
                onConfirm={() => props.onLaunched({id: props.campaign.id})}
                onCancel={props.onHide}
            />
        )
    }

    const launchCampaign = () => {
        const campaignLaunchModel: LaunchCampaignModel = {
            id: props.campaign.id,
            launchDate: launchDate.toISOString(),
            startBatchSize,
            dailyPercentRampUp
        };
        const validationErrors = validateLaunchCampaignModel(campaignLaunchModel);
        setErrors(validationErrors);
        if (validationErrors.length > 0) {
            return;
        }
        props.onLaunched(campaignLaunchModel);
    };

    return (
        <Modal
            show={props.show}
            centered={true}
            className="test-campaign-modal"
            onHide={props.onHide}
        >
            <Modal.Header>
                <Modal.Title>
                    <Translate id="SEND_CAMPAIGN" data={{campaignName: props.campaign.name}}/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DateTimeInputField
                    kind={DateTimeInputFieldKind.DateWithTime}
                    editMode={true}
                    errors={errors}
                    errorCodes={[CampaignValidationErrors.LAUNCH_DATE_FROM_PAST]}
                    name="launchDate"
                    descriptionKey="LAUNCH_DATE"
                    value={launchDate}
                    onValueChanged={date => setLaunchDate(date || getDefaultLaunchDate())}
                />
                <NumberInputField
                    editMode={true}
                    name="startBatchSize"
                    errors={errors}
                    errorCodes={[CampaignValidationErrors.START_BATCH_SIZE_IS_REQUIRED]}
                    overrideInternalErrors={['valueMissing']}
                    descriptionKey="START_BATCH_SIZE"
                    value={startBatchSize}
                    onValueChanged={setStartBatchSize}
                />
                <NumberInputField
                    kind={NumberInputFieldKind.Percent}
                    editMode={true}
                    name="dailyPercentRampUp"
                    errors={errors}
                    errorCodes={[CampaignValidationErrors.DAILY_PERCENT_RAMP_UP_IS_REQUIRED]}
                    overrideInternalErrors={['valueMissing']}
                    descriptionKey="DAILY_PERCENT_RAMP_UP"
                    value={dailyPercentRampUp}
                    onValueChanged={setDailyPercentRampUp}
                />
                <Table style={{marginTop: '20px'}}>
                    <TableHeader cells={[{index: 0, translateKey: 'DATE'}, {index: 1, translateKey: 'BANK_REPORT.AMOUNT'}]}/>
                    <TableRows<LaunchPlanPart>
                        rows={launchPlan.slice(launchPlanPageNumber * launchPlanPageSize, (launchPlanPageNumber + 1) * launchPlanPageSize)}
                        renderRow={(part, index) => (
                            <tr key={`${index}-${part.date.toISOString()}`}>
                                <td>{part.date.toLocaleDateString()}</td>
                                <td>{part.amount}</td>
                            </tr>
                        )}
                    />
                </Table>
                <TablePagination
                    pageNumber={launchPlanPageNumber}
                    pageSize={launchPlanPageSize}
                    onPagePicked={setLaunchPlanPageNumber}
                    count={launchPlan.length} 
                    pickableSizes={[]} 
                    onPageSizePicked={() => {/* noop*/}}                
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={launchCampaign}><Translate id="SEND_TO_RECIPIENTS" data={{recipients: props.recipients}}/></Button>
                <Button variant="secondary" onClick={() => props.onHide()}><Translate id="CANCEL"/></Button>
            </Modal.Footer>
        </Modal>
    )
}

const getDefaultLaunchDate = () => {
    const preferredLaunchHour = 10;
    let defaultLaunchDate = moment().hour(preferredLaunchHour).minute(0).second(0).millisecond(0);
    if (defaultLaunchDate < moment()) {
        defaultLaunchDate.add(1, 'day');
    }
    return defaultLaunchDate.toDate();
}

const getLaunchPlan = (startBatchSize: number, dailyPercentRampUp: number, launchDate: Date, recipients: number) => {
    const launchPlan: LaunchPlanPart[] = [];
    while (recipients > 0) {
        launchPlan.push({
            date: launchDate,
            amount: startBatchSize < recipients ? startBatchSize : recipients
        });
        recipients -= startBatchSize;
        launchDate = moment(launchDate).add(1, 'day').toDate();
        startBatchSize = Math.floor((dailyPercentRampUp + 100) * startBatchSize / 100);
    }
    return launchPlan;
}

export default LaunchCampaignModal;