import { Action, Dispatch } from 'redux';
import { AxiosClientFactory } from '../helpers/AxiosClientFactory';
import { AxiosResponse } from 'axios';
import { ReceivedMessageNotification } from '../models/ReceivedMessageNotification';
import { registerSignalRActionCreator } from './SignalRActionCreator';
import { User } from '../models/User';
import {CampaignSearchModel} from "../models/Campaign";

export const SET_UNREAD_MESSAGE_COUNT = 'SET_UNREAD_MESSAGE_COUNT';
export const SET_RECEIVED_MESSAGES_CAMPAIGN_FILTER = 'SET_RECEIVED_MESSAGES_CAMPAIGN_FILTER';
export const SET_RECEIVED_MESSAGES_PRIMARY_LANGUAGE_FILTER = 'SET_RECEIVED_MESSAGES_PRIMARY_LANGUAGE_FILTER';
export const SET_RECEIVED_MESSAGES_SUBSTATUSES_FILTER = 'SET_RECEIVED_MESSAGES_SUBSTATUSES_FILTER';
export const MARK_AS_READ = 'MARK_AS_READ';
export const MARK_AS_UNREAD = 'MARK_AS_UNREAD';
export const HANDLE_RECEIVED_MESSAGE_NOTIFICATION = 'HANDLE_RECEIVED_MESSAGE_NOTIFICATION';

interface SetUnreadMessageCount extends Action<typeof SET_UNREAD_MESSAGE_COUNT> {
    count: number;
}

interface SetCampaign extends Action<typeof SET_RECEIVED_MESSAGES_CAMPAIGN_FILTER> {
    campaign: CampaignSearchModel | null;
}

interface SetPrimaryLanguage extends Action<typeof SET_RECEIVED_MESSAGES_PRIMARY_LANGUAGE_FILTER> {
    primaryLanguage: string | null;
}

interface SetSubstatuses extends Action<typeof SET_RECEIVED_MESSAGES_SUBSTATUSES_FILTER> {
    substatusIds: number[];
}

interface MarkAsRead extends Action<typeof MARK_AS_READ> {
    id: number;
}

interface MarkAsUnread extends Action<typeof MARK_AS_UNREAD> {
    id: number;
}

interface HandleReceivedMessageNotification extends Action<typeof HANDLE_RECEIVED_MESSAGE_NOTIFICATION> {
    notification: ReceivedMessageNotification;
    user?: User;
}

export type ReceivedMessagesAction = SetUnreadMessageCount | SetCampaign | SetPrimaryLanguage | SetSubstatuses | MarkAsRead | MarkAsUnread | HandleReceivedMessageNotification;

registerSignalRActionCreator('UpdateInboundSmsState',
    (notification: ReceivedMessageNotification, getState: any): HandleReceivedMessageNotification =>
        ({ type: HANDLE_RECEIVED_MESSAGE_NOTIFICATION, notification, user: getState().userActionsReducer.userData.user as User | undefined }));

export const ReceivedMessagesActionCreator = {
    refreshUnreadMessageCount: () => {
        return (dispatch: Dispatch<SetUnreadMessageCount>, getState: any): void => {
            const campaign = getState().receivedMessagesActionsReducer.campaign;
            const campaignId = campaign ? campaign.id : undefined; 
            const params = {
                campaignId,
                primaryLanguage: getState().receivedMessagesActionsReducer.primaryLanguage,
                substatusIds: getState().receivedMessagesActionsReducer.substatusIds
            };

            AxiosClientFactory
                .getInstance(undefined, {silent: true})
                .get<number>('/api/inboundSmses/count?unreadOnly=true', {params})
                .then((response: AxiosResponse<number>) => dispatch({type: SET_UNREAD_MESSAGE_COUNT, count: response.data}));
        };
    },
    setCampaignFilter: (campaign: CampaignSearchModel | null) => {
        return (dispatch: Dispatch<SetCampaign>) =>
            dispatch({type: SET_RECEIVED_MESSAGES_CAMPAIGN_FILTER, campaign});
    },
    setPrimaryLanguageFilter: (primaryLanguage: string | null) => {
        return (dispatch: Dispatch<SetPrimaryLanguage>) =>
            dispatch({type: SET_RECEIVED_MESSAGES_PRIMARY_LANGUAGE_FILTER, primaryLanguage});
    },
    setSubstatusesFilter: (substatusIds: number[]) => {
        return (dispatch: Dispatch<SetSubstatuses>) =>
            dispatch({type: SET_RECEIVED_MESSAGES_SUBSTATUSES_FILTER, substatusIds});
    },
    markAsRead: (id: number) => {
        return (dispatch: Dispatch<MarkAsRead>): Promise<any> => {
            return AxiosClientFactory
                .getInstance()
                .post(`/api/inboundSmses/${id}/markAsRead`, {})
                .then(() => dispatch({type: MARK_AS_READ, id}));
        };
    },
    markAsUnread: (id: number) => {
        return (dispatch: Dispatch<MarkAsUnread>): Promise<any> => {
            return AxiosClientFactory
                .getInstance()
                .post(`/api/inboundSmses/${id}/markAsUnread`, {})
                .then(() => dispatch({type: MARK_AS_UNREAD, id}));
        };
    }
};
