import Tooltip from "../components/Tooltip";
import React from "react";

/* 
    Finds http or https link in given content
    Replace them with the host anchor and puts whole link in the tooltip 
 */
export const beautifyLinks = (content: string) => {
    // eslint-disable-next-line no-useless-escape
    const urlregexp = /(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,!@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
    const links = content.match(urlregexp);
    if (!links) {
        return content;
    }
    const chunks = links.reduce((content, link, id) => content.split(link).join(`$LINK_${id}$`), content).split('$');
    return (
        <>
            {chunks.map((chunk, id) => {
                if (chunk.includes('LINK_')) {
                    const id = chunk.match(/\d/)![0];
                    const host = (new URL(links[id])).host;
                    return <Tooltip content={links[id]} key={id}>
                        <a href={links[id]} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>{host}</a>
                    </Tooltip>
                }
                return <span key={id}>{chunk}</span>
            })}
        </>
    )
}