import {
    GET_SUBSTATUSES_BEGIN,
    GET_SUBSTATUSES_FAILURE,
    GET_SUBSTATUSES_SUCCESS,
    GetSubstatusesAction
} from '../actions/SubstatusesActionsCreator';

export function substatusesReducer(state: any, action: GetSubstatusesAction) {
    state = state || {
        isLoading: false,
        substatuses: undefined
    };

    switch (action.type) {
        case GET_SUBSTATUSES_BEGIN:
            return {
                ...state,
                isLoading: true,
                substatuses: undefined
            };
        case GET_SUBSTATUSES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                substatuses: action.substatuses
            };
        case GET_SUBSTATUSES_FAILURE:
            return {
                ...state,
                isLoading: false,
                substatuses: undefined
            };
        default: return state;
    }
}
