import {Setting} from '../models/Setting';
import {SettingsProps} from '../interfaces/SettingsProps';
import {ProductType} from '../models/ProductType';
import {SubstatusRule} from "../models/SubstatusRule";
import {MenuItem} from '../models/MenuItem';
import {Role} from '../models/Role';
import {MortgageCalculatorOptions} from "../../applications/applicant/components/MortgageCalculatorModal/MortgageCalculatorOptions";
import {CustomerCommunicationConfig} from "../../campaigns/models/CustomerCommunicationConfig";

const defaultBackgroundColor = '#677381';
const defaultSidebarBackgroundColor = '#000000';
const defaultSidebarFontColor = '#FFFFFF';
const defaultLogo = '41-5E-95-EA-8C-43-6A-24';

export const getSettingValue = (settingsProps: SettingsProps, settingName: string, defaultValue?: string) => {
    if (settingsProps.settingsData.settings && settingsProps.settingsData.isLoaded) {
        const setting = settingsProps.settingsData.settings.find((x: Setting) => (x.name === settingName));
        
        return setting ? setting.value : defaultValue;
    }

    return defaultValue;
};

export const getCountryId = (settingsProps: SettingsProps) => {
    return Number(getSettingValue(settingsProps, 'COUNTRY_ID'));
};

export const getMainBackgroundColorStyle = (settingsProps: SettingsProps) => {
    return { backgroundColor: getSettingValue(settingsProps, 'MAIN_BG_COLOR', defaultBackgroundColor) };
};

export const getSidebarBackgroundColorStyle = (settingsProps: SettingsProps) => {
    return { backgroundColor: getSettingValue(settingsProps, 'SIDEBAR_BG_COLOR', defaultSidebarBackgroundColor) };
};

export const getSidebarFontColor = (settingsProps: SettingsProps) => {
    return { color: getSettingValue(settingsProps, 'SIDEBAR_FONT_COLOR', defaultSidebarFontColor) };
};

export const getLogo = (settingsProps: SettingsProps) => {
    return getSettingValue(settingsProps, 'APP_LOGO') || defaultLogo;
};

export const getCreditScoreRules = (settingsProps: SettingsProps): any | undefined => {
    if (settingsProps.settingsData.settings && settingsProps.settingsData.isLoaded) {
        const setting = settingsProps.settingsData.settings.find((x) => x.name === 'CREDIT_SCORE_RULES');
        return setting !== undefined ? JSON.parse(setting.value) : undefined;
    }

    return undefined;
};

export const getMyApplicationUrl = (settingsProps: SettingsProps, sourceId: number, publicId: string) => {
    const urlsJSON = getSettingValue(settingsProps, 'MY_APPLICATION_URLS');
    if (urlsJSON) {
        const urls = JSON.parse(urlsJSON);
        const pattern = (urls[sourceId.toString()] || urls.default) as string;

        if (pattern) {
            // eslint-disable-next-line no-template-curly-in-string
            return pattern.replace('${publicId}', publicId);
        }
    }

    return undefined;
};

export const getIncompleteFormLink = (settingsProps: SettingsProps, lang: string | null, publicId: string | null, productType: ProductType | null, sourceId: number | null) => {
    const setting = settingsProps.settingsData.settings.find((s) => s.name === 'INCOMPLETE_FORM_URL_PATTERNS');
    if (setting === undefined || publicId === null || productType === null || sourceId === null) {
        return undefined;
    }

    const urlsJSON = getSettingValue(settingsProps, 'INCOMPLETE_FORM_URL_PATTERNS');
    const productTypeName = ProductType[productType];

    if (urlsJSON) {
        const urls = JSON.parse(urlsJSON);
        const urlsBySource = urls[sourceId.toString()] || urls.default;
        const pattern = urlsBySource[productTypeName] || urls.default[productTypeName];

        if (pattern) {
            let url = pattern.replace('{publicId}', publicId);

            if (lang !== null) {
                url += `&lang=${lang}`;
            }

            return url;
        }
    }

    return undefined;
};

export const getLoanAmountLimits = (settingsProps: SettingsProps, productType: ProductType | null): { Min: number, Max: number } => {
    const settingJson = getSettingValue(settingsProps, 'LOAN_AMOUNT_RANGE');
    if (settingJson === undefined) {
        throw new Error('Setting "LOAN_AMOUNT_RANGE" was not found');
    }

    if (productType === null) {
        return { Min: 0, Max: 0 };
    }

    return JSON.parse(settingJson)[ProductType[productType]];
};

export const getAutomaticSubstatusesRules = (settingsProps: SettingsProps): SubstatusRule[] => {
    const settingJson = getSettingValue(settingsProps, 'AUTOMATIC_SUBSTATUSES_RULES');
    if (settingJson === undefined) {
        return [];
    }
    return JSON.parse(settingJson);
};

export const getSidebarMenuItems = (settingsProps: SettingsProps): MenuItem[] => {
    const settingJson = getSettingValue(settingsProps, 'SIDEBAR_MENU_ITEMS');
    if (settingJson === undefined) {
        return [];
    }

    const items = JSON.parse(settingJson) as MenuItem[];

    return items.map((m) => {
        m.roles = m.roles && m.roles.map((r) => Number.isInteger(r) ? r : Role[r.toString()]);
        return m;
    });
};

export const getMortgageCalculatorOptions = (settingsProps: SettingsProps): MortgageCalculatorOptions => {
    const settingJson = getSettingValue(settingsProps, 'MORTGAGE_CALCULATOR_OPTIONS');
    if (settingJson === undefined) {
        throw new Error('Setting "MORTGAGE_CALCULATOR_OPTIONS" was not found');
    }
    return JSON.parse(settingJson);
};

export const getCustomerCommunicationConfig = (settingsProps: SettingsProps): CustomerCommunicationConfig => {
    const settingJson = getSettingValue(settingsProps, 'CUSTOMER_COMMUNICATION_CONFIG');
    if (settingJson === undefined) {
        throw new Error('Setting "CUSTOMER_COMMUNICATION_CONFIG" was not found');
    }
    return JSON.parse(settingJson);
};

export const getDebtInformationUpdateLink = (settingsProps: SettingsProps, publicId: string | null) => {
    const setting = settingsProps.settingsData.settings.find((s) => s.name === 'DEBT_INFORMATION_UPDATE_URL_PATTERN');
    if (setting === undefined || publicId === null) {
        return undefined;
    }

    const pattern = getSettingValue(settingsProps, 'DEBT_INFORMATION_UPDATE_URL_PATTERN')

    if (pattern) {
        return pattern.replace('{publicId}', publicId);
    }

    return undefined;
};