import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {FunctionComponent, useState, PropsWithChildren, Dispatch, SetStateAction, ReactNode, MouseEventHandler} from 'react';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import copy from 'copy-to-clipboard';
import Tooltip from './Tooltip';
import { Translate } from 'react-localize-redux';
import ReactDOM from 'react-dom';

export interface CopyToClipboardButtonProps {
    content: string;
    className?: string;
}

interface CopyToClipboardButtonState {
    show: boolean;
    copied: boolean;
    hideTooltip: boolean;
}

const CopyToClipboardButton: FunctionComponent<PropsWithChildren<CopyToClipboardButtonProps>> = (props) => {
    const [state, setState] = useState<CopyToClipboardButtonState>(createEmptyState());
    const show = () => setState({...state, show: true});
    const hide = () => setState(createEmptyState());

    if (props.children) {
        // onMouseLeave is not fired when mouse moved over tooltip, so it has also to handle mouse out and hide button if pointer outside main <span>
        const onMouseOut: MouseEventHandler<HTMLSpanElement> = (event) => handleMouseOut(event, setState);

        return (
            <span onMouseEnter={show} onMouseLeave={hide} onMouseOut={onMouseOut} className={props.className}>
                {props.children}
                {createButton(props.content, state, setState, state.show)}
            </span>
        );
    }

    return createButton(props.content, state, setState, state.show);
};

function handleMouseOut(event: React.MouseEvent<HTMLSpanElement, MouseEvent>, setState: Dispatch<SetStateAction<CopyToClipboardButtonState>>) {
    if (isOut(event)) {
        setState(createEmptyState());
    }
}

function isOut(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    const element = ReactDOM.findDOMNode(event.currentTarget) as HTMLSpanElement; // currentTarget is element on which we listen, main <span> in this case
    const rect = element.getBoundingClientRect();
    return event.clientX < rect.left
        || event.clientX > rect.right
        || event.clientY < rect.top
        || event.clientY > rect.bottom;
}

function createEmptyState() {
    return {show: false, copied: false, hideTooltip: false};
}

function createButton(content: string, state: CopyToClipboardButtonState, setState: Dispatch<SetStateAction<CopyToClipboardButtonState>>, visible: boolean) {
    const onClick: MouseEventHandler<HTMLSpanElement> = (e) => {
        e.stopPropagation();
        if (copy(content)) {
            setState({...state, copied: true, hideTooltip: true});
        }
    };
    const visibility = visible ? 'visible' : 'hidden';
    const button = <span className="grey-button" onClick={onClick} style={{marginLeft: '2px', visibility}}><FontAwesomeIcon icon={faCopy}/></span>;

    // hide tooltip for a moment in order to update its size
    if (state.hideTooltip) {
        setTimeout(() => setState((oldState) => ({...oldState, hideTooltip: false})));
        return button;
    }

    return <Tooltip content={getTooltipContent(state.copied)}>{button}</Tooltip>;
}

function getTooltipContent(copied: boolean): ReactNode {
    return <Translate id={copied ? 'COPIED' : 'CLICK_TO_COPY'}/>;
}

export default CopyToClipboardButton;
