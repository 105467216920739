import {
    GET_PUBLISHERS_BEGIN,
    GET_PUBLISHERS_FAILURE,
    GET_PUBLISHERS_SUCCESS,
    GetPublishersAction
} from '../actions/PublishersActionsCreator';

export function publishersReducer(state: any, action: GetPublishersAction) {
    state = state || {
        publishersData: {
            isLoading: false,
            publishers: undefined
        }
    };

    switch (action.type) {
        case GET_PUBLISHERS_BEGIN:
            return {
                ...state,
                publishersData: {
                    isLoading: true,
                    publishers: undefined
                }
            };
        case GET_PUBLISHERS_SUCCESS:
            return {
                ...state,
                publishersData: {
                    isLoading: false,
                    publishers: action.publishers
                }
            };
        case GET_PUBLISHERS_FAILURE:
            return {
                ...state,
                publishersData: {
                    isLoading: false,
                    publishers: undefined
                }
            };
        default: return state;
    }
}
