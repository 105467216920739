import { Transaction } from '../models/Transaction';
import { BankStatus } from '../../../common/models/BankStatus';
import { Bank } from '../models/Bank';
import { ProductType } from '../../../common/models/ProductType';
import moment from 'moment';

export const isWaitingForBankReply = (transaction: Transaction) =>
    !transaction.isProcessed || transaction.bankStatus === null || transaction.bankStatus === undefined;

export const isApplicationReadOnly = (transactions: Transaction[]) =>
    transactions.some((t) => isWaitingForBankReply(t)
        || ((t.bankStatus === BankStatus.GrantedAccepted || t.bankStatus === BankStatus.GrantedRejected) && !t.isBankReportProcessed));

export const isBankReportReadOnly = (transaction: Transaction) =>
    (transaction.bankStatus === BankStatus.GrantedAccepted || transaction.bankStatus === BankStatus.GrantedRejected)
        && transaction.isBankReportProcessed === true;

export const hasBankIntegrtaion = (bank: Bank, productType: ProductType | null) =>
    bank.bankIntegrations.some((i) => i.productType === productType);

export const getSendUnlockTime = (transaction: Transaction) => {
    const unlockTime = moment(transaction.modifiedOn).add(parseInt(process.env.REACT_APP_RESEND_UNLOCK_TIME_IN_MINUTES || '5', 10), 'minutes');

    if (unlockTime < moment()) {
        return null;
    }

    return unlockTime.toDate();
};
