import React, { FunctionComponent, useState, useEffect } from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import ActionIcon from './ActionIcon';
import { Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface TimeLockedActionIconProps {
    unlockTime: Date | null;
    tooltipKey: string;
    action: () => void;
    icon: IconDefinition;
}

const TimeLockedActionIcon: FunctionComponent<TimeLockedActionIconProps> = (props) => {
    const [timeLeft, setTimeLeft] = useState(getTimeLeft(props.unlockTime));

    useEffect(() => {
        if (!timeLeft) {
            return;
        }
        const timeout = setTimeout(() => setTimeLeft(getTimeLeft(props.unlockTime)), 1000);
        return () => clearTimeout(timeout);
    }, [timeLeft]); // eslint-disable-line react-hooks/exhaustive-deps

    const disabledTooltip = timeLeft ? <span><Translate id={props.tooltipKey}/><br/><Translate id="ACTION_UNLOCK_INFORMATION" data={{timeLeft}}/></span> : undefined;

    // hide tooltip for a moment to rescale it
    if (timeLeft === '00:00') {
        setTimeout(() => setTimeLeft(null));
        return <FontAwesomeIcon icon={props.icon}/>;
    }

    return <ActionIcon action={props.action} icon={props.icon} tooltipKey={props.tooltipKey} disabled={timeLeft !== null} tooltip={disabledTooltip} />;
};

function getTimeLeft(unlockTime: Date | null) {
    if (!unlockTime) {
        return null;
    }

    const diff = moment(unlockTime).diff(moment());

    if (diff <= 0) {
        return null;
    }

    return moment(diff).format('mm:ss');
}

export default TimeLockedActionIcon;
