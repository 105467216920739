import React, {FunctionComponent} from "react";
import {Refinance} from "../../../../common/models/Refinance";
import {Translate} from "react-localize-redux";
import NumberInputField, {NumberInputFieldKind} from "../../../../common/components/input-fields/NumberInputField";

interface BankRepliesRefinancesProps {
    refinances: Refinance[];
    countryId: number;
}
const BankRepliesRefinances: FunctionComponent<BankRepliesRefinancesProps> = (props) => {
    if (!props.refinances.length) {
        return null;
    }
    return (
        <div className="bank-replies-refinances">
            <div className="title">
                <Translate id="BANK_REPORT.REFINANCED" />
            </div>
            <table className="bank-report-history">
                <thead>
                    <tr>
                        <th>
                            <Translate id="BANK_REPORT.LENDER" />
                        </th>
                        <th>
                            <Translate id="BANK_REPORT.AMOUNT" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.refinances.map(r => 
                        <tr>
                            <td>
                                {r.lender}
                            </td>
                            <td>
                                <NumberInputField
                                    name="balance"
                                    value={r.balance}
                                    kind={NumberInputFieldKind.Money}
                                    countryId={props.countryId}
                                    editMode={false}
                                />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}
export default BankRepliesRefinances;