export const getCivilStatusTranslateKey = (status: string): string => {
    switch (status) {
        case 'married':
            return 'CIVIL_STATUS.MARRIED';
        case 'not married':
            return 'CIVIL_STATUS.NOT_MARRIED';
        case 'widow':
            return 'CIVIL_STATUS.WIDOW';
        case 'partner':
            return 'CIVIL_STATUS.PARTNER';
        case 'divorced':
            return 'CIVIL_STATUS.DIVORCED';
        case 'separated':
            return 'CIVIL_STATUS.SEPARATED';
        case 'registered partner':
            return 'CIVIL_STATUS.REGISTERED_PARTNER';
        default:
            return 'CIVIL_STATUS.UNSUPPORTED';
    }
};
