export enum BankStatus {
    Granted,
    Denied,
    Investigation,
    GrantedSelected,
    GrantedAccepted,
    GrantedRejected,
    Sent,
    Paid
}
