import { AxiosClientFactory } from '../../common/helpers/AxiosClientFactory';
import { AxiosResponse } from 'axios';

export const postSettingsLogo = async (file: any | undefined): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        if (file !== undefined) {
            const formData = new FormData();
            formData.append('files', file, file.name);

            AxiosClientFactory
                .getInstance()
                .post(`/api/settings/logos`, formData)
                .then((response: AxiosResponse<string>) => resolve(response.data))
                .catch((error) => reject(error));
        } else {
            reject('No file provided');
        }
    });
};
