import React, {FunctionComponent} from 'react';
import {ApplicantsTableHeaderModel} from '../models/ApplicantsTableHeaderModel';
import {ApplicantsTableStateProps} from '../../../common/interfaces/ApplicantsTableStateProps';
import {connect} from 'react-redux';
import TableHeader, {TableHeaderCellModel} from "../../../common/components/table/TableHeader";

interface ApplicantsTableHeaderProps {
    headers: ApplicantsTableHeaderModel[];
    onClick(index: number): void;
}

const ApplicantsTableHeader: FunctionComponent<ApplicantsTableHeaderProps & ApplicantsTableStateProps> = (props) => {
    const filteredHeaders = props.tableState.loanStatus === 1 ? props.headers : props.headers.filter((h) => h.index !== 9);
    const cells = filteredHeaders.map<TableHeaderCellModel>(header => ({
        ...header,
        orderDirection: header.index === props.tableState.orderBy ? props.tableState.orderDirection : undefined
    }));
    return <TableHeader cells={cells} onClick={props.onClick} sorting={true}/>;
};

const mapStateToProps = (state: any) => ({...state.applicantsViewDataReducer});

export default connect<ApplicantsTableStateProps, {}, {}, any>(mapStateToProps)(ApplicantsTableHeader);
