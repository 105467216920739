import Axios, {AxiosResponse} from 'axios';
import {Action, Dispatch} from 'redux';
import {AdvertisementSite} from '../models/AdvertisementSite';
import {ApplicationSource} from '../models/ApplicationSource';
import {Bank} from '../models/Bank';
import {Team} from "../models/Team";
import {getTeams} from "../api/getTeams";
import {getBanks} from "../api/getBanks";
import {getAdvertisementSites} from "../api/getAdvertisementSites";
import {getApplicationSources} from "../api/getApplicationSources";

export const GET_METADATA_BEGIN = 'GET_METADATA_BEGIN';
export const GET_METADATA_SUCCESS = 'GET_METADATA_SUCCESS';
export const GET_METADATA_FAILURE = 'GET_METADATA_FAILURE';

interface GetMetadataBeginAction extends Action<typeof GET_METADATA_BEGIN> {
}

interface GetMetadataSuccessAction extends Action<typeof GET_METADATA_SUCCESS> {
    banks: Bank[];
    advertisementSites: AdvertisementSite[];
    applicationSources: ApplicationSource[];
    teams: Team[];
}

interface GetMetadataFailureAction extends Action<typeof GET_METADATA_FAILURE> {
    error: any;
}

export type GetMetadataAction = GetMetadataBeginAction
    | GetMetadataSuccessAction
    | GetMetadataFailureAction;

const getMetadata = (): GetMetadataBeginAction => ({
    type: GET_METADATA_BEGIN
});

const getMetadataSuccess = (
    banks: Bank[],
    advertisementSites: AdvertisementSite[],
    applicationSources: ApplicationSource[],
    teams: Team[]): GetMetadataSuccessAction => ({
    type: GET_METADATA_SUCCESS,
    banks,
    advertisementSites,
    applicationSources,
    teams
});

const getMetadataFailure = (error: any): GetMetadataFailureAction => ({
    type: GET_METADATA_FAILURE,
    error
});

const MetadataActionsCreator = {
    loadMetadata: () => {
        return (dispatch: Dispatch, getState: any) => {
            dispatch(getMetadata());

            const mergeResponsesAndDispatchSuccess = (
                banksResponse: AxiosResponse<Bank[] | any>,
                advertisementSitesResponse: AxiosResponse<AdvertisementSite[] | any>,
                applicationSourcesResponse: AxiosResponse<ApplicationSource[] | any>,
                teamsResponse: AxiosResponse<Team[]>) =>
                dispatch(getMetadataSuccess(banksResponse.data, advertisementSitesResponse.data,
                    applicationSourcesResponse.data, teamsResponse.data));

            return Axios
                .all([getBanks(), getAdvertisementSites(), getApplicationSources(), getTeams()])
                .then(Axios.spread(mergeResponsesAndDispatchSuccess))
                .catch((error) => dispatch(getMetadataFailure(error)));
        };
    }
};

export default MetadataActionsCreator;
