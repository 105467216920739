import React, {FunctionComponent, useEffect} from "react";
import DropdownInputField from "./input-fields/DropdownInputField";
import TextInputField from "./input-fields/TextInputField";
import {MailSender, useMailSender} from "../helpers/useMailSender";
import {SettingsProps} from "../interfaces/SettingsProps";
import {UserProps} from "../interfaces/UserProps";
import {connect} from "react-redux";

interface MailSenderSelectorState {
    editMode?: boolean;
    selected?: MailSender;
    storeSelectedSender?: boolean;
    errors?: string[];
    errorCodes?: string[];
    overrideInternalErrors?: (keyof Omit<ValidityState, 'valid'>)[];
    onChange: (sender: MailSender, asDefault?: boolean) => void;
}

type MailSenderSelectorStateProps = SettingsProps & UserProps;
type MailSenderSelectorProps = MailSenderSelectorState & MailSenderSelectorStateProps;
const MailSenderSelector: FunctionComponent<MailSenderSelectorProps> = (props) => {
    const [mailSenderOptions, defaultSender, setSelectedMailSenderOption] = useMailSender(props);

    useEffect(() => {
        if (!props.selected) {
            props.onChange(defaultSender, true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    const onChange = (sender: MailSender) => {
        if (props.storeSelectedSender) {
            setSelectedMailSenderOption(sender);
        }
        props.onChange(sender);
    }
    
    if (!props.editMode) {
        return (
            <TextInputField
                disabled={true}
                name="sender"
                editMode={true}
                value={props.selected ? `${props.selected.name} <${props.selected.mail}>` : ''}
                descriptionKey="SEND_AS"
                onValueChanged={() => {/* nooop */}}
            />
        )
    }
    return (
        <DropdownInputField<MailSender>
            items={mailSenderOptions}
            keyValue={sender => sender.mail}
            displayValue={sender => `${sender.name} <${sender.mail}>`}
            name="sender"
            errors={props.errors}
            errorCodes={props.errorCodes}
            overrideInternalErrors={props.overrideInternalErrors}
            editMode={props.editMode}
            value={props.selected ? props.selected.mail : defaultSender.mail}
            descriptionKey="SEND_AS"
            onValueChanged={(_, __, sender) => onChange(sender)}
        />
    )
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.userActionsReducer
});

export default connect<MailSenderSelectorStateProps, {}, MailSenderSelectorState>(mapStateToProps)(MailSenderSelector);