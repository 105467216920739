export const beautifyName = (name: string, firstPartOnly: boolean) => {
    name = (name || '').trim();

    if (firstPartOnly) {
        name = name.split(' ')[0];
    }

    return name
        .toLowerCase()
        .replace(/(^|[^a-zA-Z0-9À-ž])[a-zA-Z0-9À-ž]/g, (l) => l.toUpperCase());
};

export const beautifyFirstName = (name: string) => beautifyName(name, true);
export const beautifyLastName = (name: string) => beautifyName(name, false);
