import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';

export const resetResponseCode = async (personId: string | number, loanId: string | number, transactionId: number): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .post(`/api/applicants/${personId}/consumerLoans/${loanId}/transactions/${transactionId}/resetResponseCode`);

    return new CommandResult(result);
};
