import { faUser, faPhone, faEnvelopeOpenText, faEnvelope, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ReceivedMessagesState, ReceivedMessagesDispatchProps } from '../../interfaces/ReceivedMessagesProps';
import { connect } from 'react-redux';
import { withLocalize, LocalizeContextProps, TranslateFunction } from 'react-localize-redux';
import { ReceivedMessage } from '../../models/ReceivedMessage';
import { AxiosClientFactory } from '../../helpers/AxiosClientFactory';
import { formatDateWithTime } from '../../helpers/dateFormatter';
import CurrentPageView from '../CurrentPageView';
import PagePicker from '../PagePicker';
import SendSmsModal from '../SendSmsModal';
import { ConsumerLoanApplicant } from '../../../applications/applicant/models/ConsumerLoanApplicant';
import CopyToClipboardButton from '../CopyToClipboardButton';
import { SettingsProps } from '../../interfaces/SettingsProps';
import { getDebtInformationUpdateLink, getIncompleteFormLink } from '../../helpers/settingsHelpers';
import { ReceivedMessagesActionCreator } from '../../actions/ReceivedMessagesActionCreator';
import { bindActionCreators } from 'redux';
import ActionIcon from '../ActionIcon';
import { preventDoubleClickSelection } from '../../helpers/preventDoubleClickSelection';
import SubstatusesInputField from '../input-fields/SubstatusesInputField';
import { NavLink } from 'react-router-dom';
import { BooleanToggleButtonInputField } from '../input-fields/BooleanToggleButtonInputField';
import { markAsRead } from '../../../applications/applicant/api/markAsRead';
import { markAsUnread } from '../../../applications/applicant/api/markAsUnread';
import DropdownInputField from '../input-fields/DropdownInputField';
import {getLanguageItems} from '../../helpers/getLanguageItems';
import CampaignInputField from "../input-fields/CampaignInputField";
import {CampaignSearchModel} from "../../models/Campaign";
import { ProductType } from '../../models/ProductType';

const pageSize = 10;

interface ReceivedMessagesModalOwnProps {
    show: boolean;
    onClose: () => void;
}

type ReceivedMessagesModalProps = ReceivedMessagesModalOwnProps & SettingsProps & ReceivedMessagesState & ReceivedMessagesDispatchProps & LocalizeContextProps;

const ReceivedMessagesModal: FunctionComponent<ReceivedMessagesModalProps> = (props) => {
    const [rows, setRows] = useState<ReceivedMessage[]>([]);
    const [count, setCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [showOnlyUnread, setShowOnlyUnread] = useState(false);
    const [messageForSmsModal, setMessageForModal] = useState<ReceivedMessage | null>(null);
    const languages = getLanguageItems(props.translate, true);

    const load = () => loadData(props.show, pageNumber, setRows, setCount, props.filter.primaryLanguage, props.filter.campaign, props.filter.substatusIds, showOnlyUnread);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, [props.unreadCount, showOnlyUnread, pageNumber, props.show, props.filter.primaryLanguage, props.filter.substatusIds.length, props.filter.campaign]);

    const onFlipMessageReadMark = (message: ReceivedMessage) => flipMessageReadMark(message, load);

    return (
        <Modal show={props.show} onHide={props.onClose} centered={true} size="xl" className="received-messages-modal">
            <Modal.Header>
                <Modal.Title>
                    {props.translate('RECEIVED_MESSAGES')}
                </Modal.Title>
                <div className="received-messages-filter">
                    <DropdownInputField
                        editMode={true}
                        name="primaryLanguage"
                        onValueChanged={(value) => props.setPrimaryLanguageFilter(value as string | null)}
                        value={props.filter.primaryLanguage}
                        items={languages}
                    />
                    <CampaignInputField 
                        value={props.filter.campaign}
                        onCampaignSelected={(campaign) => props.setCampaignFilter(campaign)}
                    />
                    <SubstatusesInputField
                        editMode={true}
                        substatusIds={props.filter.substatusIds}
                        onSubstatusesChanged={props.setSubstatusesFilter}
                        placeholderKey={'FILTER_SUBSTATUSES'}
                    />
                    <BooleanToggleButtonInputField
                        value={showOnlyUnread}
                        onToggle={setShowOnlyUnread}
                        descriptionKey="UNREAD_ONLY"
                    />
                </div>
            </Modal.Header>
            <Modal.Body>
                {rows.map((r) => renderRow(r, props, setMessageForModal, onFlipMessageReadMark))}
                {renderSmsModal(messageForSmsModal, setMessageForModal, props)}
            </Modal.Body>
            <Modal.Footer>
                {renderPagePicker(count, pageNumber, setPageNumber)}
                <div>
                    <Button variant="primary" onClick={props.onClose}>{props.translate('CLOSE')}</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

function loadData(show: boolean, pageNumber: number, setRows: (rows: ReceivedMessage[]) => void, setCount: (count: number) => void, primaryLanguage: string | null, campaign: CampaignSearchModel | null, substatusIds: number[], unreadOnly: boolean) {
    if (!show) {
        return;
    }
    const campaignId = campaign ? campaign.id : undefined;

    AxiosClientFactory
        .getInstance()
        .get<ReceivedMessage[]>('/api/inboundSmses', { params: { pageNumber, pageSize, primaryLanguage, campaignId, substatusIds, unreadOnly } })
        .then((response) => setRows(response.data));

    AxiosClientFactory
        .getInstance()
        .get<number>('/api/inboundSmses/count', { params: { primaryLanguage, campaignId, substatusIds, unreadOnly } })
        .then((response) => setCount(response.data));
}

function flipMessageReadMark(message: ReceivedMessage, load: () => void) {
    const flip = message.read ? markAsUnread : markAsRead;
    flip(message.id).then(() => load());
}

function renderRow(
    message: ReceivedMessage,
    props: ReceivedMessagesModalOwnProps & LocalizeContextProps,
    setMessageForSmsModal: (m: ReceivedMessage | null) => void,
    onFlipMessageReadMark: (m: ReceivedMessage) => void) {

    const onNumberClick = (e: React.MouseEvent) => {
        setMessageForSmsModal(message);
        e.preventDefault();
    };

    const onMessageButtonClick = () => onFlipMessageReadMark(message);
    const onDoubleClick = message.read ? undefined : onMessageButtonClick;
    const displayInlineStyle = { display: 'inline' };

    return (
        <div className="received-message-row" key={message.id}>
            <div className="received-message-header">
                <div>
                    {renderApplicantName(message, displayInlineStyle, props.onClose, props.translate)}
                    <div style={displayInlineStyle} className="timestamp">{formatDateWithTime(message.receivedAt, props.translate)}</div>
                </div>
                <div>
                    <CopyToClipboardButton content={message.mobileNumber}>
                        <a className="mobile" href={`tel:${message.mobileNumber}`} onClick={onNumberClick}>
                            <FontAwesomeIcon icon={faPhone} flip="horizontal" /> {message.mobileNumber}
                        </a>
                    </CopyToClipboardButton>
                    <span className="toggle-message-read-button">{renderMessageButton(message, onMessageButtonClick)}</span>
                </div>
            </div>
            <div className="received-message-content" onMouseDown={preventDoubleClickSelection}>
                <div className={message.read ? undefined : 'unread-message'}>
                    <span onDoubleClick={onDoubleClick}>{message.content}</span>
                </div>
            </div>
        </div>
    );
}

function renderPagePicker(count: number, pageNumber: number, setPageNumber: (n: number) => void) {
    return (
        <div className="received-messages-paging">
            <CurrentPageView
                variant="light"
                count={count}
                pageSize={pageSize}
                currentPage={pageNumber}
            />
            <PagePicker
                variant="light"
                count={count}
                pageSize={pageSize}
                onPagePicked={setPageNumber}
                pageNumber={pageNumber}
            />
        </div>
    );
}

function renderSmsModal(messageForSmsModal: ReceivedMessage | null, setMessageForSmsModal: (m: ReceivedMessage | null) => void, props: SettingsProps) {
    if (messageForSmsModal === null) {
        return null;
    }

    const applicant = messageForSmsModal as any as ConsumerLoanApplicant;
    const onClose = () => setMessageForSmsModal(null);
    const productType: ProductType | null = messageForSmsModal.productType === null ? null : ProductType[messageForSmsModal.productType];
    const publicLink = getIncompleteFormLink(props, messageForSmsModal.primaryLanguage, messageForSmsModal.publicId, productType, messageForSmsModal.sourceId) || '';
    const debtInformationUpdateLink = getDebtInformationUpdateLink(props, messageForSmsModal.publicId);

    return (
        <SendSmsModal
            show={true}
            productType={productType}
            applicant={applicant}
            applicationId={messageForSmsModal.applicationId}
            onClose={onClose}
            incompleteFormLink={publicLink}
            debtInformationUpdateLink={debtInformationUpdateLink}
            publicId={messageForSmsModal.publicId || ''}
        />
    );
}

function renderMessageButton(message: ReceivedMessage, onClick: () => void) {
    if (message.read) {
        return <ActionIcon icon={faEnvelopeOpenText} tooltipKey="MARK_AS_UNREAD" action={onClick} />;
    }

    return <ActionIcon icon={faEnvelope} tooltipKey="MARK_AS_READ" action={onClick} />;
}

function renderApplicantName(message: ReceivedMessage, style: {[key: string]: string}, onClose: () => void, translate: TranslateFunction) {
    if (message.personId) {
        const path = message.applicationId
            ? `/applicant/${message.personId}/application/${message.applicationId}`
            : `/applicant/${message.personId}`;

        return (
            <NavLink style={style} to={path} onClick={onClose}>
                <FontAwesomeIcon icon={faUser} /> {message.firstName} {message.lastName}
            </NavLink>
        );
    }

    return <span className="unknown-sender"><FontAwesomeIcon icon={faQuestionCircle} />&nbsp;{translate('UNKNOWN_SENDER')}</span>;
}

const mapStateToProps = (state: any) => ({
    ...state.receivedMessagesActionsReducer,
    ...state.settingsActionsReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators({
    ...ReceivedMessagesActionCreator
} as any, dispatch);

export default connect<ReceivedMessagesState & SettingsProps, ReceivedMessagesDispatchProps, ReceivedMessagesModalOwnProps, any>
    (mapStateToProps, mapActionCreatorsToProps)(withLocalize(ReceivedMessagesModal));
