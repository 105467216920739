import { AxiosClientFactory } from '../../../../common/helpers/AxiosClientFactory';
import { CommandError, CommandResult } from '../../../../common/helpers/CommandResult';
import {InsuranceBasicDetails} from "../../models/InsuranceBasicDetails";

export const activateInsurance = async (personId: string | number, insuranceId: string | number, details?: Partial<InsuranceBasicDetails>): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .post<any, any, CommandError[]>(`/api/applicants/${personId}/insurances/${insuranceId}/activate`, { ...details });

    return new CommandResult(result);
};
