import {CampaignsFilter} from "../models/CampaignsFilter";
import {TableState} from "../../common/models/TableState";
import {
    CampaignsViewDataAction,
    RESET_CAMPAIGNS_VIEW,
    SET_CAMPAIGNS,
    SET_CAMPAIGNS_PAGE_NUMBER,
    SET_CAMPAIGNS_PAGE_SIZE,
    SET_IS_LOADING,
    SET_ORDER,
    SET_STATUS_FILTER,
    SET_TEAM_FILTER,
    SET_TYPE_FILTER
} from "../actions/CampaignsViewDataActionCreators";
import {CampaignSummary} from "../../common/models/Campaign";

const defaultTableState: TableState = {
    pageNumber: 0,
    orderBy: undefined,
    orderDirection: 1,
    pageSize: 20
}

const defaultFilter: CampaignsFilter = {
    status: undefined,
    teamId: undefined,
    type: undefined
}

const defaultCampaigns = [];

export interface CampaignsViewDataState {
    filter: CampaignsFilter;
    tableState: TableState;
    campaigns: CampaignSummary[];
    isLoading: boolean;
}

export const campaignsViewDataReducer = (state: any, action: CampaignsViewDataAction): CampaignsViewDataState => {
    state = state || {};

    if (!state.tableState) {
        state.tableState = defaultTableState;
    }

    if (!state.filter) {
        state.filter = defaultFilter;
    }

    if (!state.campaigns) {
        state.campaigns = defaultCampaigns;
    }

    switch (action.type) {
        case SET_CAMPAIGNS:
            return {
                ...state,
                ...action.payload
            }
        case SET_IS_LOADING:
            return {
                ...state,
                ...action.payload
            }
        case SET_CAMPAIGNS_PAGE_NUMBER:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    ...action.payload
                }
            };
        case SET_CAMPAIGNS_PAGE_SIZE:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    ...action.payload,
                    pageNumber: 0
                }
            };
        case SET_ORDER:
            return {
                ...state,
                tableState: {
                    ...state.tableState,
                    ...action.payload,
                }
            };
        case SET_TEAM_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload
                },
                tableState: {
                    ...state.tableState,
                    pageNumber: 0
                }
            };
        case SET_STATUS_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                },
                tableState: {
                    ...state.tableState,
                    pageNumber: 0
                }
            };
        case SET_TYPE_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                },
                tableState: {
                    ...state.tableState,
                    pageNumber: 0
                }
            };
        case RESET_CAMPAIGNS_VIEW:
            return {
                ...state,
                tableState: defaultTableState,
                filter: defaultFilter,
                campaigns: defaultCampaigns
            };
        default:
            return state;
    }
}