import { AxiosClientFactory } from '../../../../common/helpers/AxiosClientFactory';
import { CommandError, CommandResult } from '../../../../common/helpers/CommandResult';

export const cancelInsurance = async (personId: string | number, insuranceId: string | number, cancellationDate: Date): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .post<any, any, CommandError[]>(`/api/applicants/${personId}/insurances/${insuranceId}/cancel`, { cancellationDate });

    return new CommandResult(result);
};
