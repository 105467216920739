import { TranslateFunction } from 'react-localize-redux';
import { ProductType } from '../models/ProductType';
import mapEnumToSelectableItems from './mapEnumToSelectableItems';
import { getEnumTranslationKey } from './getTranslationKey';

export const getProductTypeTranslateKey = (productType: string | number | null): string => getEnumTranslationKey(ProductType, productType, 'PRODUCT_TYPES', 'ALL');

const nameMapper = (dropDownMode: boolean, translate: TranslateFunction) => (productType: string | null) =>
    (dropDownMode ? translate('PRODUCT_TYPES.PREFIX') : '') + translate(getProductTypeTranslateKey(productType)).toString();

export const mapProductTypesToSelectableItems = (translate: TranslateFunction, dropDownMode: boolean, addAllItem?: boolean) =>
    mapEnumToSelectableItems<ProductType>(ProductType, nameMapper(dropDownMode, translate), addAllItem === undefined ? dropDownMode : addAllItem);