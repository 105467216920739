import {AxiosPromise} from 'axios';
import {AxiosClientFactory} from '../../../common/helpers/AxiosClientFactory';
import {Team} from "../models/Team";
import {CachingConfig} from "../../../common/helpers/Caching";

export const getTeams = (): AxiosPromise<Team[]> => {
    return AxiosClientFactory
        .getInstance()
        .get<Team[]>(`/api/teams`, {
            cache: CachingConfig.LONG
        });
};
