import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';
import { ProductType } from '../../../common/models/ProductType';

export const removeFromBlacklist = async (socialSecurityNumber: string, productType: ProductType): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .delete(`api/blacklist/${socialSecurityNumber}/${productType}`);

    return new CommandResult(result);
};
