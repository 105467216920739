import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { AxiosResponse } from 'axios';

export const putApplicantPrimaryLanguge = async (
    personId: string | number,
    loanId: string | number,
    applicantId: string | number,
    primaryLanguage: string): Promise<string[]> => {
    return new Promise<string[]>((resolve) => {
        AxiosClientFactory
            .getInstance()
            .put(`api/applicants/${personId}/consumerloans/${loanId}/applicantDetails/${applicantId}/primaryLanguage`, {primaryLanguage})
            .then((response: AxiosResponse<string[]>) => resolve(response.data));
    });
};
