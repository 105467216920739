import {AxiosPromise} from 'axios';
import {AxiosClientFactory} from '../../../common/helpers/AxiosClientFactory';
import {Bank} from "../models/Bank";
import {CachingConfig} from "../../../common/helpers/Caching";

export const getBanks = (): AxiosPromise<Bank[]> => {
    return AxiosClientFactory
        .getInstance()
        .get<Bank[]>(`/api/banks`, {
            cache: CachingConfig.LONG
        });
};
