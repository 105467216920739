import { Action, Dispatch } from 'redux';
import { Setting } from '../models/Setting';
import { AxiosClientFactory } from '../helpers/AxiosClientFactory';
import { AxiosResponse } from 'axios';
import {CachingConfig} from "../helpers/Caching";

export const GET_SETTINGS_BEGIN = 'GET_SETTINGS_BEGIN';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';

interface GetSettingsBeginAction extends Action<typeof GET_SETTINGS_BEGIN> { }

interface GetSettingsSuccessAction extends Action<typeof GET_SETTINGS_SUCCESS> {
    settings: Setting[];
}

interface GetSettingsFailureAction extends Action<typeof GET_SETTINGS_FAILURE> {
    error: any;
}

export type GetSettingsAction = GetSettingsBeginAction
    | GetSettingsSuccessAction
    | GetSettingsFailureAction;

const getSettings = (): GetSettingsBeginAction => ({
    type: GET_SETTINGS_BEGIN
});

const getSettingsSuccess = (settings: Setting[]): GetSettingsSuccessAction => ({
    type: GET_SETTINGS_SUCCESS,
    settings
});

const getSettingsFailure = (error: any): GetSettingsFailureAction => ({
    type: GET_SETTINGS_FAILURE,
    error
});

export const SettingsActionCreator = {
    loadSettings: () => {
        return (dispatch: Dispatch, getState: any) => {
            dispatch(getSettings());

            AxiosClientFactory
                .getInstance()
                .get<Setting[]>(`/api/settings`, {
                    cache: CachingConfig.SHORT
                })
                .then((response: AxiosResponse<Setting[]>) => dispatch(getSettingsSuccess(response.data)))
                .catch((error) => getSettingsFailure(error));
        };
    }
};
