import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';

export const denyApplication = async (personId: string | number, loanId: string | number): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .post(`api/applicants/${personId}/consumerloans/${loanId}/deny`);

    return new CommandResult(result);
};
