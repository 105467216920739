import {CampaignRecipient} from "../../common/models/Campaign";
import {AxiosClientFactory} from "../../common/helpers/AxiosClientFactory";
import {TableState} from "../../common/models/TableState";
import {CountCampaignRecipientsPreviewParams} from "./countCampaignRecipientsPreview";

export type GetCampaignRecipientsPreviewParams = CountCampaignRecipientsPreviewParams & TableState;

export const getCampaignRecipientsPreview = (params: GetCampaignRecipientsPreviewParams): Promise<CampaignRecipient[]> => {
    return AxiosClientFactory
        .getInstance()
        .post<CampaignRecipient[]>(`/api/campaigns/recipients`, {...params})
        .then((result) => result.data);
};