import {
    GET_CUSTOMER_ADVISORS_TEAMS_BEGIN,
    GET_CUSTOMER_ADVISORS_TEAMS_FAILURE,
    GET_CUSTOMER_ADVISORS_TEAMS_SUCCESS,
    GetCustomerAdvisorsTeamsAction
} from "../actions/CustomerAdvisorsTeamsActionsCreator";

export function customerAdvisorsTeamsReducer(state: any, action: GetCustomerAdvisorsTeamsAction) {
    state = state || {
        customerAdvisorsTeams: undefined,
        customerAdvisors: undefined
    }

    switch (action.type) {
        case GET_CUSTOMER_ADVISORS_TEAMS_BEGIN:
            return {
                ...state,
                customerAdvisorsTeams: undefined
            };
        case GET_CUSTOMER_ADVISORS_TEAMS_SUCCESS:
            return {
                ...state,
                customerAdvisorsTeams: action.customerAdvisorsTeams,
                customerAdvisors: action.customerAdvisorsTeams.map(team => team.customerAdvisors).reduce((a, b) => a.concat(b))
            };
        case GET_CUSTOMER_ADVISORS_TEAMS_FAILURE: {
            return {
                ...state,
                customerAdvisorsTeams: undefined
            }
        }

        default: return state;
    }
}