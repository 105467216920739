import React, { ReactNode, FunctionComponent } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface InformationModalProps {
    show: boolean;

    title: string | ReactNode;

    message: string | ReactNode;

    onClose: () => void;
}

const InformationModal: FunctionComponent<InformationModalProps> = (props) => {
    return (
        <Modal show={props.show} centered={true}>
            <Modal.Header>
                <Modal.Title>
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.message}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onClose}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InformationModal;
