import {faPlus, faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {CSSProperties, PropsWithChildren, ReactNode, useEffect, useRef, useState} from 'react';
import Autosuggest, {
    ChangeEvent,
    RenderSuggestionParams,
    RenderSuggestionsContainerParams,
    SuggestionSelectedEventData,
    SuggestionsFetchRequestedParams
} from 'react-autosuggest';
import {defaultDisplayValueGetter, defaultKeyValueGetter} from '../helpers/defaultItemValueGetters';
import './autosuggest-theme.css';
import './multiselect.css';
import classNames from 'classnames';
import {Translate} from "react-localize-redux";

const limit = 25;

interface MultiSelectProps<T> {
    items: T[];
    selectedItems: T[];
    onSelectionChanged: (selectedItems: T[]) => void;
    keyValue?: (item: T) => string | number;
    displayValue?: (item: T) => string;
    placeHolder?: string;
    style?: CSSProperties;
    className?: string;
    description?: string;
    descriptionKey?: string;
    getItemCustomCssClass?: (item: T) => string;
    inlineEditing?: boolean;
}

interface MultiSelectState<T> {
    filter: string;
    suggestions: T[];
}

export function MultiSelect<T>(props: PropsWithChildren<MultiSelectProps<T>>) {
    const [state, setState] = useState<MultiSelectState<T>>({ filter: '', suggestions: [] });
    const [inlineSearch, setInlineSearch] = useState<boolean>(false);
    const searchInputRef = useRef<HTMLInputElement>(null);
    
    const inputProps = {
        id: 'multiselect-search-input',
        ref: searchInputRef,
        onChange: onChange,
        placeholder: (props.selectedItems.length === 0 && props.placeHolder) || '',
        value: state.filter,
        onBlur: () => setInlineSearch(false)
    };
    
    const focusSearchInput = () => {
        searchInputRef.current?.focus();
    }
    
    useEffect(() => {
        if (inlineSearch) {
            focusSearchInput()
        }
    }, [inlineSearch]);

    function renderInputComponent(inputProps: any): ReactNode {
        inputProps.className = inputProps.className + ' form-control customized';

        return (
            <div className={classNames(`multiselect ${props.inlineEditing ? 'inline' : ''} ${inlineSearch ? 'search' : ''}`, props.className)} style={props.style}>
                {props.selectedItems.map(renderSelectedItem)}
                <div className="input-wrapper">
                    {!props.inlineEditing &&
                        <FontAwesomeIcon 
                            style={{position: 'absolute', pointerEvents: 'none', right: '10px', color: '#707070'}} 
                            icon={faSearch}
                        />
                    }
                    {props.inlineEditing && !inlineSearch &&
                        <button className="inline-search-button" onClick={() => setInlineSearch(true)}>
                            <FontAwesomeIcon icon={faPlus}/>
                        </button>
                    }
                    <input {...inputProps}/>
                </div>
            </div>
        );
    }

    function renderSelectedItem(item: T): ReactNode {
        let additionalCssClasses: string = '';
        if (props.getItemCustomCssClass) {
            additionalCssClasses = props.getItemCustomCssClass(item);
        }

        return (
            <div className={`multiselect-item ${additionalCssClasses}`} key={getKey(item)}>
                {getDisplayValue(item)}<button onClick={() => onRemoveItemClick(item)}><FontAwesomeIcon icon={faTimes} /></button>
            </div>
        );
    }

    function renderSuggestionsContainer(params: RenderSuggestionsContainerParams): ReactNode {
        return (
            <div {...params.containerProps}>
                <div className="suggestion-wrapper multiselect-suggestion-wrapper"><>{params.children}</></div>
            </div>
        );
    }

    function renderSuggestion(suggestion: T, params: RenderSuggestionParams): ReactNode {
        const displayValue = getDisplayValue(suggestion);
        const lowerCase = displayValue.toLocaleLowerCase();
        const content: ReactNode[] = [];
        let index = lowerCase.indexOf(state.filter, 0);
        let startIndex = 0;

        while (index >= 0) {
            // text before filter match
            if (startIndex < index) {
                content.push(displayValue.substring(startIndex, index));
            }

            // filter match inside <strong>
            content.push(<strong key={getKey(suggestion) + ':' + index}>{displayValue.substring(index, index + state.filter.length)}</strong>);

            startIndex = index + state.filter.length;
            index = lowerCase.indexOf(state.filter, startIndex);
        }

        // add text after last match
        if (startIndex < displayValue.length) {
            content.push(displayValue.substring(startIndex));
        }

        return (
            <div key={getKey(suggestion)}>{content}</div>
        );
    }

    function onSuggestionsFetchRequested(request: SuggestionsFetchRequestedParams): void {
        if (request.reason === 'input-changed') {
            setState({
                filter: request.value.toLocaleLowerCase(),
                suggestions: filterItems(request.value)
            });
        }
    }

    function onSuggestionsClearRequested(): void {
        setState({
            filter: '',
            suggestions: []
        });
    }

    function getSuggestionValue(): string {
        return state.filter; // we're deliberatly not changing input based on suggestion
    }

    function onChange(event: React.FormEvent<any>, params: ChangeEvent): void {
        setState({
            ...state,
            filter: params.newValue
        });
    }

    function filterItems(filter: string): T[] {
        return props.items
            .filter((i) => getDisplayValue(i).toLocaleLowerCase().includes(filter) && props.selectedItems.indexOf(i) < 0)
            .slice(0, limit);
    }

    function onSuggestionSelected(event: React.FormEvent<any>, data: SuggestionSelectedEventData<T>): void {
        const currentSuggestion = data.suggestion;
        const selectedItems = [...props.selectedItems];
        focusSearchInput();

        if (props.selectedItems.indexOf(currentSuggestion) < 0) {
            selectedItems.push(currentSuggestion);
            props.onSelectionChanged(selectedItems);
        }
    }

    function onRemoveItemClick(item: T) {
        const selectedItems = props.selectedItems.filter((s) => s !== item);
        props.onSelectionChanged(selectedItems);
    }

    function getKey(item: T): string {
        return (props.keyValue || defaultKeyValueGetter)(item).toString();
    }

    function getDisplayValue(item: T): string {
        return (props.displayValue || defaultDisplayValueGetter)(item);
    }

    function renderDescription() {
        if (props.description) {
            return <div className="description">{props.description}</div>;
        }

        if (props.descriptionKey) {
            return <div className="description"><Translate id={props.descriptionKey} /></div>;
        }

        return null;
    }

    return (
        <>
            {renderDescription()}
            <Autosuggest
                suggestions={state.suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                renderInputComponent={renderInputComponent}
                renderSuggestionsContainer={renderSuggestionsContainer}
                inputProps={inputProps}
            />
        </>
    );
}
