import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {setupCache, buildWebStorage, AxiosCacheInstance} from 'axios-cache-interceptor';
import {CachingConfig} from "./Caching";

export interface TaurusAxiosInterceptorConfig {
    silent?: boolean;
}

export const AXIOS_CACHE_PREFIX = 'taurus:'

type AxiosRequestInterceptor = (requestConfig: AxiosRequestConfig, taurusConfig?: TaurusAxiosInterceptorConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;

type AxiosResponseInterceptor = (responseConfig: AxiosResponse<any>, taurusConfig?: TaurusAxiosInterceptorConfig) => AxiosResponse<any>;

Axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

export class AxiosClientFactory {
    public static addRequestInterceptor(requestInterceptor: AxiosRequestInterceptor): void {
        AxiosClientFactory.requestInterceptors.push(requestInterceptor);
    }

    public static addResponseInterceptor(responseInterceptor: AxiosResponseInterceptor): void {
        AxiosClientFactory.responseInterceptors.push(responseInterceptor);
    }

    public static getInstance(config?: AxiosRequestConfig | undefined, taurusConfig?: TaurusAxiosInterceptorConfig): AxiosCacheInstance {
        config = config || {};
        config.paramsSerializer = config.paramsSerializer || {serialize: (params) => AxiosClientFactory.paramsSerializer(params)};
        const instance = Axios.create(config);

        AxiosClientFactory.requestInterceptors.forEach((interceptor: AxiosRequestInterceptor) =>
            instance.interceptors.request.use((c: AxiosRequestConfig) => interceptor(c, taurusConfig)));

        AxiosClientFactory.responseInterceptors.forEach((interceptor: AxiosResponseInterceptor) =>
            instance.interceptors.response.use((r: AxiosResponse<any>) => interceptor(r, taurusConfig), (e: any) => interceptor(e, taurusConfig)));

        return setupCache(instance, {
            storage: AxiosClientFactory.cacheStore,
            ...CachingConfig.DEFAULT
        });
    }

    private static requestInterceptors: AxiosRequestInterceptor[] = [];

    private static responseInterceptors: AxiosResponseInterceptor[] = [];

    private static paramsSerializer = (param: any, prefix = '') => {
        if (Array.isArray(param)) {
            const params: string[] = [];
            param.forEach((value: any) => {
                if (value !== null && value !== undefined) {
                    params.push(AxiosClientFactory.paramsSerializer(value, prefix));
                }
            });
            return params.length ? params.join('&') : '';
        } else if (param instanceof Date) {
            return `${prefix}=${param.toISOString()}`;
        } else if (param instanceof Object) {
            const params: string[] = [];
            Object.entries(param).forEach(([key, value]) => {
                if (value !== null && value !== undefined) {
                    let serialized = AxiosClientFactory.paramsSerializer(value, prefix ? `${prefix}.${key}` : key);
                    if (serialized) {
                        params.push(serialized);
                    }
                }
            });
            return params.length ? params.join('&') : '';
        } else {
            return `${prefix}=${param.toString()}`;
        }
    }
    
    private static cacheStore = buildWebStorage(localStorage, AXIOS_CACHE_PREFIX)
}
