import { useMemo, useState } from 'react';
import { SettingsProps } from '../interfaces/SettingsProps';
import { UserProps } from '../interfaces/UserProps';

const storageKey = 'mailSender';

export interface MailSender {
    name: string;
    mail: string;
}

export function useMailSender(props: SettingsProps & UserProps) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const mailSenderOptions = useMemo(() => createSenderOptionsList(props), []);
    const [selectedMailSenderOption, setSelectedMailSenderOptionState] = useState(() => getDefaultSender(mailSenderOptions));
    const setSelectedMailSenderOption = (sender: MailSender) => {
        setSelectedMailSenderOptionState(sender);
        localStorage.setItem(storageKey, sender.mail);
    };
    return [mailSenderOptions, selectedMailSenderOption, setSelectedMailSenderOption] as [MailSender[], MailSender, (sender: MailSender) => void];
}

const createSenderOptionsList = (props: SettingsProps & UserProps) => {
    if (!props.userData.user) {
        return [];
    }

    const currentUser = {
        name: props.userData.user.displayName,
        mail: props.userData.user.email
    } as MailSender;

    const setting = props.settingsData.settings.find((s) => s.name === 'MAIL_SENDER_OPTIONS');
    return setting !== undefined 
        ? [currentUser].concat((JSON.parse(setting.value) as MailSender[]))
        : [currentUser];
};

const getDefaultSender = (senders: MailSender[]) => {
    const storedSenderMail = localStorage.getItem(storageKey);
    return senders.find((s) => s.mail === storedSenderMail) || senders[0];
};
