import React, {FunctionComponent, PropsWithChildren} from 'react';
import './administration.css';
import {SettingsProps} from '../../common/interfaces/SettingsProps';
import {connect} from 'react-redux';
import {getMainBackgroundColorStyle} from '../../common/helpers/settingsHelpers';
import image from '../../under-construction.png';

const AdministrationLayout: FunctionComponent<
	PropsWithChildren<SettingsProps>
> = props => {
	return (
		<main className="main-content" style={getMainBackgroundColorStyle(props)}>
			<div className="jumbotron text-center bg-white flex-column justify-content-center row pb-4 px-0 administration">
				{/* <div className="top-panel">
                    <div className="generic-tabs">
                        <NavLink to="/administration/statuses" className="generic-tab">
                            <Translate id="STATUSES" />
                        </NavLink>
                        <NavLink to="/administration/trackings" className="generic-tab">
                            <Translate id="TRACKINGS" />
                        </NavLink>
                        <NavLink to="/administration/knockout" className="generic-tab">
                            <Translate id="KNOCKOUT" />
                        </NavLink>
                        <NavLink to="/administration/advertisements" className="generic-tab">
                            <Translate id="ADVERTISEMENTS" />
                        </NavLink>
                        <NavLink to="/administration/banks" className="generic-tab">
                            <Translate id="BANKS" />
                        </NavLink>
                        <NavLink to="/administration/source" className="generic-tab">
                            <Translate id="SOURCE" />
                        </NavLink>
                        <NavLink to="/administration/commission" className="generic-tab">
                            <Translate id="COMMISSION" />
                        </NavLink>
                        <NavLink to="/administration/settings" className="generic-tab">
                            <Translate id="SETTINGS" />
                        </NavLink>
                    </div>
                </div> */}
				<div className="under-construction">
					<img 
						alt="Under construction"
						src={image} 
					/>
				</div>
				<div className="content-panel">{props.children}</div>
			</div>
		</main>
	);
};

const mapStateToProps = (state: any) => ({ ...state.settingsActionsReducer });

export default connect<SettingsProps, {}, {}, any>(mapStateToProps)(
	AdministrationLayout
);
