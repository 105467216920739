import { registerHandler, startSignalRConnection } from '../helpers/SignalR';
import { Action, Dispatch } from 'redux';


const actionNotificationMap = {};

export const registerSignalRActionCreator = <M, A extends Action>(name: string, actionCreator: (model: M, getState: any) => A) => {
    if (actionNotificationMap[name]) {
        throw Error(`${name} alredy was assigned to different action.`);
    }

    actionNotificationMap[name] = actionCreator;
};

const dispatchSetup = () => (dispatch: Dispatch, getState: any) => {
    startSignalRConnection();
    Object.keys(actionNotificationMap).forEach((name) => registerHandler<any>(name, (model) => dispatch(actionNotificationMap[name](model, getState))));
};

export default dispatchSetup;
