import React, {FunctionComponent, useEffect, useState} from "react";
import "./NewLoan.css";
import {ConsumerLoan} from "../../../../models/ConsumerLoan";
import {Translate} from "react-localize-redux";
import NumberInputField, {NumberInputFieldKind} from "../../../../../../common/components/input-fields/NumberInputField";
import {ConsumerLoanDetails} from "../../../../models/ConsumerLoanDetails";
import {AdditionalInformation, AdditionalInformationType} from "../../../../../../common/models/AdditionalInformation";

export interface NewLoanData {
    loanAmount: number;
    interestRate: number;
    repaymentPeriod: number;
    setupFee: number;
    monthlyCost: number;
    refinance: number;
}

interface NewLoanProps {
    application: ConsumerLoan;
    personId: number;
    stressTestIncreaseByPercentage: number;
    applicantAdditionalInformation: AdditionalInformation[];
    onChange: (data: NewLoanData) => void;
    mortgageCalculationChange: (mortgageCalculation: AdditionalInformation) => void;
    applicationDetailsChange: (details: ConsumerLoanDetails) => void;
    stressTestChange: (value: number) => void;
}
const NewLoan: FunctionComponent<NewLoanProps> = (props) => {
    const [loanAmount, setLoanAmount] = useState<number | null | undefined>(props.application.details.loanAmount);
    const [interestRate, setInterestRate] = useState<number | null | undefined>();
    const [repaymentPeriod, setRepaymentPeriod] = useState<number | null | undefined>(props.application.details.repaymentPeriod);
    const [installmentFee, setInstallmentFee] = useState<number | null | undefined>();

    const calculateMonthlyCost = (increaseInterestRateByPercent?: number) => {
        if (loanAmount && interestRate && repaymentPeriod) {
            const periodicInterestRate = ((interestRate || 0) + (increaseInterestRateByPercent || 0)) / 100 / 12;
            const monthlyFee = (loanAmount * periodicInterestRate) / (1 - Math.pow((1 + periodicInterestRate), -repaymentPeriod)) + (installmentFee || 0);
            return parseFloat(monthlyFee.toFixed(2));
        }
    }
    
    const calculateRefinance = () => {
        return props.application.redemptions.reduce((total: number, r) => r.refinance ? total + r.balance : total, 0)
    }

    const [monthlyCost, setMonthlyCost] = useState<number | null | undefined>(calculateMonthlyCost());
    const [monthlyCostWithStressTest, setMonthlyCostWithStressTest] = useState<number | null | undefined>(calculateMonthlyCost(props.stressTestIncreaseByPercentage));
    const [refinance] = useState<number | null | undefined>(calculateRefinance());
    
    const onChange = () => {
        props.onChange({
            loanAmount: loanAmount || 0,
            interestRate: interestRate || 0,
            repaymentPeriod: repaymentPeriod || 0,
            setupFee: installmentFee || 0,
            monthlyCost: monthlyCost || 0,
            refinance: refinance || 0
        });
    }
    
    const updateLoanDetails = (value: number | null, detailName: keyof ConsumerLoanDetails) => {
        const details = {
            ...props.application.details,
            [detailName]: value
        };
        props.applicationDetailsChange(details);
    }
    
    const updateMortgageCalculationInformation = (name: string, value: number | null) => {
        const mortgageCalculationInformation = getMortgageCalculationInformation(name) || {
            name,
            value: value ? value.toString(10) : null,
            type: AdditionalInformationType.MortgageCalculation
        };
        props.mortgageCalculationChange(mortgageCalculationInformation);
    }
    
    const getMortgageCalculationInformation = (name: string) => props.applicantAdditionalInformation.find(
        info => info.type === AdditionalInformationType.MortgageCalculation && info.name === name
    );

    useEffect(() => {
        setMonthlyCost(calculateMonthlyCost());
        setMonthlyCostWithStressTest(calculateMonthlyCost(props.stressTestIncreaseByPercentage));
    }, [loanAmount, interestRate, repaymentPeriod, installmentFee, props.stressTestIncreaseByPercentage]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        onChange();
    }, [monthlyCost]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        const installmentFeeInfo = getMortgageCalculationInformation('installmentFee');
        const interestRateInfo = getMortgageCalculationInformation('interestRate');
        if (installmentFeeInfo) {
            setInstallmentFee(installmentFeeInfo.value ? parseFloat(installmentFeeInfo.value) : undefined);
        }
        if (interestRateInfo) {
            setInterestRate(interestRateInfo.value ? parseFloat(interestRateInfo.value) : undefined);
        }
    }, [props.applicantAdditionalInformation]); // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <div className="new-loan">
            <span className="title">
                <Translate id="NEW_LOAN" />
            </span>
            <div className="row">
                <div className="col-6">
                    <div className="row">
                        <div className="col-6" >
                            <NumberInputField
                                name="loanAmount"
                                editMode={true}
                                value={loanAmount}
                                descriptionKey="BANK_REPORT.AMOUNT"
                                kind={NumberInputFieldKind.Money}
                                countryId={props.application.applicant.countryId}
                                onValueChanged={(loanAmount) => setLoanAmount(loanAmount)}
                                onBlur={(loanAmount) => updateLoanDetails(loanAmount, 'loanAmount')}
                            />
                        </div>
                        <div className="col-6" >
                            <NumberInputField
                                name="interestRate"
                                editMode={true}
                                value={interestRate}
                                descriptionKey="INTEREST_RATE"
                                kind={NumberInputFieldKind.Percent}
                                step={0.01}
                                countryId={props.application.applicant.countryId}
                                onValueChanged={(interestRate) => setInterestRate(interestRate)}
                                onBlur={(value, dirty) => dirty && updateMortgageCalculationInformation('interestRate', value)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6" >
                            <NumberInputField
                                name="repaymentPeriod"
                                editMode={true}
                                value={repaymentPeriod}
                                descriptionKey="REPAYMENT_PERIOD"
                                kind={NumberInputFieldKind.Months}
                                countryId={props.application.applicant.countryId}
                                onValueChanged={(repaymentPeriod) => setRepaymentPeriod(repaymentPeriod)}
                                onBlur={(repaymentPeriod) => updateLoanDetails(repaymentPeriod, 'repaymentPeriod')}
                            />
                        </div>
                        <div className="col-6" >
                            <NumberInputField
                                name="installmentFee"
                                editMode={true}
                                value={installmentFee}
                                descriptionKey="BANK_REPORT.INSTALLMENT_FEE"
                                kind={NumberInputFieldKind.Money}
                                countryId={props.application.applicant.countryId}
                                onValueChanged={(setupFee) => setInstallmentFee(setupFee)}
                                onBlur={(value, dirty) => dirty && updateMortgageCalculationInformation('installmentFee', value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-6" >
                            <NumberInputField
                                className="inline-with-editable"
                                name="monthlyCost"
                                value={monthlyCost}
                                descriptionKey="BANK_REPORT.MONTHLY_COST"
                                kind={NumberInputFieldKind.Money}
                                readonlyPrecision={0}
                                countryId={props.application.applicant.countryId}
                            />
                        </div>
                        <div className="col-6" >
                            <NumberInputField
                                className="inline-with-editable"
                                name="refinance"
                                value={refinance}
                                descriptionKey="REFINANCE"
                                kind={NumberInputFieldKind.Money}
                                readonlyPrecision={0}
                                countryId={props.application.applicant.countryId}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6" >
                            <NumberInputField
                                className="inline-with-editable"
                                name="monthlyCostWithStressTest"
                                value={monthlyCostWithStressTest}
                                description={
                                    <Translate 
                                        id="MONTHLY_COST_INCREASED_BY_PERCENTAGE" 
                                        data={{percentage: props.stressTestIncreaseByPercentage}}
                                    />
                                }
                                kind={NumberInputFieldKind.Money}
                                readonlyPrecision={0}
                                countryId={props.application.applicant.countryId}
                            />
                        </div>
                        <div className="col-6" >
                            <NumberInputField
                                name="stressTestIncreaseByPercentage"
                                editMode={true}
                                nullable={false}
                                value={props.stressTestIncreaseByPercentage}
                                descriptionKey="INTEREST_RATE_STRESS_TEST"
                                kind={NumberInputFieldKind.Percent}
                                step={0.01}
                                onValueChanged={(value) => props.stressTestChange(value || props.stressTestIncreaseByPercentage)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default NewLoan;