const storageKey = 'logsAndCommentsFilterSettings';

interface LogsAndCommentsFilterSettings {
    filterComments: boolean;
    filterEvents: boolean;
    filterChanges: boolean;
    filterDocuments: boolean;
}

export const logsAndCommentsFiltersStorage = {
    get: (): LogsAndCommentsFilterSettings => {
        const savedSettings = localStorage.getItem(storageKey);
        if (savedSettings !== undefined && savedSettings !== null) {
            return JSON.parse(savedSettings);
        }

        // default values
        return {
            filterComments: true,
            filterEvents: false,
            filterChanges: false,
            filterDocuments: false
        };
    },
    set: (settings: LogsAndCommentsFilterSettings): void => {
        localStorage.setItem(storageKey, JSON.stringify(settings));
    }
};
