import React, { FunctionComponent, useState, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ConfirmationModalProps {
    show: boolean;
    title: string | ReactNode;
    message: string | ReactNode;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = (props) => {
    return (
        <Modal show={props.show} centered={true}>
            <Modal.Header>
                <Modal.Title>
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.message}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onConfirm}>Confirm</Button>
                <Button variant="secondary" onClick={props.onCancel}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export function useConfirmationModal(title: string | ReactNode, message: string | ReactNode, onConfirm: () => void, onCancel?: () => void): [ConfirmationModalProps, () => void] {
    const [show, setShow] = useState(false);
    const onConfirmCallback = () => { setShow(false); onConfirm(); };
    const onCancelCallback = () => {
        setShow(false);
        if (onCancel) {
            onCancel();
        }
    };

    return [{
        show,
        title,
        message,
        onConfirm: onConfirmCallback,
        onCancel: onCancelCallback
    }, () => setShow(true)];
}

export default ConfirmationModal;
