import {FunctionComponent, useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { SettingsProps } from '../interfaces/SettingsProps';
import { Setting } from '../models/Setting';

interface ApplicationTitleProps extends SettingsProps {
    pageTitle: string;
}

const ApplicationTitle: FunctionComponent<ApplicationTitleProps> = (props) => {
    
    useEffect(() => {
        const titleSetting = props.settingsData.settings.find((s: Setting) => s.name === 'APP_TITLE');
        if (titleSetting !== undefined) {
            const appTitle = titleSetting.value;
            document.title = `${appTitle} - ${props.pageTitle}`;
        } else {
            document.title = '';            
        }
    }, [props.settingsData.isLoaded, props.pageTitle]); // eslint-disable-line react-hooks/exhaustive-deps
    
    return null;
}

const mapStateToProps = (state: any) => ({
    ...state.setTitleActionsReducer,
    ...state.settingsActionsReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({

}, dispatch);

export default connect<ApplicationTitleProps, {}, {}, any>(mapStateToProps, mapActionCreatorsToProps)(ApplicationTitle);
