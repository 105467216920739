import { Action, Dispatch } from 'redux';

export const DECREMENT_PENDING_REQUESTS = 'DECREMENT_PENDING_REQUESTS';
export const INCREMENT_PENDING_REQUESTS = 'INCREMENT_PENDING_REQUESTS';

interface IncrementPendingRequestCountAction extends Action<typeof INCREMENT_PENDING_REQUESTS> { }

interface DecrementPendingRequestCountAction extends Action<typeof DECREMENT_PENDING_REQUESTS> { }

export type ChangePendingRequestsCountAction = IncrementPendingRequestCountAction | DecrementPendingRequestCountAction;

const getIncrementPendingRequestsAction = (): IncrementPendingRequestCountAction => ({
    type: INCREMENT_PENDING_REQUESTS
});

const getDecrementPendingRequestsAction = (): DecrementPendingRequestCountAction => ({
    type: DECREMENT_PENDING_REQUESTS
});

export const LoadingBarWrapperActionCreators = {
    incrementPendingRequests: () => {
        return (dispatch: Dispatch, getState: any) => {
            dispatch(getIncrementPendingRequestsAction());
        };
    },
    decrementPendingRequests: () => {
        return (dispatch: Dispatch, getState: any) => {
            dispatch(getDecrementPendingRequestsAction());
        };
    }
};
