import React, {FunctionComponent, MouseEvent, MouseEventHandler, useEffect, useRef, useState} from "react";
import {Button, OverlayTrigger, Popover, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCircle,
    faCommentMedical,
    faPhoneSlash,
    faPlusSquare,
    faUserMinus,
    faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import RefreshButton from "../../../../common/components/RefreshButton";
import TablePagination from "../../../../common/components/table/TablePagination";
import TableHeader from "../../../../common/components/table/TableHeader";
import {getEnumTranslationKey} from "../../../../common/helpers/getTranslationKey";
import {LoanStatus} from "../../../../common/models/LoanStatus";
import {getLoanStatusTranslateKey} from "../../../../common/helpers/loanStatusFunctions";
import TableRows from "../../../../common/components/table/TableRows";
import {Translate} from "react-localize-redux";
import {loanStatusToColor} from "../../../../common/helpers/loanStatusToColor";
import {MarketingListCustomer} from "../../../model/MarketingListCustomer";
import {ProductType} from "../../../../common/models/ProductType";
import {NavLink} from "react-router-dom";
import CopyToClipboardButton from "../../../../common/components/CopyToClipboardButton";
import SendSmsModal from "../../../../common/components/SendSmsModal";
import SendEmailModal from "../../../../common/components/SendEmailModal";
import DateTimeInputField, {
    DateTimeInputFieldKind
} from "../../../../common/components/input-fields/DateTimeInputField";
import {MarketingListCustomerStatus} from "../../../model/MarketingListCustomerStatus";
import {beautifyName} from "../../../../common/helpers/beautifyName";
import {bindActionCreators, Dispatch} from "redux";
import CustomerAdvisorsTeamsActionsCreator, {
    CustomerAdvisorsTeamsActions
} from "../../../../applications/applicants/actions/CustomerAdvisorsTeamsActionsCreator";
import {connect} from "react-redux";
import {CustomerAdvisorsTeamsProps} from "../../../../common/interfaces/CustomerAdvisorsTeamsProps";
import {CustomerAdvisor} from "../../../../applications/applicant/models/CustomerAdvisor";
import Tooltip from "../../../../common/components/Tooltip";
import ApplicantTooltip from "../../../../applications/applicants/components/ApplicantTooltip";
import TextInputField from "../../../../common/components/input-fields/TextInputField";

interface CustomersTableProps extends CustomerAdvisorsTeamsProps, CustomerAdvisorsTeamsActions {
    customers?: MarketingListCustomer[];
    count?: number;
    editable?: boolean;
    pageNumber: number;
    pageSize: number;
    isLoading?: boolean;
    isOutdated?: boolean;
    onPageChange?: (page: number) => void;
    onPageSizeChange?: (size: number) => void;
    onRefreshDataClick?: () => void;
    onCustomerFollowUpDateChange?: (customer: MarketingListCustomer, followUpDate: Date | null) => void;
    onCustomerStatusChange?: (customer: MarketingListCustomer, status: MarketingListCustomerStatus) => void;
    onCustomerCommentSave?: (customer: MarketingListCustomer, comment: string) => void;
}

const CustomersTable: FunctionComponent<CustomersTableProps> = (props) => {
    const [sendSmsModalToCustomer, setSendSmsModalToCustomer] = useState<MarketingListCustomer | undefined>();
    const [sendEmailModalToCustomer, setSendEmailModalToCustomer] = useState<MarketingListCustomer | undefined>();
    const [editCustomerFollowUp, setEditCustomerFollowUp] = useState<MarketingListCustomer | undefined>();
    const columns = [
        {index: 0, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.APPLICANT'},
        {index: 1, translateKey: 'EMAIL'},
        {index: 2, translateKey: 'MOBILE_NUMBER'},
        {index: 3, translateKey: 'PRODUCT_TYPE'},
        {index: 4, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.STATUS'},
        {index: 5, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.DATE'},
        {index: 6, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.FOLLOW_UP'},
        {index: 7, translateKey: 'CUSTOMER_ADVISOR'}
    ].concat(props.editable ? [{index: 8, translateKey: 'MARKETING_LISTS_VIEW.TABLE_HEADERS.ACTIONS'}] : []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { if (props.customerAdvisorsTeams === undefined) { props.loadCustomerAdvisorsTeams(); } }, []);
    
    const showSendSmsModal = (e: MouseEvent, customer: MarketingListCustomer) => {
        e.preventDefault();
        e.stopPropagation();
        setSendSmsModalToCustomer(customer);
    }

    const showSendEmailModal = (e: MouseEvent, customer: MarketingListCustomer) => {
        e.preventDefault();
        e.stopPropagation();
        setSendEmailModalToCustomer(customer);
    }
    
    const onFollowUpChange = (followUp: Date | null) => {
        if (editCustomerFollowUp && props.onCustomerFollowUpDateChange) {
            props.onCustomerFollowUpDateChange(editCustomerFollowUp, followUp);
        }
    }
   
    return (
        <>
            <RefreshButton
                style={{position: 'absolute', right: '-48px'}}
                isLoading={!!props.isLoading}
                onClick={props.onRefreshDataClick}
            >
                {!props.isLoading && props.isOutdated &&
                    <div className="filter-indicator" style={{fontSize: '6px', top: '2px', right: '6px'}}><FontAwesomeIcon icon={faCircle} /></div>
                }
            </RefreshButton>
            <Table
                className="customers-table"
                hover={true}
                borderless={true}
                style={{marginBottom: '24px'}}
            >
                <TableHeader cells={columns}/>
                <TableRows<MarketingListCustomer>
                    rows={props.customers || []}
                    renderRow={(customer, index) => (
                        <tr 
                            key={`${index}-${customer.personId}`}
                            className={customer.marketingStatus === MarketingListCustomerStatus.NotInterested ? 'not-interested' : ''}
                        >
                            <td>{renderCustomerCell(customer)}</td>
                            <td>{renderEmailCell(customer.email, e => showSendEmailModal(e, customer))}</td>
                            <td>{renderMobileNumberCell(customer.mobileNumber, e => showSendSmsModal(e, customer))}</td>
                            <td><Translate id={getEnumTranslationKey(ProductType, customer.productType, 'PRODUCT_TYPES')}/></td>
                            <td>{renderLoanStatusCell(customer.loanStatus)}</td>
                            <td>{new Date(customer.submittedDate).toLocaleDateString()}</td>
                            <td className="follow-up-cell">{renderFollowUpCell(
                                    customer.followUpDate, 
                                    editCustomerFollowUp?.personId === customer.personId, 
                                    props.editable ? () => setEditCustomerFollowUp(customer) : undefined, 
                                    onFollowUpChange,
                                    () => {setEditCustomerFollowUp(undefined); props.onRefreshDataClick && props.onRefreshDataClick();}
                            )}</td>
                            <td>{renderCustomerAdvisorCell(props.customerAdvisors?.find(a => a.id === customer.customerAdvisorId))}</td>
                            {props.editable &&
                                <td className="actions-cell">{renderActionsCell(props, customer)}</td>
                            }
                        </tr>
                    )}
                />
            </Table>
            <TablePagination
                isLoading={props.isLoading}
                pageNumber={props.pageNumber}
                pageSize={props.pageSize}
                onPagePicked={props.onPageChange}
                onPageSizePicked={props.onPageSizeChange}
                count={props.count || 0}
                pickableSizes={[20, 50, 100, 250, 500]}
            />
            {!!sendSmsModalToCustomer &&
                <SendSmsModal
                    show={true}
                    productType={sendSmsModalToCustomer?.productType}
                    applicant={sendSmsModalToCustomer}
                    applicationId={sendSmsModalToCustomer?.applicationId}
                    publicId={sendSmsModalToCustomer?.publicId}
                    selectedMobileNumber={sendSmsModalToCustomer?.mobileNumber}
                    onClose={() => setSendSmsModalToCustomer(undefined)}
                />
            }
            {!!sendEmailModalToCustomer &&
                <SendEmailModal
                    show={true}
                    productType={sendEmailModalToCustomer?.productType}
                    applicant={sendEmailModalToCustomer}
                    applicationId={sendEmailModalToCustomer?.applicationId}
                    publicId={sendEmailModalToCustomer?.publicId}
                    selectedEmail={sendEmailModalToCustomer?.email}
                    onClose={() => setSendEmailModalToCustomer(undefined)}
                />
            }
        </>
    )
}

const renderCustomerCell = (customer: MarketingListCustomer) => {
    const path = customer.applicationId
        ? `/applicant/${customer.personId}/application/${customer.applicationId}`
        : `/applicant/${customer.personId}`;
    
    let displayName: JSX.Element | string = <Translate id="NO_DATA"/>;
    if (customer.firstName || customer.lastName) {
        displayName = beautifyName(`${customer.firstName || ''} ${customer.lastName || ''}`, false)
    }

    return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {customer.marketingStatus === MarketingListCustomerStatus.Unanswered &&
                    <Tooltip content={<Translate id="MARKETING_LISTS_VIEW.CUSTOMER_NOT_ANSWER"/>}>
                        <FontAwesomeIcon
                            icon={faPhoneSlash}
                            style={{fontSize: '10px', marginRight: '4px'}}
                        />
                    </Tooltip>
                }
                <OverlayTrigger
                    delay={{show: 500, hide: 0}}
                    placement="auto"
                    overlay={
                        <Popover id="applicant-tooltip" className="popover applicant-tooltip">
                            <ApplicantTooltip {...customer}/>
                        </Popover>
                    }
                >
                    <NavLink to={path} target="_blank">
                        {displayName}
                    </NavLink>
                </OverlayTrigger>
            </div>
    );
}

const renderEmailCell = (email: string, onClick: MouseEventHandler) => (
    <CopyToClipboardButton content={email}>
        <a
            href={`mailto:${email}`}
            style={{textTransform: 'lowercase'}}
            onClick={onClick}
        >{email}</a>
    </CopyToClipboardButton>
)
const renderMobileNumberCell = (mobileNumber: string, onClick: MouseEventHandler) => (
    <CopyToClipboardButton content={mobileNumber}>
        <a
            href={`tel:${mobileNumber}`}
            onClick={onClick}
        >{mobileNumber}</a>
    </CopyToClipboardButton>
)

const renderLoanStatusCell = (status: LoanStatus) => {
    const statusClassName = `status${loanStatusToColor(status, ' status-')}`;
    return (
        <span className={statusClassName}>
            <Translate id={getLoanStatusTranslateKey(status)} />
        </span>
    );
}

const renderFollowUpCell = (followUpDate?: string | null, editMode?: boolean, onClick?: () => void, onChange?: (followUp: Date | null) => void, onClose?: () => void) => (
    <div onClick={onClick}>
        <DateTimeInputField
            name="followUpDate"
            value={followUpDate}
            editMode={editMode}
            defaultOpen={true}
            kind={DateTimeInputFieldKind.DateWithTime}
            timeFrom={8}
            timeTo={20}
            onValueChanged={onChange}
            onCalendarClose={onClose}
        />
    </div>
)

const renderCustomerAdvisorCell = (customerAdvisor?: CustomerAdvisor) => {
    if (!customerAdvisor) {
        return <Translate id="NO_ADVISOR"/>
    }
    return customerAdvisor.displayName;
}

const renderActionsCell = (props: CustomersTableProps, customer: MarketingListCustomer) => (
    <div>
        <Tooltip content={<Translate id="NEW_APPLICATION"/>}>
            <FontAwesomeIcon icon={faPlusSquare} onClick={() => window.open(`/applicant/${customer.personId}/application/${customer.applicationId}/copy`, '_blank')}/>
        </Tooltip>
        {customer.marketingStatus === MarketingListCustomerStatus.Unhandled &&
            <Tooltip content={<Translate id="MARKETING_LISTS_VIEW.CUSTOMER_NOT_ANSWER"/>}>
                <FontAwesomeIcon
                    icon={faPhoneSlash}
                    onClick={() => props.onCustomerStatusChange && props.onCustomerStatusChange(customer, MarketingListCustomerStatus.Unanswered)}
                />
            </Tooltip>
        }
        {customer.marketingStatus !== MarketingListCustomerStatus.NotInterested &&
            <Tooltip content={<Translate id="MARKETING_LISTS_VIEW.CUSTOMER_NOT_INTERESTED"/>}>
                <FontAwesomeIcon
                    icon={faUserMinus}
                    onClick={() => props.onCustomerStatusChange && props.onCustomerStatusChange(customer, MarketingListCustomerStatus.NotInterested)}
                />
            </Tooltip>
        }
        {customer.marketingStatus === MarketingListCustomerStatus.NotInterested &&
            <Tooltip content={<Translate id="MARKETING_LISTS_VIEW.CUSTOMER_INTERESTED"/>}>
                <FontAwesomeIcon
                    icon={faUserPlus}
                    onClick={() => props.onCustomerStatusChange && props.onCustomerStatusChange(customer, MarketingListCustomerStatus.Unhandled)}
                />
            </Tooltip>
        }
        <AddCommentAction onSave={comment => props.onCustomerCommentSave && props.onCustomerCommentSave(customer, comment)}/>
    </div>
)

const AddCommentAction = ({onSave}: {onSave: (comment: string) => void}) => {
    const [comment, setComment] = useState<string | undefined>();
    // retrieve toggle handler to close overlay after popover button click
    const toggleOverlay = useRef<() => void>(() => {/*no-op*/});
    
    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Popover>
                    <Popover.Body>
                        <TextInputField
                            name="customer-comment"
                            value={comment}
                            onValueChanged={setComment}
                            editMode={true}
                            rows={2}
                        />
                        <Button
                            disabled={!comment}
                            size="sm"
                            style={{width: '100%', marginTop: '8px'}}
                            onClick={() => {
                                onSave(comment!);
                                toggleOverlay.current();
                            }}
                        ><Translate id="SAVE"/></Button>
                    </Popover.Body>
                </Popover>
            }
            trigger="click"
            rootClose={true}
            onToggle={() => setComment(undefined)}
        >
            {/*render overlay target by function to get OT props with toggle handler*/}
            {/*see https://react-bootstrap.netlify.app/docs/components/overlays/#customizing-trigger-behavior*/}
            {props => {
                let toggleOverlayHandler = (props as any).onClick;
                toggleOverlay.current = toggleOverlayHandler;
                return (
                    <Tooltip content={<Translate id="ADD_COMMENT"/>}>
                        <FontAwesomeIcon 
                            ref={props.ref} 
                            icon={faCommentMedical}
                            onClick={toggleOverlayHandler}
                        />
                    </Tooltip>
                )}
            }
        </OverlayTrigger>
    )    
}
const mapStateToProps = (state: any) => ({
    ...state.customerAdvisorsTeamsReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({ ...CustomerAdvisorsTeamsActionsCreator }, dispatch);

export default connect<CustomerAdvisorsTeamsProps, CustomerAdvisorsTeamsActions>(mapStateToProps, mapActionCreatorsToProps)(CustomersTable);