import {Campaign, CampaignRecipient, CampaignRecipientsFilter, CampaignEngagement} from "../../common/models/Campaign";
import {
    CampaignViewDataAction,
    EDIT_CAMPAIGN,
    EDIT_RECIPIENTS_FILTER,
    SET_ACTIVE_TAB,
    SET_CAMPAIGN,
    SET_CAMPAIGN_RECIPIENTS,
    SET_CAMPAIGN_RECIPIENTS_COUNT,
    SET_CAMPAIGN_ENGAGEMENTS,
    SET_ERROR,
    SET_ERRORS,
    SET_IS_LOADING,
    SET_PAGE_NUMBER,
    SET_PAGE_SIZE
} from "../actions/CampaignViewDataActionCreators";
import {TableState} from "../../common/models/TableState";
import {Optional} from "utility-types";

const defaultTableState: TableState = {
    pageNumber: 0,
    orderBy: undefined,
    orderDirection: 1,
    pageSize: 20
}

const defaultActiveTab = 'design';

export const campaignRecipientsDefaultFilter: Partial<CampaignRecipientsFilter> = {
    excludeInkasso: true,
    hasProperty: false,
    skipActiveFollowUps: (new Date()).toISOString()
}

export interface CampaignViewDataState {
    campaign?: Optional<Campaign, 'name' | 'id'>;
    campaignDirty: boolean;
    campaignRecipients?: CampaignRecipient[];
    lastCampaignRecipientsFilter: CampaignRecipientsFilter | null;
    campaignRecipientsCount: number;
    campaignEngagements?: CampaignEngagement[];
    campaignRecipientsTableState: TableState;
    campaignEngagementsTableState: TableState;
    isLoading: boolean;
    activeTab: 'design' | 'recipients' | 'engagements';
    errors: {[name: string]: boolean}
}

export const campaignViewDataReducer = (state: any, action: CampaignViewDataAction): CampaignViewDataState => {
    state = state || {};

    if (!state.campaignRecipientsTableState) {
        state.campaignRecipientsTableState = defaultTableState;
    }

    if (!state.campaignEngagementsTableState) {
        state.campaignEngagementsTableState = defaultTableState;
    }

    if (!state.campaignRecipients && !state.campaignRecipientsCount) {
        state.campaignRecipientsCount = 0;
    }

    if (!state.activeTab) {
        state.activeTab = defaultActiveTab;
    }
    
    if (!state.errors) {
        state.errors = {};
    }
    
    switch(action.type) {
        case SET_CAMPAIGN:
            return {
                ...state,
                ...action.payload,
                campaignDirty: false,
                errors: {}
            }
        case EDIT_CAMPAIGN:
            return {
                ...state,
                campaignDirty: action.payload.dirty !== undefined ? action.payload.dirty : true,
                campaign: {
                    ...(state.campaign || {}),
                    ...action.payload.campaign
                }
            }
        case SET_CAMPAIGN_RECIPIENTS:
            return {
                ...state,
                ...action.payload,
                lastCampaignRecipientsFilter: state.campaign ? state.campaign.campaignRecipientsFilter : null
            }
        case SET_CAMPAIGN_RECIPIENTS_COUNT:
            return {
                ...state,
                ...action.payload
            }
        case EDIT_RECIPIENTS_FILTER:
            return {
                ...state,
                campaignDirty: true,
                campaign: {
                    ...state.campaign,
                    campaignRecipientsFilter: {
                        ...state.campaign.campaignRecipientsFilter,
                        ...action.payload.campaignRecipientsFilter
                    }
                }
            }
        case SET_CAMPAIGN_ENGAGEMENTS:
            return {
                ...state,
                ...action.payload
            }
        case SET_ACTIVE_TAB:
            return {
                ...state,
                ...action.payload
            }
        case SET_IS_LOADING:
            return {
                ...state,
                ...action.payload
            }
        case SET_PAGE_NUMBER:
            if (action.payload.tableName === 'recipients') {
                return {
                    ...state,
                    campaignRecipientsTableState: {
                        ...state.campaignRecipientsTableState,
                        pageNumber: action.payload.pageNumber
                    }
                };
            } else {
                return {
                    ...state,
                    campaignEngagementsTableState: {
                        ...state.campaignEngagementsTableState,
                        pageNumber: action.payload.pageNumber
                    }
                };
            }
        case SET_PAGE_SIZE:
            if (action.payload.tableName === 'recipients') {
                return {
                    ...state,
                    campaignRecipientsTableState: {
                        ...state.campaignRecipientsTableState,
                        pageSize: action.payload.pageSize,
                        pageNumber: 0
                    }
                };
            } else {
                return {
                    ...state,
                    campaignEngagementsTableState: {
                        ...state.campaignEngagementsTableState,
                        pageSize: action.payload.pageSize,
                        pageNumber: 0
                    }
                };
            }
        case SET_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.name]: action.payload.value
                }
            }
        case SET_ERRORS:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}