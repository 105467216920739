import { Action, Dispatch } from 'redux';
import { ToastMessageType, ToastMessage } from '../models/ToastMessage';

export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE';
export const HIDE_TOAST_MESSAGE = 'HIDE_TOAST_MESSAGE';

interface ShowToastMessageAction extends Action<typeof SHOW_TOAST_MESSAGE> {
    toast: ToastMessage;
}

interface HideToastMessageAction extends Action<typeof HIDE_TOAST_MESSAGE> {
    id: string;
}

export type ToastMessageAction = ShowToastMessageAction | HideToastMessageAction;

const getShowToastMessageAction = (messageType: ToastMessageType, title: string, content: string | string[], id?: string): ShowToastMessageAction => ({
    type: SHOW_TOAST_MESSAGE,
    toast: {
        id: id || getId(),
        messageType,
        title,
        content
    }
});

const getHideToastMessageAction = (id: string): HideToastMessageAction => ({
    type: HIDE_TOAST_MESSAGE,
    id
});

export const showToastMessage = (messageType: ToastMessageType, title: string, content: string | string[], id?: string) => {
    return (dispatch: Dispatch, getState: any) => {
        dispatch(getShowToastMessageAction(messageType, title, content, id));
    };
};

export const hideToastMessage = (id: string) => {
    return (dispatch: Dispatch, getState: any) => {
        dispatch(getHideToastMessageAction(id));
    };
};

export interface ShowToastMessageProps {
    showToastMessage: typeof showToastMessage;
}

let lastId = 0;

function getId() {
    return `__TOAST_MESSAGE:${++lastId}__`;
}
