import {AxiosClientFactory} from "../../common/helpers/AxiosClientFactory";
import {CampaignRecipientsFilter, CampaignType} from "../../common/models/Campaign";


export interface CountCampaignRecipientsPreviewParams {
    campaignType: CampaignType;
    filter: CampaignRecipientsFilter | null;
}

export const countCampaignRecipientsPreview = (params: CountCampaignRecipientsPreviewParams): Promise<number> => {
    return AxiosClientFactory
        .getInstance()
        .post<number>(`/api/campaigns/recipients/count`, {...params})
        .then((result) => result.data);
};