import React, {
	FunctionComponent,
	CSSProperties
} from 'react';

import CopyToClipboardButton from './CopyToClipboardButton';
import {beautifyLinks} from "../helpers/beautifyLinks";

interface TextClipperProps {
	className: string;
	content: string;
}

const TextClipper: FunctionComponent<TextClipperProps> = props => {
	const style = {
		cursor: 'default',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	} as CSSProperties;

	return (
		<CopyToClipboardButton content={props.content} className={props.className}>
			<div style={style} title={props.content}>
				{beautifyLinks(props.content)}
			</div>
		</CopyToClipboardButton>
	);
};

export default TextClipper;
