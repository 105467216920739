import {Action, Dispatch} from "redux";
import {CampaignSummary, CampaignStatus, CampaignType} from "../../common/models/Campaign";
import {CampaignsFilter} from "../models/CampaignsFilter";
import {TableState} from "../../common/models/TableState";
import {getCampaigns} from "../api/getCampaigns";

export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_CAMPAIGNS_PAGE_SIZE = 'SET_CAMPAIGNS_PAGE_SIZE';
export const SET_CAMPAIGNS_PAGE_NUMBER = 'SET_CAMPAIGNS_PAGE_NUMBER';
export const SET_ORDER = 'SET_ORDER';
export const SET_TEAM_FILTER = 'SET_TEAM_FILTER';
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export const SET_TYPE_FILTER = 'SET_TYPE_FILTER';
export const RESET_CAMPAIGNS_VIEW = 'RESET_CAMPAIGNS_VIEW';

interface SetCampaigns extends Action<typeof SET_CAMPAIGNS> {
    payload: {
        campaigns: CampaignSummary[];
    }
}

interface SetIsLoading extends Action<typeof SET_IS_LOADING> {
    payload: {
        isLoading: boolean;
    }
}

interface SetCampaignsPageSize extends Action<typeof SET_CAMPAIGNS_PAGE_SIZE> {
    payload: {
        pageSize: number;
    }
}

interface SetCampaignsPageNumber extends Action<typeof SET_CAMPAIGNS_PAGE_NUMBER> {
    payload: {
        pageNumber: number;
    }
}

interface SetOrder extends Action<typeof SET_ORDER> {
    payload: {
        orderBy?: number;
        orderDirection: number;
    }
}

interface SetTeamFilter extends Action<typeof SET_TEAM_FILTER> {
    payload: {
        teamId?: number;
    }
}

interface SetStatusFilter extends Action<typeof SET_STATUS_FILTER> {
    payload: {
        status?: CampaignStatus;
    }
}

interface SetTypeFilter extends Action<typeof SET_TYPE_FILTER> {
    payload: {
        type?: CampaignType;
    }
}

interface ResetCampaignsView extends Action<typeof RESET_CAMPAIGNS_VIEW> {
}

export type CampaignsViewDataAction =
    SetCampaigns
    | SetIsLoading
    | SetCampaignsPageSize
    | SetCampaignsPageNumber
    | SetOrder
    | SetTeamFilter
    | SetStatusFilter
    | SetTypeFilter
    | ResetCampaignsView;

const setCampaigns = (campaigns: CampaignSummary[]): SetCampaigns => ({
    type: SET_CAMPAIGNS,
    payload: {
        campaigns
    }
});

const setIsLoading = (isLoading: boolean): SetIsLoading => ({
    type: SET_IS_LOADING,
    payload: {
        isLoading
    }
});

const setCampaignsPageSize = (pageSize: number): SetCampaignsPageSize => ({
    type: SET_CAMPAIGNS_PAGE_SIZE,
    payload: {
        pageSize
    }
});

const setCampaignsPageNumber = (pageNumber: number): SetCampaignsPageNumber => ({
    type: SET_CAMPAIGNS_PAGE_NUMBER,
    payload: {
        pageNumber
    }
});

const setOrder = (orderDirection: number, orderBy?: number): SetOrder => ({
    type: SET_ORDER,
    payload: {
        orderBy,
        orderDirection
    }
});

const setTeamFilter = (teamId?: number): SetTeamFilter => ({
    type: SET_TEAM_FILTER,
    payload: {
        teamId
    }
});

const setStatusFilter = (status?: CampaignStatus): SetStatusFilter => ({
    type: SET_STATUS_FILTER,
    payload: {
        status
    }
});

const setTypeFilter = (type?: CampaignType): SetTypeFilter => ({
    type: SET_TYPE_FILTER,
    payload: {
        type
    }
});

const resetCampaignsView = (): ResetCampaignsView => ({
    type: RESET_CAMPAIGNS_VIEW
});

export interface CampaignsViewDataActions {
    loadCampaigns(params: Partial<CampaignsFilter & TableState>);
    setPageSize(pageSize: number);
    setPageNumber(pageNumber: number);
    setOrder(orderDirection: number, orderBy?: number);
    setTeamFilter(teamId?: number);
    setStatusFilter(status?: CampaignStatus);
    setTypeFilter(type?: CampaignType);
    resetCampaignsView();
}

export const CampaignsViewDataActionCreators: CampaignsViewDataActions = {
    loadCampaigns: (params: Partial<CampaignsFilter & TableState>) => {
        return (dispatch: Dispatch) => {
            dispatch(setIsLoading(true));
            getCampaigns(params).then(campaigns => {
                dispatch(setCampaigns(campaigns));
                dispatch(setIsLoading(false));
            });
        }
    },
    setPageSize: (pageSize: number) => {
        return (dispatch: Dispatch) => {
            dispatch(setCampaignsPageSize(pageSize));
        }
    },
    setPageNumber: (pageNumber: number) => {
        return (dispatch: Dispatch) => {
            dispatch(setCampaignsPageNumber(pageNumber));
        }
    },
    setOrder: (orderDirection: number, orderBy?: number) => {
        return (dispatch: Dispatch) => {
            dispatch(setOrder(orderDirection, orderBy));
        }
    },
    setTeamFilter: (teamId?: number) => {
        return (dispatch: Dispatch) => {
            dispatch(setTeamFilter(teamId));
        }
    },
    setStatusFilter: (status?: CampaignStatus) => {
        return (dispatch: Dispatch) => {
            dispatch(setStatusFilter(status));
        }
    },
    setTypeFilter: (type?: CampaignType) => {
        return (dispatch: Dispatch) => {
            dispatch(setTypeFilter(type));
        }
    },
    resetCampaignsView: () => {
        return (dispatch: Dispatch) => {
            dispatch(resetCampaignsView());
        }
    },
}