import {Redemption} from "../models/Redemption";
import {DebtType} from "../models/DebtType";
import {RedemptionSource} from "../models/RedemptionSource";


export const calculateMonthlyCost = (redemption: Redemption) => {
    const {balance, interestRate, remainingRepaymentPeriod, debtType, source} = {...redemption};
    let monthlyCost = 0;
    if (remainingRepaymentPeriod && interestRate && (debtType === DebtType.CreditCard || debtType === DebtType.ConsumerLoan) && source === RedemptionSource.Gjeldsregisteret) {
        const decimalNomInterest = Math.pow(1 + interestRate/100, 1/12) - 1;
        monthlyCost = balance * decimalNomInterest / (1 - 1 / Math.pow(1 + decimalNomInterest, remainingRepaymentPeriod));
    }
    return monthlyCost;
}

export function calculateMortgageLoan(redemptions: Redemption[], onlyNotRefinanced?: boolean): number {
    const mortgageLoanReducer = (mortgageLoan: number, redemption: Redemption) => (
        redemption.debtType === DebtType.MortgageLoan && (onlyNotRefinanced ? !redemption.refinance : true) ?
            mortgageLoan + redemption.balance : mortgageLoan
    );
    return redemptions.reduce(mortgageLoanReducer, 0);
}

export function calculateLTV(mortgageLoans: number, commonDebt: number | null | undefined, estimatedValue: number | null | undefined): number {
    commonDebt = commonDebt || 0;
    estimatedValue = estimatedValue || 0;
    return (mortgageLoans + commonDebt) / (estimatedValue + commonDebt);
}