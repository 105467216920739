import { PropertyType } from "../models/PropertyType";

export const getPropertyTypesTranslateKeys = (propertyType: PropertyType): string => {
    switch (propertyType) {
        case PropertyType.DetachedHouse: return 'PROPERTY_TYPES.DETACHED_HOUSE';
        case PropertyType.SemiDetachedHouse: return 'PROPERTY_TYPES.SEMI_DETACHED_HOUSE';
        case PropertyType.TownHouse: return 'PROPERTY_TYPES.TOWN_HOUSE';
        case PropertyType.Cottage: return 'PROPERTY_TYPES.COTTAGE';
        case PropertyType.Cooperative: return 'PROPERTY_TYPES.COOPERATIVE';
        case PropertyType.HousingCorporation: return 'PROPERTY_TYPES.HOUSING_CORPORATION';
        case PropertyType.Condominium: return 'PROPERTY_TYPES.CONDOMINIUM';
        case PropertyType.Farm: return 'PROPERTY_TYPES.FARM';
        default: throw new Error('Unknown property type');
    }
};