import {AxiosClientFactory} from "../../common/helpers/AxiosClientFactory";
import {MarketingListCustomersFilter} from "../model/MarketingListCustomersFilter";

export interface CountMarketingListCustomersPreviewParams {
    filter?: MarketingListCustomersFilter;
}

export const countMarketingListCustomersPreview = (params: CountMarketingListCustomersPreviewParams): Promise<number> => {
    return AxiosClientFactory
        .getInstance()
        .post<number>(`/api/marketingLists/customers/count`, params.filter)
        .then((result) => result.data);
};