export enum PropertyType {
    DetachedHouse = 0,
    SemiDetachedHouse = 1,
    TownHouse = 2,
    Cottage = 3,
    Cooperative = 4,
    HousingCorporation = 5,
    Condominium = 6,
    Farm = 7
}
