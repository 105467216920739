import React, {FunctionComponent, useEffect, useState} from "react";
import "./Footer.css";
import DropdownInputField from "../../../../../../common/components/input-fields/DropdownInputField";
import FileLink from "../../../../../../common/components/FileLink";
import {Translate} from "react-localize-redux";
import {File} from "../../../../../../common/helpers/File";
import TextInputField from "../../../../../../common/components/input-fields/TextInputField";

interface FooterProps {
    applicationId: number;
    purpose: string | null;
    banks: string[];
    getEconomyOverViewClick: (bank: string | null, purpose: string | null) => Promise<File>;
}
const Footer: FunctionComponent<FooterProps> = (props) => {
    const [bank, setBank] = useState<string | null>(null);
    const [purpose, setPurpose] = useState<string | null>(props.purpose);
    
    useEffect(() => {
        if (props.banks.length) {
            setBank(props.banks[0]);
        }
    }, [props.banks]);
    
    return (
        <div className="mortgage-calculator-modal-footer">
            <div>
                <DropdownInputField<string>
                    name="bankName"
                    style={{width: '200px'}}
                    descriptionKey={'BANK'}
                    value={bank}
                    editMode={true}
                    searchable={true}
                    items={props.banks}
                    keyValue={(bankName) => bankName}
                    displayValue={(bankName) => bankName}
                    onValueChanged={(bankName) => setBank(bankName as string | null)}
                />
            </div>
            <div>
                <TextInputField
                    name="purpose"
                    className="purpose"
                    value={purpose}
                    editMode={true}
                    descriptionKey={'PURPOSE.LABEL'}
                    onValueChanged={purpose => setPurpose(purpose)}
                />
            </div>
            <FileLink 
                variant="primary"
                getFile={() => props.getEconomyOverViewClick(bank!, purpose)}
            >
                <Translate id="ECONOMY_OVERVIEW"/>
            </FileLink>
        </div>
    )
}
export default Footer;