import {AxiosClientFactory} from "../../common/helpers/AxiosClientFactory";
import {CountMarketingListCustomersPreviewParams} from "./countMarketingListCustomerPreview";

export interface CountMarketingListCustomersParams extends CountMarketingListCustomersPreviewParams {
    listId: number;
}

export const countMarketingListCustomers = (params: CountMarketingListCustomersParams): Promise<number> => {
    return AxiosClientFactory
        .getInstance()
        .post<number>(`/api/marketingLists/${params.listId}/customers/count`, params.filter)
        .then((result) => result.data);
};