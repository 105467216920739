import React, {CSSProperties, FunctionComponent, useEffect, useRef, useState} from "react";
import {getEmail} from "../../applications/applicant/api/getEmail";
import {Popover} from "react-bootstrap";
import {CustomerCommunicationEmail} from "../models/CustomerCommunicationEmail";
import {Translate} from "react-localize-redux";

interface PreviewEmailPopoverProps {
    id: number;
}
const PreviewEmailPopover: FunctionComponent<PreviewEmailPopoverProps> = (props) => {
    const [email, setEmail] = useState<CustomerCommunicationEmail>();
    const body = useRef<HTMLDivElement | null>(null);
    const popoverBodyStyle: CSSProperties = {
        minWidth: '600px', 
        maxHeight: '500px',
        overflow: 'hidden auto',
        width: 'min-content'
    }
    
    useEffect(() => {
        getEmail(props.id).then(res => setEmail(res.data));
    }, [props.id]);
    
    useEffect(() => {
        if (email && body.current) {
            if (!body.current!.shadowRoot) {
                body.current!.attachShadow({mode: 'open'});
            }
            body.current!.shadowRoot!.innerHTML = email.body || '';
        }
    }, [email])
    
    const emailAddressStyle = {fontSize: '12px', color: '#c6c6c6', lineHeight: '21px'};
    
    return <>
        <Popover.Header as="h3">
            {email &&
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{lineHeight: '21px'}}>{email.subject}</span>
                    {email.senderAddress &&
                        <span style={emailAddressStyle}>
                            <Translate id="FROM_EMAIL" data={{email: email.senderAddress}}/>
                        </span>
                    }
                    {email.recipientAddress &&
                        <span style={emailAddressStyle}>
                            <Translate id="TO_EMAIL" data={{email: email.recipientAddress}}/>
                        </span>
                    }
                </div>
            }
        </Popover.Header>
        <Popover.Body style={popoverBodyStyle}>
            <div style={{border: email && email.body ? '1px solid #c6c6c6' : 'none'}} ref={body}/>
            {email && !email.body &&
                <span><Translate id="EMAIL_CONTENT_NOT_AVAILABLE"/></span>
            }
        </Popover.Body>
    </>
}
export default PreviewEmailPopover;