import {AxiosClientFactory} from "../../common/helpers/AxiosClientFactory";
import {TableState} from "../../common/models/TableState";
import {CountMarketingListCustomersParams} from "./countMarketingListCustomers";
import {MarketingListCustomer} from "../model/MarketingListCustomer";

export type GetMarketingListCustomersParams = CountMarketingListCustomersParams & Partial<TableState>;

export const getMarketingListCustomers = (params: GetMarketingListCustomersParams): Promise<MarketingListCustomer[]> => {
    return AxiosClientFactory
        .getInstance()
        .post<MarketingListCustomer[]>(`/api/marketingLists/${params.listId}/customers`, params)
        .then((result) => result.data);
};