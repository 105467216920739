export const Storage = {
    getItem,
    setItem
};

function getItem<T>(key: string): T | null {
    const jsonString = window.localStorage.getItem(key);
    return jsonString !== null ? JSON.parse(jsonString) : null;
}

function setItem<T>(key: string, item: T): void {
    window.localStorage.setItem(key, JSON.stringify(item));
}
