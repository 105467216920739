import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { ApplicantsFilter } from '../models/ApplicantsFilter';
import { ApplicantsTotal } from '../models/ApplicantsTotal';

export const getApplicantsTotals = (params: ApplicantsFilter): Promise<ApplicantsTotal[]> => {
    return new Promise<ApplicantsTotal[]>((executor) => {
        params = params || {};

        AxiosClientFactory
            .getInstance()
            .get<ApplicantsTotal[]>('/api/applicants/totals', { params })
            .then((result) => executor(result.data));
    });
};
