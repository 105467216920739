import React, { FunctionComponent } from 'react';
import { ApplicantsTotal } from '../models/ApplicantsTotal';
import { LoanStatus } from '../../../common/models/LoanStatus';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { getLoanStatusTranslateKey } from '../../../common/helpers/loanStatusFunctions';
import { getCurrencySymbolForCountryId } from '../../../common/helpers/currencySymbolFunctions';

const statues = [LoanStatus.Paid, LoanStatus.Sent, LoanStatus.Granted];

interface ApplicantsTotalsProps extends LocalizeContextProps {
    totals: ApplicantsTotal[];
    countryId: number;
}

const ApplicantsTotals: FunctionComponent<ApplicantsTotalsProps> = (props: ApplicantsTotalsProps) => {
    return (
        <div className="applicants-totals">
            {statues.map((s) => renderTotal(s, props))}
        </div>
    );
};

function renderTotal(status: LoanStatus, props: ApplicantsTotalsProps) {
    const filter = status === LoanStatus.Granted
        ? (total: ApplicantsTotal) => total.loanStatus === LoanStatus.Granted || total.loanStatus === LoanStatus.GrantedSelected
        : (total: ApplicantsTotal) => total.loanStatus === status;
    return (
        <div key={status}>
            <span>{props.translate('APPLICANT_TOTAL', {status: props.translate(getLoanStatusTranslateKey(status))})}</span>&nbsp;
            {props.totals.filter(filter).reduce((p, c) => c.total + p, 0).toLocaleString()}&nbsp;
            {getCurrencySymbolForCountryId(props.countryId)}
        </div>
    );
}

export default withLocalize(ApplicantsTotals);
