import { ConsumerLoanBrief } from '../models/ConsumerLoanBrief';

import { Action, Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { getConsumerLoans } from '../api/getConsumerLoans';
import { SortUtilities } from '../../../common/helpers/SortUtilities';
import { ConsumerLoan } from '../models/ConsumerLoan';
import { getConsumerLoan } from '../api/getConsumerLoan';
import { getInsurances } from '../api/insurances/getInsurances';
import { InsuranceReadModel } from '../models/InsuranceReadModel';

export const SET_APPLICANT_APPLICATIONS = 'SET_APPLICANT_APPLICATIONS';
export const SET_APPLICANT_CURRENT_APPLICATION = 'SET_APPLICANT_CURRENT_APPLICATION';
export const SET_APPLICANT_INSURANCES = 'SET_APPLICANT_INSURANCES';

interface SetApplicantApplicationsAction extends Action<typeof SET_APPLICANT_APPLICATIONS> {
    payload: ConsumerLoanBrief[];
}

interface SetApplicantCurrentApplication extends Action<typeof SET_APPLICANT_CURRENT_APPLICATION> {
    payload: ConsumerLoan;
}

interface SetApplicantInsurances extends Action<typeof SET_APPLICANT_INSURANCES> {
    payload: InsuranceReadModel[];
}

export type ApplicantDataAction = SetApplicantApplicationsAction | SetApplicantCurrentApplication | SetApplicantInsurances;

const setApplicantApplications = (payload: ConsumerLoanBrief[]): SetApplicantApplicationsAction => ({
    type: SET_APPLICANT_APPLICATIONS,
    payload
});

const setApplicantCurrentApplication = (payload: ConsumerLoan): SetApplicantCurrentApplication => ({
    type: SET_APPLICANT_CURRENT_APPLICATION,
    payload
});

const setApplicantInsurances = (payload: InsuranceReadModel[]) => ({
    type: SET_APPLICANT_INSURANCES,
    payload
});

const ApplicationDataActionsCreator = {
    loadApplications: (personId: number) => {
        return (dispatch: Dispatch) => {
            return getConsumerLoans(personId)
                .then((response: AxiosResponse<ConsumerLoanBrief[]>) => {
                    const data = SortUtilities.sortDescByDateProperty(response.data, (x) => x.submittedDate);
                    dispatch(setApplicantApplications(data));
                    return data;
                });
        };
    },
    setCurrentApplication: setApplicantCurrentApplication,
    loadCurrentApplication: (personId: number, applicationId: number) => {
        return (dispatch: Dispatch) => {
            return getConsumerLoan(personId, applicationId).then((response: AxiosResponse<ConsumerLoan>) => {
                dispatch(setApplicantCurrentApplication(response.data));
                return response.data;
            });
        };
    },
    loanInsurances: (personId: number) => {
        return (dispatch: Dispatch) => {
            return getInsurances(personId).then((response: AxiosResponse<InsuranceReadModel[]>) => {
                dispatch(setApplicantInsurances(response.data));
                return response.data;
            });
        };
    }
};

export default ApplicationDataActionsCreator;
