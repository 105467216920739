import React, { FunctionComponent } from 'react';
import { LocalizeContextProps, withLocalize, Translate } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './add-co-applicant-button.css';

interface AddCoApplicantProps extends LocalizeContextProps {
    addCoApplicant: () => void;
}

const AddCoApplicantButton: FunctionComponent<AddCoApplicantProps> = (props) => {
    return (
        <div className="add-co-applicant-button-wrapper">
            <div>
                <Translate id="ADD_CO_APPLICANT" />
            </div>
            <div onClick={props.addCoApplicant} className="add-co-applicant-button">
                <FontAwesomeIcon icon={faPlus} />
            </div>
        </div>
    );
};

export default withLocalize(AddCoApplicantButton);
