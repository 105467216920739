import { AxiosResponse } from 'axios';
import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { ConsumerLoanApplicantDetails } from '../models/ConsumerLoanApplicantDetails';

export const getConsumerLoanApplicantDetails = (personId: number | string, loanId: number | string, applicantId: number | string): Promise<ConsumerLoanApplicantDetails> => {
    return new Promise<ConsumerLoanApplicantDetails>((resolve) => {
        AxiosClientFactory
            .getInstance()
            .get<ConsumerLoanApplicantDetails>(`/api/applicants/${personId}/consumerLoans/${loanId}/applicantdetails/${applicantId}`)
            .then((response: AxiosResponse<ConsumerLoanApplicantDetails>) => resolve(response.data));
    });
};

export const getConsumerLoanCoApplicantDetails = (personId: number | string, loanId: number | string, coApplicantId: number | string): Promise<ConsumerLoanApplicantDetails> => {
    return new Promise<ConsumerLoanApplicantDetails>((resolve) => {
        AxiosClientFactory
            .getInstance()
            .get<ConsumerLoanApplicantDetails>(`/api/applicants/${personId}/consumerLoans/${loanId}/coapplicantdetails/${coApplicantId}`)
            .then((response: AxiosResponse<ConsumerLoanApplicantDetails>) => resolve(response.data));
    });
};
