import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';

export const sendCreditInformationEnrichmentRequest = async (personId: string | number, applicationId: string | number): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .post(`/api/applicants/${personId}/applications/${applicationId}/creditInformation/enrichmentRequests`);

    return new CommandResult(result);
};
