import { faSearch, faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, FunctionComponent, useState, MouseEventHandler } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import Checkbox from '../../../../common/components/Checkbox';
import { CommentNotification } from '../../models/CommentNotification';
import { connect } from 'react-redux';
import { SubstatusesProps } from '../../../../common/interfaces/SubstatusesProps';
import { ApplicantDataStateProps } from '../../models/ApplicantDataProps';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

interface LogsAndCommentsFormProps extends LocalizeContextProps, SubstatusesProps, ApplicantDataStateProps {
    filter: string;
    onFilterChanged: (filter: string) => void;
    onSaveComment: (commentText: string, notification: CommentNotification, file: any[]) => void;
    onFormInputChanged: (isEmpty: boolean) => void;
}

const LogsAndCommentsForm: FunctionComponent<LogsAndCommentsFormProps> = (props) => {
    const [fileInputReference, setFileInputReference] = useState<any>(undefined);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
    const [commentText, setCommentText] = useState('');
    const [notification, setNotification] = useState(CommentNotification.None);

    const onNotificationUrgentClicked = () => setNotification(notification === CommentNotification.Urgent ? CommentNotification.None : CommentNotification.Urgent);
    const onNotificationCompleteClicked = () => setNotification(notification === CommentNotification.Complete ? CommentNotification.None : CommentNotification.Complete);
    const onNotificationCaseWorkerClicked = () => setNotification(notification === CommentNotification.CaseWorker ? CommentNotification.None : CommentNotification.CaseWorker);

    const onSaveCommentClickHandler = () => {
        props.onSaveComment(commentText, notification, selectedFiles);
        setCommentText('');
        setSelectedFiles([]);
    };

    const onCommentTextChangedHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        props.onFormInputChanged(value === '');
        setCommentText(value);
    };

    const renderSelectedFileName = (file: any) => {
        const removeFile = () => setSelectedFiles(selectedFiles.filter((f: any) => f.name !== file.name));

        return (
            <span key={`file-${file.name}`} className="deletable-file" style={{ padding: '2px 1px' }}>
                <FontAwesomeIcon icon={faPaperclip} />
                {file.name}
                <span onClick={removeFile}>
                    <FontAwesomeIcon icon={faTimes} />
                </span>
            </span>
        );
    }

    const renderSelectedFiles = () => selectedFiles.length > 0 ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {selectedFiles.map(renderSelectedFileName)}
        </div>
    ) : null;

    const onAddFileButtonClickedHandler = () => {
        if (fileInputReference !== undefined) {
            fileInputReference.click();
        }
    };

    const onFilterChangedHandler = (event: ChangeEvent<HTMLInputElement>) => props.onFilterChanged(event.target.value);

    const onDrop = (files: any[]) => setSelectedFiles(selectedFiles.concat(files));

    const onFileChangedHandler = (event: any) => {
        const files: any[] = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[i]); // event.target.files is actually a FileList, not an array
        }
        setSelectedFiles(selectedFiles.concat(files));
    };

    const filterPlaceHolder = props.translate('FILTER').toString();
    const commentTextPlaceholder = props.translate('WRITE_COMMENT').toString();

    const isAnySubstatusWithUserId: () => boolean = () => {
        const applicationSubstatuses = (props.currentApplication && props.currentApplication.substatusIds.length > 0) ? props.currentApplication.substatusIds : [];
        let result = false;
        applicationSubstatuses.forEach((as) => {
            const subStatus = props.substatuses && props.substatuses.find((ss) => ss.id === as);
            if (subStatus !== undefined && subStatus.userId !== null) {
                result = result || true;
            }
        });

        return result;
    };

    const renderNotificationCheckboxes = () => (isAnySubstatusWithUserId() ? (
        <div className="notification-selection">
            <div>
                <Translate id="COMMENT_NOTIFICATION" />
            </div>
            <div>
                <Checkbox
                    checked={notification === CommentNotification.Complete}
                    onClick={onNotificationCompleteClicked}
                >
                    <Translate id="COMPLETE" />
                </Checkbox>
                <Checkbox
                    checked={notification === CommentNotification.Urgent}
                    onClick={onNotificationUrgentClicked}
                >
                    <Translate id="URGENT" />
                </Checkbox>
                <Checkbox
                    checked={notification === CommentNotification.CaseWorker}
                    onClick={onNotificationCaseWorkerClicked}
                >
                    <Translate id="CASE_WORKER" />
                </Checkbox>
            </div>
        </div>
    ) : null);

    const renderDropzoneContents = ({ getRootProps, getInputProps, isDragActive }) => {
        const stopPropagation: MouseEventHandler<HTMLTextAreaElement> = (e) => e.stopPropagation();
        return (
            <div {...getRootProps()}>
                <div className="input-group">
                    <textarea
                        style={{ display: isDragActive ? 'none' : 'block' }}
                        onClick={stopPropagation}
                        className="form-control customized"
                        placeholder={commentTextPlaceholder}
                        value={commentText}
                        onChange={onCommentTextChangedHandler}
                    />
                    <div className={classNames('drag-n-drop-zone', { 'drag-over': isDragActive })}>
                        <Translate id="DRAG_AND_DROP_AREA" />
                    </div>
                </div>
                <input {...getInputProps()} />
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="input-group">
                <div className="input-group-prepend">
                    <div className="input-group-text customized">
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
                </div>
                <input
                    className="form-control customized"
                    placeholder={filterPlaceHolder}
                    onChange={onFilterChangedHandler}
                    value={props.filter}
                />
            </div>
            <Dropzone onDrop={onDrop}>
                {renderDropzoneContents}
            </Dropzone>
            {renderSelectedFiles()}
            {renderNotificationCheckboxes()}
            <div className="logs-and-comments-buttons">
                <button className="btn lc-buttons add-file-btn" onClick={onAddFileButtonClickedHandler}>
                    <Translate id="ADD_FILES" />
                    <input
                        style={{ display: 'none' }}
                        multiple={true}
                        type="file"
                        name="file"
                        ref={setFileInputReference}
                        onChange={onFileChangedHandler}
                    />
                </button>
                <button
                    className="btn lc-buttons save-comment-btn"
                    onClick={onSaveCommentClickHandler}
                    disabled={commentText === '' && selectedFiles.length === 0}
                >
                    <Translate id="SAVE_COMMENT" />
                </button>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    ...state.substatusesReducer,
    ...state.applicantDataReducer
});

export default connect<SubstatusesProps & ApplicantDataStateProps, {}, {}, any>(mapStateToProps)(withLocalize(LogsAndCommentsForm));
