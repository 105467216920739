import React, {FunctionComponent} from "react";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {Translate} from "react-localize-redux";

interface MarketingListMenuProps {
    onCopyClick?: () => void;
    onDeleteClick?: () => void;
}
const MarketingListMenu: FunctionComponent<MarketingListMenuProps> = (props) => {
    return (
        <Dropdown className="actions" drop="down" align="end">
            <Dropdown.Toggle id="application-menu" className="btn-white-bg" style={{ padding: '3px 12px' }}>
                <FontAwesomeIcon icon={faBars} />
            </Dropdown.Toggle>
            <div className="dropdown-arrow-down" />
            <Dropdown.Menu>
                <Dropdown.Item key="copy-marketing-list" onClick={props.onCopyClick}>
                    <Translate id="COPY" />
                </Dropdown.Item>
                <Dropdown.Item key="delete-marketing-list" onClick={props.onDeleteClick} style={{color: '#F05B59'}}>
                    <Translate id="DELETE" />
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}
export default MarketingListMenu;