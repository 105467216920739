import React, {FunctionComponent, useEffect} from "react";
import {Table} from "react-bootstrap";
import TableHeader, {TableHeaderCellModel} from "../../../../../../common/components/table/TableHeader";
import TableRows from "../../../../../../common/components/table/TableRows";
import {CampaignRecipient, CampaignRecipientStatus, CampaignStatus} from "../../../../../../common/models/Campaign";
import TablePagination from "../../../../../../common/components/table/TablePagination";
import {bindActionCreators, Dispatch} from "redux";
import {
    CampaignViewDataActionCreators,
    CampaignViewDataActions
} from "../../../../../actions/CampaignViewDataActionCreators";
import {connect} from "react-redux";
import {CampaignViewDataState} from "../../../../../reducers/campaignViewDataReducer";
import {CountCampaignRecipientsPreviewParams} from "../../../../../api/countCampaignRecipientsPreview";
import {Translate} from "react-localize-redux";
import {loanStatusToColor} from "../../../../../../common/helpers/loanStatusToColor";
import {getLoanStatusTranslateKey} from "../../../../../../common/helpers/loanStatusFunctions";
import {NavigateFunction, useNavigate} from "react-router";
import {LoanStatus} from "../../../../../../common/models/LoanStatus";
import {beautifyName} from "../../../../../../common/helpers/beautifyName";
import RefreshButton from "../../../../../../common/components/RefreshButton";
import {getEnumTranslationKey, getTranslationKey} from "../../../../../../common/helpers/getTranslationKey";
import {CountCampaignRecipientsParams} from "../../../../../api/countCampaignRecipients";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";

interface RecipientsTableProps extends CampaignViewDataState, CampaignViewDataActions {
    editMode: boolean;
}

const RecipientsTable: FunctionComponent<RecipientsTableProps> = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        refreshCampaignRecipients(false);
    }, [props.campaignRecipientsTableState.pageNumber, props.campaignRecipientsTableState.pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.campaign?.status === CampaignStatus.SENT) {
            refreshCampaignRecipients();
        }
    }, [props.campaign?.campaignRecipientsFilter?.recipientStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // save button was clicked
        if (!props.campaignDirty && recipientsAreOutdated()) {
            refreshCampaignRecipients();
        }
    }, [props.campaignDirty]); // eslint-disable-line react-hooks/exhaustive-deps
    
    if (!props.campaign) {
        return null;
    }

    const campaignRecipientsTableHeaderCells: TableHeaderCellModel[] = [
        {index: 0, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.APPLICANT'},
        {index: 1, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.STATUS'},
        {index: 2, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.DATE'},
        {index: 3, translateKey: 'CAMPAIGNS_VIEW.TABLE_HEADERS.SSN'},
        {index: 4, translateKey: 'EMAIL'},
        {index: 5, translateKey: 'MOBILE_NUMBER'},
        {index: 6, translateKey: 'PRODUCT_TYPE'}
    ].concat(props.campaign.status === CampaignStatus.SENT && !props.editMode ? [{index: 7, translateKey: 'CAMPAIGNS_VIEW.TABLE_HEADERS.MESSAGE_STATUS'}] : [])

    const refreshCampaignRecipients = (resetPage = true) => {
        if (props.editMode) {
            const params: CountCampaignRecipientsPreviewParams = {
                filter: props.campaign!.campaignRecipientsFilter,
                campaignType: props.campaign!.type,
            }
            props.loadCampaignRecipientsPreview({
                ...props.campaignRecipientsTableState,
                ...params,
                pageNumber: resetPage ? 0 : props.campaignRecipientsTableState.pageNumber
            });
        } else if (props.campaign!.id) {
            const params: CountCampaignRecipientsParams = {
                campaignId: props.campaign!.id!,
                status: props.campaign?.campaignRecipientsFilter?.recipientStatus
            }
            props.loadCampaignRecipients({
                ...props.campaignRecipientsTableState,
                ...params,
                pageNumber: resetPage ? 0 : props.campaignRecipientsTableState.pageNumber
            });
        }
    }
    
    const recipientsAreOutdated = () => {
        return JSON.stringify(props.lastCampaignRecipientsFilter) !== JSON.stringify(props.campaign!.campaignRecipientsFilter);
    }
    
    return (
        <>
            <RefreshButton
                style={{position: 'absolute', right: '-42px'}}
                isLoading={props.isLoading}
                onClick={refreshCampaignRecipients}
            >
                {!props.isLoading && recipientsAreOutdated() &&
                    <div className="filter-indicator" style={{fontSize: '6px', top: '2px', right: '6px'}}><FontAwesomeIcon icon={faCircle} /></div>
                }
            </RefreshButton>
            <Table
                className="campaigns-table"
                hover={true}
                borderless={true}
                style={{marginBottom: '24px'}}
            >
                <TableHeader cells={campaignRecipientsTableHeaderCells}/>
                <TableRows<CampaignRecipient>
                    isLoading={props.isLoading}
                    rows={props.campaignRecipients || []}
                    renderRow={(campaignRecipient, index) => (
                        <tr 
                            key={`${index}-${campaignRecipient.personId}`}
                            onAuxClick={() => openLastApplication(navigate, campaignRecipient, true)}
                            onClick={e => openLastApplication(navigate, campaignRecipient, e.ctrlKey)}
                        >
                            <td>{campaignRecipient.firstName} {campaignRecipient.lastName}</td>
                            <td>{renderLoanStatusCell(campaignRecipient.loanStatus)}</td>
                            <td>{new Date(campaignRecipient.submittedDate).toLocaleDateString()}</td>
                            <td>{campaignRecipient.socialSecurityNumber}</td>
                            <td>{campaignRecipient.email}</td>
                            <td>{campaignRecipient.mobileNumber}</td>
                            <td><Translate id={getTranslationKey(campaignRecipient.productType, 'PRODUCT_TYPES')}/></td>
                            {props.campaign!.status === CampaignStatus.SENT && 
                                <td>
                                    <Translate id={getEnumTranslationKey(CampaignRecipientStatus, campaignRecipient.status, 'CAMPAIGN_RECIPIENT_STATUS')}/>
                                </td>
                            }
                        </tr>
                    )}
                />
            </Table>
            <TablePagination
                isLoading={props.isLoading}
                pageNumber={props.campaignRecipientsTableState.pageNumber}
                pageSize={props.campaignRecipientsTableState.pageSize}
                onPagePicked={(pageNumber) => props.setPageNumber(pageNumber, 'recipients')}
                onPageSizePicked={(pageSize) => props.setPageSize(pageSize, 'recipients')}
                count={props.campaignRecipientsCount}
                pickableSizes={[20, 50, 100, 250, 500]}
            />
        </>
    )
}

const renderLoanStatusCell = (statusName: string) => {
    const statusValue: LoanStatus = LoanStatus[beautifyName(statusName, true) as keyof LoanStatus];
    const statusClassName = `status${loanStatusToColor(statusValue, ' status-')}`;
    return (
        <span className={statusClassName}>
            <Translate id={getLoanStatusTranslateKey(statusValue)} />
        </span>
    );
}

const openLastApplication = (navigate: NavigateFunction, campaignRecipient: CampaignRecipient, newTab: boolean) => {
    const path = `/applicant/${campaignRecipient.personId}/application/${campaignRecipient.applicationId}`;
    if (newTab) {
        window.open(path, '_blank');
    } else {
        navigate(path);
    }
}


const mapStateToProps = (state: any) => ({
    ...state.campaignViewDataReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...CampaignViewDataActionCreators
}, dispatch);

export default connect<CampaignViewDataState, CampaignViewDataActions>(mapStateToProps, mapActionCreatorsToProps)(RecipientsTable);