import { faBars, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { UserProps } from '../../../common/interfaces/UserProps';
import { connect } from 'react-redux';
import { Role } from '../../../common/models/Role';
import ActionIcon from '../../../common/components/ActionIcon';

interface ActionMenuProps {
    newAppliaction(): void;
    exportCustomers(): void;
    exportApplications(): void;
}

const ActionMenu: FunctionComponent<ActionMenuProps & UserProps> = (props: ActionMenuProps & UserProps) => {
    if (props.userData && props.userData.user && props.userData.user.role >= Role.Admin) {
        return renderMenu(props);
    }

    const addIconStyle = {
        marginRight: 12,
        marginTop: 5
    };

    return <div style={addIconStyle}><ActionIcon icon={faPlusSquare} tooltipKey="NEW_APPLICATION" action={props.newAppliaction} /></div>;
};

function renderMenu(props: ActionMenuProps) {
    return (
        <Dropdown drop="down" align="end">
            <Dropdown.Toggle id="applicants-menu" className="btn-white-bg">
                <FontAwesomeIcon icon={faBars} />
            </Dropdown.Toggle>
            <div className="dropdown-arrow-down" />
            <Dropdown.Menu>
                <Dropdown.Item key="new-application" onClick={props.newAppliaction}>
                    <Translate id="NEW_APPLICATION" />
                </Dropdown.Item>
                <Dropdown.Divider key="applicant-actions-divider-1" />
                <Dropdown.Item key="export-customers" onClick={props.exportCustomers}>
                    <Translate id="EXPORT_CUSTOMERS" />
                </Dropdown.Item>
                <Dropdown.Item key="export-applications" onClick={props.exportApplications}>
                    <Translate id="EXPORT_APPLICATIONS" />
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

const mapStateToProps = (state: any) => ({ ...state.userActionsReducer });

export default connect<UserProps, {}, ActionMenuProps, any>(mapStateToProps)(ActionMenu);
