export const getTranslationKey = (name: string, prefix?: string) => {
    const camelCased = name.slice(0, 1).toLowerCase() + name.slice(1);
    const key = camelCased
        .split(/([A-Z][a-z]+)/)
        .reduce((result, word) => {
            if (word) {
                return result ? result.concat('_', word) : word;
            }
            return result;
        })
        .replaceAll(' ', '_')
        .toUpperCase();

    if (prefix) {
        return  `${prefix}.${key}`;
    }

    return key;
};

export const getEnumTranslationKey = (enumObject: any, value: string|number|null|undefined, prefix: string, defaultKey?: string): string => {
    if (value === null || value === undefined) {
        return `${prefix}.${defaultKey}`;
    }

    return getTranslationKey(enumObject[value], prefix);
};
