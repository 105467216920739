import { faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { ManageableComponentProps } from '../../../common/interfaces/ManageableComponentProps';
import ActionIcon from '../../../common/components/ActionIcon';

interface EditIconsProps<T> extends ManageableComponentProps<T> {
    editMode: boolean;
    data: T;
}

const EditIcons = <T extends ({} | null)>(props: EditIconsProps<T>) => {
    const [saveInProgress, setSaveInProgress] = useState(false);
    const saveChangesCallback = () => saveChanges(props, setSaveInProgress);
    const discardChangesCallback = () => props.exitedEditCallBack(true);

    if (props.canEnterEdit || props.editMode) {
        if (props.editMode) {
            return (
                <React.Fragment>
                    <ActionIcon action={saveChangesCallback} icon={faSave} tooltipKey="SAVE" disabled={saveInProgress}/>
                    <ActionIcon action={discardChangesCallback} icon={faTimesCircle} tooltipKey="CANCEL" disabled={saveInProgress}/>
                </React.Fragment>
            );
        }
    }

    return null;
};

export function useEditIcons<T>(props: ManageableComponentProps<T>, data: T): EditIconsProps<T> {
    const [editMode, setEditMode] = useState(false);

    return {
        editMode,
        data,
        canEnterEdit: props.canEnterEdit,
        enteredEditCallBack: () => { setEditMode(true); props.enteredEditCallBack(); },
        exitedEditCallBack: (cancelled: boolean) => { setEditMode(false); props.exitedEditCallBack(cancelled); },
        saveCallBack: props.saveCallBack
    };
}

function saveChanges<T>(props: EditIconsProps<T>, setSaveInProgress: React.Dispatch<React.SetStateAction<boolean>>) {
    setSaveInProgress(true);
    props.saveCallBack(props.data)
        .then((validationErrors) => {
            setSaveInProgress(false);
            if ((validationErrors || []).length === 0) {
                props.exitedEditCallBack(false);
            }
        });
}

export default EditIcons;
