import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import LoadingBar from 'react-top-loading-bar';
import { bindActionCreators, Dispatch } from 'redux';

export interface LoadingBarWrapperProps {
    pendingRequests: number;
}

interface LoadingBarWrapperState {
    progress: number;
}

const LoadingBarWrapper: FunctionComponent<LoadingBarWrapperProps> = (props) => {
    const [state, setState] = useState<LoadingBarWrapperState>({ progress: 0 });
    const initialPendingRequests = useRef<number>(props.pendingRequests);
    
    useEffect(() => {
        if (props.pendingRequests === initialPendingRequests.current) {
            return;
        }
        if (props.pendingRequests > 0) {
            if (state.progress === 0) {
                setState({ progress: 20 });
            } else if (props.pendingRequests > initialPendingRequests.current && state.progress < 50) {
                setState((state) => ({ progress: state.progress + 10 }));
            } else if (props.pendingRequests < initialPendingRequests.current && state.progress < 90) {
                setState((state) => ({ progress: state.progress + 10 }));
            }
        } else {
            setState({ progress: 100 });
        }
        initialPendingRequests.current = props.pendingRequests;
    }, [props.pendingRequests]); // eslint-disable-line react-hooks/exhaustive-deps

    const onLoaderFinished = () => {
        setState({ progress: 0 });
    }

    return (
        <div style={{ position: 'absolute' }}>
            <LoadingBar
                progress={state.progress}
                color="#559AF4"
                onLoaderFinished={onLoaderFinished}
            />
        </div>
    );
}

LoadingBarWrapper.defaultProps = {
    pendingRequests: 0
}

export default connect<LoadingBarWrapperProps, {}, {}, any>(
    (state: any) => state.loadingBarWrapperReducer,
    (dispatch: Dispatch) => bindActionCreators({}, dispatch)
)(LoadingBarWrapper);
