import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

export const BisnodeIcon: FunctionComponent = (props) => {
    return (
        <span style={{ fontSize: '8px', lineHeight: '20px', verticalAlign: 'middle', marginLeft: '10px' }}>
            <span style={{ color: '#329e98' }}>
                <FontAwesomeIcon icon={faCircle} />
            </span>
            <span style={{ color: '#ffa426', marginLeft: '2px' }}>
                <FontAwesomeIcon icon={faCircle} />
            </span>
            <span style={{ color: '#d53868', marginLeft: '2px' }}>
                <FontAwesomeIcon icon={faCircle} />
            </span>
        </span>
    );
};
